<app-dashboard-sidemenu></app-dashboard-sidemenu>


<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">


	<app-dashboard-navbar></app-dashboard-navbar>


	<!-- Breadcrumb Area -->
	<div *ngFor="let Content of breadcrumb;" class="breadcrumb-area">
		<h1>{{Content.title}}</h1>
		<ol class="breadcrumb">
			<li class="item">
				<a routerLink="/">Home</a>
			</li>
			<li class="item">
				<a routerLink="/dashboard">
					{{Content.subTitle}}
				</a>
			</li>
			<li class="item">{{Content.title}}</li>
		</ol>
	</div>
	<!-- End Breadcrumb Area -->
	<div class="text-right add-listings-btn" *ngIf="role.roleName === 'Admin' || role.checker1 == true">
		<div class="form-group">
			<button type="submit" class="submit_btn m-0" routerLink="/tool-loan" style="margin-right:5px !important;">
				Add
			</button>
			<button type="submit" class="submit_btn m-0" (click)="sendToolListId()">
				Send to Supplier
			</button>
		</div>
	</div>
	<div class="text-right add-listings-btn" *ngIf="role.roleName === 'Supplier'">
		<div class="form-group">
			<button type="submit" class="submit_btn m-0" style="margin-right:5px !important;" (click)="downloadFile()">
				Download
			</button>
			<button type="submit" class="submit_btn m-0" (click)="uploadDailog()">
				Upload
			</button>
		</div>
	</div>
	<!-- <p-table #dt2 [value]="supplierEnquiryData" dataKey="supplierId" [rows]="10" responsiveLayout="scroll"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true"
        currentPageReportTemplate="Showing {first} to
        {last} of {totalRecords} entries" [filterDelay]="0" [globalFilterFields]="['mobileNo', 'enquiryNo' , 'supplierName' ,
        'itemName']">
        <ng-template pTemplate="caption">
            <span class="p-input-icon-left d-flex align-items-center">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                    'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
                <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
            </span>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th>S.No.</th>
                <th>Action</th>
                <th>Mail Status</th>
                <th>Enquiry No.</th>
                <th>Enquiry Date</th>
                <th>Part Number</th>
                <th>User Name</th>
                <th>Supplier Name</th>
                <th>Mobile No</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer let-i="rowIndex">
            <tr class="p-selectable-row">
                <td>{{i+1}}</td>
                <td class="d-flex" style="align-items: center;">
                    <button pButton pRipple type="button" (click)="getToolLoan(customer)" pTooltip="Add Documents"
                        icon="bx bx-show" class="p-button-rounded p-button-primary p-button-text"></button>
                    <button pButton pRipple type="button" *ngIf="customer.toolLoanAgreementId == 0"
                        (click)="sendMail(customer)" pTooltip="Send Mail" icon="bx bxs-send"
                        class="p-button-rounded p-button-primary p-button-text"></button>
                </td>
                <td style="padding: 0.1rem 0.4rem!important;">
                    <button pButton pRipple type="button" *ngIf="customer.toolLoanAgreementId > 0"
                        icon="bx bx-check-circle" class="p-button-rounded p-button-success  p-button-text"></button>
                    <button pButton pRipple type="button" *ngIf="customer.toolLoanAgreementId == 0"
                        icon="pi pi-times-circle" class="p-button-rounded p-button-danger p-button-text"></button>
                </td>
                <td>{{customer.enquiryNo}}</td>
                <td>{{customer.enquiryDate | date}}</td>
                <td>{{customer.itemName}}</td>
                <td> <span>{{customer.userName ?? '-'}}</span></td>
                <td> <span>{{customer.supplierName }}</span></td>
                <td>
                    <span class="image-text">{{customer.mobileNo}}</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No customers found.</td>
            </tr>
        </ng-template>
    </p-table> -->


	<p-table #dt2 [value]="ToolListData" [rows]="10" responsiveLayout="scroll" [showCurrentPageReport]="true"
		[rowsPerPageOptions]="[10,20,30,50]" [paginator]="true" currentPageReportTemplate="Showing {first} to
    {last} of {totalRecords} entries" [filterDelay]="0"
		[globalFilterFields]="['businessUnit', 'toolingCustodyName','currencyName' ,
    'itemName','value','s.no','action','active','inactive','buName','businessUnitName','poType','poNo','partNoName','toolDescription','location','toolLoanAgreementSignedornot','remarks','Shift To Supplier/Location','Shift From Supplier/Location','Capital/Expense']">
		<ng-template pTemplate="caption">
			<span class="p-input-icon-left d-flex align-items-center">
				<i class="pi pi-search"></i>
				<input [(ngModel)]="filterval" (input)="dt2.filterGlobal($event.target.value,
                'contains')" pInputText type="text" placeholder="Global Search">
				<p-button (click)="reset(dt2)" type="button" class="ml-2" icon="bx bx-reset"></p-button>
			</span>
		</ng-template>


		<ng-template pTemplate="header">
			<tr>
				<th>S.No.</th>
				<th>Action</th>
				<th style="min-width: 160px">Status</th>
				<th>Active</th>
				<th>BU</th>
				<th>Business Unit</th>
				<th>PO Type</th>
				<th>PO No.</th>
				<th>Part/Drawing No.</th>
				<th>Tool Description</th>
				<th>Currency</th>
				<th>Value</th>
				<th>Tooling Custody</th>
				<th>Location</th>
				<th>
					Tool Loan Agreement Signed or Not
				</th>
				<!-- <th>Link for Tool Loan Agreement</th> -->
				<th>
					IDEX Asset No./Unique ID No.
				</th>
				<th>Capital/Expense</th>
				<th>Die Shift Yes/No</th>
				<th>
					Shift From Supplier/Location
				</th>
				<th>
					Shift To Supplier/Location
				</th>
				<th>Remarks</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-tool let-i="rowIndex">
			<tr class="p-selectable-row">
				<td>{{i+1}}</td>
				<!-- <td class="d-flex" style="align-items: center;">
                 <button pButton pRipple type="button" (click)="getToolLoan(customer)" pTooltip="Add Documents"
                    icon="bx bx-show" class="p-button-rounded p-button-primary p-button-text"></button> 
                 <button pButton pRipple type="button" *ngIf="customer.toolLoanAgreementId == 0"
                    (click)="sendMail(customer)" pTooltip="Send Mail" icon="bx bxs-send"
                    class="p-button-rounded p-button-primary p-button-text"></button> 
            </td>
             <td style="padding: 0.1rem 0.4rem!important;">
                <button pButton pRipple type="button" *ngIf="customer.toolLoanAgreementId > 0"
                    icon="bx bx-check-circle" class="p-button-rounded p-button-success  p-button-text"></button>
                <button pButton pRipple type="button" *ngIf="customer.toolLoanAgreementId == 0"
                    icon="pi pi-times-circle" class="p-button-rounded p-button-danger p-button-text"></button>
            </td> -->
				<!-- <td>{{tool.toolListId}}</td> -->
				<td>
					<div class="delete-checkbox-onerow" style="display: flex; align-items: center;">
						<button *ngIf="roleName == 'Admin'" (click)="confirm1(tool.toolListId)" pButton pRipple
							type="button" icon="bx bx-trash" class="p-button-rounded p-button-danger
                    p-button-text"></button>
						<ng-container *ngIf="role.roleName === 'Admin' || role.roleName === 'Finance' || role.checker1 == true || role.roleName == 'Supplier'">
							<input type="checkbox"
								(change)="toolListCheckId($event,tool.toolListId,tool.toolingCustody,tool.toolingCustody)"
								*ngIf="tool.capital ==='capital' && tool.toolLoanAgreementSigned === 'yes' || tool.isApproved == true"
								[disabled]="true">
							<input type="checkbox"
								(change)="toolListCheckId(tool.toolListId,tool.toolingCustody,tool.toolingCustody)"
								*ngIf="tool.capital && tool.toolLoanAgreementSigned === 'no'"
								[(ngModel)]="toolListChecked[i]">
						</ng-container>
						<!-- <button pButton pRipple type="button" (click)="navigateToolForm(tool.toolListId)"
							icon="bx bx-show" class="p-button-rounded p-button-primary
						p-button-text" *ngIf="role.roleName === 'Finance' || role.checker1 == true"></button> -->
						<ng-container *ngIf="role.checker1 == true">
							<button pButton pRipple icon="pi pi-external-link"
							  class="p-button-rounded p-button-info p-button-text" (click)="onAprrovPress(tool.toolListId,item, 'verify')"
							  pTooltip="Actions" tooltipPosition="bottom" ></button>
						  </ng-container>
					</div>


				</td>
				<td class="status">
				<span class="approved" *ngIf="tool.isApproved == true">
					Approved
				  </span>
				  <span class="rejected" *ngIf="tool.isRejected == true">
					Rejected
				  </span>
				  <span class="rejected" *ngIf="tool.isRejected == true">
					Rejected
				  </span>
				  <span class="inprogress" *ngIf="tool.isApproved == false && tool.isRejected == false">
					In Progress
				  </span>
				</td>
				<td *ngIf="tool.isActive == true">Active</td>
				<td *ngIf="tool.isActive == false">Inactive</td>
				<td>{{tool.buName}}</td>
				<td>
					{{tool.businessUnitName}}
				</td>
				<td>{{tool.poTypeName}}</td>
				<td>
					<span>{{tool.poNo}}</span>
				</td>
				<td>
					<span>{{tool.partNoName }}</span>
				</td>
				<td>
					<span>
						{{tool.toolDescription }}
					</span>
				</td>
				<td>
					<span>{{tool.currencyName }}</span>
				</td>
				<td>
					<span>{{tool.value }}</span>
				</td>
				<td>
					<span>
						{{tool.toolingCustodyName }}
					</span>
				</td>
				<td>
					<span>{{tool.location }}</span>
				</td>
				<td>
					<span>
						{{tool.toolLoanAgreementSigned }}
					</span>
				</td>
				<!-- <td> <span>{{tool.linkorToolLoanAgreement }}</span></td> -->
				<td>
					<span>
						{{tool.idexAssetNo }}
					</span>
				</td>
				<td>
					<span>{{tool.capital }}</span>
				</td>
				<td>
					<span>
						{{tool.dieShift == true ? 'Yes' : 'No' }}
					</span>
				</td>
				<td>
					<span>
						{{tool.shiftFSupplier }}
					</span>
				</td>
				<td>
					<span>
						{{tool.shiftTSupplier }}
					</span>
				</td>
				<td>
					<span>{{tool.remarks }}</span>
				</td>



			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="5">No data found.</td>
			</tr>
		</ng-template>
	</p-table>



	<div class="flex-grow-1"></div>


	<app-copyrights></app-copyrights>


</div>
<!-- End Main Content Wrapper Area -->










<p-dialog [header]="this.header" [(visible)]="displayToolLoan" [modal]="true" [style]="{width: '85vw'}"
	[draggable]="false" [resizable]="false">


	<!-- <div class=""> -->
	<div class="add-listings-box-breakup extra-wallet">
		<h6>Part Details</h6>
		<p-table #dt4 [value]="itemDetails" class="mb-2" dataKey="id" responsiveLayout="scroll">
			<ng-template pTemplate="header">
				<tr>
					<th>S.No.</th>
					<th>Part Number</th>
					<th>Part Description</th>
					<th>
						Material of Construction
					</th>
					<th>Material Remark</th>
					<th>
						Raw Part Weight (Kg)
					</th>
					<th>
						Finish Part Weight (Kg)
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-customer let-i="rowIndex">
				<tr>
					<td>{{i+1}}</td>
					<td>
						<span>
							{{customer?.itemName}}
						</span>
					</td>
					<td>
						<span>
							{{customer?.itemDesc}}
						</span>
					</td>
					<td>
						<span class="image-text">
							{{customer?.materialofconstructionList}}
						</span>
					</td>
					<td>
						<span class="image-text">
							{{customer?.materialRemark ? customer?.materialRemark : '-'}}
						</span>
					</td>
					<td>
						<span class="image-text">
							{{customer?.rawPartWeight}}kg
						</span>
					</td>
					<td>
						<span class="image-text">
							{{customer?.finishPartWeight}}kg
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5">No item found.</td>
				</tr>
			</ng-template>
		</p-table>


		<div class="row my-3">
			<div class="col-lg-4 form-group">
				<label for>Sample Document</label>
				<label for>
					<a [href]="this.allData?.documentPath">Document Preview</a>
				</label>
			</div>
			<div class="col-lg-4 form-group">
				<label for>
					Uploaded Signed Document
				</label>
				<label *ngIf="this.allData?.signedDocument" for>
					<a [href]="this.allData?.signedDocument">
						Uploaded
						Document
					</a>
				</label>
				<p *ngIf="!this.allData?.signedDocument && roleName != 'Supplier'" style="margin-left: 70px;">
					<span>-</span>
				</p>
				<p>
					<span *ngIf="!this.allData?.signedDocument && roleName == 'Supplier'">
						<input (change)="upload_doc($event , 'signed_doc')" type="file" class="form-control">
					</span>
				</p>
			</div>
			<div class="col-lg-3 form-group">
				<label for>FAA Report</label>
				<label *ngIf="this.allData?.faaDocument" for>
					<a [href]="this.allData?.faaDocument" target="_blank">
						Uploaded Document
					</a>
				</label>
				<input #faaDocument *ngIf="!this.allData?.faaDocument && roleName != 'Supplier'"
					(change)="upload_doc($event , 'faaDocument')" type="file" class="form-control">
				<span *ngIf="!this.allData?.faaDocument && roleName == 'Supplier'">-</span>
			</div>
		</div>
		<p-table #dt3 [value]="toolData" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]"
			[paginator]="false" dataKey="id" responsiveLayout="scroll" currentPageReportTemplate="Showing
            {first} to {last} of {totalRecords} entries">
			<ng-template pTemplate="header">
				<tr>
					<th>S. No.</th>
					<th>Tool Name</th>
					<th>Amount</th>
					<th>Tool Document</th>
					<th *ngIf="roleName == 'Supplier'">Action</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-customer let-i="rowIndex">
				<tr class="earnings-box">
					<td>{{i+1}}</td>
					<td class="form-group">
						<span class="form-control">
							{{customer.toolLoanAgreementName}}
						</span>
					</td>
					<td>
						<div style="display: flex;">
							<input [(ngModel)]="partDetails.cond2" [disabled]="true" type="text" class="form-control"
								style="width: 80px; border-radius: 5px;
                                line-height : 22px; display: flex; align-items:
                                center; border-right: none!important;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;">
							<input [(ngModel)]="customer.toolLoanAgreementAmount" [disabled]="true" pKeyFilter="money"
								type="text" class="form-control" style="border-left: none!important; width:
                                150px;
                                border-top-left-radius: 0;
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0">
						</div>
					</td>
					<td>
						<span *ngIf="!customer.isActive" class="form-group">
							<span *ngIf="roleName != 'Supplier' && !customer?.isActive">-</span>
							<span *ngIf="roleName == 'Supplier'">
								<input *ngIf="!customer?.isActive" (change)="upload_doc($event , 'toolDoc' , i)"
									type="file" class="form-control" multiple>
							</span>
						</span>
						<span *ngIf="customer.isActive">
							<span (click)="getImages(customer)" style="text-decoration: underline; cursor: pointer;">
								Preview
							</span>
						</span>
					</td>
					<td *ngIf="roleName == 'Supplier'">
						<button (click)="getToolDoc(i)" pButton pRipple type="button" label="Submit"
							class="p-button-rounded p-button-primary p-button-text"></button>
					</td>
				</tr>
				<tr *ngIf="i == toolData.length-1">
					<td></td>
					<td style="font-size: 15px;">
						<b>Total Tools Amount</b>
					</td>
					<td>
						<div style="display: flex; ">
							<input [disabled]="true" [(ngModel)]="partDetails.cond2" type="text" class="form-control"
								style="width:
                                80px; border-radius: 5px; line-height : 22px;
                                display: flex; align-items: center;
                                border-right: none!important;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;">
							<input [(ngModel)]="rfqToolTotal" [disabled]="true" pKeyFilter="money" type="text"
								class="form-control" style="border-left:
                                none!important; width: 150px;
                                border-top-left-radius: 0;
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0">
						</div>
					</td>
					<td></td>
					<td></td>
				</tr>
			</ng-template>
		</p-table>
	</div>


	<div class="add-listings-btn col-lg-4 col-md-6">
		<div class="form-group">
			<button (click)="submitFAAImages()" type="submit" class="submit_btn">
				Submit
			</button>
		</div>
	</div>
</p-dialog>


<p-dialog header="ToolList Buyer Request" [(visible)]="display" [modal]="true" [style]="{width: '80vw'}"
	[draggable]="false" [resizable]="false">
	<form [formGroup]="toolLoanForm" (ngSubmit)="submitForm()" #register="ngForm">
		<div class="add-listings-box1">
			<div class="row">
				<div class="col-lg-12 col-md-12 form-group">
					<label>Document Name<span class="star_class">*</span></label>
					<input class="form-control" type="text" placeholder="Document Name" autocomplete="off" formControlName="docName" [class.is-invalid]="register.submitted &&
					toolLoanForm.get('uploadDoc')?.invalid ||
					toolLoanForm.get('uploadDoc')?.invalid &&
					toolLoanForm.get('uploadDoc')?.touched" />
					<div class="invalid-feedback">Please Fill Document Name</div>

				</div>
				<div class="col-lg-12 col-md-12 form-group">
					<label>Document Path<span class="star_class">*</span></label>
					<input class="form-control" type="text" placeholder="Document Path" autocomplete="off" formControlName="docPath"  [class.is-invalid]="register.submitted &&
					toolLoanForm.get('uploadDoc')?.invalid ||
					toolLoanForm.get('uploadDoc')?.invalid &&
					toolLoanForm.get('uploadDoc')?.touched" />
					<div class="invalid-feedback">Please Fill Document Path</div>

				</div>
				<div class="col-lg-12 col-md-12 form-group">
					<label>Select Document<span class="star_class">*</span></label>
					<!-- <input class="form-control" type="file" formControlName="uploadDoc"
                    [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('uploadDoc')?.invalid ||
                        toolLoanForm.get('uploadDoc')?.invalid &&
                        toolLoanForm.get('uploadDoc')?.touched"
                     autocomplete="off" /> -->
					 <input type="file" class="form-control" [class.is-invalid]="(register.submitted &&
					 toolLoanForm.get('uploadDoc')?.invalid) ||
					 (toolLoanForm.get('uploadDoc')?.touched &&
					 toolLoanForm.get('uploadDoc')?.invalid)" (change)="getFile($event)" required>
                 <div class="invalid-feedback">Please Upload Document</div>

				</div>

			</div>
			<div class="form-group">
				<button type="submit" class="submit_btn">Submit</button>

			</div>
		</div>
	</form>
</p-dialog>



<p-dialog [style]="{'width' : '50vw'}" [(visible)]="displayImages" [modal]="true" [draggable]="false"
	[resizable]="false" header="Tool Name : {{toolName}}">
	<ng-container *ngFor="let drawing of toolImages; let index = index">
		<span style="font-size: 12px;" class="mx-2">
			{{index+1}}.
		</span>
		<a [href]="drawing.value" [download]="drawing.value" style="font-size: 12px;" target="_blank">
			{{_utility.getDrawingName(drawing.value)}}
		</a>
		<span *ngIf="roleName == 'Supplier'" (click)="deleteImage(drawing)" pToolTip="Delete Tool Image"
			style="cursor: pointer;">
			x
		</span>
		<br>
	</ng-container>
</p-dialog>
<p-dialog header="Actions" [breakpoints]="{'850px' : '90vw'}" [(visible)]="approveModalState" [modal]="true"
  [style]="{ width: '42vw' }" [baseZIndex]="10001" [draggable]="false" (onHide)="resetApprovalForm()"
  [resizable]="false" [closable]="true" [closeOnEscape]="true">
  <div class="add-listings-box">
    <form [formGroup]="approvalForm" (ngSubmit)="onSubmit(form)" #form="ngForm">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <label class="mb-3" for="">
            Remark/Reason<span class="required">*</span>:</label>
          <textarea type="text" placeholder="Remark/Reason" formControlName="ApprovedRemark" class="form-control mb-3"
            [class.is-invalid]="
              (form.submitted && approvalForm.get('ApprovedRemark')?.invalid) ||
              (approvalForm.get('ApprovedRemark')?.invalid &&
                approvalForm.get('ApprovedRemark').touched)
            ">
          </textarea>
          <div class="invalid-feedback" style="margin-top: 1.25rem">
            Remark/Reason is Required
          </div>
          <!-- <hr> -->
        </div>
        <div class="col-lg-12">
          <div class="add-listings-btn" style="text-align: right">
			<button class="mr-2 success-btn" type="submit" (click)="btnClick('aprrove')" style="background: #40b340; color: white;">
				Approve
            </button>
            <button class="mr-2 reject-btn" type="submit" (click)="btnClick('reject')" style="background: #e34747; color: white;">
              Reject
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>