import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';
@Component({
  selector: 'app-customer-audit-logs',
  templateUrl: './customer-audit-logs.component.html',
  styleUrls: ['./customer-audit-logs.component.scss']
})


export class CustomerAuditLogsComponent implements OnInit{
  breadcrumb = [
    {
      title: 'Customer Audit Logs',
      subTitle: 'Dashboard',
    },
  ];

  constructor(private _apiService : ApiServiceService,private _utility : AppUtility ,){

  }

  customerList : any = [];

  ngOnInit(){
   this.getAuditLogs();
  }
  getAuditLogs(){
    this._utility.loader(true);
    this._apiService.getAuditLogsOfCustomers()
    .then((res)=>{
      this._utility.loader(false);
      console.log(res);
      if (res['status']) {
        // Filter out duplicates based on crDate
        const uniqueCustomers = [];
        const customerMap = new Map(); // Using Map to keep track of customers by crDate
        res['returnValue'].forEach((customer, index) => {
            if (!customerMap.has(customer.crDate)) {
                customerMap.set(customer.crDate, index);
            } else {
                const existingIndex = customerMap.get(customer.crDate);
                // Replace the existing customer with the one having a larger index
                if (index > existingIndex) {
                    customerMap.set(customer.crDate, index);
                }
            }
        });
    
        // Extract unique customers using the indexes from customerMap
        customerMap.forEach((index, crDate) => {
            uniqueCustomers.push(res['returnValue'][index]);
        });
    
        this.customerList = uniqueCustomers;
    } else {
    
        this.customerList = [];
    }
    })
  }
}
