import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any, searchText: string): any[] {
    console.log(items , searchText)
    if(!items) return [];
    if(!searchText) return items;
    return items.filter( item => {
      console.log(item , searchText);
      return item.itemName.includes(searchText) || item.enquiryNo.includes(searchText);
    });
  }

}