import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { Message, ConfirmationService } from 'primeng/api';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-customer-upload-doc',
  templateUrl: './customer-upload-doc.component.html',
  styleUrls: ['./customer-upload-doc.component.scss']
})

export class CustomerUploadDocComponent implements OnInit {
  date: any;
  submitButton: string = 'Submit'
  manufactureTable: any = [];
  currentDate: any;
  loading: boolean = false;
  msgs: Message[] = [];
  documentList: any = [];
  userLogin: any;
  showDoc: any = false;

  constructor(private _apiService: ApiServiceService, private _utility: AppUtility, private confirmService: ConfirmationService) { }

  ngOnInit(): void {
    this.currentDate = new Date().toLocaleString();
    this.getStatic();
    this.getAllTableData();
    this.userLogin = JSON.parse(localStorage.getItem('UserObject'));
  }

  breadcrumb = [
    {
      title: 'Customer Additional Document',
      subTitle: 'Dashboard'
    }
  ]


  supplierList: any = [];
  async getStatic() {
    await this._apiService.getCustomer().then((res: any) => {
      if (res.success) {
        this.supplierList = res.returnValue;
      } else {
        this.supplierList = [];
      }
    })
    console.log('supplierList', this.supplierList);

    this.manufactureTable.forEach((obj) => {
      // Find the corresponding customerName from supplierList
      const supplier = this.supplierList.find((supplier) => supplier.customerId === obj.customerId);
  
      // If a matching supplier is found, push the customerName into the object
      if (supplier) {
          obj.customerName = supplier.customerName;
      } else {
          // If no matching supplier is found, you can handle it according to your requirements
          // For example, you can set a default value or throw an error
          obj.customerName = "Unknown";
          console.log('unknown')
      }
  });
  console.log('manufactureList',this.manufactureTable);
    await this._apiService.getDocument().then((res: any) => {
      if (res.success) {
        this.documentList = res.returnValue.filter(res => res.documentType == 'Customer');
      } else {
        this.documentList = [];
      }
    })

    let localStorage = this._utility.getLocalStorageDetails();
    if (localStorage.roleName == 'Customer') {
      this.roleName = 'Customer';
      this.manufactureProcessForm.controls['customerName'].setValue(localStorage?.supplierId);
      this.setcustomerCode();
    }
  }

  manufactureProcessForm = new FormGroup({
    customerName: new FormControl('', [Validators.required]),
    documentUpload: new FormControl('', [Validators.required]),
    documentName: new FormControl('', [Validators.required]),
    customerCode: new FormControl(''),
    invoiceNo: new FormControl(''),
    customerPONo: new FormControl(''),
    IsShow: new FormControl(''),
    orderNo: new FormControl('')
  })

  manufactureProcessFormSubmit(manufacture: FormGroupDirective) {
    console.log(this.manufactureProcessForm.value);
    if (this.manufactureProcessForm.valid) {
      this._utility.loader(true);
      const customerId = this.manufactureProcessForm.controls['customerName'].value
      let customerEmail
      let customerName
      let formData = new FormData();
      if(this.userLogin.roleName == 'Customer'){
        this.supplierList.forEach((supplier) => {
          if (supplier.customerId === customerId) {
            customerEmail = supplier.csTeam;
            customerName = supplier.customerName
            console.log(supplier);
            formData.append('EmailId',customerEmail)
            formData.append('ResourceName',customerName)
          }
        });
      }else{
        this.supplierList.forEach((supplier) => {
          if (supplier.customerId === customerId) {
            customerEmail = supplier.emailAddress;
            customerName = supplier.customerName
            console.log(supplier);
            formData.append('EmailId',customerEmail)
            formData.append('ResourceName',customerName)
          }
        });
      }
      formData.append('customerId', this.manufactureProcessForm.controls['customerName'].value)
      formData.append('documentName', this.manufactureProcessForm.controls['documentName'].value)
      formData.append('document', this.manufactureProcessForm.controls['documentUpload'].value)
      formData.append('JDECustNo', this.manufactureProcessForm.controls['customerCode'].value)
      formData.append('invoiceNo', this.manufactureProcessForm.controls['invoiceNo'].value)
      formData.append('customerPONo', this.manufactureProcessForm.controls['customerPONo'].value)
      formData.append('orderNumber', this.manufactureProcessForm.controls['orderNo'].value)
      formData.append('IsShow', this.showDoc)
      if (this.submitButton == 'Submit') {
        this._apiService.addCustomerDocument(formData).then((res: any) => {
          this._utility.loader(false);
          if (res.success == true) {
            this._apiService.showMessage(res.message, 'success')
            window.scroll(0, 0)
            this.manufactureProcessForm.reset();
            // ;          
            Object.keys(this.manufactureProcessForm.controls).forEach(key => {
              this.manufactureProcessForm.controls[key].setErrors(null)
            });
            manufacture.resetForm();
          }
          else {
            this._apiService.showMessage(res.message, 'success');
          }
          this.getAllTableData();
          this.getStatic()
        })
      }
      else {
        formData.append('customerDocumentId', this.editItemId)
        this._apiService.editCustomerDocument(formData).then((res: any) => {
          this._utility.loader(false);
          if (res.success == true) {
            this._apiService.showMessage(res.message, 'success');
            this.manufactureProcessForm.reset();
            Object.keys(this.manufactureProcessForm.controls).forEach(key => {
              this.manufactureProcessForm.controls[key].setErrors(null)
            });
            manufacture.resetForm();
            this.submitButton = 'Submit'
            let localStorage = this._utility.getLocalStorageDetails();
            this.getAllTableData();
            this.getStatic();
          }

          else {
            this._apiService.showMessage(res.message, 'error');
          }
        })

        if (localStorage.roleName == 'Customer') {
          this.roleName = 'Customer';
          this.manufactureProcessForm.controls['customerName'].setValue(localStorage?.supplierId);
          this.setcustomerCode();
        }
      }
    }
  }

  editItemId: any;
  EditItem(customer: any) {
    if (customer.supplierDocumentId) {
      Object.keys(this.manufactureProcessForm.controls).forEach(key => {
        this.manufactureProcessForm.controls[key].setValue(customer[key]);
      });
      this.submitButton = 'Update'
      this.editItemId = customer.supplierDocumentId;
    }
  }

  roleName: any = '';
  getAllTableData() {
    this._utility.loader(true);
    this._apiService.getCustomerDocument('false')
      .then((res: any) => {
        this._utility.loader(false);
        if (res.success == true) {
          this.manufactureTable = res.returnValue;
        }
        else {
          this.manufactureTable = [];
        }
      })
      .catch((error: any) => {
        this._utility.loader(false);
        this.manufactureTable = [];
      })

  }

  confirm1(costBreakupId: any) {
    this.confirmService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Delete Manufacture Record',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteItem(costBreakupId);
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  deleteItem(itemId: any) {
    this._utility.loader(true);
    this._apiService.deleteCustomerDocument(itemId)
      .then((res: any) => {
        this._utility.loader(false);
        console.log(res);
        if (res.success == true) {
          this._apiService.showMessage(res.message, 'success');
          this.getAllTableData();
        }

        else {
          window.scroll(0, 0);
          this._apiService.showMessage(res.message, 'error');
          this.getAllTableData();
        }

      })
  }

  getFile(event) {
    let file = this._utility.onFileChange(event);
    this.manufactureProcessForm.controls['documentUpload'].setValue(file);
  }

  setcustomerCode() {
    let localStorage = this._utility.getLocalStorageDetails();
    let supplierId = this.manufactureProcessForm.controls['customerName'].value;
    let object = this.supplierList.filter(res => res.customerId == supplierId);

    if (object[0]?.customerCode) {
      this.manufactureProcessForm.controls['customerCode'].setValue(object[0]?.customerCode);
      if (localStorage.roleName != 'Customer-Approval') {
        this.manufactureProcessForm.controls['customerCode'].setValidators([Validators.required,]);
        this.manufactureProcessForm.controls['customerCode'].updateValueAndValidity();
      }
    } else {
      this._apiService.showMessage('document cannot be uploaded because you are not registered in JDE', 'warn');
      this.manufactureProcessForm.controls['customerCode'].setValue('');
      if (localStorage.roleName == 'Customer-Approval') {
        this.manufactureProcessForm.controls['customerCode'].clearValidators();
        this.manufactureProcessForm.controls['customerCode'].updateValueAndValidity();
      }
    }
  }
  
  ShowtoCustomer(event: any) {
    console.log(event.target.checked)
    if (event.target.checked) {
      this.showDoc = true;
    } else {
      this.showDoc = false;
    }
  }
  setFieldValues() {
    this.submitButton = 'Submit';
    if (localStorage.roleName == 'Customer') {
      this.roleName = 'Customer';
      this.manufactureProcessForm.controls['customerName'].setValue(localStorage?.supplierId);
      this.setcustomerCode();
    }
  }
}