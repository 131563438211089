<!-- <img src="../../../../../assets/img/Group 1.svg" alt="" style="width: 100%; height: 100vh;"> -->
<!-- <div class="background-image">
    <div class="form-UI">
        <div class="login-form-UI">
            <div style="text-align: center;">
                <h4>Login to your account</h4>
            </div>
            <form class="mt-4">
                <div class="row m-0">
                    <div class="col-lg-12 mt-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-user" style="color: #708090"></i>
                            <input type="text" pInputText class="input-username" placeholder="Username"  />
                        </span>
                    </div>
                    <div class="col-lg-12 mt-4">
                        <span class="p-input-icon-left">
                            <i class="pi pi-lock" style="color: #708090"></i>
                            <input type="text" pInputText class="input-username" placeholder="Password"  />
                        </span>
                    </div>
                    <div class="submit-button mt-4">
                        <button type="submit" class="submit_btn">Login Now</button>
                    </div>
                </div>
            </form>
            
        </div>
        <div class="logos">
            <h4>Take a look at our other Bussinesses</h4>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
    </div>
</div> -->


<div class="background">
    <!-- background images -->
    <!-- <div class="left-img">
        <img src="../../../../../assets/img/loginForm/left.png" alt="image" loading="lazy">
    </div>
    <div class="left-bottom">
        <img src="../../../../../assets/img/loginForm/left-bottom.png" alt="image" loading="lazy">
    </div>
    <div class="right-img">
        <img src="../../../../../assets/img/loginForm/right.png" alt="image" loading="lazy">
    </div> -->
    <!-- background images end-->

    <!-- logo image -->
    <div class="logo-container">
        <img src="../../../../../assets/img/loginForm/idex.png" alt="Idex" loading="lazy">
    </div>
    <!-- main container -->
    <div class="form-container">
        <!-- form container -->
        <div class="login-form vesax-login">
            <h1 class="form-heading">Login to your account</h1>
            <!-- <p class="heading-content">How to i get started lorem ipsum dolor </p> -->
            <form [formGroup]="userFormControl" (ngSubmit)="signinWithEmail()" #signin="ngForm" class="form">
                <div class="input-container">
                    <img src="../../../../../assets/img/loginForm/Frame.png" alt="username" class="user-icons"
                        loading="lazy">
                    <input type="email" class="form-input form-control" id="emailAddress" autocomplete="off"
                        placeholder="Username" formControlName="emailAddress"
                        [class.is-invalid]="signin.submitted &&
                    userFormControl.get('emailAddress')?.invalid || userFormControl.get('emailAddress')?.touched && userFormControl.get('emailAddress')?.invalid" required>
                    <div class="invalid-feedback" *ngIf="userFormControl.controls.emailAddress?.errors?.required">
                        Username is Required
                    </div>
                </div>
                <div class="input-container">
                    <img src="../../../../../assets/img/loginForm/password.svg" alt="username" class="user-icons"
                        loading="lazy">
                    <input type="password" class="form-input form-control" placeholder="Password" id="password"
                        autocomplete="off" formControlName="password"
                        [class.is-invalid]="signin.submitted &&
                    userFormControl.get('password')?.invalid || userFormControl.get('password')?.touched && userFormControl.get('password')?.invalid" required>
                    <div class="invalid-feedback">
                        Password is Required
                    </div>
                </div>
                <button class="submit-button submit_login">Login Now</button>
                <div class="forgot-password">
                    <span style="font-size: 15px;">Forgot Your Password ? <a routerLink="/reset-password">Forgot Password</a></span>
                    </div>
            </form>
        </div>

        <!-- logo conitainer -->
        <div class="right-container">

            <h2 class="form-heading">Greeting from IDEX
            </h2>
            <p class="content heading-content">IDEX is a leading global manufacturer of highly engineered
                industrial products and technologies such as industrial
                pumps & valves, compressors, flow meters, milling equipment, dispensers, firefighting tools and rescue
                equipment,

                spare parts, services and accessories.</p>
            <div class="idex-logo">

                <div class="grid-container row-cols-5 logos">
                    <div class="bg-img">
                        <img src="../../../../../assets/img/loginForm/bg.png" alt="image" loading="lazy">
                    </div>
                    <!-- column-1 -->
                    <div class="  column ">
                        <img src="../../../../../assets/img/loginForm/image 9.png" alt="Vicking pumps">
                    </div>
                    <!-- column-2 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 2.png" alt="Vicking pumps">
                    </div>
                    <!-- column-3 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 3.png" alt="Vicking pumps">
                    </div>
                    <!-- column-4 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 4.png" alt="Vicking pumps">
                    </div>
                    <!-- column-5 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 5.png" alt="Vicking pumps">
                    </div>
                    <!-- column-6 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 6.png" alt="Vicking pumps">
                    </div>
                    <!-- column-7 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 7.png" alt="Vicking pumps">
                    </div>
                    <!-- column-8 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 8.png" alt="Vicking pumps">
                    </div>
                    <!-- column-9 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image7.png" alt="Vicking pumps">
                    </div>
                    <!-- column-10 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 10.png" alt="Vicking pumps">
                    </div>
                    <!-- column-11 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 11.png" alt="Vicking pumps">
                    </div>
                    <!-- column-12 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 12.png" alt="Vicking pumps">
                    </div>
                    <!-- column-13 -->
                    <div class="  column">
                        <img src="../../../../../assets/img/loginForm/image 13.png" alt="Vicking pumps">
                    </div>
                    <!-- column-14 -->
                    <div class="aws  column">
                        <img src="../../../../../assets/img/loginForm/image 14.png" alt="Vicking pumps">
                    </div>
                    <!-- column-15 -->
                    <div class=" column">
                        <img src="../../../../../assets/img/loginForm/image 15.png" alt="Vicking pumps">
                    </div>
                    <!-- column-16 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/fit.jpg" alt="Vicking pumps">
                    </div>
                    <!-- column-17 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/fm.jpg" alt="Vicking pumps">
                    </div>
                    <!-- column-19 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/img.jpg" alt="Vicking pumps">
                    </div>
                    <!-- column-20 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/lq.png" alt="Vicking pumps">
                    </div>
                    <!-- column-21 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/mat.jpg" alt="Vicking pumps">
                    </div>
                    <!-- column-22 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/mf.jpg" alt="Vicking pumps">
                    </div>
                    <!-- column-23 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/quadro.png" alt="Vicking pumps">
                    </div>
                    <!-- column-24 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/sampi.png" alt="Vicking pumps">
                    </div>
                    <!-- column-25 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/sp.jpg" alt="Vicking pumps">
                    </div>
                    <!-- column-26 -->
                    <div class=" column">
                        <img src="assets/img/loginForm/warren.jpg" alt="Vicking pumps">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>