<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <p-accordion (onOpen)="getOrderById($event)">
        <ng-container *ngFor="let data of supplierOrders">
            <p-accordionTab header="Enquiry No : {{data.enquiryNo}} &nbsp;&nbsp;&nbsp; Enquiry Date : {{data.enquiryDate | date}}">
              <p-table [value]="orderItemList" dataKey="id" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="itemName" style="min-width: 190px;">Part Number<p-sortIcon field="itemName"></p-sortIcon></th>
                        <th pSortableColumn="supplierName" style="min-width: 190px;">Assigned Supplier<p-sortIcon field="supplierName"></p-sortIcon></th>
                        <th pSortableColumn="orderQuantity" style="min-width: 190px;">Order Quantity<p-sortIcon field="orderQuantity"></p-sortIcon></th>
                        <th pSortableColumn="quantity" style="min-width: 190px;">Quantity<p-sortIcon field="quantity"></p-sortIcon></th>
                        <th pSortableColumn="orderStatus" style="min-width: 190px;">Order Acknowledge Status<p-sortIcon field="orderStatus"></p-sortIcon></th>
                        <th pSortableColumn="orderDocument" style="min-width: 190px;">Order Document<p-sortIcon field="orderDocument"></p-sortIcon></th>
                        <th pSortableColumn="orderRemark" style="min-width: 190px;">Order Remark<p-sortIcon field="orderRemark"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order>
                    <tr>
                        <td style="padding: 0.5rem 1rem!important;">{{order.itemName}}</td>
                        <td style="padding: 0.5rem 1rem!important;">{{order.supplierName}}</td>
                        <td style="padding: 0.5rem 1rem!important;">{{order.orderQuantity}}</td>
                        <td style="padding: 0.5rem 1rem!important;">{{order.quantity}}</td>
                        <td style="min-width: 360px;"><span [class]="'product-badge status-' + order.orderStatus" (click)="openModel(order)">{{order.orderStatus == '' ? 'Pending for Order Acknowledgement' : order.orderStatus}}</span></td>
                        <td style="padding: 0.5rem 1rem!important;"><span *ngIf="order.orderDocument"><a [href]="order.orderDocument" target="_blank" [download]="order.orderDocument">Preview</a></span><span *ngIf="!(order.orderDocument)"></span></td>
                        <td style="padding: 0.5rem 1rem!important; min-width: 300px;">{{order.orderRemark}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">There are no order for this product yet.</td>
                    </tr>
                </ng-template>
            </p-table>
            </p-accordionTab>
        </ng-container>
    </p-accordion>

    <app-copyrights></app-copyrights>
</div>

<p-dialog header="Order Acknowledgement" [(visible)]="display" [modal]="true"
    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <div class="row">
        <div class="col-lg-12 form-group">
            <label for="">Document Upload</label>
            <input type="file" class="form-control" (change)="getFile($event)">
        </div>
        <!-- <div class="col-lg-6 form-group">
            <label for="">Confirm Order</label>
            <select name="" id="" class="form-group form-select form-control" [(ngModel)]="confirmOrder.orderStatus">
                <option value="">Pending</option>
                <option value="Acknowledged">Acknowledge</option>
            </select>
        </div> -->

        <div class="col-lg-12 form-group">
            <label for="">Supplier Remark</label>
            <textarea name="" class="form-control" id="" cols="30" rows="10" [(ngModel)]="confirmOrder.orderRemark"></textarea>
        </div>

        <div class="add-listings-btn">
            <button type="submit" class="submit_btn" (click)="submitOrderStatus()">Acknowledge</button>
        </div>
    </div>
</p-dialog>