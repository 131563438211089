import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { from } from 'rxjs';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';
import { md5 } from 'src/md5';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {
  stateDropdown: any = [];
  countryDropdown: any = [];
  paymentDropdown: any = [];
  purposeDropdown: any = [];
  customerCategory: any = [];
  isCurrencyDisabled = false;
  customerType: any = [
    { name: 'Distributor', id: 1 },
    { name: 'End/Direct Customer', id: 2 },
    { name: 'Agent', id: 3 }
  ]
  addressType: any = [
    { name: 'Ship To Details' },
    { name: 'Sold To Details' }
  ]
  incotermsDropdown: any = [];
  applicationTypeDropdown: any = [];
  industryDropdown: any = [];
  selectManufacturingData: any = [];
  submitButton: string = 'Submit';
  selectMaterialListData: any = [];
  showRegisterForm: boolean = false;
  nextWizard: boolean = false;
  myDate: Date;
  futureYears: number;
  showSuccess: boolean = false;
  userName: string = '';
  @ViewChild('cancelCheque') cancelCheque: ElementRef;
  @ViewChild('panUpload') panUpload: ElementRef;
  @ViewChild('gstUpload') gstUpload: ElementRef;
  customerDetails: any;
  marketSegmentDropdown: any;
  marketSubSegmentDropdown: any;
  customerBuDropdown: any;
  branchPlantDropdown: any;
  isGST: boolean = true;
  isShipToDetails: boolean = false;
  selectedIndustry: string = '';
  showGST: boolean = true;
  showOtherCityBoolean: boolean = false;
  showOtherCityShipBoolean: boolean = false;
  constructor(
    private _apiService: ApiServiceService,
    private activate: ActivatedRoute,
    private route: Router,
    public _utility: AppUtility,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.futureYears = new Date().getFullYear();
    this.myDate = new Date();
    let slug = this.activate.snapshot.params;
    if (localStorage.getItem('UserObject')) {
      this.userLogin = JSON.parse(localStorage.getItem('UserObject'));
    }

    this.getState();
    if (slug.action == 'create') {
      if (localStorage.getItem('showCustomerRegister')) {
        let showForm = localStorage.getItem('showCustomerRegister');
        if (showForm) {
          this.addContactRow('add', 0, slug.slug);
          this.userName = JSON.parse(localStorage.getItem('customerUserName')).userName;
        }

        else {
          this.route.navigateByUrl('verification/Customer');
        }
      }

      else {
        this.route.navigateByUrl('verification/Customer');
      }
    }


    else {
      this.submitButton = slug.action == 'edit' ? 'Update' : 'Submit';
      this._apiService.getCustomer(slug.slug).then((res: any) => {
        if (res.success) {
          console.log(res)
          this.customerDetails = res.returnValue;
          this.setCustomerFormId();
          
        } else {
          this.route.navigateByUrl('/customer-list');
        }

        if(this.customerDetails.micrNo == 'Ship To Details'){
          this.isShipToDetails = true;
        }
      });
      console.log(this.customerDetails.micrNo);
      
    }
  }

  documentList: any = {};
  editCustomerId: any;
  userLogin: any;
  
  isApproved: string = '';
  isVerify: string = '';
  password: string = '';
  setCustomerFormId() {
    this.editCustomerId = this.customerDetails?.customerId;
    this.isApproved = this.customerDetails?.isApproved;
    this.isVerify = this.customerDetails?.isVerify;
    Object.keys(this.customerDetails).forEach((key) => {
      if (['password', 'confirmPassword', 'stateId', 'paymentTermsDesc', 'gstin_Date', 'shipStateId', 'otherState', 'shipOtherState', 'customerId', 'customerCode', 'customerContactDetails', 'micrNo', 'supplierType', 'marketSubsegmentId', 'paymentTerms'].includes(key)) {
        if (key == 'password' || key == 'confirmPassword') {
          this.supplierFormControl.controls[key].setValue('test@123');
          this.password = this.customerDetails['password'];
        }

        else if (key == 'gstin_Date') {
          this.supplierFormControl.controls[key].setValue(moment(this.customerDetails[key]).format('MM/DD/YYYY'));
        }

        else if (key == 'micrNo') {
          this.supplierFormControl.controls[key].setValue(this.customerDetails[key]);
          this.showShipSoldDetails();
        }

        else if (key == 'marketSubsegmentId') {
          this.supplierFormControl.controls['marketSubsegmentId'].setValue(this.customerDetails[key]);
        }

        else if (key == 'supplierType') {
          this.supplierFormControl.controls[key].setValue(this.customerDetails[key]);
          this.getSupplierType(this.customerDetails[key]);
        }


        else if (key == 'stateId') {
          if (this.customerDetails.countryId == 1) {
            // this.supplierFormControl.controls[key].setValue(this.customerDetails[key]);
            this.getStateDropdown('', this.customerDetails['countryId'], this.customerDetails[key], 'country1');
          }
        }

        else if (key == 'shipStateId') {
          if (this.customerDetails.shipCountryId == 1) {
            this.getStateDropdown('', this.customerDetails['shipCountryId'], this.customerDetails[key], 'country2');
          }
        }


        else if (key == 'otherState') {
          if (![0, 1].includes(this.customerDetails.countryId)) {
            this.getStateDropdown('', this.customerDetails['countryId'], this.customerDetails[key], 'country1');
          }
        }

        else if (key == 'shipOtherState') {
          if (![0, 1].includes(this.customerDetails.shipCountryId)) {
            this.getStateDropdown('', this.customerDetails['shipCountryId'], this.customerDetails[key], 'country2');
          }
        }

        else if (key == 'paymentTerms') {
          this._apiService.getpaymentTerms()
          .then((res: any) => {
            if (res.success) {
              this.paymentTermsList = res.returnValue;
              this.setPaymentTermsDesc(this.customerDetails[key]);
            }
  
            else {
              this.paymentTermsList = [
                { id: 1, value: 'regionDropdown1' },
                { id: 2, value: 'regionDropdown2' },
                { id: 3, value: 'regionDropdown3' },
                { id: 4, value: 'regionDropdown4' },
              ];
            }
          })
          .catch((error) => {
            console.log(error);
            this.paymentTermsList = []
          });
         
          //  this.supplierFormControl.controls[key].setValue(this.customerDetails['paymentTerms']);
          // this.supplierFormControl.controls.paymentTerms.setValue(this.customerDetails?.paymentTerms)
        }

        else if (key == 'customerContactDetails') {
          let contactDetails = this.getContactArray();
          if (this.customerDetails['customerContactDetails'].length > 0) {
            this.customerDetails['customerContactDetails'].forEach(department => {
              // contactDetails.push(this.fb.group({contactPerson : department.contactPerson} , {telephoneNumber : department.contactNumber} , {faxNumber : department.faxNumber} ,{emailAddress : department.emailAddress}))
              contactDetails.push(this.fb.group({
                contactPerson: new FormControl(department.contactPerson),
                contactNumber: new FormControl(department.contactNumber),
                alternetNumber: new FormControl(department.faxNumber),
                emailAddress: new FormControl(department.emailAddress),
              }))
            })
          }

          else {
            this.addContactRow('add');
          }
        }


      }

      else {
        if (this.supplierFormControl.controls.hasOwnProperty(key) && key != 'cancelCheque' && key != 'panUpload' && key != 'gstinUpload') {
          this.supplierFormControl.controls[key].setValue(this.customerDetails[key]);
        }
        else if (key == 'cancelCheque' || key == 'panUpload' || key == 'gstinUpload') {
          ;
          this.documentList[key] = this.customerDetails[key];
          console.log(this.documentList);
        }
      }
    })

  }

  public validateAreEqual(c: AbstractControl): { notSame: boolean } {
    return c.value.password == c.value.confirmPassword
      ? { notSame: false }
      : { notSame: true };
  }

  //formGroup
  supplierFormControl = this.fb.group({
    customerName: new FormControl(null, [Validators.required]),
    addressWithPinCode: new FormControl(''),
    branchPlant: new FormControl(''),
    address1: new FormControl('', Validators.required),
    address2: new FormControl('', Validators.required),
    address3: new FormControl('', Validators.required),
    pincode: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    gstinUpload: new FormControl('', [Validators.required]),
    panUpload: new FormControl(''),
    countryId: new FormControl('', Validators.required),
    stateId: new FormControl('', Validators.required),
    tinNumber: new FormControl(''),
    otherState: new FormControl(''),
    shipAddress4: new FormControl(''),
    shipAddress1: new FormControl('', Validators.required),
    shipAddress2: new FormControl('', Validators.required),
    shipAddress3: new FormControl('', Validators.required),
    shipPincode: new FormControl('', Validators.required),
    businessUnit: new FormControl(''),
    shipCity: new FormControl('', Validators.required),
    shipCountryId: new FormControl('', Validators.required),
    shipStateId: new FormControl('', Validators.required),
    shipOtherState: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
    intercompanyCorrectCONo: new FormControl(''),
    supplierType: new FormControl('domestic', [Validators.required]),
    currency: new FormControl('', [Validators.required]),
    gstin_no: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'
      ),
    ]),
    gstin_Date: new FormControl(null, [Validators.required]),
    panNumber: new FormControl('', [
      Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}'),
    ]),
    tanNumber: new FormControl(''),
    vanNumber: new FormControl(''),
    customerContactDetails: this.fb.array([]),
    applicationTypeId: new FormControl(''),
    industryId: new FormControl('', [Validators.required]),
    marketSegmentId: new FormControl(''),
    marketSubsegmentId: new FormControl(''),
    freightHandlingCodeId: new FormControl(''),
    subRegionId: new FormControl(''),
    regionId: new FormControl(''),
    companyOwnershipId: new FormControl(''),
    carrierNumber: new FormControl(''),
    bankAddress: new FormControl(''),
    cancelCheque: new FormControl(''),
    micrNo: new FormControl(''),
    foreignNo: new FormControl(''),

    purposeId: new FormControl('', [Validators.required]),

    //cs team
    creditLimit: new FormControl(''),
    bussinessUnit: new FormControl(''),
    customerCategory: new FormControl(''),
    employeeCode: new FormControl(0),
    supplierMode: new FormControl(0),
    paymentTerms: new FormControl(''),
    paymentTermsDesc: new FormControl(''),
    incotermsId: new FormControl(0)
  });

  certificateDetails: any = [];

  getError() {
    return this.validateAreEqual(this.supplierFormControl);
  }

  // Register Suppliers

  registerSupplierBoolean: string = '';
  registerSupplier() {
    Object.keys(this.supplierFormControl.controls).forEach((key: any) => {
      console.log(
        key,
        this.supplierFormControl.controls[key].status,
        this.supplierFormControl.controls[key].value
      );
    });

    if (this.supplierFormControl.valid) {
      this._utility.loader(true);
      let apiRequestSchema: any = {};
      Object.keys(this.supplierFormControl.value).forEach((key) => {
        ;
        if (
          [
            'countryId',
            'password',
            'confirmPassword',
            'currency',
            'purposeId',
            'intercompanyCorrectCONo',
            'tinNumber',
            'faxNumber',
            'cancelCheque',
            'gstinUpload',
            'panUpload',
            'gstin_Date',
            'shipCountryId'
          ].includes(key)
        ) {
          if ((key == 'countryId' || key == 'shipCountryId' || key == 'purposeId') && this.supplierFormControl.controls[key].value != '') {
            apiRequestSchema[key] = JSON.parse(
              this.supplierFormControl.controls[key].value
            );
          }

          else if (key == 'gstin_Date') {
            if (this.supplierFormControl.controls[key].value) {
              apiRequestSchema[key] = moment(this.supplierFormControl.controls[key].value).format('MM/DD/YYYY');
            } else {
              apiRequestSchema[key] = null;
            }
          }

          else if (key == 'password' || key == 'confirmPassword') {
            if (this.submitButton == 'Submit') {
              apiRequestSchema[key] = md5(
                this.supplierFormControl.controls[key].value
              );
            }

            else {
              if (this.supplierFormControl.controls[key].value == 'test@123') {
                apiRequestSchema[key] = this.password;
              }
              else {
                apiRequestSchema[key] = md5(this.supplierFormControl.controls[key].value);
              }
            }
          }
        }

        else {
          apiRequestSchema[key] = this.supplierFormControl.controls[key].value;
        }
      });

      apiRequestSchema['userName'] = this.userName;
      apiRequestSchema['emailAddress'] = this.supplierFormControl.value.customerContactDetails[0]['emailAddress'];

      if (
        this.supplierFormControl.value.currency != null ||
        this.supplierFormControl.value.currency != ''
      ) {
        apiRequestSchema['currency'] = this.supplierFormControl.value.currency;
      }
      if (this.supplierFormControl.value.intercompanyCorrectCONo != '') {
        apiRequestSchema['intercompanyCorrectCONo'] =
          this.supplierFormControl.value.intercompanyCorrectCONo;
      }
      if (this.supplierFormControl.value.tinNumber != '') {
        apiRequestSchema['tinNumber'] =
          this.supplierFormControl.value.tinNumber;
      }
      // if (this.supplierFormControl.value.faxNumber != '') {
      //   apiRequestSchema['faxNumber'] = this.supplierFormControl.value.faxNumber;
      // }

      let formData = new FormData();

      if (
        this.supplierFormControl.value.cancelCheque != 'Upload' &&
        this.supplierFormControl.value.cancelCheque != null
      ) {
        formData.append(
          'cancelCheque',
          this.supplierFormControl.value.cancelCheque
        );
      }
      if (
        this.supplierFormControl.value.gstinUpload != 'Upload' &&
        this.supplierFormControl.value.gstinUpload != null
      ) {
        formData.append(
          'gstinUpload',
          this.supplierFormControl.value.gstinUpload
        );
      }
      if (
        this.supplierFormControl.value.panUpload != 'Upload' &&
        this.supplierFormControl.value.panUpload != null
      ) {
        formData.append('panUpload', this.supplierFormControl.value.panUpload);
      }

      if (this.submitButton == 'Submit') {
        console.log(apiRequestSchema);
        formData.append('jsonData', JSON.stringify(apiRequestSchema));
        this._apiService.customerRegistration(formData).then((res: any) => {
          this._utility.loader(false);
          if (res.success == false) {
            this._apiService.showMessage(res.message, 'error');
          } else {
            this._apiService.showMessage(res.message, 'success');
            this.showSuccess = true;
            localStorage.clear();
          }
        });
      }


      else {
        apiRequestSchema.customerId = this.editCustomerId;
        formData.append('customerId', this.editCustomerId)
        apiRequestSchema['isVerify'] =
          this.isVerify == 'Rework'
            ? 'Reworked But Not Reviewed'
            : this.isVerify;
        apiRequestSchema['isApproved'] =
          this.isApproved == 'Rework'
            ? 'Reworked But Not Reviewed'
            : this.isApproved;
        console.log(apiRequestSchema);
        formData.append('jsonData', JSON.stringify(apiRequestSchema));
        this._apiService.customerUpdate(formData).then((res: any) => {
          this._utility.loader(false);
          if (res.success == false) {
            this._apiService.showMessage(res.message, 'error');
          } else {
            this._apiService.showMessage(res.message, 'success');
            this.route.navigateByUrl('/customer-list');
          }
        });
      }
    } else {
      window.scroll(100, 100);
      this._apiService.showMessage(
        'Please Fill Required Details For Registration!',
        'error'
      );
    }
  }

  getContactArray() {
    return this.supplierFormControl.get('customerContactDetails') as FormArray;
  }

  addContactRow(string: any, indexat?: any, email?: any) {
    let contact = this.getContactArray();
    if (string == 'add') {
      let emailAddress = email ?? '';
      // if (contact.valid) {
      contact.push(
        this.fb.group({
          contactPerson: new FormControl('', [Validators.required]),
          contactNumber: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(18),
          ]),
          alternetNumber: new FormControl('', [
            Validators.minLength(10),
            Validators.maxLength(18),
          ]),
          emailAddress: new FormControl(emailAddress, [
            Validators.required,
            Validators.pattern(
              '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'
            ),
          ]),
        })
      );
      // }
    } else {
      if (indexat == 0 && contact.value.length > 1) {
        contact.removeAt(indexat);
      } else if (indexat != 0) {
        contact.removeAt(indexat);
      }
    }
  }

  currencyDropdown: any = [];
  otherCategoryDropdown: any = [];
  getState() {
    this._apiService
      .dropdowndata('country')
      .then((res: any) => {
        if (res.success) {
          this.countryDropdown = res.returnValue;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('currency')
      .then((res: any) => {
        if (res.success) {
          this.currencyDropdown = res.returnValue;
          console.log(this.currencyDropdown, "currency");
          
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('paymentTerms')
      .then((res: any) => {
        if (res.success) {
          this.paymentDropdown = res.returnValue;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CustomerCategory')
      .then((res: any) => {
        if (res.success) {
          this.customerCategory = res.returnValue;
        } else {
          this.customerCategory = [
            { id: 1, value: 'category1' },
            { id: 2, value: 'category2' },
            { id: 3, value: 'category3' },
            { id: 4, value: 'category4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('Incoterms')
      .then((res: any) => {
        if (res.success) {
          this.incotermsDropdown = res.returnValue;
        } else {
          this.incotermsDropdown = [
            { id: 1, value: 'Incoterms1' },
            { id: 2, value: 'Incoterms2' },
            { id: 3, value: 'Incoterms3' },
            { id: 4, value: 'Incoterms4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('ApplicationType')
      .then((res: any) => {
        if (res.success) {
          this.applicationTypeDropdown = res.returnValue;
        } else {
          this.applicationTypeDropdown = [
            { id: 1, value: 'ApplicationType1' },
            { id: 2, value: 'ApplicationType2' },
            { id: 3, value: 'ApplicationType3' },
            { id: 4, value: 'ApplicationType4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('Industry')
      .then((res: any) => {
        if (res.success) {
          this.industryDropdown = res.returnValue;
        } else {
          this.industryDropdown = [
            { id: 1, value: 'Individual' },
            { id: 2, value: 'LLP' },
            { id: 3, value: 'Company' },
            { id: 4, value: 'Partnership' },
            { id: 5, value: 'Others' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('FreightHandlingCode')
      .then((res: any) => {
        if (res.success) {
          this.freightCodeDropdown = res.returnValue;
        } else {
          this.freightCodeDropdown = [
            { id: 1, value: 'freight_code1' },
            { id: 2, value: 'freight_code2' },
            { id: 3, value: 'freight_code3' },
            { id: 4, value: 'freight_code4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('Region')
      .then((res: any) => {
        if (res.success) {
          this.regionDropdown = res.returnValue;
        } else {
          this.regionDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('SubRegion')
      .then((res: any) => {
        if (res.success) {
          this.subRegionDropdown = res.returnValue;
        } else {
          this.subRegionDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CompanyOwnership')
      .then((res: any) => {
        if (res.success) {
          this.companyOwnershipDropdown = res.returnValue;
        } else {
          this.companyOwnershipDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('marketSegment')
      .then((res: any) => {
        if (res.success) {
          this.marketSegmentDropdown = res.returnValue;
        } else {
          this.marketSegmentDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('marketSubSegment')
      .then((res: any) => {
        if (res.success) {
          this.marketSubSegmentDropdown = res.returnValue;
        } else {
          this.marketSubSegmentDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CustomerBUMaster')
      .then((res: any) => {
        if (res.success) {
          this.customerBuDropdown = res.returnValue;
        } else {
          this.customerBuDropdown = [
            { id: 1, value: 'BUMaster1' },
            { id: 2, value: 'BUMaster2' },
            { id: 3, value: 'BUMaster3' },
            { id: 4, value: 'BUMaster4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CustomerBussinessUnitNumber')
      .then((res: any) => {
        if (res.success) {
          this.branchPlantDropdown = res.returnValue;
        } else {
          this.branchPlantDropdown = [
            { id: 1, value: 'BussinessUnit1' },
            { id: 2, value: 'BussinessUnit2' },
            { id: 3, value: 'BussinessUnit3' },
            { id: 4, value: 'BussinessUnit4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (this.userLogin) {
      this._apiService.getpaymentTerms()
        .then((res: any) => {
          if (res.success) {
            this.paymentTermsList = res.returnValue;
          }

          else {
            this.paymentTermsList = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
          this.paymentTermsList = []
        });
    }
  }

  freightCodeDropdown: any = [];
  regionDropdown: any = [];
  paymentTermsList: any = [];
  subRegionDropdown: any = [];
  companyOwnershipDropdown: any = [];
  
  async getStateDropdown(event: any, countryId: any, stateId: any, string?: any, otherState?: any) {
    const value = event?.target?.value ?? null;
  
    if(value == 1){ 
     
      this.isCurrencyDisabled = true;
      // this.supplierFormControl.controls['currency'].disable(); 
      this.supplierFormControl.controls['currency'].setValue(value);
      // console.log(this.supplierFormControl.controls['currency'].value, "check"); // Log the current value of the form control
      
    
    }
    else {
      this.isCurrencyDisabled = false;
      // Handle other cases or reset the control if needed
      this.supplierFormControl.controls['currency'].enable(); // Enable control if not '1'
      this.supplierFormControl.controls['currency'].setValue(null); 
    }
    let state = '';
    state = stateId;

    let object = {
      Mode: 'state',
      cond3: event?.target?.value ?? countryId,
    };

    await this._apiService.dropdowndata('', object).then((res: any) => {
      ;
      console.log(res);
      if (res.status == true) {
        if (this.stateDropdown.length == 0) {
          this.stateDropdown = res.returnValue;
        }
        if (string == 'country1') {
          this.supplierFormControl.setControl(
            'stateId',
            this.fb.control(state, [Validators.required])
          );
          
          this.supplierFormControl.setControl(
            'otherState',
            this.fb.control('')
          );
          
        
          
          this.showOtherCityBoolean = false;
        }

        else {
          // this.supplierFormControl.setControl(
          //   'stateId',
          //   this.fb.control(state, [Validators.required])
          // );

          this.supplierFormControl.setControl(
            'shipStateId',
            this.fb.control(state, [Validators.required])
          );
          this.supplierFormControl.setControl(
            'shipOtherState',
            this.fb.control('')
          );
          this.showOtherCityShipBoolean = false;
        }
      }

      else {
        if (string == 'country1') {
          
          this.showOtherCityBoolean = true;
          this.supplierFormControl.setControl(
            'otherState',
            this.fb.control(state, [Validators.required])
          );
          this.supplierFormControl.get('stateId').clearValidators();
          this.supplierFormControl.get('stateId').updateValueAndValidity();
          // this.supplierFormControl.setControl('stateId', this.fb.control(''));
          this.supplierFormControl.get('shipStateId').setValidators([]); 
          this.supplierFormControl.get('shipStateId').updateValueAndValidity();

        }

        else {
          this.showOtherCityShipBoolean = true;

          this.supplierFormControl.setControl(
            'shipOtherState',
            this.fb.control(state, [Validators.required])
          );
          this.supplierFormControl.setControl('shipStateId', this.fb.control(''));
        }
      }
    });
    if(value == null){
     
      if(this.customerDetails.shipCountryId == 1 ){
        this.isCurrencyDisabled = true;
        // console.log('null valuye',this.customerDetails);
        // this.supplierFormControl.controls['currency'].disable(); 
        this.supplierFormControl.controls['currency'].setValue(this.customerDetails.currency);
        // console.log(this.supplierFormControl.controls['currency'].value, "check"); // Log the current value of the form control
        
      
      }
      else {
        this.isCurrencyDisabled = false;
        // Handle other cases or reset the control if needed
        this.supplierFormControl.controls['currency'].enable(); // Enable control if not '1'
        this.supplierFormControl.controls['currency'].setValue(null); 
      }
      if(this.customerDetails.shipCountryId !== 1 ){
        this.isCurrencyDisabled = true;
        console.log('null valuye',this.customerDetails);
        // this.supplierFormControl.controls['currency'].disable(); 
        this.supplierFormControl.controls['currency'].setValue(this.customerDetails.currency);
        // console.log(this.supplierFormControl.controls['currency'].value, "check"); // Log the current value of the form control
        
      
      }
      
    }
  }
  getSupplierType(string: any) {
    console.log(string);
    if (string == 'foreign') {
      this.isGST = true;
      this.showGST = string == 'foreign' ? false : true;
      this.supplierFormControl.setControl('panNumber', this.fb.control(''));
      this.supplierFormControl.setControl('gstin_no', this.fb.control(''));
      this.supplierFormControl.setControl('gstin_Date', this.fb.control(''));
      this.supplierFormControl.setControl('panUpload', this.fb.control(''));
      this.supplierFormControl.setControl('gstinUpload', this.fb.control(''));
    }
    else if (string == 'intercompany') {
      this.isGST = false;
      this.showGST = string == 'foreign' ? false : true;
      this.supplierFormControl.setControl('panNumber', this.fb.control(''));
      // this.supplierFormControl.setControl('gstin_no', this.fb.control(''));
      // this.supplierFormControl.setControl('gstin_Date', this.fb.control(''));
      this.supplierFormControl.setControl('panUpload', this.fb.control(''));
      // this.supplierFormControl.setControl('gstinUpload', this.fb.control(''));
    }

    else if (string == 'domestic') {
      this.isGST = true;
      this.showGST = true;
      if (this.editCustomerId) {
        this.supplierFormControl.setControl(
          'panNumber',
          this.fb.control(this.customerDetails.panNumber, [
            Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}'),
          ])
        );
        this.supplierFormControl.setControl(
          'gstin_no',
          this.fb.control(this.customerDetails.gstin_no, [
            Validators.required,
            Validators.pattern(
              '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'
            ),
          ])
        );
        this.supplierFormControl.setControl(
          'gstin_Date',
          this.fb.control(new Date(this.customerDetails.gstin_Date), [
            Validators.required,
          ])
        );
        this.supplierFormControl.setControl(
          'panUpload',
          this.fb.control('Upload')
        );
        this.supplierFormControl.setControl(
          'gstinUpload',
          this.fb.control('Upload', [Validators.required])
        );
      } else {
        this.supplierFormControl.setControl(
          'panNumber',
          this.fb.control('', [
            Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}'),
          ])
        );
        this.supplierFormControl.setControl(
          'gstin_no',
          this.fb.control('', [
            Validators.required,
            Validators.pattern(
              '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'
            ),
          ])
        );
        this.supplierFormControl.setControl(
          'gstin_Date',
          this.fb.control(null, [Validators.required])
        );
        this.supplierFormControl.setControl(
          'panUpload',
          this.fb.control('')
        );
        this.supplierFormControl.setControl(
          'gstinUpload',
          this.fb.control('', [Validators.required])
        );
      }
    }
  }

  additionalDocument: any = [];
  upload_doc(event: any, string?: any, idx?: any) {
    let file = this._utility.onFileChange(event);
    if (string == 'cancelCheque') {
      if (typeof file == 'object') {
        if (Object.keys(file).length > 0) {
          Object.keys(file).forEach((key) => {
            this.additionalDocument.push(file[key]);
          });
        } else {
          console.log(file);
          // this.additionalDocument.push(file);
          this.supplierFormControl.controls['cancelCheque'].setValue(file);
        }
      }
      console.log(this.additionalDocument);
    } else if (string == 'gstinUpload') {
      if (file == false) {
        this.supplierFormControl.patchValue({
          gstinUpload: '',
        });
        this.gstUpload.nativeElement.value = null;
      } else {
        this.supplierFormControl.patchValue({
          gstinUpload: file,
        });
      }
    } else if (string == 'panUpload') {
      if (file == false) {
        this.supplierFormControl.patchValue({
          panUpload: '',
        });
        this.panUpload.nativeElement.value = null;
      } else {
        this.supplierFormControl.patchValue({
          panUpload: file,
        });
      }
    }
  }

  showShip: string = '';
  showShipSoldDetails() {
    console.log(this.supplierFormControl.controls['micrNo'].value);
    if (this.supplierFormControl.controls['micrNo'].value == 'Sold To Details') {
      this.showShip = 'Sold & Ship';

      this.supplierFormControl.controls['addressWithPinCode'].setValue(this.customerDetails?.addressWithPinCode);
      // this.supplierFormControl.setControl(
      //   'addressWithPinCode',
      //   this.fb.control(this.customerDetails.addressWithPinCode, [Validators.required])
      // );

      this.supplierFormControl?.controls['address1']?.setValue(this.customerDetails?.address1)
      this.supplierFormControl.controls['address1'].addValidators(Validators.required)
      // this.supplierFormControl.setControl(
      //   'address1',
      //   this.fb.control(this.customerDetails.address1, [Validators.required])
      // );


      this.supplierFormControl?.controls['address2']?.setValue(this.customerDetails?.address2)
      this.supplierFormControl?.controls['address2']?.addValidators(Validators.required)
      // this.supplierFormControl.setControl(
      //   'address2',
      //   this.fb.control(this.customerDetails.address2, [Validators.required])
      // );


      this.supplierFormControl?.controls['address3']?.setValue(this.customerDetails?.address3)
      this.supplierFormControl?.controls['address3']?.addValidators(Validators.required)
      // this.supplierFormControl.setControl(
      //   'address3',
      //   this.fb.control(this.customerDetails.address3)
      // );




      this.supplierFormControl?.controls['pincode']?.setValue(this.customerDetails?.pincode)
      this.supplierFormControl?.controls['pincode']?.addValidators(Validators.required)
      // this.supplierFormControl.setControl(
      //   'pincode',
      //   this.fb.control(this.customerDetails.pincode, [Validators.required])
      // );


      this.supplierFormControl?.controls['countryId']?.setValue(this.customerDetails?.countryId)
      this.supplierFormControl?.controls['countryId']?.addValidators(Validators.required)
      // this.supplierFormControl.setControl(
      //   'countryId',
      //   this.fb.control(this.customerDetails.countryId, [Validators.required])
      // );





      this.supplierFormControl?.controls['stateId']?.setValue(this.customerDetails?.stateId)
      this.supplierFormControl?.controls['stateId'].addValidators(Validators.required)
      // this.supplierFormControl.setControl(
      //   'stateId',
      //   this.fb.control(this.customerDetails.stateId, [Validators.required])
      // );



      this.supplierFormControl?.setControl(
        'city',
        this.fb.control(this.customerDetails?.city, [Validators.required])
      );


      //ship address
      this.supplierFormControl?.setControl(
        'shipAddress1',
        this.fb.control(this.customerDetails?.shipAddress1, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipAddress2',
        this.fb.control(this.customerDetails?.shipAddress2, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipAddress3',
        this.fb.control(this.customerDetails?.shipAddress3, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipAddress4',
        this.fb.control(this.customerDetails?.shipAddress4)
      );
      this.supplierFormControl?.setControl(
        'shipPincode',
        this.fb.control(this.customerDetails?.shipAddress4, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipCountryId',
        this.fb.control(this.customerDetails?.shipCountryId, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipStateId',
        this.fb.control(this.customerDetails?.shipStateId, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipCity',
        this.fb.control(this.customerDetails?.shipCity, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipOtherState',
        this.fb.control(this.customerDetails?.shipOtherState)
      );

    }

    else {
      this.supplierFormControl.controls.paymentTerms.setValue('0');
      this.supplierFormControl.controls.paymentTermsDesc.setValue('0');
      this.supplierFormControl.controls.creditLimit.setValue('0');
      this.showShip = 'Ship'
      this.supplierFormControl?.setControl(
        'addressWithPinCode',
        this.fb.control(this.customerDetails?.addressWithPinCode)
      );
      this.supplierFormControl?.setControl(
        'address1',
        this.fb.control(this.customerDetails?.address1)
      );
      this.supplierFormControl?.setControl(
        'address2',
        this.fb.control(this.customerDetails?.address2)
      );
      this.supplierFormControl?.setControl(
        'address3',
        this.fb.control(this.customerDetails?.address3)
      );
      this.supplierFormControl?.setControl(
        'pincode',
        this.fb.control(this.customerDetails?.pincode)
      );
      this.supplierFormControl?.setControl(
        'countryId',
        this.fb.control(this.customerDetails?.countryId)
      );
      // this.supplierFormControl?.setControl(
      //   'stateId',
      //   this.fb.control(this.customerDetails?.stateId)
      // );
      this.supplierFormControl.get('stateId').setValidators([]); 
      this.supplierFormControl?.setControl(
        'city',
        this.fb.control(this.customerDetails?.city)
      );
      this.supplierFormControl?.setControl(
        'otherState',
        this.fb.control(this.customerDetails?.otherState)
      );

      this.supplierFormControl?.setControl(
        'shipAddress1',
        this.fb.control(this.customerDetails?.shipAddress1, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipAddress2',
        this.fb.control(this.customerDetails?.shipAddress2, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipAddress3',
        this.fb.control(this.customerDetails?.shipAddress3, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipPincode',
        this.fb.control(this.customerDetails?.shipPincode, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipCountryId',
        this.fb.control(this.customerDetails?.shipCountryId, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipStateId',
        this.fb.control(this.customerDetails?.shipStateId, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipCity',
        this.fb.control(this.customerDetails?.shipCity, [Validators.required])
      );
      this.supplierFormControl?.setControl(
        'shipOtherState',
        this.fb.control(this.customerDetails?.shipOtherState)
      );
    }
  }


  setPaymentTermsDesc(desc: any) {
    let value = this.paymentTermsList.filter(res => res.paymentTermsName == desc)
    this.supplierFormControl.controls['paymentTermsDesc'].setValue(value[0]?.description);
    this.supplierFormControl.controls['paymentTerms'].setValue(desc);
  }

  sameAsSold: boolean = false;
  sameAsSoldDetails() {
    if (!this.sameAsSold) {
      this.supplierFormControl.controls['shipAddress1'].setValue(this.supplierFormControl.controls['address1'].value)
      this.supplierFormControl.controls['shipAddress2'].setValue(this.supplierFormControl.controls['address2'].value)
      this.supplierFormControl.controls['shipAddress3'].setValue(this.supplierFormControl.controls['address3'].value)
      this.supplierFormControl.controls['shipAddress4'].setValue(this.supplierFormControl.controls['addressWithPinCode'].value)
      this.supplierFormControl.controls['shipPincode'].setValue(this.supplierFormControl.controls['pincode'].value)
      this.supplierFormControl.controls['shipCountryId'].setValue(this.supplierFormControl.controls['countryId'].value)
      this.supplierFormControl.controls['shipCity'].setValue(this.supplierFormControl.controls['city'].value)
      this.supplierFormControl.controls['shipStateId'].setValue(this.supplierFormControl.controls['stateId'].value)
      if (!this.showOtherCityBoolean) {
        this.supplierFormControl.controls['shipOtherState'].setValue(this.supplierFormControl.controls['stateId'].value)
      } else {
        this.supplierFormControl.controls['shipOtherState'].setValue(this.supplierFormControl.controls['otherState'].value)
      }
      if (this.supplierFormControl.controls['shipCountryId'].value == '1') {
        this.showOtherCityShipBoolean = false;
      }
      else {
        this.showOtherCityShipBoolean = true;
      }
      if (!this.showOtherCityShipBoolean) {
        this.supplierFormControl.controls['shipStateId'].setValidators([Validators.required]);
      } else {
        this.supplierFormControl.controls['shipStateId'].setValidators([]);
      }
    }

    else {
      this.showOtherCityShipBoolean = false;
      this.supplierFormControl.controls['shipAddress1'].setValue('')
      this.supplierFormControl.controls['shipAddress2'].setValue('')
      this.supplierFormControl.controls['shipAddress3'].setValue('')
      this.supplierFormControl.controls['shipAddress4'].setValue('')
      this.supplierFormControl.controls['shipPincode'].setValue('')
      this.supplierFormControl.controls['shipCountryId'].setValue('0')
      this.supplierFormControl.controls['shipCity'].setValue('')
      this.supplierFormControl.controls['shipStateId'].setValue('0')
      this.supplierFormControl.controls['shipOtherState'].setValue('')
    }

    this.sameAsSold = !this.sameAsSold;
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /^\d+(\.\d{0,2})?$/;

    const inputValue = (event.target as HTMLInputElement).value;
    const proposedValue = inputValue + String.fromCharCode(event.charCode);

    if (!pattern.test(proposedValue)) {
      // Invalid input, prevent input
      event.preventDefault();
    }
  }
  
  onDropdownChange(event: any) {
    const selectedValue = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text;
    this.selectedIndustry = selectedText;
    if (selectedText === 'LLP' || selectedText === 'Company') {
      console.log("Selected value is 'LLP' or 'Company'");
      this.supplierFormControl.get('vanNumber').setValidators([Validators.required]);
      // this.supplierFormControl.get('contact_no').setValidators([Validators.required]);
  
      // Update validation status
      this.supplierFormControl.get('vanNumber').updateValueAndValidity();
      // this.supplierFormControl.get('contact_no').updateValueAndValidity();
    } else {
      this.supplierFormControl.get('vanNumber').clearValidators();
      // this.supplierFormControl.get('report_name').clearValidators();
  
      // Update validation status
      this.supplierFormControl.get('vanNumber').updateValueAndValidity();
      // this.supplierFormControl.get('contact_no').updateValueAndValidity();
    }
  }
  
  
}
