import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.scss']
})
export class DashboardNavbarComponent implements OnInit {

  userProfile : any;
  errorMessage: string = ''
  errorMessageCheck: string = ''
  constructor(private _utility : AppUtility , private router : Router,private _apiService: ApiServiceService) { }

  ngOnInit(): void {
    let userData  = this._utility.getLocalStorageDetails();
    this.userProfile = userData;
    // this.getSwitch();
  }

  logout(){
    localStorage.removeItem('UserObject');
    this.router.navigateByUrl('/login');
    this.errorMessage = "Logout successfully!",
    this.errorMessageCheck = 'success'
    this._apiService.showMessage(this.errorMessage , this.errorMessageCheck)
  }

  // switchDropdown : any = [];
  // getSwitch(){
  //   let object = {
  //     Mode : 'switchCase',
  //     itemIds : this.userProfile.loginPassword,
  //     cond3 : this.userProfile.loginId
  //   }
  //   this._apiService.dropdowndata('' , object).then((res:any)=>{
  //     if(res.success){
  //       this.switchDropdown = res.returnValue;
  //     }
  //   })
  // }
  
  // switchAccount(user){
  //   this._utility.loader(true);
  //   let object = {
  //     loginName : user.value,
  //     loginPassword : user.cond2
  //   }
  //   this._apiService.login(object)
  //   .then((res: any) => {  
  //     this._utility.loader(false);
  //     if (res.success == false) {
  //       window.scroll(0, 0);
  //       this._apiService.showMessage(res.message , 'error')
  //     }

  //     else {
  //       localStorage.setItem('UserObject', JSON.stringify(res.returnValue));
  //       this._apiService.showMessage(res.message, 'success');
  //       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //       this.router.onSameUrlNavigation = 'reload';
  //       this.router.navigate(['/dashboard-my-profile']);
  //     }
  //   })
  // }

}
