<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar ></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->    

    <div class="row">
        
    </div>
    <!-- Start -->
    <ng-container *ngIf="roleName.roleName == 'SuperAdmin' || roleName.roleName == 'Admin' || roleName.roleName == 'User' || roleName.roleName == 'Supplier' || roleName.roleName == 'Normal User' || roleName.roleName == 'Bussiness head'">
    <div class="add-listings-box-report">
        <h3>RFQ List</h3>    
        <div class="row">
            <app-common-fields (getFormValue)="getFormInput($event , 'list')"></app-common-fields>
            <div class="add-listings-btn m-0 col-lg-12 col-md-6">
                <div class="form-group">
                    <button type="submit" class="submit_btn" (click)="getValue('list')">Download</button>
                    <!-- <button type="reset" class="reset_btn mx-2">Reset</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- End -->

    <!-- Start -->
    <div class="add-listings-box-report">
        <h3>RFQ List Details</h3>
        <div class="row">
            <app-common-fields (getFormValue)="getFormInput($event , 'detail')"></app-common-fields>
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label>Part Name</label>
                    <select class="form-control form-select" [(ngModel)]="RFQDetailreportObject.itemId">
                        <option value="">Select Part Name</option>
                        <ng-container *ngFor="let item of itemList">
                            <option [value]="item.id">{{item.value}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="add-listings-btn col-lg-12 col-md-6">
                <div class="form-group">
                    <button type="submit" class="submit_btn" (click)="getValue('details')">Download</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End -->

    <!-- Start -->
    <div class="add-listings-box-report">
        <h3>RFQ Supplier</h3>
        <div class="row">
            <app-common-fields (getFormValue)="getFormInput($event , 'supplier')"></app-common-fields>
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label>Part Name</label>
                    <select class="form-control form-select" [(ngModel)]="RFQSupplierreportObject.itemId">
                        <option value="">Select Part Name</option>
                        <ng-container *ngFor="let item of itemList">
                            <option [value]="item.id">{{item.value}}{{item.id}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label>Supplier Name</label>
                    <select class="form-control form-select" [(ngModel)]="RFQSupplierreportObject.supplierId" [disabled]="enableSupplierDropdown">
                        <option value="">Select Supplier Name</option>
                        <ng-container *ngFor="let supplier of supplierList">
                            <option [value]="supplier.id">{{supplier.value}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="add-listings-btn col-lg-12 col-md-6">
                <div class="form-group">
                    <button type="submit" class="submit_btn" (click)="getValue('supplier')">Download</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End -->

    <!-- Start -->
    <div class="add-listings-box-report">
        <h3>Order Report</h3>
        <div class="row">
            <app-common-fields (getFormValue)="getFormInput($event , 'order_list')"></app-common-fields>
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label>Part Name</label>
                    <select class="form-control form-select" [(ngModel)]="RFQOrderreportObject.itemId">
                        <option value="">Select Part Name</option>
                        <ng-container *ngFor="let item of itemList">
                            <option [value]="item.id">{{item.value}}{{item.id}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label>Supplier Name</label>
                    <select class="form-control form-select" [(ngModel)]="RFQOrderreportObject.supplierId" [disabled]="enableSupplierDropdown">
                        <option value="">Select Supplier Name</option>
                        <ng-container *ngFor="let supplier of supplierList">
                            <option [value]="supplier.id">{{supplier.value}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="add-listings-btn col-lg-12 col-md-6">
                <div class="form-group">
                    <button type="submit" class="submit_btn" (click)="getValue('order_list')">Download</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End -->

    <!-- Start -->
    <div class="add-listings-box-report">
        <h3>Supplier Report</h3>
        <div class="row">
              <div class="col-lg-3 col-md-12">
                <div class="form-group">
                    <label>Supplier Name</label>
                    <select class="form-select form-control" [(ngModel)]="RFQSupplierreportObject.supplierId" [disabled]="enableSupplierDropdown" required>
                        <option value="" >Select Supplier Name</option>
                        <option value="0">All Supplier</option>
                        <ng-container *ngFor="let status of supplierList">
                            <option [value]="status.id">{{status.value}}</option>
                        </ng-container>
                    </select>
                </div>
              </div>
            <div class="add-listings-btn col-lg-12 col-md-6">
                <div class="form-group">
                    <button type="submit" class="submit_btn" (click)="getValue('supplier_list')">Download</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End -->

    <!-- Start -->
    <div class="add-listings-box-report" *ngIf="!enableSupplierDropdown">
        <h3>Assessment Report</h3>
        <div class="row">
              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                    <label>Assessment Status</label>
                    <select class="form-select form-control" [(ngModel)]="RFQAssessmentReportObject.assessmentStatus"  required>
                        <option value="" disabled>Select Assessment Status</option>
                            <option value="Approved">Approved</option>
                            <option value="Conditional Approved">Conditional Approved</option>
                            <option value="Pending">Pending</option>
                    </select>
                </div>
              </div>
            <div class="add-listings-btn col-lg-12 col-md-6">
                <div class="form-group">
                    <button type="submit" class="submit_btn" (click)="getValue('assessment_report')">Download</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
    <!-- End -->
<!-- start -->
<div class="add-listings-box-report" *ngIf="roleName.roleName == 'CS Team' || roleName.roleName == 'Customer-Approval' || roleName.roleName == 'Customer-JDE' || roleName.roleName == 'Admin'">
    <h3>Customer Report</h3>
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="form-group">
                <label>Select Date</label>
                <p-calendar [(ngModel)]="rangeDates" (onSelect)="selectedDate()" #calendar [readonlyInput]="true" selectionMode="range" [showIcon]="true" placeholder="Select Date Range"></p-calendar>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="form-group">
                <label>BU Name</label>
                <select class="form-control form-select" [(ngModel)]="BuId">
                    <option value="" disabled>Select BU Name</option>
                    <ng-container *ngFor="let item of BUList">
                        <option [value]="item.id">{{item.value}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="form-group">
                <label>Bussiness Name</label>
                <select class="form-control form-select" [(ngModel)]="BusinessMasterId ">
                    <option value="" disabled>Select Bussiness Name</option>
                    <ng-container *ngFor="let customer of BussinessUnitNumberList">
                        <option [value]="customer.id">{{customer.value}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="add-listings-btn col-lg-12 col-md-6">
            <div class="form-group">
                <button type="submit" class="submit_btn" (click)="getValue('customer')">Download</button>
            </div>
        </div>
    </div>
</div>
<!-- end -->
    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->