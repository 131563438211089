<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <!-- <div class="send-email-checker2"> -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- </div> -->
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="card finance-page">
        <p-table #dt2 [value]="supplierList"
            dataKey="supplierId" [rows]="10"
            responsiveLayout="scroll" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,20,30,50]"
            [paginator]="true" currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [filterDelay]="0"
            [globalFilterFields]="['emailAddress','mobileNo','supplierAddress','manufacturingProcessList','materialofconstructionList',
            'isVerify' , 'supplierName' , 'isApproved', 'jdeNo','userName','createUserName','supplierTypeName']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text"
                        (input)="dt2.filterGlobal($event.target.value,
                        'contains')"
                        placeholder="Global Search" [(ngModel)]="filterval" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)"
                        icon="bx bx-reset"></p-button>
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th style="text-align: center;">Action</th>
                    <th>JDE supplier/Customer No</th>
                    <th>Checker1</th>
                    <th>Checker2</th>
                    <th>User Name</th>
                    <th>Supplier Name</th>
                    <th>Supplier Email</th>
                    <th>Supplier Type</th>
                    <th>Buyer Name</th>
                    <th>Assessment Required</th>
                    <th>Assessment Status</th>
                    <th>Assessment Approval Status</th>
                    <th>Mobile No</th>
                    <th>Supplier Address</th>
                    <th>Manufacturing Process List</th>
                    <th>Material of Construction List</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr class="p-selectable-row">
                    <td>{{i+1}}</td>
                    <td class="d-flex" style="text-align: center;">
                        <button pButton pRipple pTooltip="Show Supplier Details" type="button" icon="bx bx-show"
                            class="p-button-rounded p-button-primary
                            p-button-text"
                            (click)="openModal(customer.supplierId , 'show')"></button>
                        <button *ngIf="this.userroleName.roleName == 'Admin' &&
                            customer.isApproved != 'Registered'" pButton pRipple
                            type="button" icon="bx bx-trash"
                            class="p-button-rounded p-button-danger
                            p-button-text"
                            (click)="confirmDelete(customer.supplierId)"></button>
                        <button *ngIf="this.userroleName.roleName == 'Admin'"
                            pButton pRipple type="button" icon="bx bx-block"
                            class="p-button-rounded p-button-text" [pTooltip]="customer.isBlock
                            ? 'Blocked' : 'Unblock'"
                            [ngClass]="[!customer.isBlock ? 'p-button-success' :
                            'p-button-danger']"
                            (click)="confirmblock(customer)"></button>
                    </td>
                    <td>{{customer.jdeNo ? customer.jdeNo : '-'}}</td>
                    <td>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isVerify == 'Verified'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-success status_button" style="font-size:
                            12px; padding:
                            0.2rem 1rem;"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isVerify == 'Pending'"
                            [label]="customer.isVerify" class="p-button-rounded
                            status_button pending"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isVerify == 'Rework'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-info status_button"></button>
                        <button pButton pRipple type="button"
                            (click)="openModal(customer.supplierId ,'show')"
                            *ngIf="customer.isVerify == 'Rejected'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-danger status_button"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isVerify == 'Reworked but not Reviewed'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-primary status_button"></button>
                    </td>
                    <td>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isApproved == 'Approved'"
                            (click)="jdeWindow(customer)"
                            label="Approved for JDE" class="p-button-rounded
                            p-button-success status_button"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isApproved == 'Registered'"
                            label="Registered In JDE" class="p-button-rounded
                            registered_button status_button"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isApproved == 'Pending'"
                            label="Awaiting Checker2 Approval"
                            class="p-button-rounded status_button pending"></button>
                        <a href="javascript:void(0)" *ngIf="customer.isVerify == 'Verified' && customer.isApproved == '' && userroleName.checker1 == true"
                            (click)="confirm1(customer)">Send to Checker2</a>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isApproved == 'Rework'"
                            [label]="customer.isApproved"
                            class="p-button-rounded p-button-info
                            status_button"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isApproved == 'Rejected'"
                            [label]="customer.isApproved"
                            class="p-button-rounded p-button-danger
                            status_button"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isApproved == 'Reworked but not Reviewed'"
                            [label]="customer.isApproved"
                            class="p-button-rounded
                            p-button-primary status_button"></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.isApproved == 'send For rework'"
                            label="Send for Rework"
                            class="p-button-rounded
                            p-button-danger status_button" (click)="captureStatus(customer , 'sendForrework')" pTooltip="Send for Rework to Supplier"></button>
                        <span *ngIf="customer.isApproved == '' && customer.isVerify != 'Verified'">-</span>
                    </td>
                    <td style="text-align: center;">
                        <span>{{customer.userName ? customer.userName : '-'}}</span>
                    </td>
                    <td>
                        <span>{{customer.supplierName }}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.emailAddress}}</span>
                    </td>
                    <td>
                        <span class="image-text" [ngClass]="customer.isDomestic ? 'domestic' : 'indian'">{{customer.isDomestic ? 'FOREIGN' : 'DOMESTIC'}}</span>
                    </td>
                    <td>{{customer.createUserName}}</td>
                    <td style="text-align: center;">
                        <i style="font-size: 20px; color: var(--mainColor);"
                            *ngIf="customer.assessment" class='bx
                            bxs-badge-check'></i>
                        <span *ngIf="!customer.assessment">-</span>
                    </td>
                    <td style="text-align: center;">
                        <span *ngIf="customer.assessment">
                            <span class="product-badge status-Acknowledged"
                                *ngIf="customer.assessmentUpload">Uploaded</span>
                            <span *ngIf="!customer.assessmentUpload"
                                class="product-badge status-">Pending</span>
                        </span>
                        <span *ngIf="!customer.assessment">
                            <span>-</span>
                        </span>
                    </td>
                    <td style="text-align: center;">
                        <span *ngIf="customer.assessment">
                            <span class="product-badge status-Acknowledged"
                                *ngIf="customer.assessmentStatus == 'Approved'">Approved</span>
                            <span *ngIf="customer.assessmentStatus == ''"
                                class="product-badge status-">Pending</span>
                            <span *ngIf="customer.assessmentStatus ==
                                'Conditional Approved'" class="product-badge
                                status-condition">Conditional Approved</span>
                            <span *ngIf="customer.assessmentStatus ==
                                'Rejected'" class="product-badge
                                status-Rejected">Rejected</span>
                        </span>
                        <span *ngIf="!customer.assessment">
                            <span>-</span>
                        </span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.mobileNo}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.address1}}
                            {{customer.address2}} {{customer.address3}}</span>
                    </td>

                    <td>
                        <span class="image-text">{{customer.manufacturingProcessList}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.materialofconstructionList}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex-grow-1"></div>
    </div>
    <!-- End -->

    <app-copyrights></app-copyrights>

</div>



<p-dialog [header]="this.header" [(visible)]="display" [modal]="true"
    [style]="{width: '70vw'}" [draggable]="false" [resizable]="false">
    <div class="container authentication">
        <!-- registerform -->
        <div class="signup-form">
            <!-- supplier registration form -->
            <div class="row" *ngIf="readonly">
                <div class="col-lg-3 col-md-12 form-group">
                    <select class="form-control form-select" id="applicable"
                        required [disabled]="userCapture" (change)="approvalChangeStatus($event)"
                        [(ngModel)]="supplierListById.statusCapture">
                        <ng-container *ngFor="let approvalStatus of approvalStatus">
                            <option [value]='approvalStatus.value'
                                [disabled]="approvalStatus.value == 'Reworked but not Reviewed' || approvalStatus.value == 'Pending' || approvalStatus.value == 'send For rework'">{{approvalStatus.name}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-3 col-md-12 form-group" *ngIf="!userCapture">
                    <p-multiSelect [filter]="false" [showToggleAll]="false"
                        class="multiselect-custom-virtual-scroll"
                        [options]="document_dropdown" placeholder="Select
                        Documents" [disabled]="supplierListById.statusCapture ==
                        'Rejected' || supplierListById.statusCapture ==
                        'Pending' || supplierListById.statusCapture ==
                        'Verified' || supplierListById.statusCapture == 'Reworked but not Reviewed'" [(ngModel)]="document"
                        optionLabel="value"></p-multiSelect>
                </div>
                <div class="col-lg-3 col-md-12 form-group" *ngIf="!userCapture">
                    <p-multiSelect [filter]="false" [showToggleAll]="false"
                        class="multiselect-custom-virtual-scroll"
                        [options]="section_dropdown" placeholder="Select Sections"  [disabled]="supplierListById.statusCapture ==
                        'Rejected' || supplierListById.statusCapture ==
                        'Pending' || supplierListById.statusCapture ==
                        'Approved' || supplierListById.statusCapture ==
                        'Verified' || supplierListById.statusCapture == 'Reworked but not Reviewed'" [(ngModel)]="selectedSections" optionValue="value"
                        optionLabel="value"></p-multiSelect>
                </div>
                <div class="col-lg-3 col-md-12 form-group" *ngIf="!userCapture">
                    <textarea class="form-control" placeholder="Action"
                        [disabled]="supplierListById.statusCapture == 'Pending'
                        || supplierListById.statusCapture == 'Approved' ||
                        supplierListById.statusCapture == 'Verified' || supplierListById.statusCapture == 'Reworked but not Reviewed'"
                        [(ngModel)]="supplierRemark"
                        autocomplete="off" required></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="first-name">Bussiness Unit Number</label>
                    <select class="form-control form-select" name="" id="" [(ngModel)]="supplierListById.bussinessName" placeholder="Select Bussiness Unit Number"  [disabled]="(supplierListById.statusCapture == 'Rework' || supplierListById.statusCapture == '' || supplierListById.statusCapture == 'Pending' || supplierListById.statusCapture == 'Rejected' || supplierListById.isVerify == 'Verified')">
                        <option value="" disabled>Select Bussiness Unit Number</option>
                        <ng-container *ngFor="let bussinessNumber of bussinessName">
                            <option [value]="bussinessNumber.value">{{bussinessNumber.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="first-name">Bussiness Unit</label>
                    <select name="" class="form-control form-select"  [disabled]="(supplierListById.statusCapture == 'Rework' || supplierListById.statusCapture == '' || supplierListById.statusCapture == 'Pending' || supplierListById.statusCapture == 'Rejected' || supplierListById.isVerify == 'Verified')" [(ngModel)]="supplierListById.buName" placeholder="Select Bussiness Unit Number">
                        <option value="" disabled>Select Bussiness Unit</option>
                        <ng-container *ngFor="let bussiness of bussinessUnit">
                            <option [value]="bussiness.value">{{bussiness.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group" *ngIf="assessmentBoolean">
                    <label for="">Assessment Document</label>
                    <ng-container *ngIf="supplierListById.assessmentUpload == '' || supplierListById.assessmentUpload == null;
                        then firstblock else secondblock"></ng-container>
                    <ng-template #secondblock>
                        <label for=""><a
                                [href]="supplierListById.assessmentUpload"
                                target="_blank"
                                [download]="supplierListById.assessmentUpload">Preview</a><span
                                (click)="supplierListById.assessmentUpload=
                                null" style="cursor: pointer;
                                margin-left:5px;" *ngIf="userroleName.checker1 && (supplierListById.assessmentStatus == '' || supplierListById.assessmentStatus == 'Pending')">x</span></label>
                    </ng-template>

                    <ng-template #firstblock class="form-group">
                        <ng-container *ngIf="userroleName.checker1">
                            <input type="file" class="form-control"
                            (change)="upload_doc($event , 'Assessment')">
                        </ng-container>
                        <span *ngIf="!userroleName.checker1 || supplierListById.assessmentStatus == 'Pending'">-</span>
                    </ng-template>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 d-flex" style="align-items: center;">
                    <input type="checkbox" [disabled]="userroleName.roleName == 'Admin' || (supplierListById.isVerify == 'Verified' && userroleName.checker1) || ((supplierListById.isApproved == 'Approved' || supplierListById.isApproved == 'Registered') && userroleName.checker2)" [(ngModel)]="assessmentBoolean"><label
                        for="">Assessment Required</label>
                </div>
                <div class="col-lg-4 col-md-6 mt-2" *ngIf="supplierListById.formDetails">
                    <label for="">Supplier Details</label>
                    <p style="font-size : 13px"> {{supplierListById.formDetails}}</p>
                </div>
                <div class="col-lg-4 col-md-6 mt-2" *ngIf="supplierListById.documentDetails">
                    <label for="">Document Details</label>
                    <p style="font-size : 13px"> {{supplierListById.documentDetails}}</p>
                </div>
            </div>

            <div class="add-listings-btn col-lg-2 col-md-12 form-group p-0"
                *ngIf="!userCapture">
                <div class="submit-toggle1 mt-1">
                    <button type="submit" class="submit_btn"
                        (click)="captureStatus(supplierListById ,
                        'Submit')">Submit</button>
                </div>
            </div>

            <div class="section-heading">
                <h5>Supplier Certificates</h5>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 form-group">
                    <a [href]="supplierListById.documentUploadPath"
                        style="font-size:13px; font-weight: 600; color:
                        var(--mainColor);"
                        (click)="_utility.FileSaver(supplierListById.documentUploadPath)"
                        target="_blank"
                        [download]="supplierListById.documentUploadPath">Organization
                        Profile</a>
                </div>
                <ng-container *ngFor="let doc of certificates">
                    <ng-container *ngIf="doc.documentPath != '' &&
                        doc.documentName != ''">
                        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
                            <!-- <span class="form-control"> -->
                            <a [href]="doc.documentPath" style="font-size:13px;
                                font-weight: 600; color: var(--mainColor);"
                                target="_blank"
                                (click)="_utility.FileSaver(doc.documentPath)"
                                [download]="doc.documentPath">{{doc.documentName}}</a>
                            <!-- </span> -->
                        </div>
                    </ng-container>
                </ng-container>
                <div class="col-lg-3 col-md-6 col-sm-12 form-group">
                    <a [href]="supplierListById.cancelledCheque"
                        target="_blank" style="font-size:13px; font-weight: 600;
                        color: var(--mainColor);"
                        (click)="_utility.FileSaver(supplierListById.cancelledCheque)"
                        [download]="supplierListById.cancelledCheque">Cancelled
                        Cheque Preview</a>
                </div>
            </div>

            <!-- Supplier / Vendor Master - Supplier Master -->
            <div class="section-heading">
                <h5>Supplier / Vendor Master - Supplier Master</h5>
            </div>

            <!-- row-1 -->
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.jdeNo">
                    <label for="first-name">JDE supplier/Customer No </label>
                    <input type="text" readonly="true"
                        class="form-control"
                        placeholder="Enter JDE supplier/Customer No"
                        [(ngModel)]="supplierListById.jdeNo"
                        autocomplete="off" required
                        />
                </div>
            </div>
            <div class="row">

                <div class="col-lg-4 col-md-12 form-group">
                    <label for="first-name">Supplier Name<span
                            class="star_class">*</span></label>
                    <input type="text" [readonly]="readonly"
                        class="form-control"
                        placeholder="Enter Supplier Name"
                        [(ngModel)]="supplierListById.supplierName"
                        autocomplete="off" minlength="3" maxlength='35'
                        required
                        />
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="address">Address 1<span
                            class="star_class">*</span></label>
                    <div class="d-flex">
                        <textarea name="" id="" cols="1" rows="1"
                            placeholder="Enter Your Address"
                            [(ngModel)]="supplierListById.address1"
                            class="form-control" type="text"
                            [readonly]="readonly" required></textarea>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="address">Address 2<span
                            class="star_class">*</span></label>
                    <div class="d-flex">
                        <textarea name="" id="" cols="1" rows="1"
                            placeholder="Enter Your Address"
                            [(ngModel)]="supplierListById.address2"
                            class="form-control" type="text"
                            [readonly]="readonly" required></textarea>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="address">Address 3<span
                            class="star_class">*</span></label>
                    <div class="d-flex">
                        <textarea name="" id="" cols="1" rows="1"
                            placeholder="Enter Your Address"
                            [(ngModel)]="supplierListById.address3"
                            class="form-control" type="text"
                            [readonly]="readonly" required></textarea>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="supplierListById.supplierAddress" >
                    <label for="address">Address 4</label>
                    <div class="d-flex">
                        <textarea name="" id="" cols="1" rows="1"
                            placeholder="Enter Your Address"
                            [(ngModel)]="supplierListById.supplierAddress"
                            class="form-control" type="text"
                            [readonly]="readonly" required></textarea>
                    </div>
                </div>

                <!-- row-2 -->
                <!-- <div class="row"> -->
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="state">Country<span
                            class="star_class">*</span></label>
                    <select class="form-control form-select"
                        [disabled]="readonly"
                        [(ngModel)]="supplierListById.countryId"
                        minlength="3"
                        maxlength="35" required>
                        <ng-container *ngFor="let value of countryDropdown">
                            <option [value]="value.id"
                                [disabled]="readonly">{{value.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.stateId> 0">
                    <label for="state">State<span
                            class="star_class">*</span></label>
                    <select class="form-control form-select"
                        [disabled]="readonly"
                        [(ngModel)]="supplierListById.stateId"
                        minlength="3"
                        maxlength="35" required>
                        <ng-container *ngFor="let value of stateDropdown">
                            <option [value]="value.id"
                                [disabled]="readonly">{{value.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.otherState">
                    <label for="">Other State<span class="star_class">*</span></label>
                    <input type="text" class="form-control" disabled
                        [(ngModel)]="supplierListById.otherState"
                        placeholder="Enter Your State">
                </div>
                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.otherState">
                    <label for="">City<span class="star_class">*</span></label>
                    <input type="text" class="form-control" disabled
                        [(ngModel)]="supplierListById.city"
                        placeholder="Enter Your City">
                </div>
                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.otherState">
                    <label for="">Pincode<span class="star_class">*</span></label>
                    <input type="text" class="form-control" disabled
                        [(ngModel)]="supplierListById.pinCode"
                        placeholder="Enter Pincode">
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="city">Purpose<span
                            class="star_class">*</span></label>
                    <select class="form-control form-select"
                        [disabled]="readonly"
                        [(ngModel)]="supplierListById.purposeId"
                        minlength="3"
                        maxlength="35" required>
                        <ng-container *ngFor="let value of
                            purposeDropdown">
                            <option [value]="value.id"
                                [disabled]="readonly">{{value.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.services">
                    <label for="city">Service Provided<span
                            class="star_class">*</span></label>
                    <input type="text" class="form-control"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.services"
                        autocomplete="off"
                        placeholder="Services" required />
                </div>

                <!-- row-3 -->
                <div class="col-lg-4 col-md-12 form-group" *ngIf="supplierListById.interCompany">
                    <label for="city">In case of Intercompany correct Co. No. In
                        GL off</label>
                    <input class="form-control"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.interCompany"
                        autocomplete="off" placeholder="Enter CO No." />
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="credit">Payment Terms<span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.payTermsId"
                        autocomplete="off" placeholder="Enter CO
                        No." />
                </div>

                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.documentUploadPath
                    && !displayProfile">
                    <label for="">Organization Profile</label>
                    <span class="form-control"><a
                            [href]="supplierListById.documentUploadPath"
                            target="_blank"
                            [download]="supplierListById.documentUploadPath">Preview</a></span>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="displayProfile
                    || supplierListById.documentUploadPath == ''">
                    <label for="">Organization Profile</label>
                    <span>-</span>
                    <!-- <input class="form-control" type="file"
                                        #organizationProfile
                                        (change)="upload_doc($event ,
                                        'organizationProfile')"
                                        accept="image/jpeg,image/gif,image/png,application/pdf"
                                        autocomplete="off" required /> -->
                </div>

                <div class="col-lg-4 col-md-12 form-group">
                    <label for="tds-applicable">TDS Applicable<span
                            class="star_class">*</span></label>
                    <input type="text" class="form-control"
                        *ngIf="supplierListById.tdsApplicable"
                        value="Yes" [readonly]="readonly"
                        autocomplete="off"
                        placeholder="Services" required />
                    <input type="text" class="form-control"
                        *ngIf="!supplierListById.tdsApplicable"
                        value="No" [readonly]="readonly"
                        autocomplete="off"
                        placeholder="Services" required />
                </div>



                <div class="col-lg-4 col-md-12 form-group">
                    <label for="email">Whether Domestic or
                        Foreign <span
                            class="star_class">*</span></label>
                    <select class="form-control form-select"
                        [disabled]="true"
                        [(ngModel)]="supplierListById.isDomestic"
                        minlength="3"
                        maxlength="35" required>
                        <option value="false" selected>Domestic</option>
                        <option value="true">Foreign</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group"
                    *ngIf="supplierListById.isDomestic">
                    <label for="email">If Foreign give Currency
                        <span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.currency"
                        id="currency"
                        placeholder="Enter Currency Type" />
                </div>
            </div>

            <!-- Tax Registration Details -->


            <div class="section-heading">
                <h5>Tax Registration Details</h5>
            </div>

            <!-- row-5 -->
            <div class="row" *ngIf="!supplierListById.isDomestic">
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="city">GSTIN<span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.gstinNo"
                        placeholder="Enter GSTIN"
                        minlength="15"
                        maxlength="15" autocomplete="off"
                        required/>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="city">GSTIN Date<span
                            class="star_class">*</span></label>
                    <p-calendar [disabled]="readonly"
                        [(ngModel)]="supplierListById.date"
                        placeholder="Select
                        GSTIN Date" required></p-calendar>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="city">PAN <span
                            class="star_class">*</span></label>
                    <input class="form-control" type="text"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.panNo"
                        minlength="10"
                        maxlength="10" required
                        autocomplete="off" />
                </div>
            </div>

            <!-- row-6 -->
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group" *ngIf="supplierListById.tanNo">
                    <label for="city">TAN Number</label>
                    <input class="form-control"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.tanNo"
                        placeholder="Enter TAN Number" minlength="10"
                        maxlength="10"
                        autocomplete="off" />
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="email">MSME Applicable<span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        *ngIf="supplierListById.msmeApplicable"
                        value="Yes"
                        placeholder="Enter TAN Number"
                        minlength="10"
                        maxlength="10" autocomplete="off" />
                    <input class="form-control"
                        [readonly]="readonly"
                        *ngIf="!supplierListById.msmeApplicable"
                        value="No"
                        placeholder="Enter TAN Number"
                        minlength="10"
                        maxlength="10" autocomplete="off" />
                </div>
            </div>



            <!-- Vendor Contact Details -->

            <div class="section-heading">
                <h5>Supplier Contact Details</h5>
            </div>

            <!-- row-7 -->
            <div class="row">
                <div class="col-lg-4 form-group">
                    <label for="contact">Contact Person<span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        placeholder="Enter
                        Contact Person"
                        [(ngModel)]="supplierListById.contactPerson"
                        autocomplete="off" required/>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="tel">Contact Number<span
                            class="star_class">*</span></label>
                    <input class="form-control" maxlength="10"
                        [(ngModel)]="supplierListById.mobileNo"
                        maxlength="10"
                        placeholder="Enter Contact Number"
                        autocomplete="off" [readonly]="readonly"
                        required/>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="tel">Alternate Contact Number</label>
                    <input class="form-control" maxlength="10"
                        [(ngModel)]="supplierListById.faxNo"
                        maxlength="10"
                        placeholder="Enter Contact Number"
                        autocomplete="off" [readonly]="readonly"
                        required/>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="email">Email Address<span
                            class="star_class">*</span></label>
                    <span class="form-control">{{supplierListById.emailAddress}}</span>
                </div>
                <div class="col-lg-4 form-group" *ngIf="supplierListById.alternateEmail">
                    <label for="email">Alternate Email Address</label>
                    <input class="form-control" type="text"
                        [disabled]="readonly"
                        [(ngModel)]="supplierListById.alternateEmail"
                        placeholder="Enter Email Address"
                        autocomplete="off" required/>
                </div>
            </div>

            <!-- Vendor Bank Details -->
            <div class="section-heading">
                <h5>Bank Details</h5>
            </div>
            <!-- row-8-->
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label for="ifscCode">Account Type<span
                            class="star_class">*</span></label>
                    <div style="display: flex;">
                        <div style="display: flex; align-items:
                            center; margin:
                            0 5px;">
                            <input type="radio" value="C"
                                [(ngModel)]="supplierListById.accountType"
                                autocomplete="off" disabled /> <span
                                style="margin: 0
                                2px; font-size: 13px;">Current
                                Account</span>
                        </div>
                        <div style="display: flex; align-items:
                            center;">
                            <input type="radio"
                                [(ngModel)]="supplierListById.accountType"
                                value="S"
                                autocomplete="off" disabled /><span
                                style="margin: 0
                                2px; font-size: 13px;">Saving
                                Account</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label for="bankName">Bank Name<span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        type="text"
                        [(ngModel)]="supplierListById.bankName"
                        placeholder="Enter Bank Name"
                        autocomplete="off" />
                </div>
                <div class="col-lg-3 form-group">
                    <label for="account">Account No.<span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        type="text"
                        [(ngModel)]="supplierListById.accountNo"
                        maxlength="16"
                        placeholder="Enter Account No."
                        autocomplete="off" />

                </div>
                <div class="col-lg-3 form-group">
                    <label for="ifscCode">IFSC Code or Swift
                        Code <span
                            class="star_class">*</span></label>
                    <input class="form-control"
                        [readonly]="readonly"
                        type="text"
                        [(ngModel)]="supplierListById.ifscCode"
                        placeholder="Enter IFSC Code"
                        autocomplete="off" />
                </div>

                <div class="col-lg-3 form-group" *ngIf="supplierListById.micrNo">
                    <label for="ifscCode">MICR No.</label>
                    <input class="form-control"
                        [readonly]="readonly"
                        type="text"
                        maxlength="10"
                        [(ngModel)]="supplierListById.micrNo"
                        placeholder="Enter MICR No."
                        autocomplete="off" />
                </div>

                <div class="col-lg-3 form-group" *ngIf="supplierListById.bankAddress">
                    <label for="ifscCode">Bank Address</label>
                    <textarea class="form-control" readonly name="" id=""
                        cols="30" rows="10"
                        [(ngModel)]="supplierListById.bankAddress"
                        placeholder="Enter Bank Address"></textarea>
                    <!-- <input class="form-control"
                        [readonly]="readonly"
                        type="text"
                        maxlength="10"
                        [(ngModel)]="supplierListById.bankAddress"
                        placeholder="Enter Bank Address"
                        autocomplete="off" /> -->
                </div>

                <div class="col-lg-3 form-group"
                    *ngIf="supplierListById.cancelledCheque &&
                    !displayCheque">
                    <label for="account">Cancel Cheque<span
                            class="star_class">*</span></label>
                    <span class="form-control"><a
                            [href]="supplierListById.cancelledCheque"
                            target="_blank"
                            [download]="supplierListById.cancelledCheque">Preview</a></span>
                </div>
                <div class="col-lg-3 form-group"
                    *ngIf="displayCheque ||
                    supplierListById.cancelledCheque == ''">
                    <label for="account">Cancel Cheque<span
                            class="star_class">*</span></label>
                    <span>-</span>
                    <!-- <input class="form-control" type="file"
                                        #cancelCheque
                                        (change)="upload_doc($event ,
                                        'cancelCheque')"
                                        accept="image/jpeg,image/gif,image/png,application/pdf"
                                        autocomplete="off" required /> -->
                </div>
            </div>

            <!-- previous 3 years Turnover -->
            <div class="section-heading">
                <h5>Turnover Details</h5>
            </div>

            <!-- row-9 -->
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label for="city">Year of Establishment:</label>
                </div>
                <div class="col-lg-3 form-group">
                    <p-calendar autocomplete="off" view="year"
                        dateFormat="yy"
                        [showIcon]="true" [disabled]="readonly"
                        [(ngModel)]="supplierListById.yearOfEstablished"></p-calendar>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3 form-group d-flex
                    align-items-center">
                    <label for="city" style="margin: 0;">Upload Balance Sheet :</label>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 form-group" style="display:
                    flex;
                    justify-content:left; align-items: center;
                    text-align:
                    center;">
                    <label for="city">Turnover (Year 1)</label>
                </div>
                <div class="col-lg-3 form-group">
                    <p-calendar autocomplete="off" view="year"
                        dateFormat="yy"
                        [disabled]="readonly" [maxDate]="myDate"
                        (onSelect)="setYears('year1')"
                        [showIcon]="true"
                        [(ngModel)]="supplierListById.year1"
                        placeholder="Please Select Year"></p-calendar>
                </div>
                <div class="col-lg-3 form-group">
                    <div class="d-flex">
                        <select class="form-select form-control
                            select-currency"
                            style="width: 80px; line-height :
                            22px; display:
                            flex; align-items:
                            center; border-right:
                            none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                            background-color:
                            #f1f1f1!important;"
                            [disabled]="readonly"
                            [(ngModel)]="supplierListById.currencyId">
                            <option value=""></option>
                            <ng-container *ngFor="let currency
                                of
                                currencyDropdown">
                                <option [value]="currency.id">{{currency.value}}</option>
                            </ng-container>
                        </select>

                        <input class="form-control"
                            style="border-left:
                            none!important;
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;"
                            type="text" disabled
                            [disabled]="readonly"
                            placeholder="Enter Year Turnover"
                            [(ngModel)]="supplierListById.turnover1"
                            />
                    </div>
                </div>
                <div class="col-lg-3 form-group"
                    *ngIf="supplierListById.balanceSheet1 &&
                    !displayYear1">
                    <span class="form-control"><a
                            [href]="supplierListById.balanceSheet1"
                            target="_blank"
                            [download]="supplierListById.balanceSheet1">Preview</a></span>
                </div>
                <div class="col-lg-3 form-group" style="text-align: center;"
                    *ngIf="displayYear1 ||
                    supplierListById.balanceSheet1 == ''">
                    <span>-</span>
                    <!-- <input class="form-control" type="file"
                                        #year1File
                                        accept="image/jpeg, image/png ,
                                        application/jpeg"
                                        (change)="upload_doc($event , 'year1')"
                                        autocomplete="off" /> -->
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 form-group" style="display:
                    flex;
                    justify-content:left; align-items: center;
                    text-align:
                    center;">
                    <label for="city">Turnover (Year 2)</label>
                </div>
                <div class="col-lg-3 form-group">
                    <p-calendar autocomplete="off" view="year"
                        dateFormat="yy"
                        [disabled]="readonly"
                        (onSelect)="setYears('year2')"
                        [showIcon]="true"
                        [(ngModel)]="supplierListById.year2"
                        placeholder="Please Select Year"></p-calendar>
                </div>
                <div class="col-lg-3 form-group">
                    <div class="d-flex">
                        <select class="form-select form-control
                            select-currency"
                            style="width: 80px;
                            line-height : 22px; display: flex;
                            align-items:
                            center; border-right:
                            none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                            background-color:
                            #f1f1f1!important;" disabled
                            [(ngModel)]="supplierListById.currencyId">
                            <option value=""></option>
                            <ng-container *ngFor="let currency
                                of
                                currencyDropdown">
                                <option [value]="currency.id">{{currency.value}}</option>
                            </ng-container>
                        </select>
                        <input class="form-control"
                            style="border-left:
                            none!important;
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;"
                            type="text"
                            [disabled]="readonly"
                            placeholder="Enter Year Turnover"
                            [(ngModel)]="supplierListById.turnover2"
                            />
                    </div>
                </div>
                <div class="col-lg-3 form-group"
                    *ngIf="supplierListById.balanceSheet2 &&
                    !displayYear2">
                    <span class="form-control"><a
                            [href]="supplierListById.balanceSheet2"
                            target="_blank"
                            [download]="supplierListById.balanceSheet2">Preview</a></span>
                </div>
                <div class="col-lg-3 form-group" style="text-align: center;"
                    *ngIf="displayYear2 ||
                    supplierListById.balanceSheet2 == ''">
                    <span>-</span>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 form-group" style="display:
                    flex;
                    justify-content:left; align-items: center;
                    text-align:
                    center;">
                    <label for="city">Turnover (Year 3)</label>
                </div>
                <div class="col-lg-3 form-group">
                    <p-calendar autocomplete="off" view="year"
                        dateFormat="yy"
                        [disabled]="readonly" [showIcon]="true"
                        (onSelect)="setYears('year3')"
                        [(ngModel)]="supplierListById.year3"
                        placeholder="Please
                        Select Year"></p-calendar>
                </div>
                <div class="col-lg-3 form-group">
                    <div class="d-flex">
                        <select class="form-select form-control
                            select-currency"
                            style="width: 80px;
                            line-height : 22px; display: flex;
                            align-items:
                            center; border-right:
                            none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                            background-color:
                            #f1f1f1!important;" disabled
                            [(ngModel)]="supplierListById.currencyId">
                            <option value=""></option>
                            <ng-container *ngFor="let currency of
                                currencyDropdown">
                                <option [value]="currency.id">{{currency.value}}</option>
                            </ng-container>
                        </select>
                        <input class="form-control"
                            style="border-left:
                            none!important;
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;"
                            type="text"
                            [disabled]="readonly"
                            placeholder="Enter Year Turnover"
                            [(ngModel)]="supplierListById.turnover3"
                            />
                    </div>
                </div>
                <div class="col-lg-3 form-group"
                    *ngIf="supplierListById.balanceSheet3 &&
                    !displayYear3">
                    <span class="form-control"><a
                            [href]="supplierListById.balanceSheet3"
                            target="_blank"
                            [download]="supplierListById.balanceSheet3">Preview</a></span>
                </div>
                <div class="col-lg-3 form-group" style="text-align: center;"
                    *ngIf="displayYear3 ||
                    supplierListById.balanceSheet3 == ''">
                    <span>-</span>
                    <!-- <input class="form-control" type="file"
                                        #year3File
                                        accept="image/jpeg, image/png ,
                                        application/jpeg"
                                        (change)="upload_doc($event , 'year3')"
                                        autocomplete="off" /> -->
                </div>
            </div>


            <div
                class="section-heading"*ngIf="supplierListById.supplierDirectorsDetails?.length>
                0" >
                <h5>Authorized Signatory Details</h5>
            </div>

            <!-- row-10 -->

            <ng-container *ngFor="let director of
                supplierListById.supplierDirectorsDetails; index
                as idx; last as
                last">
                <div class="row">
                    <div class="form-group col-lg-4 col-md-4"
                        style="margin-top:
                        0.3rem!important;">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">
                            Name<span class="star_class">*</span></label>
                        <input class="form-control" type="text"
                            [readonly]="readonly"
                            placeholder="Enter Authorized
                            Signatory Name"
                            [(ngModel)]="director.directorName"
                            autocomplete="off" />
                    </div>
                    <div class=" form-group col-lg-4 col-md-4"
                        style="margin-top: 0.3rem!important;">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">
                            Contact Number<span class="star_class">*</span></label>
                        <input class="form-control" type="text"
                            [readonly]="readonly"
                            placeholder="Enter Authorized Signatory Contact"
                            [(ngModel)]="director.mobileNo"
                            maxlength="10"
                            minlength="10" autocomplete="off" />
                    </div>
                    <div class="form-group col-lg-4 col-md-4"
                        style="margin-top:
                        0.3rem!important;">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">Email
                            Address<span class="star_class">*</span></label>
                        <input class="form-control" type="text"
                            [readonly]="readonly"
                            [(ngModel)]="director.email"
                            placeholder="Enter Director Email Address"
                            autocomplete="off" />
                    </div>
                </div>
            </ng-container>
            <div class="section-heading"
                *ngIf="supplierListById.supplierMajorCustomersDetails?.length>0">
                <h5>Major Customer</h5>
            </div>
            <div class="row">
                <ng-container *ngFor="let majorCustomer of
                    supplierListById.supplierMajorCustomersDetails;
                    index as
                    idx; last as last">
                    <div class="form-group col-lg-3 col-md-12"
                        style="margin-top: 0.3rem!important;" *ngIf="majorCustomer.organizationName">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">Organization
                            Name</label>
                        <input class="form-control"
                            [readonly]="readonly"
                            type="text"
                            placeholder="Enter Organization
                            Name"
                            [(ngModel)]="majorCustomer.organizationName"
                            autocomplete="off" />
                    </div>
                    <div class="form-group col-lg-3 col-md-12"
                        style="margin-top: 0.3rem!important;" *ngIf="majorCustomer.contactPerson">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">Contact
                            Person</label>
                        <input class="form-control"
                            [readonly]="readonly"
                            type="text"
                            placeholder="Enter Contact Person
                            Name"
                            [(ngModel)]="majorCustomer.contactPerson"
                            autocomplete="off" />
                    </div>
                    <div class="form-group col-lg-2 col-md-12" *ngIf="majorCustomer.designation"
                        style="margin-top: 0.3rem!important;">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">Designation</label>
                        <input class="form-control"
                            [readonly]="readonly"
                            type="text"
                            placeholder="Enter Your Designation"
                            [(ngModel)]="majorCustomer.designation"
                            autocomplete="off" />
                    </div>
                    <div class="form-group col-lg-2 col-md-12"
                        style="margin-top: 0.3rem!important;" *ngIf="majorCustomer.email">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">Email
                            Address</label>
                        <input class="form-control"
                            [readonly]="readonly"
                            type="text"
                            placeholder="Enter Email Address"
                            [(ngModel)]="majorCustomer.email"
                            autocomplete="off"
                            />
                    </div>
                    <div class="form-group col-lg-2 col-md-12"
                        style="margin-top: 0.3rem!important;" *ngIf="majorCustomer.mobileNo">
                        <label for="" style="margin-bottom:
                            0.3rem!important;">Mobile
                            no.</label>
                        <input class="form-control"
                            [readonly]="readonly"
                            type="text"
                            placeholder="Enter Mobile No."
                            [(ngModel)]="majorCustomer.mobileNo"
                            autocomplete="off" />
                    </div>
                </ng-container>
            </div>
            <div class="section-heading">
                <h5>Manufacturing Scope</h5>
            </div>
            <!-- row-11 -->
            <div class="row">
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="">Manufacturing List</label>
                    <p-multiSelect [filter]="false"
                        [showToggleAll]="false"
                        [options]="manufacturingArray"
                        [disabled]="readonly"
                        (onChange)="selectManufacturingList('manufacture')"
                        [(ngModel)]="supplierListById.supplierManufacturingDropdown"
                        class="multiselect-custom-virtual-scroll"
                        placeholder="Select manufacture name"
                        optionLabel="value" optionValue="id"></p-multiSelect>
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="">Material Construction List</label>
                    <p-multiSelect [filter]="false"
                        [ngModelOptions]="{standalone: true}"
                        [showToggleAll]="false"
                        (onChange)="selectManufacturingList('material')"
                        [disabled]="readonly"
                        [(ngModel)]="supplierListById.supplierMaterialDropdown"
                        class="multiselect-custom-virtual-scroll"
                        [options]="materialConstructionArray"
                        placeholder="Select material name"
                        optionValue="id"
                        optionLabel="value"></p-multiSelect>
                </div>

                <div class="col-lg-3 col-md-12 form-group">
                    <label for="">Other Category</label>
                    <select class="form-control form-select"
                        [(ngModel)]="supplierListById.otherCategoryId"
                        [disabled]="readonly">
                        <option value="0">Select Other Category</option>
                        <ng-container *ngFor="let dropdown of
                            otherCategoryArray">
                            <option [value]="dropdown.id">{{dropdown.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-3 col-md-12 form-group"
                    *ngIf="supplierListById.otherRemark">
                    <label for="">Other Remarks</label>
                    <input type="text" class="form-control"
                        [readonly]="readonly"
                        [(ngModel)]="supplierListById.otherRemark"
                        placeholder="Enter Other Remarks">
                </div>
                <!-- <div class="add-listings-btn col-lg-2 col-md-12 form-group"
                    *ngIf="!readonly">
                    <div class="submit-toggle1">
                        <button type="submit" class="submit_btn"
                            (click)="captureStatus(supplierListById ,
                            'Update')">Update</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [header]="this.jdeHeader" [(visible)]="displayJde"
    [modal]="true"
    [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="row">
        <form action="" #jdeSubmit="ngForm"
            (ngSubmit)="submitJDE(jdeSubmit , 'rework')">
            <div class="col-lg-12 col-md-12 form-group m-0">
                <label for="first-name">Remark<span
                        class="star_class">*</span></label>
                <input type="text" [(ngModel)]="jdeForm.isApprovedRemark"
                    class="form-control"
                    placeholder="Enter Remark" name="isApprovedRemark"
                    autocomplete="off"
                    />
                <div *ngIf="jdeForm?.isApprovedRemark.length == 0 &&
                    jdeSubmit.submitted" class="error-msg">Please Enter Remark</div>
            </div>
            <div class="add-listings-btn col-lg-12 col-md-12
                form-group">
                <div class="submit-toggle1">
                    <button type="submit" class="submit_btn">Send</button>
                </div>
            </div>
        </form>
    </div>
</p-dialog>

<ng-container *ngIf="displayFinance == 'true'">
    <p-dialog header="Supplier Details" [(visible)]="displayFinance"
        [modal]="true"
        [style]="{width: '70vw'}" [draggable]="false"
        [resizable]="false">
        <app-finance [supplierList]="supplierListById"
            (displayFinance)="callFunction($event)"></app-finance>
    </p-dialog>
</ng-container>

<p-dialog header="Supplier Unblock Remark" [(visible)]="displayRemarkDialog"
    [modal]="true"
    [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="row">
        <div class="col-lg-12 form-group">
            <input class="form-control" type="text" [(ngModel)]="unblockRemark"
                placeholder="Enter Remark for Unblock">
        </div>
        <div class="col-lg-12">
            <div class="add-listings-btn">
                <button class="submit_btn" (click)="blockSupplier()">Submit</button>
            </div>
        </div>
    </div>
</p-dialog>

<!-- <app-footer-style-one></app-footer-style-one> -->