import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/api-service.service';

@Component({
  selector: 'app-dashboard-sidemenu',
  templateUrl: './dashboard-sidemenu.component.html',
  styleUrls: ['./dashboard-sidemenu.component.scss']
})
export class DashboardSidemenuComponent implements OnInit {

  roleName : any ;
  checker2 : any
  // adminRoutes : any = [];

  state: boolean = false;
  constructor(private router : Router , private apiService : ApiServiceService) { }

  userRole : any;
  ngOnInit(): void {      
    let Storage = localStorage.getItem('UserObject');
    if(Storage){
      this.userRole = JSON.parse(Storage)
      this.roleName = JSON.parse(Storage).roleName;
    }

  }

  logOut(){
    this.apiService.showMessage('Logout Successfully' , 'success')
    localStorage.removeItem('UserObject');
    this.router.navigateByUrl('/')
  }

  reloadPage(string:any){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([string]);
  }
  
}
