import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmationService, Message } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
})
export class CustomerListComponent implements OnInit {
  @ViewChild('dt2') dataTable: Table;
  customerList: any = [];
  breadcrumb = [
    {
      title: 'Customer Master',
      subTitle: 'Dashboard',
    },
  ];

  customerType: any = [
    { name: 'Distributor', id: 1 },
    { name: 'End/Direct Customer', id: 2 },
    { name: 'Agent', id: 3 }
  ]

  filterval: string;
  dateFilterVal: string;
  countryDropdown: any;
  readonly: boolean = true;
  unblockRemark: string = '';
  currencyDropdown: any;
  paymentDropdown: any;
  userroleName: any;
  msgs: Message[] = [];
  customerCategory: any;
  incotermsDropdown: any = [];
  applicationTypeDropdown: any;
  industryDropdown: any;
  freightCodeDropdown: any;
  regionDropdown: any;
  subRegionDropdown: any;
  companyOwnershipDropdown: any;
  marketSegmentDropdown: any;
  marketSubSegmentDropdown: any;
  displayRemarkDialog: boolean;
  inco: any = '0';
  branchPlantDropdown: any = [];
  paymentTermsList: any = [];
  customerBuDropdown: any = [];
  documentForm: FormGroup;
  customerId: any;
  attachedDocuments: any[] = [];
  isDisable: boolean = false
  excelData: any[] = [];
  filteredXLSData: any[] = [];
  emailAddress:any;
  userName:any;
  customerCode2:any;
  customerName:any;
  isApproved:any;
  csTeam:any;
  isVerify:any;

  status = [
    { label: 'Verified', value: 'Verified' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Rework', value: 'Rework' },
    { label: 'Rejected', value: 'Rejected' },
  ];
  approval = [
    { label: 'Approved for JDE', value: 'Approved' },
    { label: 'Registered In JDE', value: 'Registered' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Rework', value: 'Rework' },
    { label: 'Rejected', value: 'Rejected' },
  ];
  constructor(
    public _utility: AppUtility,
    private confirmationService: ConfirmationService,
    private _apiService: ApiServiceService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.documentForm = this.fb.group({
      documents: this.fb.array([
        this.createDocument() // Add one document field by default
      ])
    });
  }
  ngOnInit(): void {
    this.getState();
    this.getState(1);
    this.getAllCustomerDetail();
    this.userroleName = this._utility.getLocalStorageDetails();
    this.approvalStatus = [];
    if (this.userroleName.roleName == 'Customer-Approval') {
      this.approvalStatus.push(
        { name: 'Pending', value: 'Pending' },
        { name: 'Approved', value: 'Approved' },
        { name: 'Rework', value: 'Rework' },
        { name: 'Rejected', value: 'Rejected' },
        { name: 'Reworked But Not Reviewed', value: 'Reworked but not Reviewed' }
      );
    } else if (this.userroleName.roleName == 'Customer-JDE') {
      this.approvalStatus.push(
        { name: 'Pending', value: 'Pending' },
        { name: 'Registered', value: 'Registered' },
        { name: 'Approved', value: 'Approved' }
      );
    } else {
      this.approvalStatus.push(
        { name: 'Pending', value: 'Pending' },
        { name: 'Rework', value: 'Rework' },
        { name: 'Rejected', value: 'Rejected' },
        { name: 'Verified', value: 'Verified' },
        {
          name: 'Reworked But Not Reviewed',
          value: 'Reworked but not Reviewed',
        }
      );
    }
  }

  customerDetails: any;
  getAllCustomerDetail() {
    this._utility.loader(true);
    this._apiService.getCustomer().then((res: any) => {
      this._utility.loader(false);
      if (res.success == true) {
        this.customerList = res.returnValue;
      } else {
        this.customerList = [];
      }
    });
  }

  showModal: boolean = false;
  approvalStatus: any = [];
  async openModal(customer: any, action: any) {
    this.isDisable = false;
    this.documentForm.enable();
    this.customerId = ''
    this._utility.loader(true);
    console.log(customer);
    if (action == 'edit') {
      this.router.navigateByUrl(
        '/customer-form/' + 'edit/' + customer.customerId
      );
      this._utility.loader(false);
    }


    else {
      await this._apiService.getCustomerById(customer.customerId).then((res) => {
        if (res['success']) {
          this.customerDetails = res['returnValue'];
        }
        this.getAttachedDocuments(customer.customerId)
      });
      if (customer?.isApproved == 'Registered' || customer.isApproved == 'Approved') {
        this.isDisable = true;
        this.documentForm.disable();
      }
      console.log(this.customerDetails);
      this.customerCode = customer?.customerCode;
      if (this.userroleName.roleName == 'Customer-Approval') {
        this.userCapture = customer.isApproved == 'Registered'
          ? true
          : false;
        this.customerDetails.statusCapture =
          customer.isApproved == 'Registered'
            ? 'Approved'
            : customer.isApproved;
      }

      else if (this.userroleName.roleName == 'Finance-JDE') {
        this.userCapture = customer.isApproved == 'Registered' ? true : false;
        this.customerDetails.statusCapture =
          customer.isApproved == 'Registered'
            ? 'Approved'
            : customer.isApproved;

        this.customerCode = this.customerDetails.customerCode;
      }

      else if (this.userroleName.roleName == 'Customer-JDE') {
        this.userCapture = customer.isApproved == 'Registered' ? true : false;
        this.customerDetails.statusCapture =
          customer.isApproved == 'Registered'
            ? 'Approved'
            : customer.isApproved;

        this.customerCode = this.customerDetails.customerCode;
      }

      else {
        this.userCapture = customer.isVerify == 'Verified' ? true : false;
        this.customerDetails.statusCapture = customer.isVerify;
      }
      this.supplierRemark = this.customerDetails.status;
      console.log(this.customerDetails.statusCapture);
      this.customerId = customer.customerId;
      setTimeout(() => {
        this.showModal = true;
        this._utility.loader(false);
      }, 1500);
    }
  }

  userCapture: boolean = false;
  reset(dt2) {
    dt2.reset();
    this.filterval = '';
    this.dateFilterVal = '';
    this.emailAddress = '';
    this.userName = '';
    this.customerCode2 = '';
    this.customerName = '';
    this.csTeam = '';
    this.isVerify = '';
    this.isApproved = '';
  }

  stateDropdown: any = [];
  async getState(stateId?: any) {
    if (stateId) {
      let object = {
        Mode: 'state',
        cond3: stateId,
      };
      await this._apiService.dropdowndata('', object).then((res: any) => {
        if (res.success) {
          this.stateDropdown = res.returnValue;
        } else {
          this.stateDropdown = [];
        }
      });
    } else {
      await this._apiService.dropdowndata('country').then((res: any) => {
        if (res.success) {
          this.countryDropdown = res.returnValue;
        } else {
          this.countryDropdown = [];
        }
      });

      await this._apiService.dropdowndata('currency').then((res: any) => {
        if (res.success) {
          this.currencyDropdown = res.returnValue;
        } else {
          this.currencyDropdown = [];
        }
      });

      await this._apiService.dropdowndata('paymentTerms').then((res: any) => {
        if (res.success) {
          this.paymentDropdown = res.returnValue;
        } else {
          this.paymentDropdown = [];
        }
      });

      await this._apiService
        .dropdowndata('CustomerCategory')
        .then((res: any) => {
          if (res.success) {
            this.customerCategory = res.returnValue;
          } else {
            this.customerCategory = [
              { id: 1, value: 'category1' },
              { id: 2, value: 'category2' },
              { id: 3, value: 'category3' },
              { id: 4, value: 'category4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('Incoterms')
        .then((res: any) => {
          if (res.success) {
            this.incotermsDropdown = res.returnValue;
          } else {
            this.incotermsDropdown = [
              { id: 1, value: 'Incoterms1' },
              { id: 2, value: 'Incoterms2' },
              { id: 3, value: 'Incoterms3' },
              { id: 4, value: 'Incoterms4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('ApplicationType')
        .then((res: any) => {
          if (res.success) {
            this.applicationTypeDropdown = res.returnValue;
          } else {
            this.applicationTypeDropdown = [
              { id: 1, value: 'ApplicationType1' },
              { id: 2, value: 'ApplicationType2' },
              { id: 3, value: 'ApplicationType3' },
              { id: 4, value: 'ApplicationType4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('Industry')
        .then((res: any) => {
          if (res.success) {
            this.industryDropdown = res.returnValue;
          } else {
            this.industryDropdown = [
              { id: 1, value: 'Individual' },
              { id: 2, value: 'LLP' },
              { id: 3, value: 'Company' },
              { id: 4, value: 'Partnership' },
              { id: 5, value: 'Others' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('FreightHandlingCode')
        .then((res: any) => {
          if (res.success) {
            this.freightCodeDropdown = res.returnValue;
          } else {
            this.freightCodeDropdown = [
              { id: 1, value: 'freight_code1' },
              { id: 2, value: 'freight_code2' },
              { id: 3, value: 'freight_code3' },
              { id: 4, value: 'freight_code4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('Region')
        .then((res: any) => {
          if (res.success) {
            this.regionDropdown = res.returnValue;
          } else {
            this.regionDropdown = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('SubRegion')
        .then((res: any) => {
          if (res.success) {
            this.subRegionDropdown = res.returnValue;
          } else {
            this.subRegionDropdown = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('CompanyOwnership')
        .then((res: any) => {
          if (res.success) {
            this.companyOwnershipDropdown = res.returnValue;
          } else {
            this.companyOwnershipDropdown = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('marketSegment')
        .then((res: any) => {
          if (res.success) {
            this.marketSegmentDropdown = res.returnValue;
          } else {
            this.marketSegmentDropdown = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });

      await this._apiService
        .dropdowndata('marketSubSegment')
        .then((res: any) => {
          if (res.success) {
            this.marketSubSegmentDropdown = res.returnValue;
          } else {
            this.marketSubSegmentDropdown = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    this._apiService
      .dropdowndata('CustomerBUMaster')
      .then((res: any) => {
        if (res.success) {
          this.customerBuDropdown = res.returnValue;
        } else {
          this.customerBuDropdown = [
            { id: 1, value: 'BUMaster1' },
            { id: 2, value: 'BUMaster2' },
            { id: 3, value: 'BUMaster3' },
            { id: 4, value: 'BUMaster4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CustomerBussinessUnitNumber')
      .then((res: any) => {
        if (res.success) {
          this.branchPlantDropdown = res.returnValue;
        } else {
          this.branchPlantDropdown = [
            { id: 1, value: 'BussinessUnit1' },
            { id: 2, value: 'BussinessUnit2' },
            { id: 3, value: 'BussinessUnit3' },
            { id: 4, value: 'BussinessUnit4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (this.userLogin) {
      this._apiService.getpaymentTerms()
        .then((res: any) => {
          if (res.success) {
            this.paymentTermsList = res.returnValue;
          }

          else {
            this.paymentTermsList = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
          this.paymentTermsList = []
        });
    }
  }



  userLogin: any = JSON.parse(localStorage.getItem('UserObject'));
  supplierRemark: string = '';
  submitStatus(customer) {
    console.log(customer);
    let object = {};
    if (this.userroleName.roleName == 'CS Team') {
      if (
        customer.statusCapture != 'Pending' ||
        customer.statusCapture != 'Reworked but not reviewed'
      ) {
        if (customer.micrNo == 'Sold To Details') {
          if (customer.creditLimit && customer.paymentTerms && customer.branchPlant && (customer.businessUnit || customer.bussinessUnit)) {
            this.showModal = false;
            this._utility.loader(true);
            object = {
              isVerify: customer.statusCapture,
              isVerifyRemark: this.supplierRemark,
              customerId: customer.customerId,
              customerName: customer.customerName,
              branchPlant: customer.branchPlant,
              bussinessUnit: customer.bussinessUnit,
              userName: customer.userName,
              emailAddress: customer.emailAddress,
              customerAuditRemark: this.supplierRemark
            };

            this._apiService.customerVerify(object).then((res: any) => {
              if (res.success) {
                this._apiService.showMessage(res.message, 'success');
                this.getAllCustomerDetail();
                this._utility.loader(false);
              } else {
                this._apiService.showMessage(res.message, 'error');
                this._utility.loader(false);
                this.showModal = false;
              }
            });
          }

          else {
            this._apiService.showMessage(
              'Please Fill Customer Credit Limit or Payment Terms or Branch Plant or Bussiness Unit',
              'error'
            );
          }
        }

        else {
          if (customer.branchPlant && (customer.businessUnit || customer.bussinessUnit)) {
            this.showModal = false;
            this._utility.loader(true);
            object = {
              isVerify: customer.statusCapture,
              isVerifyRemark: this.supplierRemark,
              customerId: customer.customerId,
              customerName: customer.customerName,
              branchPlant: customer.branchPlant,
              bussinessUnit: customer.bussinessUnit,
              userName: customer.userName,
              emailAddress: customer.emailAddress,
              customerAuditRemark: this.supplierRemark
            };

            this._apiService.customerVerify(object).then((res: any) => {
              if (res.success) {
                this._apiService.showMessage(res.message, 'success');
                this.getAllCustomerDetail();
                this._utility.loader(false);
              } else {
                this._apiService.showMessage(res.message, 'error');
                this._utility.loader(false);
                this.showModal = false;
              }
            });
          }

          else {
            this._apiService.showMessage(
              'Please Fill Customer Branch Plant or Bussiness Unit',
              'error'
            );
          }
        }
      } else {
        this._apiService.showMessage(
          'Please Change Status and Submit the form',
          'error'
        );
      }
    }

    else if (this.userroleName.roleName == 'Finance-JDE' || this.userroleName.roleName == 'Customer-JDE') {
      this.showModal = false;
      this.getSelectedSupplier(customer);
    }

    else {
      this.showModal = false;
      this._utility.loader(true);
      object = {
        isApproved: customer.statusCapture,
        status: this.supplierRemark,
        customerId: customer.customerId,
        customerName: customer.customerName,
        emailAddress: customer.emailAddress,
        customerAuditRemark: this.supplierRemark
      };

      this._apiService.customerApproved(object).then((res: any) => {
        if (res.success) {
          this._apiService.showMessage(res.message, 'success');
          this.getAllCustomerDetail();
          this._utility.loader(false);
        } else {
          this._apiService.showMessage(res.message, 'error');
          this._utility.loader(false);
          this.showModal = false;
        }
      });
    }
  }

  // confirm1(customer: any) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure that you want to proceed?',
  //     header: 'Send for JDE Approval',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.getSelectedSupplier(customer);
  //       this.msgs = [
  //         {
  //           severity: 'info',
  //           summary: 'Confirmed',
  //           detail: 'You have accepted',
  //         },
  //       ];
  //     },
  //     reject: () => {
  //       this.msgs = [
  //         {
  //           severity: 'info',
  //           summary: 'Rejected',
  //           detail: 'You have rejected',
  //         },
  //       ];
  //     },
  //   });
  // }

  customerCode: any = '';
  getSelectedSupplier(customer) {
    console.log(customer);
    if (this.customerCode) {
      this._utility.loader(true);
      this.showModal = false;
      let object = {
        customerId: customer.customerId,
        emailAddress:customer.emailAddress,
        customerCode: this.customerCode,
      };
      this._apiService.customerSendForChecker2(object).then((res: any) => {
        this._utility.loader(false);
        console.log(res);
        if (res.success) {
          this._apiService.showMessage(res.message, 'success');
          this.getAllCustomerDetail();
        } else {
          this._apiService.showMessage(res.message, 'error');
        }
      });
    } else {
      this._apiService.showMessage('Please Enter Customer Code', 'error');
    }
  }

  dateFormat(string) {
    console.log(string);
    return new Date(string);
  }


  customerInfo: any;
  confirmblock(customer: any) {
    let message = !customer.isBlock ? 'Are You sure you want to Block Customer' : 'Are You sure you want to Unblock Customer'
    this.confirmationService.confirm({
      message: message,
      header: !customer.isBlock ? 'Block Customer Record' : 'Unblock Customer Record',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        if (customer.isBlock) {
          this.displayRemarkDialog = true;
          this.customerInfo = customer;
        }

        else {
          this.blockCustomer(customer, 'block');
        }
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  blockCustomer(customer?: any, string?: any) {
    let object: any;
    if (string == 'block') {
      this._utility.loader(true);
      console.log(customer);
      object = {
        isBlock: !customer.isBlock,
        customerId: customer.customerId
      }
    }
    else {
      this.displayRemarkDialog = false;
      object = {
        isBlock: !this.customerInfo.isBlock,
        customerId: this.customerInfo.customerId,
        unBlockRemark: this.customerInfo.unblockRemark
      }
    }
    this._apiService.blockCustomer(object).then((res: any) => {
      this._utility.loader(false);
      console.log(res);
      if (res.success) {
        this._apiService.showMessage(res.message, 'success');

        this.getAllCustomerDetail();
      }
      else {
        this._apiService.showMessage(res.message, 'error');
      }
    })
  }

  confirmDelete(customerId: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Delete Supplier Record',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSupplier(customerId);
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  deleteSupplier(customerId) {
    this._apiService.customerDelete(customerId).then((res: any) => {
      console.log(res);
      if (res.success) {
        this._apiService.showMessage(res.message, 'success');
        this.getAllCustomerDetail();
      }
      else {
        this._apiService.showMessage(res.message, 'error');
      }
    })
  }
  get documents(): FormArray {
    return this.documentForm.get('documents') as FormArray;
  }

  createDocument() {
    return this.fb.group({
      documentName: ['', Validators.required],
      documentFile: ['', Validators.required]
    });
  }

  addDocument() {
    this.documents.push(this.createDocument());
  }

  removeDocument(index: number) {
    if (this.documents.length > 1) { // Only remove if more than one document field
      this.documents.removeAt(index);
    }
  }

  uploadDoc(event: any, index: number) {
    const file = event.target.files[0]; // Get the first file from the input
    const documentControl = this.documents.at(index).get('documentFile');

    // Update the value of the documentFile form control with the selected file
    documentControl.setValue(file);
    debugger
  }
  submitForm() {
    if (this.documentForm.valid) {
      this._utility.loader(true);
      const formData = new FormData();
      const documentsArray = []; // Array to hold document data objects
      const documentFilesArray = []; // Array to hold document file objects

      this.documents.controls.forEach(control => {
        // Get the value of the documentName form control
        const documentName = control.get('documentName').value;

        // Append the documentName to the documentsArray
        documentsArray.push(documentName);

        // Get the value of the documentFile form control
        const documentFile = control.get('documentFile').value;

        // Append the documentFile to the documentFilesArray
        documentFilesArray.push(documentFile);
      });

      // Append the documentsArray to the formData object
      formData.append('NewDocumentName', documentsArray.join(','));

      // Append each documentFile to the formData object
      documentFilesArray.forEach((file, index) => {
        formData.append(`files`, file);
      });
      formData.append('IsNewDocument', 'true');
      formData.append('CustomerId', this.customerId);
      // Send the formData to the API
      this._apiService.attachCustomerDocuments(formData).then(response => {
        this._utility.loader(false);
        console.log('Response from API:', response);
        this._apiService.showMessage(response.message, 'success');
        this.getAttachedDocuments(this.customerId)
        // Reset the form and clear added fields
        this.documentForm.reset();
        this.documents.clear();
        this.documents.push(this.createDocument()); // Add a new document field

        // Clear the documentFile control
        this.documentForm.get('documents.0.documentFile').setValue(null);

      }, error => {
        this._utility.loader(false);
        console.error('Error posting data to API:', error);
        this._apiService.showMessage(error.message, 'error');
        // Handle error as needed
      });
    } else {
      this._apiService.showMessage('Please fill the data', 'error')
      console.log('Form is invalid');
    }
  }
  getAttachedDocuments(customerId) {
    this.attachedDocuments = [];
    // Assuming this._apiService.getCustomerDocument() returns a promise
    this._apiService.getCustomerDocument('true')
      .then((res) => {
        if (res.success === true) {
          // Assuming res.data contains the list of documents
          const attachedDocuments = res.returnValue;
          attachedDocuments.forEach((document) => {
            // Check if the document is new and belongs to the specified customerId
            if (document.isNewDocument && document.customerId === customerId) {
              this.attachedDocuments.push(document);
            }
          });
          console.log(this.attachedDocuments);
        } else {
          // Handle the case where the request was successful but no documents were found
          console.log("No documents found for the customer ID:", customerId);
        }
      })
      .catch((error) => {
        // Handle errors that occurred during the API call
        console.error("Error fetching customer documents:", error);
      });
  }
  onXLSExport() {
    this.excelData = [];
    let filteredBy
    this.filteredXLSData = [];
    console.log('dataTable', this.dataTable.filteredValue);
    if (this.dataTable.filteredValue != null) {
      this.filteredXLSData = this.dataTable.filteredValue;
      filteredBy= this._utility.getFilteredByName(this.dataTable)
    } else if (this.dataTable.filteredValue == null) {
      this.filteredXLSData = this.customerList;
    }
    console.log('filteredXLSData', this.filteredXLSData);
    for (let i = 0; i < this.filteredXLSData.length; i++) {
      // var state:any = '';
      var country:any = '';
      var customerCategory:any = ''; 
      var currency: any = '';
      var incoTerms:any = '';
      var business_unit:any='';
      var branch_Plant:any='';
      let state = '';  // Default value
      let shipStateId = this.filteredXLSData[i]?.shipStateId;
      let shipOtherState = this.filteredXLSData[i]?.shipOtherState;
    
      this.stateDropdown.forEach((key) => {
          if (key?.id === shipStateId) {
              state = key?.value;
          }
      });
      if (state === '' && shipOtherState) {
          state = shipOtherState;
      }
      console.log("state", state);
      
      this.countryDropdown.forEach((ele)=>{
        if(ele?.id == this.filteredXLSData[i]?.shipCountryId){
          country = ele?.value
          console.log("state",country);
        }
      });

      this.customerCategory.forEach((ele)=>{
        if(ele?.id == this.filteredXLSData[i]?.customerCategory){
          customerCategory = ele?.value
        }
      });
      this.currencyDropdown.forEach((ele)=>{
        if(ele?.id == this.filteredXLSData[i]?.currency){
          currency = ele?.value
        }
      });
      this.incotermsDropdown.forEach((inco)=>{
        if(inco?.id == this.filteredXLSData[i]?.incotermsId){
          incoTerms = inco?.value
          console.log("incoterms",incoTerms);
          
        }
      });
      this.customerBuDropdown.forEach((bu)=>{
        if(bu?.id == this.filteredXLSData[i]?.businessUnit){
          business_unit = bu?.value
          console.log("business_unit",business_unit);
          
        }
      });
      this.branchPlantDropdown.forEach((branch)=>{
        if(branch?.id == this.filteredXLSData[i]?.branchPlant){
          branch_Plant = branch?.value
          console.log("branch_Plant",branch_Plant);
          
        }
      });
      this.excelData.push({
        // 'Sr No': i + 1,
        'Customer_Name':
        this.filteredXLSData[i]?.customerName ?? '-',
        'PAN': this.filteredXLSData[i]?.gstin_no ?? '-',
        'Branch_Plant': branch_Plant??'-',
        'Search_Type' : 'C',
        'Address_Line_1' : this.filteredXLSData[i]?.shipAddress1 ?? '-',
        'Address_Line_2' : this.filteredXLSData[i]?.shipAddress2 ?? '-',
        'Address_Line_3' : this.filteredXLSData[i]?.shipAddress3 ?? '-',
        'Address_Line_4' : this.filteredXLSData[i]?.shipAddress4 ?? '-',
        'City' : this.filteredXLSData[i]?.shipCity ?? '-',
        'State' : state ?? '-',
        // 'State Code' : '',
        'Country': country ?? '-',
        'Pincode': this.filteredXLSData[i]?.shipPincode ?? '-',
        'Payable_Y_N_M': 'N',
        'AR_AP_Netting':'Y',
        'Industry_Classification': '14200',
        'Ticker': '',
        'Number_Of_Employee': '',
        'BU': '',
        'BU1': '',
        'Customer_Category': customerCategory ?? '-',
        'InterState': '',
        'Company': '14200',
        'Payment_Terms': this.filteredXLSData[i]?.paymentTerms ?? '-',
        'Currency_Code': currency ?? '-',
        'Base_Currency': 'INR',
        'Credit_Limit': this.filteredXLSData[i]?.creditLimit ?? '-',
        'Policy_Name': '14200',
        'Billing_Address_Type': this.filteredXLSData[i]?.micrNo ?? '-',
        'Related_Address_Number': '7',
        'Adjustment_Schedule': 'IDEXINAS',
        'Credit_Check_Level': 'L',
        'Invoice_Copies': '2',
        'Freight_Handling_Code': incoTerms??'-',
        'GSTNo': this.filteredXLSData[i]?.gstin_no ?? '-',
        'Select_Row':'1',
        'Phone_No': this.filteredXLSData[i]?.customerContactDetails[0]?.contactNumber,
        'Phone_Type':'BUS1',
        'Email_Address_Type':'E',
        "Email": this.filteredXLSData[i]?.emailAddress,
        'Contact_Person': this.filteredXLSData[i]?.customerContactDetails[0]?.contactPerson,
        'Idex Business Unit' : business_unit??'-',
        'Customer_UserName': this.filteredXLSData[i]?.userName ?? '-',
        'Customer_Rating':'',
        'CIN/LLP No': this.filteredXLSData[i]?.vanNumber ?? '-',
        'Payment_Term_Description':this.filteredXLSData[i]?.paymentTermsDesc ?? '-',
      });
    }
    this._apiService.exportExcel(this.excelData, 'Customer-list' , filteredBy);
  }

}
