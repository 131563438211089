import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-change-request-logs',
  templateUrl: './change-request-logs.component.html',
  styleUrls: ['./change-request-logs.component.scss']
})

export class ChangeRequestLogsComponent implements OnInit{
  stateDropdown: any;
  paymentDropdown: any;
  purposeDropdown: any;
  manufacturingArray: any;
  materialConstructionArray: any;
  otherCategoryArray: any;
  currencyDropdown: any;
  userroleName: any;
  approvalStatus: any = [];
  msgs: Message[] = [];
  filterval: string;
  dateFilterVal: string;
  constructor(private _utility : AppUtility , private _apiService : ApiServiceService , private confirmationService : ConfirmationService){}
  ngOnInit(){
    this.getStatic();
    this.userroleName = this._utility.getLocalStorageDetails();
    if (this.userroleName.checker1 == true) {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Verified', value: "Verified" }, { name: 'Rejected', value: "Rejected" })
    }
    else if (this.userroleName.roleName == 'Finance') {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Registered', value: "Registered" })
    }
    else if (this.userroleName.checker2 == true) {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Approved', value: "Approved" }, { name: 'Rejected', value: "Rejected" })
    }
    else {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Approved', value: "Approved" }, { name: 'Verified', value: "Verified" })
    }
    this.getAllSupplierDetail();
  }

  supplierList : any = [];
  display : boolean = false;
  getAllSupplierDetail() {
    this._utility.loader(true);
    this.supplierList = [];
    this._apiService.supplierChangesLog().then((res: any) => {
      console.log(res);
      this._utility.loader(false);
      if (res.success == true) {
        this.supplierList = res.returnValue;
        // res.returnValue.forEach((resp:any)=>{
        //   this.supplierList.push(resp);
        // })
      }
      else {
        this.supplierList = [];
      }
      console.log(this.supplierList);
      
    })
  }

  breadcrumb = [
    {
      title: 'Supplier Change Request List',
      subTitle: 'Dashboard'
    }
  ]

  getStatic() {
    let data = this._utility.getDropdownData();
    data.then((res) => {
      res.forEach((response: any) => {
        if (response.stateDropdown) {
          this.stateDropdown = response.stateDropdown;
        }
        else if (response.paymentDropdown) {
          this.paymentDropdown = response.paymentDropdown;
        }
        else if (response.purposeDropdown) {
          this.purposeDropdown = response.purposeDropdown;
        }
        else if (response.otherCategoryData) {
          this.otherCategoryArray = response.otherCategoryData;
        }
      });
    })

    this._apiService.dropdowndata('currency').then((res: any) => {
      this.currencyDropdown = res.returnValue;
    })
  }

  userCapture: any;
  readonly: boolean = false;
  supplierId : any;
  supplierListById : any = {};
  supplierJsonData : any = {};
  supplierRaiseRequest : any ;
  RaiseRequestData : any = [];
  certificates: any = [];
  async openModal(customer: any, string: any) {
    console.log(customer);
    this.supplierId = customer.currencyId;
    // this.supplierListById = JSON.parse(customer.jsonData);
    // this.supplierJsonData = JSON.parse(customer.previousData);
    // this._utility.loader(true);
    await this._apiService.supplierChangesLog(this.supplierId).then((res)=>{
      this._utility.loader(false);
      if (res.success == true) {
        this.display = true;
        this.RaiseRequestData = res.returnValue;
        this.supplierRaiseRequest = res.returnValue[0].supplierRaiseRequestList1;
        this.supplierListById = JSON.parse(res.returnValue[0]?.previousData)[0];
        this.supplierJsonData = JSON.parse(res.returnValue[0]?.jsonData)
      }
    })

    if (this.userroleName.roleName == 'Finance') {
      this.RaiseRequestData.statusCapture = this.RaiseRequestData.raiseApproved == 'Approved' ? 'Pending' : this.RaiseRequestData.raiseApproved;
      this.userCapture = this.RaiseRequestData.raiseApproved == 'Approved' ? false : true;
    }

    else if ((this.userroleName.checker2 == true)) {
      if (this.RaiseRequestData.raiseApproved == 'Pending' || this.RaiseRequestData.raiseApproved == 'Rework' || this.RaiseRequestData.raiseApproved == '') {
        this.RaiseRequestData.statusCapture = this.RaiseRequestData.raiseApproved == '' ? 'Pending' : this.RaiseRequestData.raiseApproved;
        if (this.RaiseRequestData.raiseVerify == 'Pending') {
          this.userCapture = true;
        }

        if(this.RaiseRequestData.raiseApproved == 'Rework'){
          this.remarkBoolean = true;
          this.remarkInput = this.RaiseRequestData.changeRemark;
        }
        
        else {
          this.userCapture = false;
        }
      }
      else {
        this.userCapture = true;
        this.supplierListById.statusCapture = 'Approved';
      }
    }

    else if (this.userroleName.checker1 == true) {
      this.supplierListById.statusCapture = this.supplierListById.raiseVerify;
      this.userCapture = this.supplierListById.raiseVerify == 'Pending' || this.supplierListById.raiseVerify == 'Rework' ? false : true;
      if(this.supplierListById.raiseVerify == 'Rejected'){
        this.remarkBoolean = true;
        this.remarkInput = this.supplierListById.raiseRemark;
      }
    }
    else {
      this.supplierListById.statusCapture = this.supplierListById.raiseApproved == 'Approved' ? this.supplierListById.raiseApproved : this.supplierListById.raiseApproved;
      this.userCapture = true;
    }
  }

  captureStatus(supplierListById: any , string: any) {
   console.log(string);
   if(string == 'Submit'){
    let msg: string = '';
    if (supplierListById.statusCapture != 'Pending') {
      // if (confirm("Do you want to approve supplier change request" + msg)) {
        console.log(supplierListById , this.supplierJsonData);
        this.supplierJsonData.NewcancelledCheque = this.supplierListById.NewcancelledCheque;
        this.supplierJsonData.organizationDocument = this.supplierListById.organizationDocument;
        let object = this.supplierJsonData;
        this._utility.loader(true);
        this.display = false;
        object['userName'] = this.userroleName.userName;
        if (this.userroleName.checker1 == true) {
          object['raiseVerify'] = supplierListById.statusCapture;
          object['raiseRemark'] = this.remarkInput;
          object['checker1'] = this.userroleName.checker1;
        }
        else if (this.userroleName.roleName == 'Finance') {
          object['raiseApproved'] = supplierListById.statusCapture;
        }
        else if (this.userroleName.checker2 == true) {
          object['raiseApproved'] = supplierListById.statusCapture;
          object['raiseRemark'] = this.remarkInput;
          object['checker2'] = this.userroleName.checker2;
        }
        let formData = new FormData(); 
        formData.append('jsonData', JSON.stringify(object))
        this._apiService.putRaiseRequest(formData)
        .then((res: any) => {
          this._utility.loader(false);
          console.log(res);
          if (res.success == true) {
            this._apiService.showMessage(res.message, "success");
            this.getAllSupplierDetail();
          }
          else {
            this._apiService.showMessage(res.message, "error");
          }
        })
      // }
    }

    else {
      this.display = false;
    }
   }
  }

  reset(dt2) {
    dt2.reset();
    this.filterval = '';
    this.dateFilterVal = ''
  }

  remarkBoolean : boolean = false;
  remarkInput : string = '';
  showRemark(event:any){
    console.log(event.target.value);
    if(event.target.value == 'Rejected'){
      this.remarkBoolean = true;
    }

    else{
      this.remarkBoolean = false;
    }
  }

  confirm1(customer: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Send for JDE Approval',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.getSelectedSupplier(customer);
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  getSelectedSupplier(customer) {
    console.log(customer , JSON.stringify(this.supplierJsonData));
    this.display = false;
    this._utility.loader(true);
    let object: any = {
      raiseApproved : 'Pending',
      supplierId  : customer.supplierId,
      supplierName : customer.supplierName
    };

    
    this._apiService.putRaiseRequestChecker2(object).then((res: any) => {
    this._utility.loader(false);
    if (res.success == true) {
      this._apiService.showMessage(res.message, 'success');
      this.getAllSupplierDetail();
    }
    else {
      this._apiService.showMessage(res.message, 'error');
    }
    })
  }
}

