<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="card">
        <p-table #dt2 [value]="customerList"
            dataKey="id" [rows]="10"
            responsiveLayout="scroll" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,20,30,50]"
            [paginator]="true" currentPageReportTemplate="Showing  {totalRecords} of {{customerList.length}} entries"
            [globalFilterFields]="['emailAddress','mobileNo','supplierAddress',
            'isVerify' , 'supplierName' , 'isApproved', 'jdeNo']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text"
                        (input)="dt2.filterGlobal($event.target.value,
                        'contains')"
                        placeholder="Global Search" [(ngModel)]="filterval" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)"
                        icon="bx bx-reset"></p-button>
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>Action</th>
                    <th>Change Requested Date</th>
                    <th>Checker1</th>
                    <th>Checker2</th>
                    <th>Supplier Name</th>
                    <th>Supplier Email</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <ng-container>
                    <tr class="p-selectable-row">
                        <td>{{i+1}}</td>
                        <td>
                            <button pButton pRipple type="button" icon="bx bx-show"
                                class="p-button-rounded p-button-primary
                                p-button-text"
                                (click)="openModal(customer ,'show')"></button>
                        </td>
                        <td>
                            <span class="image-text">{{customer.crDate | date}}</span>
                        </td>
                        <td style="white-space: nowrap;">
                            <button pButton pRipple type="button"
                                *ngIf="customer.raiseVerify == 'Verified'"
                                [label]="customer.raiseVerify"
                                class="p-button-rounded
                                p-button-success status_button"
                                style="font-size:
                                12px; padding:
                                0.2rem 1rem;"></button>
                            <button pButton pRipple type="button"
                                (click)="openModal(customer.supplierId)"
                                *ngIf="customer.raiseVerify == 'Pending'"
                                label="Pending" class="p-button-rounded
                                status_button pending"></button>
                            <button pButton pRipple type="button"
                                *ngIf="customer.raiseVerify == 'Rework'"
                                [label]="customer.raiseVerify"
                                class="p-button-rounded
                                p-button-info status_button"></button>
                            <button pButton pRipple type="button"
                                *ngIf="customer.raiseVerify == 'Rejected'"
                                [label]="customer.raiseVerify"
                                class="p-button-rounded
                                p-button-danger status_button"></button>
                        </td>
                        <td style="white-space: nowrap;">
                            <!-- <ng-container *ngIf="customer.raiseVerify == 'Verified'"> -->
                                <button pButton pRipple type="button"
                                    *ngIf="customer.raiseApproved == 'Approved'"
                                    label="Approved for JDE"
                                    class="p-button-rounded
                                    p-button-success status_button"></button>
                                <button pButton pRipple type="button"
                                    *ngIf="customer.raiseApproved == 'Registered'"
                                    label="Registered In JDE"
                                    class="p-button-rounded
                                    p-button-success registered_button status_button"></button>
                                <button pButton pRipple type="button"
                                    *ngIf="customer.raiseApproved == 'Pending'"
                                    label="Awaiting Checker2 Approval"
                                    class="p-button-rounded status_button
                                    pending"></button>
                                <a href="javascript:void(0)"
                                    *ngIf="customer.raiseApproved == '' &&
                                    customer.isVerify == 'Verified' &&
                                    userroleName.checker1 == true"
                                    (click)="confirm1(customer)">Send to Checker2</a>
                                <button pButton pRipple type="button"
                                    *ngIf="customer.raiseApproved == 'Rework'"
                                    [label]="customer.raiseApproved"
                                    class="p-button-rounded p-button-info
                                    status_button"></button>
                                <button pButton pRipple type="button"
                                    *ngIf="customer.raiseApproved == 'Rejected'"
                                    [label]="customer.raiseApproved"
                                    class="p-button-rounded p-button-danger
                                    status_button"></button>  
                                
                                <span *ngIf="customer.raiseVerify == 'Pending' && customer.raiseApproved == ''">-</span>
                            <!-- </ng-container> -->
                        </td>
                        <td>
                            <span>{{customer.customerName }}</span>
                        </td>
                        <td>
                            <span class="image-text">{{customer.emailAddress}}</span>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    
    <div class="flex-grow-1"></div>
    <!-- End -->

    <app-copyrights></app-copyrights>

</div>












<p-dialog header="Supplier Change Request" [(visible)]="display" [modal]="true"
    [style]="{width: '95vw'}" [draggable]="false" [resizable]="false">
    <div class="container-fluid authentication">
        <div class="signup-form">
            <div class="row">
                <div class="col-lg-6">
                    <h3 style="text-align: center;">Previous Details</h3>
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Customer Master')">
                        <div class="section-heading">
                            <h5>Customer Master</h5>
                        </div>
            
                        <!-- row-1 -->
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="first-name">Company Name<span class="star_class">*</span></label>
                                <input type="text" [readonly]="readonly" class="form-control" placeholder="Enter Supplier Name"
                                    [(ngModel)]="customerListById.customerName" autocomplete="off" minlength="3" maxlength='35'
                                    required />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById.purposeId">
                                <label for="">Customer Type</label>
                                <ng-container *ngFor="let customerType of customerType">
                                    <span class="form-control"
                                        *ngIf="customerListById.purposeId == customerType.id">{{customerType.name}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="email">Whether Domestic or Foreign <span class="star_class">*</span></label>
                                <span class="form-control">{{customerListById.supplierType}}</span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="email">Address Type <span class="star_class">*</span></label>
                                <span class="form-control">{{customerListById.micrNo}}</span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById.currency">
                                <label for="">Currency</label>
                                <ng-container *ngFor="let currency of currencyDropdown">
                                    <ng-container *ngIf="currency.id == customerListById.currency">
                                        <span class="form-control">{{currency.value}}</span>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById.intercompanyCorrectCONo">
                                <label for="city">In case of Intercompany correct Co. No. In
                                    GL off</label>
                                <input class="form-control" [readonly]="readonly"
                                    [(ngModel)]="customerListById.intercompanyCorrectCONo" autocomplete="off"
                                    placeholder="Enter CO No." />
                            </div>
                        </div>
                    </ng-container>
                    <!-- row-3 -->
        
                    <!-- Sold By -->
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Sold to Details')">
                        <div class="section-heading">
                            <h5>Sold to / Bill to Details</h5>
                        </div>
        
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 1<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.address1" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 2<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.address2" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 3<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.address3" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 4</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.addressWithPinCode" class="form-control" type="text"
                                        [readonly]="readonly"></textarea>
                                </div>
                            </div>
        
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="country">Country<span class="star_class">*</span></label>
                                <ng-container *ngFor="let value of countryDropdown">
                                    <span class="form-control"
                                        *ngIf="value.id == customerListById.countryId">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById.stateId> 0">
                                <label for="state">State<span class="star_class">*</span></label>
        
                                <ng-container *ngFor="let value of stateDropdown">
                                    <span class="form-control"
                                        *ngIf="value.id == customerListById.stateId">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById.otherState">
                                <label for="">State<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly"
                                    [(ngModel)]="customerListById.otherState" placeholder="Enter Your State">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">City<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly" [(ngModel)]="customerListById.city"
                                    placeholder="Enter Your City">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">Pincode<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly"
                                    [(ngModel)]="customerListById.pincode" placeholder="Enter Your pincode">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="email">Whether Domestic or Foreign <span class="star_class">*</span></label>
                                <span class="form-control">{{customerListById.supplierType == 'true' ? 'Foreign' :
                                    'Domestic'}}</span>
                            </div>
                            <div class="col-lg-3 col-md-12 form-group" *ngIf="customerListById.supplierType == 'true'">
                                <label for="email">If Foreign give Currency
                                    <span class="star_class">*</span></label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerListById.currency"
                                    id="currency" placeholder="Enter Currency Type" />
                            </div>
                        </div>
                    </ng-container>

                    <!-- Ship to Details -->
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Ship to Details')">
                        <div class="section-heading">
                            <h5>Ship to Details</h5>
                        </div>
        
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 1<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.shipAddress1" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 2<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.shipAddress2" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 3<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.shipAddress3" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 4</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerListById.ShipAddress4" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
        
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="country">Country<span class="star_class">*</span></label>
                                <ng-container *ngFor="let value of countryDropdown">
                                    <span class="form-control"
                                        *ngIf="value.id == customerListById.shipCountryId">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById?.shipCountryId == 1">
                                <label for="state">State<span class="star_class">*</span></label>
        
                                <ng-container *ngFor="let value of stateDropdown">
                                    <span class="form-control"
                                        *ngIf="value.id == customerListById.shipStateId">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById?.shipCountryId != 1">
                                <label for="">State<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly"
                                    [(ngModel)]="customerListById.shipOtherState" placeholder="Enter Your State">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">City<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly"
                                    [(ngModel)]="customerListById.shipCity" placeholder="Enter Your City">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">Pincode<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly"
                                    [(ngModel)]="customerListById.shipPincode" placeholder="Enter Your pincode">
                            </div>
                        </div>
                    </ng-container>

                    <!-- Tax Details -->
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Tax Registration')">
                        <div class="section-heading">
                            <h5>Tax Registration Details</h5>
                        </div>
            
                        <!-- row-5 -->
                        <div class="row" *ngIf="customerListById.supplierType != 'foreign'">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="city">GSTIN<span class="star_class">*</span></label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerListById.gstin_no"
                                    placeholder="Enter GSTIN" minlength="15" maxlength="15" autocomplete="off" required />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById.gstin_Date">
                                <label for="city">GSTIN Date<span class="star_class">*</span></label>
                                <span class="form-control">{{customerListById.gstin_Date | date}}</span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById.panNumber">
                                <label for="city">PAN Card<span class="star_class">*</span></label>
                                <input class="form-control" type="text" [readonly]="readonly"
                                    [(ngModel)]="customerListById.panNumber" minlength="10" maxlength="10" required
                                    autocomplete="off" />
                            </div>
                        </div>
            
                        <!-- row-6 -->
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="city">TAN Number</label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerListById.tanNumber"
                                    placeholder="Enter TAN Number" minlength="10" maxlength="10" autocomplete="off" />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="city">CIN/LLP Number</label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerListById.vanNumber"
                                    placeholder="Enter CIN/LLP Number" autocomplete="off" />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById?.supplierType == 'foreign'">
                                <label for="city">Other No</label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerListById.foreignNo"
                                    placeholder="Enter Other No" autocomplete="off" />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById?.gstinUpload">
                                <label for="city">GSTIN Upload<span class="star_class">*</span></label>
                                <span class="form-control"><a href="{{customerListById?.gstinUpload}}" target="_blank"
                                        download="{{customerListById?.gstinDocument}}">Preview</a></span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerListById?.panUpload">
                                <label for="city">PAN Upload<span class="star_class">*</span></label>
                                <span class="form-control"><a href="{{customerListById?.panUpload}}" target="_blank"
                                        download="{{customerListById?.panDocument}}">Preview</a></span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-lg-6" style="border-left: 1px dashed #eeeeee;">
                    <h3 style="text-align: center;">New Details</h3>
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Customer Master')">
                        <div class="section-heading">
                            <h5>Customer Master</h5>
                        </div>
            
                        <!-- row-1 -->
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="first-name">Company Name<span class="star_class">*</span></label>
                                <input type="text" [readonly]="readonly" class="form-control" placeholder="Enter Supplier Name"
                                    [(ngModel)]="customerJsonData.customerName" [ngClass]="[customerJsonData.customerName != customerListById.customerName ? 'field-css' : '']" autocomplete="off" minlength="3" maxlength='35'
                                    required />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData.purposeId">
                                <label for="">Customer Type</label>
                                <ng-container *ngFor="let customerType of customerType">
                                    <span class="form-control" [ngClass]="[customerJsonData.purposeId != customerListById.purposeId ? 'field-css' : '']"
                                        *ngIf="customerJsonData.purposeId == customerType.id">{{customerType.name}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="email">Whether Domestic or Foreign <span class="star_class">*</span></label>
                                <span class="form-control" [ngClass]="[customerJsonData.supplierType != customerListById.supplierType ? 'field-css' : '']">{{customerJsonData.supplierType}}</span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="email">Address Type <span class="star_class">*</span></label>
                                <span class="form-control" [ngClass]="[customerJsonData.micrNo != customerListById.micrNo ? 'field-css' : '']">{{customerJsonData.micrNo}}</span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData.currency">
                                <label for="">Currency</label>
                                <ng-container *ngFor="let currency of currencyDropdown">
                                    <ng-container *ngIf="currency.id == customerJsonData.currency">
                                        <span class="form-control">{{currency.value}}</span>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData.intercompanyCorrectCONo">
                                <label for="city">In case of Intercompany correct Co. No. In
                                    GL off</label>
                                <input class="form-control" [readonly]="readonly" [ngClass]="[customerJsonData.intercompanyCorrectCONo != customerListById.intercompanyCorrectCONo ? 'field-css' : '']"
                                    [(ngModel)]="customerJsonData.intercompanyCorrectCONo" autocomplete="off"
                                    placeholder="Enter CO No." />
                            </div>
                        </div>
                    </ng-container>
                    <!-- row-3 -->
        
                    <!-- Sold By -->
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Sold to Details')">
                        <div class="section-heading">
                            <h5>Sold to Details</h5>
                        </div>
        
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 1<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.address1" [ngClass]="[customerJsonData.address1 != customerListById.address1 ? 'field-css' : '']" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 2<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.address2" [ngClass]="[customerJsonData.address2 != customerListById.address2 ? 'field-css' : '']" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 3<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.address3" [ngClass]="[customerJsonData.address3 != customerListById.address3 ? 'field-css' : '']" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 4</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.addressWithPinCode" [ngClass]="[customerJsonData.addressWithPinCode != customerListById.addressWithPinCode ? 'field-css' : '']" class="form-control" type="text"
                                        [readonly]="readonly"></textarea>
                                </div>
                            </div>
        
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="country">Country<span class="star_class">*</span></label>
                                <ng-container *ngFor="let value of countryDropdown">
                                    <span class="form-control" [ngClass]="[customerJsonData.countryId != customerListById.countryId ? 'field-css' : '']"
                                        *ngIf="value.id == customerJsonData.countryId">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData.stateId> 0">
                                <label for="state">State<span class="star_class">*</span></label>
        
                                <ng-container *ngFor="let value of stateDropdown">
                                    <span class="form-control"
                                        *ngIf="value.id == customerJsonData.stateId" [ngClass]="[customerJsonData.stateId != customerListById.stateId ? 'field-css' : '']">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData.otherState">
                                <label for="">State<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly" [ngClass]="[customerJsonData.otherState != customerListById.otherState ? 'field-css' : '']"
                                    [(ngModel)]="customerJsonData.otherState" placeholder="Enter Your State">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">City<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly" [ngClass]="[customerJsonData.city != customerListById.city ? 'field-css' : '']" [(ngModel)]="customerJsonData.city"
                                    placeholder="Enter Your City">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">Pincode<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly"
                                    [(ngModel)]="customerJsonData.pincode" placeholder="Enter Your pincode">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="email">Whether Domestic or Foreign <span class="star_class">*</span></label>
                                <span class="form-control" [ngClass]="[customerJsonData.supplierType != customerListById.supplierType ? 'field-css' : '']">{{customerJsonData.supplierType == 'true' ? 'Foreign' :
                                    'Domestic'}}</span>
                            </div>
                            <div class="col-lg-3 col-md-12 form-group" *ngIf="customerJsonData.supplierType == 'true'">
                                <label for="email">If Foreign give Currency
                                    <span class="star_class">*</span></label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerJsonData.currency"
                                    id="currency" placeholder="Enter Currency Type" />
                            </div>
                        </div>
                    </ng-container>

                    <!-- Ship to Details -->
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Ship to Details')">
                        <div class="section-heading">
                            <h5>Ship to Details</h5>
                        </div>
        
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 1<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" [ngClass]="[customerJsonData.shipAddress1 != customerListById.shipAddress1 ? 'field-css' : '']" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.shipAddress1" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 2<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.shipAddress2" [ngClass]="[customerJsonData.shipAddress2 != customerListById.shipAddress2 ? 'field-css' : '']" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 3<span class="star_class">*</span></label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" [ngClass]="[customerJsonData.shipAddress3 != customerListById.shipAddress3 ? 'field-css' : '']" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.shipAddress3" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="address">Address 4</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                        [(ngModel)]="customerJsonData.shipAddress4" [ngClass]="[customerJsonData.shipAddress4 != customerListById.shipAddress4 ? 'field-css' : '']" class="form-control" type="text"
                                        [readonly]="readonly" required></textarea>
                                </div>
                            </div>
        
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="country">Country<span class="star_class">*</span></label>
                                <ng-container *ngFor="let value of countryDropdown">
                                    <span class="form-control" [ngClass]="[customerJsonData.shipCountryId != customerListById.shipCountryId ? 'field-css' : '']"
                                        *ngIf="value.id == customerJsonData.shipCountryId">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData?.shipCountryId == 1">
                                <label for="state">State<span class="star_class">*</span></label>
        
                                <ng-container *ngFor="let value of stateDropdown">
                                    <span class="form-control" [ngClass]="[customerJsonData.shipStateId != customerListById.shipStateId ? 'field-css' : '']"
                                        *ngIf="value.id == customerJsonData.shipStateId">{{value.value}}</span>
                                </ng-container>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData?.shipCountryId != 1">
                                <label for="">State<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [ngClass]="[customerJsonData.shipOtherState != customerListById.shipOtherState ? 'field-css' : '']" [disabled]="readonly"
                                    [(ngModel)]="customerJsonData.shipOtherState" placeholder="Enter Your State">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">City<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [ngClass]="[customerJsonData.shipCity != customerListById.shipCity ? 'field-css' : '']" [disabled]="readonly"
                                    [(ngModel)]="customerJsonData.shipCity" placeholder="Enter Your City">
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="">Pincode<span class="star_class">*</span></label>
                                <input type="text" class="form-control" [disabled]="readonly" [ngClass]="[customerJsonData.shipPincode != customerListById.shipPincode ? 'field-css' : '']"
                                    [(ngModel)]="customerJsonData.shipPincode" placeholder="Enter Your pincode">
                            </div>
                        </div>
                    </ng-container>

                    <!-- Tax Details -->
                    <ng-container *ngIf="customerRaiseRequestList?.includes('Tax Registration')">
                        <div class="section-heading">
                            <h5>Tax Registration Details</h5>
                        </div>
            
                        <!-- row-5 -->
                        <div class="row" *ngIf="customerJsonData.supplierType != 'foreign'">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="city">GSTIN<span class="star_class">*</span></label>
                                <input class="form-control" [readonly]="readonly" [ngClass]="[customerJsonData.gstin_no != customerListById.gstin_no ? 'field-css' : '']" [(ngModel)]="customerJsonData.gstin_no"
                                    placeholder="Enter GSTIN" minlength="15" maxlength="15" autocomplete="off" required />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData.gstin_Date">
                                <label for="city">GSTIN Date<span class="star_class">*</span></label>
                                <span class="form-control" [ngClass]="[customerJsonData.gstin_Date != customerListById.gstin_Date ? 'field-css' : '']">{{customerJsonData.gstin_Date | date}}</span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData.panNumber">
                                <label for="city">PAN Card<span class="star_class">*</span></label>
                                <input class="form-control" type="text" [readonly]="readonly" [ngClass]="[customerJsonData.panNumber != customerListById.panNumber ? 'field-css' : '']"
                                    [(ngModel)]="customerJsonData.panNumber" minlength="10" maxlength="10" required
                                    autocomplete="off" />
                            </div>
                        </div>
            
                        <!-- row-6 -->
                        <div class="row">
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="city">TAN Number</label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerJsonData.tanNumber"
                                    placeholder="Enter TAN Number" minlength="10" maxlength="10" autocomplete="off" [ngClass]="[customerJsonData.tanNumber != customerListById.tanNumber ? 'field-css' : '']" />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group">
                                <label for="city">CIN/LLP Number</label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerJsonData.vanNumber" [ngClass]="[customerJsonData.vanNumber != customerListById.vanNumber ? 'field-css' : '']"
                                    placeholder="Enter CIN/LLP Number" autocomplete="off" />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData?.supplierType == 'foreign'">
                                <label for="city">Other No</label>
                                <input class="form-control" [readonly]="readonly" [(ngModel)]="customerJsonData.foreignNo" [ngClass]="[customerJsonData.foreignNo != customerListById.foreignNo ? 'field-css' : '']"
                                    placeholder="Enter Other No" autocomplete="off" />
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData?.gstinUpload">
                                <label for="city">GSTIN Upload<span class="star_class">*</span></label>
                                <span class="form-control" [ngClass]="[customerJsonData.gstinDocument != customerListById.gstinDocument ? 'field-css' : '']"><a href="{{customerJsonData?.gstinUpload}}" target="_blank" 
                                        download="{{customerJsonData?.gstinDocument}}">Preview</a></span>
                            </div>
                            <div class="col-lg-4 col-md-12 form-group" *ngIf="customerJsonData?.panUpload">
                                <label for="city">PAN Upload<span class="star_class">*</span></label>
                                <span class="form-control" [ngClass]="[customerJsonData.panUpload != customerListById.panUpload ? 'field-css' : '']"><a href="{{customerJsonData?.panUpload}}" target="_blank"
                                        download="{{customerJsonData?.panDocument}}">Preview</a></span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</p-dialog>