import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-verification-form',
  templateUrl: './verification-form.component.html',
  styleUrls: ['./verification-form.component.scss']
})
export class VerificationFormComponent implements OnInit {

  constructor(private route : Router , private activatedRoute : ActivatedRoute , private fb : FormBuilder , public _apiService : ApiServiceService , public _utility : AppUtility) { }
  submitButton : any = 'GET OTP';
  verificationUser : any = '';
  ngOnInit(): void {
    let slug = this.activatedRoute.snapshot.params;
    this.verificationUser = slug.roleName;
  }
  verificationFormControl = this.fb.group({
    emailAddress: new FormControl('', [Validators.required ,  Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')] ),
    userName: new FormControl(''),
    otp: new FormControl('')
  })

  verifyPhoneEmail(form : FormGroupDirective){
    console.log(this.verificationFormControl.value); 
    if(this.submitButton == 'Submit')   {
      if(this.verificationFormControl.valid){
          this._utility.loader(true);
          let object = {
            emailAddress : this.verificationFormControl.value.emailAddress,
            isApplied : false,
            userName : this.verificationFormControl.value.userName,
            otp : this.verificationFormControl.value.otp
          }
  
          console.log(object);        
          localStorage.clear();
          if(this.verificationUser == 'Supplier'){
            this._apiService.verifySupplier(object)
            .then((res:any)=>{
              this._utility.loader(false);
              if(res.success == false){
                this._apiService.showMessage(res.message , 'error')
              }
              else {
                localStorage.setItem('showRegister' , JSON.stringify('show'));
                let object1 = {
                  userName : this.verificationFormControl.value.userName
                }
                localStorage.setItem('userName' , JSON.stringify(object1))
                if(res.returnValue[0].id){
                  let object = {
                    supplierId : res.returnValue[0].id,
                    emailAddress : res.returnValue[0].emailAddress
                  }
  
                  localStorage.setItem('already' , JSON.stringify(object))
                  localStorage.setItem('supplier' , JSON.stringify(object))
                }
                this.route.navigateByUrl('register/'+ this.verificationFormControl.value.emailAddress)
                  this.verificationFormControl.reset();
                  Object.keys(this.verificationFormControl.controls).forEach(key => {
                  this.verificationFormControl.controls[key].setErrors(null)
                });
                form.resetForm();
              }
            })
          }

          else{
            this._apiService.verifyCustomer(object)
            .then((res:any)=>{
              this._utility.loader(false);
              if(res.success == false){
                this._apiService.showMessage(res.message , 'error')
              }
              else {
                localStorage.setItem('showCustomerRegister' , JSON.stringify('show'));
                let object1 = {
                  userName : this.verificationFormControl.value.userName
                }
                localStorage.setItem('customerUserName' , JSON.stringify(object1))
                if(res.returnValue[0].id >= 0){
                  let object = {
                    customerId : res.returnValue[0].id,
                    emailAddress : res.returnValue[0].emailAddress
                  }
  
                  localStorage.setItem('alreadyAccount' , JSON.stringify(object))
                  localStorage.setItem('customer' , JSON.stringify(object))
                }
                this.route.navigateByUrl('customer-form/create/'+ this.verificationFormControl.value.emailAddress)
                  this.verificationFormControl.reset();
                  Object.keys(this.verificationFormControl.controls).forEach(key => {
                  this.verificationFormControl.controls[key].setErrors(null)
                });
                form.resetForm();
              }
            })
          }
      }
  
      else {
        this._apiService.showMessage('Please Fill the Form' , 'error')
      }
    }

    else{
      if(this.verificationFormControl.valid){
        this._utility.loader(true);
        let object = {
          emailAddress : this.verificationFormControl.value.emailAddress , 
          userName : this.verificationFormControl.value.userName
        }
        if(this.verificationUser == 'Supplier'){
          this._apiService.registrationOTP(object).then((res:any)=>{
            this._utility.loader(false);
            if(res.success){
              this.submitButton = 'Submit';
              this.verificationFormControl.setControl('otp' , this.fb.control('' , [Validators.required]))
              this._apiService.showMessage(res.message , 'success');
            }
  
            else{
              this._apiService.showMessage(res.message , 'error');
            }
          })
        }

        else{
          this._apiService.registrationOTPForCustomer(object).then((res:any)=>{
            this._utility.loader(false);
            if(res.success){
              this.submitButton = 'Submit';
              this.verificationFormControl.setControl('otp' , this.fb.control('' , [Validators.required]))
              this._apiService.showMessage(res.message , 'success');
            }
  
            else{
              this._apiService.showMessage(res.message , 'error');
            }
          })
        }
      }
    }
  }

}
