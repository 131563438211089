import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  public _baseurl = environment.base_url;
  codec = new HttpUrlEncodingCodec;
  constructor(public http: HttpClient, private messageService: MessageService, private primengConfig: PrimeNGConfig) {
    this.primengConfig.ripple = true;
  }
   getHeaders= {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  ngEncode(param?: string) {
    return this.codec.encodeValue(param);
  }

  dropdowndata(string: any, object?: any): Promise<any> {
    let endpoint = 'dropdowns';
    if (!object) {
      return this.http.get(this._baseurl + 'dropdowns' + "?Mode=" + string).toPromise()
    }
    else {
      if (object) {
        var queryStrings = new HttpParams({ fromObject: object }).toString();
        endpoint = `${endpoint}?${queryStrings}`;
      }
      return this.http.get(`${this._baseurl}${endpoint}`).toPromise()
    }
  }

  // customer process
  customerRegistration(registerFormData: any): Promise<any> {
    return this.http.post(this._baseurl + 'Customer', registerFormData).toPromise()
  }

  getCustomerById(id){
    return this.http.get(this._baseurl + 'Customer/'+id).toPromise();
  }

  customerUpdate(registerFormData: any): Promise<any> {
    return this.http.put(this._baseurl + 'Customer', registerFormData).toPromise()
  }

  customerVerify(registerFormData: any): Promise<any> {
    return this.http.put(this._baseurl + 'Customer/verify', registerFormData).toPromise()
  }
  
  customerApproved(registerFormData: any): Promise<any> {
    return this.http.put(this._baseurl + 'Customer/approved', registerFormData).toPromise()
  }
  
  customerSendForChecker2(registerFormData: any): Promise<any> {
    return this.http.put(this._baseurl + 'Customer/sendForChecker2', registerFormData).toPromise()
  }

  getCustomer(object?:any): Promise<any> {
    let endpoint = 'Customer';
    if(object){
      console.log(object);
      endpoint = endpoint + '/'
      return this.http.get(this._baseurl + endpoint + object).toPromise()
    }
    else{
      return this.http.get(this._baseurl + endpoint).toPromise()
    }
  }

  // supplier process
  supplierRegistration(registerFormData: any): Promise<any> {
    return this.http.post(this._baseurl + 'supplier', registerFormData).toPromise()
  }

  supplierUpdate(registerFormData: any, mode?: string): Promise<any> {
    if (!mode) {
      return this.http.put(this._baseurl + 'supplier', registerFormData).toPromise()
    }

    else {
      return this.http.put(this._baseurl + 'supplier/' + mode, registerFormData).toPromise();
    }
  }

  supplierGetById(id: any): Promise<any> {
    return this.http.get(this._baseurl + 'supplier/' + id).toPromise()
  }

  supplierDelete(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'supplier/' + id).toPromise()
  }

  supplierBlock(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'supplier/SupplierBlock' , object).toPromise()
  }
  
  supplierById(id: any): Promise<any> {
    return this.http.get(this._baseurl + 'supplier/getdatasupplier/' + id).toPromise()
  }

  supplierChangeById(data: any): Promise<any> {
    return this.http.post(this._baseurl + 'supplier/raiseRequstsendForChecker2' , data).toPromise()
  }

  supplierRaiseRequest(object:any):Promise<any>{
    return this.http.post(this._baseurl+'SupplierRaiseRequest',object).toPromise();
  }


  supplierAssessment():Promise<any>{
    return this.http.get(this._baseurl + 'supplier/getAssessment').toPromise();
  }

  supplierChangesLog(object?:any):Promise<any>{
    if(object){
      return this.http.get(this._baseurl + 'supplier/GetRaiseRequestData'+ "?supplierId=" + object).toPromise();
    }
    else{
      return this.http.get(this._baseurl + 'supplier/GetRaiseRequestData').toPromise();
    }
  }

  assessmentStatus(object:any):Promise<any>{
    return this.http.put(this._baseurl + 'supplier/assessmentStatus' , object).toPromise();
  }



  verifySupplier(object: any): Promise<any> {
    return this.http.get(this._baseurl + 'SupplierLinkCreationVerification'  + "?EmailAddress=" + object.emailAddress + "&IsApplied=" + object.isApplied + "&otp=" + object.otp + "&userName=" + object.userName).toPromise()
  }
  
  registrationOTP(object : any){
    return this.http.post(this._baseurl + 'supplier/registrationOTP' , object).toPromise()
  }

  generateLinkForSupplier(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'SupplierLinkCreationVerification', object).toPromise()
  }

  getAllLinkedSupplier() {
    return this.http.get(this._baseurl + 'SupplierLinkCreationVerification/search').toPromise()
  }

  getSupplier(supplier_id?: any): Promise<any> {
    if (supplier_id) {
      return this.http.get(this._baseurl + 'supplier/' + supplier_id).toPromise();
    }

    else {
      return this.http.get(this._baseurl + 'supplier').toPromise();
    }
  }

  getRaiseRequest(){
    return this.http.get(this._baseurl + 'supplier/GetRaiseRequest').toPromise();
  }

  postSupplier(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'supplier', object).toPromise();
  }

  putJDEEntry(supplierId: any): Promise<any> {
    return this.http.put(this._baseurl + 'supplier/finance', supplierId).toPromise();
  }
  
  putReworkByFinance(object : any){
    return this.http.put(this._baseurl + 'supplier/financeRework', object).toPromise();
  }

  putRaiseRequest(object){
    return this.http.put(this._baseurl+'supplier/updateraiseRequst', object).toPromise();
  }
  
  putRaiseRequestChecker2(object){
    return this.http.put(this._baseurl+'supplier/raiseRequstsendForChecker2', object).toPromise();
  }

  postSupplierRFQReply(array: any): Promise<any> {
    return this.http.post(this._baseurl + 'supplierRFQReply', array).toPromise();
  }

  postSupplierRFQChangeReply(array: any): Promise<any> {
    return this.http.put(this._baseurl + 'supplierRFQReply/CreateCostRaiseRequest', array).toPromise();
  }

  unlockRFQReply(array: any): Promise<any> {
    return this.http.put(this._baseurl + 'AssignSupplier/Locked', array).toPromise();
  }
  
  deleteAssignedSupplier(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'AssignSupplier/' + id).toPromise();
  }

  RFQVerify(array: any): Promise<any> {
    return this.http.put(this._baseurl + 'supplierRFQReply/CheckerRaiseVerify', array).toPromise();
  }

  downloadExcel(searchParam : any):Promise<any>{
    let endpoint = 'PurchaseEnquiryCompare/PriceVariationReport';
    if (searchParam) {
      var queryStrings = new HttpParams({ fromObject: searchParam }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }

  certificateUpload(formData: any): Promise<any> {
    return this.http.post(this._baseurl + 'supplier/CertificatesUpload', formData).toPromise()
  }
  
  assessmentUpload(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'supplier/assessment', object).toPromise()
  }


  //user 
  login(logindata: any): Promise<any> {
    return this.http.post(this._baseurl + 'User/login', logindata).toPromise()
  }

  register(registerdata: any): Promise<any> {
    return this.http.post(this._baseurl + 'User/insert', registerdata).toPromise()
  }

  getAllRegisteredUser() {
    return this.http.get(this._baseurl + 'User').toPromise();
  }

  updateUserInfo(registerdata: any) {
    return this.http.put(this._baseurl + 'User/update', registerdata).toPromise()
  }

  forgotPassword(email: any): Promise<any> {
    return this.http.put(this._baseurl + 'User/forgot-password', email).toPromise()
  }

  matchOTP(OTP: any): Promise<any> {
    return this.http.put(this._baseurl + 'User/match-otp', OTP).toPromise()
  }

  changePassword(password: any): Promise<any> {
    return this.http.post(this._baseurl + 'User/change-password', password).toPromise()
  }


  //supplier
  supplierDocUpload(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'supplierOnboarding', object).toPromise()
  }

  getAllSupplierUploadedDoc(): Promise<any> {
    return this.http.get(this._baseurl + 'supplierOnboarding').toPromise()
  }

  getSupplierDocById(object: any): Promise<any> {
    return this.http.get(this._baseurl + 'supplierOnboarding/' + object).toPromise()
  }

  deleteSupplierDocs(object: any): Promise<any> {
    return this.http.delete(this._baseurl + 'supplierOnboarding/' + object).toPromise()
  }

  updateDocStatus(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'supplierOnboarding', object).toPromise()
  }


  //item master
  addItemMaster(object?: any): Promise<any> {
    return this.http.post(this._baseurl + 'item', object).toPromise()
  }

  getItemMaster(): Promise<any> {
    return this.http.get(this._baseurl + 'item').toPromise()
  }

  getItemMasterById(id: any): Promise<any> {
    return this.http.get(this._baseurl + 'item/' + id).toPromise()
  }
  
  getEnggDrawing(itemId : any){
    return this.http.get(this._baseurl + 'item/EngineeringDrawing/' + itemId ).toPromise()
  }
  
  uploadEnggDrawing(object:any){
    return this.http.put(this._baseurl + 'item/updateEngineeringDrawing' , object ).toPromise()
  }
  deleteEnggDrawing(itemId : any){
    return this.http.delete(this._baseurl + 'item/DeleteEngineeringDrawing/' + itemId ).toPromise()
  }

  editItemMaster(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'item', object).toPromise()
  }

  deleteItemMaster(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'item/' + id).toPromise()
  }




  //cost breakup
  addcostBreakup(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'CostBreakup', object).toPromise()
  }

  getcostBreakup(): Promise<any> {
    return this.http.get(this._baseurl + 'CostBreakup').toPromise()
  }

  editcostBreakup(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'CostBreakup', object).toPromise()
  }

  deletecostBreakup(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'CostBreakup/' + id).toPromise()
  }

  //manufacture process
  addmanufacture(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'Manufacturing', object).toPromise()
  }

  getmanufacture(): Promise<any> {
    return this.http.get(this._baseurl + 'Manufacturing').toPromise()
  }

  editmanufacture(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'Manufacturing', object).toPromise()
  }

  deletemanufacture(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'Manufacturing/' + id).toPromise()
  }

  //SupplierDocument process
  addSupplierDocument(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'SupplierDocument', object).toPromise()
  }

  getSupplierDocument(): Promise<any> {
    return this.http.get(this._baseurl + 'SupplierDocument').toPromise()
  }

  editSupplierDocument(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'SupplierDocument', object).toPromise()
  }

  deleteSupplierDocument(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'SupplierDocument/' + id).toPromise()
  }


  //CustomerDocument process
  addCustomerDocument(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'CustomerDocument', object).toPromise()
  }

  getCustomerDocument(isTrue): Promise<any> {
    return this.http.get(this._baseurl + 'CustomerDocument?IsNewDocument=' + `${isTrue}`).toPromise()
  }

  editCustomerDocument(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'CustomerDocument', object).toPromise()
  }

  deleteCustomerDocument(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'CustomerDocument/' + id).toPromise()
  }

  blockCustomer(object){
    return this.http.put(this._baseurl + 'Customer/block' , object).toPromise();
  }

  customerDelete(id: any): Promise<any> {
      return this.http.delete(this._baseurl + 'customer/' + id).toPromise()
  }

  customerRaiseRequest(object:any):Promise<any>{
    return this.http.post(this._baseurl + 'CustomerRaiseRequest',object).toPromise();
  }

  getCustomerRaiseRequest(){
    return this.http.get(this._baseurl + 'customer/GetRaiseRequest').toPromise();
  }

  putCustomerRaiseRequest(object){
    return this.http.put(this._baseurl+'customer/updateraiseRequst', object).toPromise();
  }

  putCustomerRaiseRequestChecker2(object){
    return this.http.put(this._baseurl+'customer/raiseRequstsendForChecker2', object).toPromise();
  }

  customerChangesLog(object?:any):Promise<any>{
    if(object){
      return this.http.get(this._baseurl + 'customer/GetRaiseRequestData'+ "?customerId=" + object).toPromise();
    }
    else{
      return this.http.get(this._baseurl + 'customer/GetRaiseRequestData').toPromise();
    }
  }
  


  //material grade process
  addMaterialGride(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'MaterialGrid', object).toPromise()
  }

  getMaterialGride(): Promise<any> {
    return this.http.get(this._baseurl + 'MaterialGrid').toPromise()
  }

  editMaterialGride(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'MaterialGrid', object).toPromise()
  }

  deleteMaterialGride(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'MaterialGrid/' + id).toPromise()
  }


  //material form process
  addMaterialForm(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'MaterialForm', object).toPromise()
  }

  getMaterialForm(): Promise<any> {
    return this.http.get(this._baseurl + 'MaterialForm').toPromise()
  }

  editMaterialForm(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'MaterialForm', object).toPromise()
  }

  deleteMaterialForm(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'MaterialForm/' + id).toPromise()
  }

  //Other category
  addCategory(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'otherCategory', object).toPromise()
  }

  getCategory(): Promise<any> {
    return this.http.get(this._baseurl + 'otherCategory').toPromise()
  }

  editCategory(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'otherCategory', object).toPromise()
  }

  deleteCategory(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'otherCategory/' + id).toPromise()
  }

  //materialConstruction process
  addmaterialConstruction(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'Materialconstruction', object).toPromise()
  }

  getmaterialConstruction(): Promise<any> {
    return this.http.get(this._baseurl + 'Materialconstruction').toPromise()
  }

  editmaterialConstruction(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'Materialconstruction', object).toPromise()
  }

  deletematerialConstruction(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'Materialconstruction/' + id).toPromise()
  }


  //BU process
  addBU(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'BUMaster', object).toPromise()
  }

  getBU(): Promise<any> {
    return this.http.get(this._baseurl + 'BUMaster').toPromise()
  }

  editBU(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'BUMaster', object).toPromise()
  }

  deleteBU(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'BUMaster/' + id).toPromise()
  }


  //Bussiness Unit Name process
  AddbussinessUnitNumber(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'BussinessUnitNumber', object).toPromise()
  }

  getBussinessUnitNumber(): Promise<any> {
    return this.http.get(this._baseurl + 'BussinessUnitNumber').toPromise()
  }

  editBussinessUnitNumber(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'BussinessUnitNumber', object).toPromise()
  }

  deleteBussinessUnitNumber(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'BussinessUnitNumber/' + id).toPromise()
  }


   //payment Terms process
   addpaymentTerms(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'PaymentTerms', object).toPromise()
  }

  getpaymentTerms(): Promise<any> {
    return this.http.get(this._baseurl + 'PaymentTerms').toPromise()
  }

  editpaymentTerms(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'PaymentTerms', object).toPromise()
  }

  deletepaymentTerms(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'PaymentTerms/' + id).toPromise()
  }


  //enquiry details
  addenquiryDetails(data: any): Promise<any> {
    return this.http.post(this._baseurl + 'Enquiry', data).toPromise()
  }

  editenquiryDetails(data: any): Promise<any> {
    return this.http.put(this._baseurl + `Enquiry`, data).toPromise()
  }

  getenquiryDetailsById(id?: any): Promise<any> {
    return this.http.get(this._baseurl + `Enquiry/${id}`).toPromise()
  }

  geteditenquiryDetailsById(data: any): Promise<any> {
    return this.http.get(this._baseurl + 'Enquiry/getalldata/' + data).toPromise()
  }

  getAllEnquiryDetails(): Promise<any> {
    return this.http.get(this._baseurl + 'Enquiry').toPromise()
  }

  uploadDrawings(itemDrawings: any) {
    return this.http.post(this._baseurl + 'enquiry/drawingUpload', itemDrawings).toPromise()
  }



  assignSupplier(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'AssignSupplier', object).toPromise()
  }

  getAssignedSupplierById(object?: any): Promise<any> {
    if (!object.loginId) {
      return this.http.get(this._baseurl + 'AssignSupplier' + "?SupplierId=" + object.supplierId + "&roleName=" + object.roleName).toPromise()
    }

    else if (object.loginId) {
      return this.http.get(this._baseurl + 'AssignSupplier' + "?roleName=" + object.roleName + "&SupplierId=" + object.supplierId).toPromise()
    }
  }

  getAssignedSupplierByIdWithEnquiryNo(object: any): Promise<any> {
    if(object?.enquiryNo){
      return this.http.get(this._baseurl + 'AssignSupplier/getbyId' + "?SupplierId=" + object.supplierId + "&enquiryNo=" + object.enquiryNo + "&roleName=" + object.roleName).toPromise()
    }
    else{
      return this.http.get(this._baseurl + 'AssignSupplier/getbyId' + "roleName=" + object.roleName).toPromise()
    }
  }

  getAssignedSupplierQuotationCostBreakup(object: any): Promise<any> {
    return this.http.get(this._baseurl + 'AssignSupplier/getcostbreakup' + "?SupplierId=" + object.supplierId + "&enquiryNo=" + object.enquiryNo + "&itemId=" + object.itemId).toPromise()
  }




  //profile
  profile(object: any) {
    return this.http.get(this._baseurl + 'profile' + "?id=" + object.loginId + "&roleName=" + object.roleName).toPromise();
  }



  //comparison

  getItemFromQuotation(object?: any): Promise<any> {
    return this.http.get(this._baseurl + 'PurchaseEnquiryCompare/getEnquiryItem').toPromise()
  }



  getQuotationByItemId(object: any) {
    return this.http.get(this._baseurl + 'PurchaseEnquiryCompare/getByItemId' + "?enquiryId=" + object.enquiryId).toPromise()
  }

  getJson(object: any) {
    return this.http.get(this._baseurl + 'PurchaseEnquiryCompare/getJson' + "?Ids=" + object.enquiryId+ "&itemId=" + object.itemId).toPromise()
  }

  submitOrderQuantity(object: any) {
    return this.http.post(this._baseurl + 'PurchaseEnquiryCompare', object).toPromise();
  }

  submitOrderConfirmation(object: any) {
    return this.http.put(this._baseurl + 'PurchaseEnquiryCompare', object).toPromise();
  }

  get(endpoint: string, searchParam?: any, options?: any): Observable<any> {
    if (searchParam) {
      var queryStrings = new HttpParams({ fromObject: searchParam }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(`${this._baseurl}${endpoint}`, options);
  }


  //report
  getRFQListReport(object: any): Promise<any> {
    return this.get('RFQReports/getRFQ', object, { responseType: 'blob' }).toPromise()
  }

  getRFQDetailReport(object: any): Promise<any> {
    return this.get('RFQReports/getRFQDetails', object, { responseType: 'blob' }).toPromise()
  }

  getRFQSupplierReport(object: any): Promise<any> {
    return this.get('RFQReports/getRFQSupplierDetails', object, { responseType: 'blob' }).toPromise()
  }
  getRFQOrderReport(object: any): Promise<any> {
    return this.get('RFQReports/getOrderList', object, { responseType: 'blob' }).toPromise()
  }


  //orders
  getSupplierOrders(object?: any) {
    console.log(object);
    let endpoint = 'OrderList'
    if (object) {
      if (object.supplier == '') {
        if (object?.enquiryId) {
          endpoint = endpoint + '/' + object.enquiryId + '/' + 0;
        }
      }

      else if (object.supplier == 'supplierbyId') {
        if (object?.enquiryId) {
          endpoint = endpoint + '/' + object.enquiryId;
        }
        if (object?.supplierId) {
          endpoint = endpoint + '/' + object.supplierId;
        }
      }

      else if (object.supplier == 'supplier') {
        endpoint = endpoint + "?supplierId=" + object.supplierId
        // var queryStrings = new HttpParams({ fromObject: object }).toString();
        // endpoint = `${endpoint}?${queryStrings}`;
      }
    }
    return this.http.get(this._baseurl + endpoint).toPromise()
  }



  //apqp
  getAPQPDataByItemId(object: any): Promise<any> {
    let endpoint = 'APQP';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }

  postAPQPDocument(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'APQP', object).toPromise();
  }

  getAPQPInfo(object: any): Promise<any> {
    let endpoint : any = 'APQP/getAPQPDocumentDetails';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }

  putAPQPDocument(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'APQP', object).toPromise();
  }

  getApqpData(object?:any): Promise<any> {
    let endpoint = 'APQP';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }

  // getAPQPInfo(object): Promise<any> {
  //   let endpoint = 'APQP/getAPQPDocumentDetails';
  //   if (object) {
  //     var queryStrings = new HttpParams({ fromObject: object }).toString();
  //     endpoint = `${endpoint}?${queryStrings}`;
  //   }
  //   return this.http.get(this._baseurl + endpoint).toPromise();
  // }


  //raiseRequest
  raiseRequest(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'supplier/raiseRequst', object).toPromise();
  }




  //ppap
  getPPAPDataByItemId(object: any): Promise<any> {
    let endpoint = 'PPAP';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }

  getPPAPData(object?:any): Promise<any> {
    let endpoint = 'PPAP';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }


  postPPAPDocument(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'PPAP', object).toPromise();
  }

  putPPAPDocument(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'PPAP', object).toPromise();
  }

  
  getAssessmentList(object: any): Promise<any> {
    let endpoint = 'RFQReports/getAssessmentList';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint , {responseType : 'blob'}).toPromise();
  }


  getPPAPInfo(object): Promise<any> {
    let endpoint = 'PPAP/getPPAPDocumentDetails';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }


  //ToolLoanAgreement
  getToolLoanAgreementDataByItemId(object: any): Promise<any> {
    let endpoint = 'ToolLoanAgreement';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }

  postToolLoanAgreementDocument(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'ToolLoanAgreement', object).toPromise();
  }

  putToolLoanAgreementDocument(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'ToolLoanAgreement', object).toPromise();
  }

  deleteToolLoanAgreementDocument(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'ToolLoanAgreement/' + id).toPromise();
  }

  putToolImage(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'ToolLoanAgreement/deleteToolImage', object).toPromise();
  }


  putToolDocument(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'ToolLoanAgreement/documentinsert', object).toPromise();
  }

  //ToolHealthCheck
  getToolHealthCheckDataByItemId(object: any): Promise<any> {
    let endpoint = 'ToolHealthCheckup';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint).toPromise();
  }

  postToolHealthCheckDocument(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'ToolHealthCheckup', object).toPromise();
  }

  putToolHealthCheckDocument(object: any): Promise<any> {
    return this.http.put(this._baseurl + 'ToolHealthCheckup', object).toPromise();
  }
  getAssestCode() {
    return this.http.get(this._baseurl + 'AutoNumber').toPromise();
  }



  //common functions
  validateUserEmail(email: any) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email.target.value) == false) {
      return false;
    }
    return true;
  }

  validateEmail(event: any) {
    const value = event.target.value;

    if (
      value &&
      !/^[0-9]*$/.test(value) &&
      !this.validateUserEmail(event)
    ) {
      return true
    }

    else {
      return false
    }
  }

  showMessage(errorMsg: any, errorMsgCheck: any) {
    this.messageService.add({ severity: errorMsgCheck, summary: errorMsgCheck, detail: errorMsg });
  }


  //document
  addDocument(object: any) {
    return this.http.post(this._baseurl + 'document', object).toPromise();
  }

  editDocument(object: any) {
    return this.http.put(this._baseurl + 'document', object).toPromise();
  }

  getDocument() {
    return this.http.get(this._baseurl + 'document').toPromise();
  }

  deleteDocument(id: any): Promise<any> {
    return this.http.delete(this._baseurl + 'document/' + id).toPromise()
  }

  bussinessUnitName(): Promise<any> {
    return of(
      [{name : "142000" , id : '142000'},
      {name : '324342' , id : '324342'},
      {name : '21111' , id : '21111'}]
    ).toPromise();
    // return this.http.get(this._baseurl + 'document/').toPromise()
  }

  bussinessUnit(): Promise<any> {
    return of(
      [{name : 'lC'  , id : 'lC'},
      {name : 'AC' , id : 'AC'},
      {name : 'EC' , id : 'EC'}]
    ).toPromise();
    // return this.http.get(this._baseurl + 'document/').toPromise()
  }


  verifyCustomer(object: any): Promise<any> {
    return this.http.get(this._baseurl + 'CustomerLinkCreationVerification'  + "?EmailAddress=" + object.emailAddress + "&IsApplied=" + object.isApplied + "&otp=" + object.otp + "&userName=" + object.userName).toPromise()
  }
  
  registrationOTPForCustomer(object : any){
    return this.http.post(this._baseurl + 'CustomerLinkCreationVerification/registrationOTP' , object).toPromise()
  }

  generateLinkForCustomer(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'CustomerLinkCreationVerification', object).toPromise()
  }

  getAllLinkedCustomer() {
    return this.http.get(this._baseurl + 'CustomerLinkCreationVerification/search').toPromise()
  }

  getAuditLogsOfCustomers(){
    return this.http.get(this._baseurl + 'Customer/GetCustomerAuditHistory').toPromise();
  }

  getAuditLogsOfSuppliers(){
    return this.http.get(this._baseurl + 'Supplier/GetSupplierAuditHistory').toPromise();
  }

   //raiseRequest
   customerRaiseRequestPost(object: any): Promise<any> {
    return this.http.post(this._baseurl + 'customer/raiseRequst', object).toPromise();
  }
  
  // tool loan update api
  addToolLoanAgreement(object: any): Promise<any>{
    return this.http.post(this._baseurl + 'toollist', object, this.getHeaders).toPromise();
  }
  //part drawing api
  getPartDrawing(){
    return this.http.get(this._baseurl + 'PartDrawing').toPromise()
  }
  // add part drawing name api
  addPartDrawingName(partDrawingName): Promise<any>{
    return this.http.post(this._baseurl + 'PartDrawing', partDrawingName).toPromise();
  }
  //edit part drawing name
  editPartDrawingName(partDrawingName): Promise<any>{
    return this.http.put(this._baseurl + 'PartDrawing', partDrawingName).toPromise();
  }
  // delete part drwaing record
  deletePartDrwaingRecord(partId): Promise<any>{
    return this.http.delete(this._baseurl + 'PartDrawing/'+ partId).toPromise();
  }
  // get tool list
  getToolList(){
    return this.http.get(this._baseurl + 'toollist').toPromise();
  }
  //get po type
  getPoType(){
    return this.http.get(this._baseurl + 'Potype').toPromise();
  }
  //delete tool list record
  deleteToolListRecord(toollistId): Promise<any>{
    return this.http.delete(this._baseurl + 'toollist/'+ toollistId).toPromise();
  }
  //put tool list id
  putToolList(partId):Promise<any>{
    return this.http.put(this._baseurl + 'toollist/' + 'ToolListSupplierRequest', partId ).toPromise();
  }
  //send asset no. for capital select , finance role
  sendAssetNo(data):Promise<any>{
    return this.http.put(this._baseurl + 'toollist/' + 'ToolListUpdate', data ).toPromise();
  }
  // download dummy pdf for tool loan
  downloadToolDocument(fileUrl: any){
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'BuyerDocument.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  //toolList buyer request
  toolListBuyerRequest(data):Promise<any>{
    return this.http.put(this._baseurl + 'toollist/' + 'ToolListBuyerRequest', data ).toPromise();
  }
  //toolList approve request
  toolListApproveRequest(data):Promise<any>{
    return this.http.put(this._baseurl + 'toollist/' + 'ToolListApproved', data ).toPromise();
  }
  //toolList reject request
  toolListRejectRequest(data):Promise<any>{
    return this.http.put(this._baseurl + 'toollist/' + 'ToolListRejected', data ).toPromise();
  }
  
  getCustomerList(object: any): Promise<any> {
    let endpoint = 'RFQReports/CustomerReport';
    if (object) {
      var queryStrings = new HttpParams({ fromObject: object }).toString();
      endpoint = `${endpoint}?${queryStrings}`;
    }
    return this.http.get(this._baseurl + endpoint , {responseType : 'blob'}).toPromise();
    // let endpoint = 'RFQReports/CustomerReport';
    // return this.http.get(this._baseurl + 'RFQReports/CustomerReport',object).toPromise();
  }
  // additional customer documents upload
   attachCustomerDocuments(data): Promise<any>{
    return this.http.post(this._baseurl + 'CustomerDocument/RediectCustomerDocument?', data).toPromise();
  }
  orderDetails(): Promise<any> {
    return this.http.get(this._baseurl + 'Jde_Sales_OrderStatus').toPromise()
  }

  exportExcel(data: any, name?: string, filterBy?: any) {
    import('xlsx').then((xlsx) => {
        const currentDateTime = new Date().toLocaleString('en-GB'); // Get current date and time
        let header = '';

        if (name) {
            const headerOfFile = name.replace(/-/g, '  ');
            header += `  ${headerOfFile}  `;
        }

        if (filterBy && filterBy.length === 2) {
            const startDate = typeof filterBy[0] === 'string' ? filterBy[0] : filterBy[0].toLocaleDateString('en-GB');
            const endDate = typeof filterBy[1] === 'string' ? filterBy[1] : filterBy[1].toLocaleDateString('en-GB');
            header += `   From: ${startDate} - To:${endDate}`;
        } else if (filterBy) {
            const filter = filterBy.replace(/_/g, ' ');
            header += `  ${filter}`;
        }

        header += `   Date & Time: ${currentDateTime}`;

        // Convert data to worksheet format
        const worksheet = xlsx.utils.json_to_sheet(data);

        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        if (name) {
            this.saveAsExcelFile(excelBuffer, name);
        } else {
            this.saveAsExcelFile(excelBuffer, 'data');
        }
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = '.xlsx';
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE,
  });
  // import("file-saver").then(FileSaver => {
  saveAs(
    data,
    fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
  );
  // })
}


}