<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

     <!-- supplier registration form -->
     <form [formGroup]="supplierFormControl" autocomplete="false"
     *ngIf="!nextWizard" (ngSubmit)="registerSupplier('test')"
     #register="ngForm">
     <div class="signup-head">
         <span id="auth-head">
             <h3>Customer Registration</h3>
         </span>
     </div>
     <!-- Supplier / Vendor Master - Supplier Master -->
     <div class="section-heading" *ngIf="userLogin?.roleName == 'CS Team'">
         <h5>CS Team Details</h5>
     </div>
     
     <div class="row" *ngIf="userLogin?.roleName == 'CS Team'">
         <div class="col-lg-4 col-md-12 form-group">
             <label for="">Branch Plant</label>
             <span>{{supplierFormControl.controls['branchPlant'].value}}</span>
         </div>

         <div class="col-lg-4 col-md-12 form-group">
             <label for="">IDEX Bussiness Unit</label>
             <span>{{supplierFormControl.controls['businessUnit'].value}}</span>
         </div>
         <div class="col-lg-4 col-md-12 form-group">
             <label for="city">Credit Limit</label>
             <span class="form-control">{{supplierFormControl.controls['creditLimit'].value}}</span>
         </div>
         <!-- row-3 -->
         <div class="col-lg-4 col-md-12 form-group">
             <label for="city">Suggest Business Unit</label>
             <span class="form-control">{{supplierFormControl.controls['bussinessUnit'].value}}</span>
         </div>
         <div class="col-lg-4 col-md-12 form-group">
             <label for="credit">Customer Category</label>
             <p-dropdown *ngIf="supplierFormControl.get('countryId').value == 1" [options]="customerCategory"
                [class.is-invalid]="register.submitted &&
                supplierFormControl.get('customerCategory')?.invalid ||
                supplierFormControl.get('customerCategory')?.invalid &&
                supplierFormControl.get('customerCategory')?.touched"
                [readonly]="readonlyField" [style]="{width : '100%', height
                :'35px'}" optionLabel="value" optionValue="id"
                formControlName="customerCategory"></p-dropdown>
             <!-- <select class="form-control form-select" [disabled]="readonlyField" id="tds"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('customerCategory')?.invalid ||
                 supplierFormControl.get('customerCategory')?.invalid &&
                 supplierFormControl.get('customerCategory')?.touched"
                 placeholder="Enter Customer Category" disabled [readonly]="readonlyField" formControlName="customerCategory">
                 <option disabled value="">Select</option>
                 <ng-container *ngFor="let category of customerCategory">
                     <option [value]=category.id>{{category.value}}</option>
                 </ng-container>
             </select> -->
         </div>
         <div class="col-lg-4 col-md-12 form-group">
             <label for="credit">Incoterms</label>
             <select class="form-control form-select" [disabled]="readonlyField" id="tds"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('incotermsId')?.invalid ||
                 supplierFormControl.get('incotermsId')?.invalid &&
                 supplierFormControl.get('incotermsId')?.touched"
                 placeholder="Enter incotermsId" disabled [readonly]="readonlyField" formControlName="incotermsId">
                 <option disabled value="0">Select</option>
                 <ng-container *ngFor="let inco of incotermsDropdown">
                     <option [value]=inco.id>{{inco.value}}</option>
                 </ng-container>
             </select>
             <div class="invalid-feedback"> Incoterms is Required
             </div>
         </div>
         <div class="col-lg-4 col-md-12 form-group">
             <label for="credit">Employee Code</label>
             <input class="form-control" disabled [readonly]="readonlyField" formControlName="employeeCode"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('employeeCode')?.invalid ||
                 supplierFormControl.get('employeeCode')?.invalid &&
                 supplierFormControl.get('employeeCode')?.touched"
                 autocomplete="off" placeholder="Enter Employee Code"/>
         </div>
         <div class="col-lg-4 col-md-12 form-group">
             <label for="credit">Customer Mode</label>
             <span class="form-control">{{supplierFormControl.controls['supplierMode'].value}}</span>
         </div>
         <div class="col-lg-4 col-md-12 form-group">
             <label for="payment">Payment Terms Code</label>
             <select class="form-control form-select" [disabled]="readonlyField" id="tds"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('paymentTerms')?.invalid ||
                 supplierFormControl.get('paymentTerms')?.invalid &&
                 supplierFormControl.get('paymentTerms')?.touched" disabled (change)="setPaymentTermsDesc($event.target.value)"
                 placeholder="Enter Payment Terms" [readonly]="readonlyField" formControlName="paymentTerms">
                 <option disabled value="" selected>Select</option>
                 <ng-container *ngFor="let inco of paymentTermsList">
                     <option [value]=inco.paymentTermsName>{{inco.paymentTermsName}}</option>
                 </ng-container>
             </select>
         </div>
         <div class="col-lg-4 col-md-12 form-group">
             <label for="credit">Payment Terms Description</label>
             <input class="form-control" disabled [readonly]="readonlyField" formControlName="paymentTermsDesc"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('paymentTermsDesc')?.invalid ||
                 supplierFormControl.get('paymentTermsDesc')?.invalid &&
                 supplierFormControl.get('paymentTermsDesc')?.touched"
                 autocomplete="off" placeholder="Enter Payment Terms" />
             <div class="invalid-feedback"> Payment Terms Description is Required
             </div>
         </div>
     </div>
     <ng-container *ngIf="(this.customerDetails?.crFlag ? (customerChangeList?.includes('Customer Master') ? true : false) : true)">
         <div class="section-heading">
             <h5>Customer Master</h5>
         </div>
         <!-- row-1 -->
         <div class="row">
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="first-name">Company Name<span
                         class="star_class">*</span></label>
                 <input type="text" class="form-control" placeholder="Enter Company Name"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('customerName')?.invalid ||
                     supplierFormControl.get('customerName')?.invalid &&
                     supplierFormControl.get('customerName')?.touched"
                     [readonly]="readonlyField" formControlName="customerName" autocomplete="off"
                     minlength="3" maxlength='35' required />
                 <div class="invalid-feedback">
                     Company Name is Required
                 </div>
             </div>
             <!-- <div class="col-lg-4 col-md-12 form-group">
                 <label for="city">TIN Number</label>
                 <input class="form-control" [readonly]="readonlyField" formControlName="tinNumber"
                     placeholder="Enter TIN Number"
                     maxlength="10" autocomplete="off" />
                 <div class="error" *ngIf="register.submitted &&
                     supplierFormControl.get('tinNumber')?.invalid ||
                     supplierFormControl.get('tinNumber')?.invalid &&
                     supplierFormControl.get('tinNumber')?.touched">
                     Please Enter Valid TIN Number
                 </div>
             </div> -->
    
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="credit">Customer Type<span class="star_class">*</span></label>
                 <p-dropdown [options]="customerType"
                    [class.is-invalid]="register.submitted &&
                    supplierFormControl.get('purposeId')?.invalid ||
                    supplierFormControl.get('purposeId')?.invalid &&
                    supplierFormControl.get('purposeId')?.touched"
                    [readonly]="readonlyField" [style]="{width : '100%', height
                    :'35px'}" optionLabel="name" optionValue="id"
                    formControlName="purposeId"></p-dropdown>
                 <div class="invalid-feedback"> Customer Type is Required
                 </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="credit">Address Type<span class="star_class">*</span></label>
                 <p-dropdown [options]="addressType"
                    [class.is-invalid]="register.submitted &&
                    supplierFormControl.get('micrNo')?.invalid ||
                    supplierFormControl.get('micrNo')?.invalid &&
                    supplierFormControl.get('micrNo')?.touched"
                    [readonly]="readonlyField" [style]="{width : '100%', height
                    :'35px'}" optionLabel="name" optionValue="name"
                    formControlName="micrNo"></p-dropdown>
                 <div class="invalid-feedback"> Address Type is Required
                 </div>
             </div>
    
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="city">In case of Intercompany correct Co. No. In GL off </label>
                 <input class="form-control"
                     [readonly]="readonlyField" formControlName="intercompanyCorrectCONo"
                     autocomplete="off" placeholder="Enter CO No." />
                 <div class="error" *ngIf="register.submitted &&
                     supplierFormControl.get('intercompanyCorrectCONo')?.invalid
                     ||
                     supplierFormControl.get('intercompanyCorrectCONo')?.invalid
                     &&
                     supplierFormControl.get('intercompanyCorrectCONo')?.touched">
                     inter company CorrectCONo is Required </div>
             </div>
    
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="email">Whether Domestic or Foreign<span
                         class="star_class">*</span></label>
                <p-dropdown [options]="domesticType" (change)="getSupplierType($event.target.value)"
                [class.is-invalid]="register.submitted &&
                supplierFormControl.get('supplierType')?.invalid ||
                supplierFormControl.get('supplierType')?.invalid &&
                supplierFormControl.get('supplierType')?.touched"
                [readonly]="readonlyField" [style]="{width : '100%', height
                :'35px'}" optionLabel="name" optionValue="value"
                formControlName="supplierType"></p-dropdown> 
                 <div class="invalid-feedback"> Please Select Supplier Type</div>
             </div>
    
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="email">Currency Code <span class="star_class">*</span></label>
                 <p-dropdown [options]="currencyDropdown"
                [readonly]="readonlyField" [style]="{width : '100%', height
                :'35px'}" optionLabel="value" optionValue="id"
                formControlName="currency"></p-dropdown>
                 <div class="invalid-feedback"> Please Enter Currency</div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                <label for="email">Company <span class="star_class">*</span></label>
                <p-dropdown [options]="industryDropdown"
               [readonly]="readonlyField" [style]="{width : '100%', height
               :'35px'}" optionLabel="value" optionValue="id"
               formControlName="industryId"></p-dropdown>
                <div class="invalid-feedback"> Please Enter Company</div>
            </div>
         </div>
     </ng-container>


     <!-- Sold By -->
     <ng-container *ngIf="(showShip == 'Sold & Ship' || showShip == '') && (this.customerDetails?.crFlag ? (customerChangeList?.includes('Sold to Details') ? true : false) : true)">
         <div class="section-heading">
             <h5>Sold to Details</h5>
         </div>

         <!-- row-2 -->
         <div class="row">
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 1<span
                     class="star_class">*</span></label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('address1')?.invalid ||
                     supplierFormControl.get('address1')?.invalid &&
                     supplierFormControl.get('address1')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="address1"   class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address 1 is Required </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 2<span
                     class="star_class">*</span></label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('address2')?.invalid ||
                     supplierFormControl.get('address2')?.invalid &&
                     supplierFormControl.get('address2')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="address2" class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address2 is Required </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 3<span
                     class="star_class">*</span></label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('address3')?.invalid ||
                     supplierFormControl.get('address3')?.invalid &&
                     supplierFormControl.get('address3')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="address3" class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address3 is Required </div>
             </div>

             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 4</label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('addressWithPinCode')?.invalid ||
                     supplierFormControl.get('addressWithPinCode')?.invalid &&
                     supplierFormControl.get('addressWithPinCode')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="addressWithPinCode" class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address is Required </div>
             </div>

             <div class="col-lg-4 col-md-12 form-group">
                 <label for="country">Country<span class="star_class">*</span></label>
                 <p-dropdown [options]="countryDropdown" (change)="getStateDropdown($event, '','' , 'country1')"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('countryId')?.invalid ||
                 supplierFormControl.get('countryId')?.invalid &&
                 supplierFormControl.get('countryId')?.touched"
                 [readonly]="readonlyField" [style]="{width : '100%', height
                 :'35px'}" optionLabel="value" optionValue="id" [formControl]="supplierFormControl.controls['countryId']"></p-dropdown> 


                 <!-- <select class="form-control form-select" id="country"
                     [disabled]="readonlyField" formControlName="countryId"
                     (change)="getStateDropdown($event, '','' , 'country1')"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('countryId')?.invalid ||
                     supplierFormControl.get('countryId')?.invalid &&
                     supplierFormControl.get('countryId')?.touched">
                     <option value="" disabled>Select Country</option>
                     <ng-container *ngFor="let country of countryDropdown">
                         <option [value]="country.id">{{country.value}}</option>
                     </ng-container>
                 </select> -->
                 <div class="invalid-feedback"> Country is Required </div>
             </div>

             <!-- row-2 -->
             <!-- <div class="row"> -->
             <div class="col-lg-4 col-md-12 form-group" *ngIf="!showOtherCityBoolean">
                 <label for="state">State<span class="star_class">*</span></label>
                 <p-dropdown [options]="stateDropdown"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('stateId')?.invalid ||
                 supplierFormControl.get('stateId')?.invalid &&
                 supplierFormControl.get('stateId')?.touched"
                 [readonly]="readonlyField" [style]="{width : '100%', height
                 :'35px'}" optionLabel="value" optionValue="id"
                 formControlName="stateId"></p-dropdown> 
                 <div class="invalid-feedback"> State is Required </div>
             </div>
           
             <div class="col-lg-4 col-md-12 form-group" *ngIf="showOtherCityBoolean">
                 <label for="">State<span class="star_class">*</span></label>
                 <input type="text" class="form-control"
                     [readonly]="readonlyField" formControlName="otherState"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('otherState')?.invalid ||
                     supplierFormControl.get('otherState')?.invalid &&
                     supplierFormControl.get('otherState')?.touched"
                     placeholder="Enter Other State">
                 <div class="invalid-feedback"> Other State is Required
                 </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="">City<span class="star_class">*</span></label>
                 <input type="text" class="form-control"
                     [readonly]="readonlyField" formControlName="city"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('city')?.invalid ||
                     supplierFormControl.get('city')?.invalid &&
                     supplierFormControl.get('city')?.touched"
                     placeholder="Enter Your City">
                 <div class="invalid-feedback"> City is Required
                 </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="">Pincode<span class="star_class">*</span></label>
                 <input type="text" class="form-control"
                     [readonly]="readonlyField" formControlName="pincode" minlength="4" maxlength="10" pKeyFilter="alphanum"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('pincode')?.invalid ||
                     supplierFormControl.get('pincode')?.invalid &&
                     supplierFormControl.get('pincode')?.touched"
                     placeholder="Enter Your Pincode">
                 <div class="invalid-feedback"> Pincode is Required
                 </div>
             </div>
         </div>
     </ng-container>


     <!-- Ship to Details -->
     <ng-container *ngIf="(showShip == 'Ship' || showShip == '' || showShip == 'Sold & Ship') && (this.customerDetails?.crFlag ? (customerChangeList?.includes('Ship to Details') ? true : false) : true)">
         <div class="section-heading d-flex align-items-center justify-content-center">
             <h5>Ship to Details</h5>
             <!-- <input type="checkbox" (click)="sameAsSoldDetails()" pTooltip="Same As Sold Details" *ngIf="supplierFormControl.controls['micrNo'].value == 'Sold To Details'" class="mx-2"> -->
         </div>

         <!-- row-3 -->
         <div class="row">
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 1<span
                     class="star_class">*</span></label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('shipAddress1')?.invalid ||
                     supplierFormControl.get('shipAddress1')?.invalid &&
                     supplierFormControl.get('shipAddress1')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="shipAddress1"   class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address 1 is Required </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 2<span
                     class="star_class">*</span></label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('shipAddress2')?.invalid ||
                     supplierFormControl.get('shipAddress2')?.invalid &&
                     supplierFormControl.get('shipAddress2')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="shipAddress2" class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address2 is Required </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 3<span
                     class="star_class">*</span></label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('shipAddress3')?.invalid ||
                     supplierFormControl.get('shipAddress3')?.invalid &&
                     supplierFormControl.get('shipAddress3')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="shipAddress3" class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address3 is Required </div>
             </div>
 
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="address">Address 4</label>
                 <textarea name="" id="" cols="1" rows="1"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('shipAddress4')?.invalid ||
                     supplierFormControl.get('shipAddress4')?.invalid &&
                     supplierFormControl.get('shipAddress4')?.touched"
                     placeholder="Enter Your Address"
                     [readonly]="readonlyField" formControlName="shipAddress4" class="form-control"
                     type="text"></textarea>
                 <div class="invalid-feedback"> Address is Required </div>
             </div>
 
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="country">Country<span class="star_class">*</span></label>
                 <p-dropdown [options]="countryDropdown" (change)="getStateDropdown($event, '','' , 'country2')"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('shipCountryId')?.invalid ||
                 supplierFormControl.get('shipCountryId')?.invalid &&
                 supplierFormControl.get('shipCountryId')?.touched"
                 [readonly]="readonlyField" [style]="{width : '100%', height
                 :'35px'}" optionLabel="value" optionValue="id"
                 [formControl]="supplierFormControl.controls['shipCountryId']"></p-dropdown> 
                 <div class="invalid-feedback"> Country is Required </div>
             </div>
 
             <!-- row-2 -->
             <!-- <div class="row"> -->
             <div class="col-lg-4 col-md-12 form-group" *ngIf="!showOtherCityShipBoolean">
                 <label for="state">State<span class="star_class">*</span></label>
                 <p-dropdown [options]="stateDropdown"
                 [class.is-invalid]="register.submitted &&
                 supplierFormControl.get('shipStateId')?.invalid ||
                 supplierFormControl.get('shipStateId')?.invalid &&
                 supplierFormControl.get('shipStateId')?.touched"
                 [readonly]="readonlyField" [style]="{width : '100%', height
                 :'35px'}" optionLabel="value" optionValue="id"
                 [formControl]="supplierFormControl.controls['shipStateId']"></p-dropdown> 
                 <div class="invalid-feedback"> State is Required </div>
             </div>
           
             <div class="col-lg-4 col-md-12 form-group" *ngIf="showOtherCityShipBoolean">
                 <label for="">State<span class="star_class">*</span></label>
                 <input type="text" class="form-control"
                     [readonly]="readonlyField" formControlName="shipOtherState"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('shipOtherState')?.invalid ||
                     supplierFormControl.get('shipOtherState')?.invalid &&
                     supplierFormControl.get('shipOtherState')?.touched"
                     placeholder="Enter Other State">
                 <div class="invalid-feedback"> Other State is Required
                 </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="">City<span class="star_class">*</span></label>
                 <input type="text" class="form-control"
                     [readonly]="readonlyField" formControlName="shipCity"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('shipCity')?.invalid ||
                     supplierFormControl.get('shipCity')?.invalid &&
                     supplierFormControl.get('shipCity')?.touched"
                     placeholder="Enter Your City">
                 <div class="invalid-feedback"> City is Required
                 </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="">Pincode<span class="star_class">*</span></label>
                 <input type="text" class="form-control"
                     [readonly]="readonlyField" formControlName="shipPincode" minlength="4" maxlength="10" pKeyFilter="alphanum"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('shipPincode')?.invalid ||
                     supplierFormControl.get('shipPincode')?.invalid &&
                     supplierFormControl.get('shipPincode')?.touched"
                     placeholder="Enter Your Pincode">
                 <div class="invalid-feedback"> Pincode is Required
                 </div>
             </div>
         </div>
     </ng-container>


     <!-- Tax Registration Details -->
     <ng-container *ngIf="(this.customerDetails?.crFlag ? (customerChangeList?.includes('Tax Registration') ? true : false) : true)">
         <div class="section-heading">
             <h5>Tax Registration Details</h5>
         </div>
    
         <!-- row-4 -->
         <div class="row" *ngIf="showGST">
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="city">GSTIN<span class="star_class">*</span></label>
                 <input class="form-control" [readonly]="readonlyField" formControlName="gstin_no"
                     placeholder="Enter GSTIN" minlength="15"
                     oninput="this.value= this.value.toUpperCase()"
                     maxlength="15" [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('gstin_no')?.invalid ||
                     supplierFormControl.get('gstin_no')?.touched &&
                     supplierFormControl.get('gstin_no')?.invalid"
                     autocomplete="off"/>
                 <div class="invalid-feedback"
                     *ngIf="supplierFormControl.controls.gstin_no?.errors?.required">
                     Please Enter GSTIN</div>
                 <div class="invalid-feedback"
                     *ngIf="supplierFormControl.controls.gstin_no?.errors?.pattern">Enter
                     Valid GSTIN </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="city">GSTIN Date<span class="star_class">*</span></label>
                 <p-calendar formControlName="gstin_Date" placeholder="Select GSTIN Date" [readonlyInput]="true" [maxDate]="myDate"
                     [showIcon]="true"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('gstin_Date')?.invalid ||
                     supplierFormControl.get('gstin_Date')?.invalid &&
                     supplierFormControl.get('gstin_Date')?.touched"
                 ></p-calendar>
                 <div class="invalid-feedback"> Please Select Date</div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="city">PAN Card<span class="star_class">*</span></label>
                 <input class="form-control" [readonly]="readonlyField" formControlName="panNumber"
                     minlength="10" maxlength="10"
                     [class.is-invalid]="register.submitted &&
                     supplierFormControl.get('panNumber')?.invalid ||
                     supplierFormControl.get('panNumber')?.touched &&
                     supplierFormControl.get('panNumber')?.invalid"
                     placeholder="Enter PAN"
                     autocomplete="off" />
                 <div class="invalid-feedback"
                     *ngIf="supplierFormControl.controls.panNumber?.errors?.required">
                     Please Enter PAN </div>
                 <span class="error"
                     *ngIf="supplierFormControl.controls.panNumber?.errors?.pattern">Enter
                     Valid PAN </span>
             </div>
         </div>
    
         <!-- row-5 -->
         <div class="row">
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="city">TAN No</label>
                 <input class="form-control" [readonly]="readonlyField" formControlName="tanNumber"
                     placeholder="Enter TAN Card"
                     maxlength="10" autocomplete="off" />
                 <div class="error" *ngIf="register.submitted &&
                     supplierFormControl.get('tanNumber')?.invalid ||
                     supplierFormControl.get('tanNumber')?.invalid &&
                     supplierFormControl.get('tanNumber')?.touched">
                     Please Enter Valid TAN No
                 </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group">
                 <label for="city">CIN/LLP No</label>
                 <input class="form-control" [readonly]="readonlyField" formControlName="vanNumber"
                     placeholder="Enter CIN/LLP No"
                     autocomplete="off" />
                 <div class="error" *ngIf="register.submitted &&
                     supplierFormControl.get('vanNumber')?.invalid ||
                     supplierFormControl.get('vanNumber')?.invalid &&
                     supplierFormControl.get('vanNumber')?.touched">
                     Please Enter Valid CIN/LLP No
                 </div>
             </div>
             <div class="col-lg-4 col-md-12 form-group" *ngIf="!showGST">
                 <label for="city">Other No</label>
                 <input class="form-control" [readonly]="readonlyField" formControlName="foreignNo"
                     placeholder="Enter Other No"/>
                 <div class="error" *ngIf="register.submitted &&
                     supplierFormControl.get('foreignNo')?.invalid ||
                     supplierFormControl.get('foreignNo')?.invalid &&
                     supplierFormControl.get('foreignNo')?.touched">
                     Please Enter Other No
                 </div>
             </div>
    
             <div class="col-lg-4 form-group" *ngIf="showGST && !documentList?.panUpload">
                 <label for="account">PAN Card Upload<span class="star_class">*</span></label><br>
                 <input class="form-control" type="file" #panUpload
                     [class.is-invalid]="(register.submitted && supplierFormControl.get('panUpload')?.invalid) || (supplierFormControl.get('panUpload')?.invalid && supplierFormControl.get('panUpload')?.touched)"
                     (change)="upload_doc($event , 'panUpload')"
                     autocomplete="off" />
                 <div class="invalid-feedback">Please Upload PAN Document</div>
             </div>
             <div *ngIf="documentList.panUpload" class="col-lg-4 form-group"> 
                 <label for="account">PAN Card Upload<span class="star_class">*</span></label><br>
                 <a [href]="documentList.panUpload" target="_blank" [download]="documentList.panUpload">Preview</a><span class="cross_btn mx-2" *ngIf="!readonlyField" (click)="documentList.panUpload = ''">X</span>
             </div>
    
             <div class="col-lg-4 form-group" *ngIf="showGST && !documentList?.gstinUpload">
                 <label for="account">GST Registration Certificate<span class="star_class">*</span></label>
                 <input class="form-control" type="file" #gstUpload
                     [class.is-invalid]="(register.submitted && supplierFormControl.get('gstinUpload')?.invalid) || (supplierFormControl.get('gstinUpload')?.invalid && supplierFormControl.get('gstinUpload')?.touched)"
                     (change)="upload_doc($event , 'gstinUpload')"
                     autocomplete="off" />
                 <div class="invalid-feedback">Please Upload GST Document</div>
             </div>
             <div *ngIf="documentList.gstinUpload" class="col-lg-4 form-group"> 
                 <label for="account">GST Registration Certificate<span class="star_class">*</span></label><br>
                 <a [href]="documentList.gstinUpload" target="_blank" [download]="documentList.gstinUpload">Preview</a><span class="cross_btn mx-2" *ngIf="!readonlyField" (click)="documentList.gstinUpload = ''">X</span>
             </div>
         </div>
     </ng-container>


     <!-- Vendor Contact Details -->

     <ng-container *ngIf="!this.customerDetails?.crFlag">
         <div class="section-heading">
            <h5>Customer Contact Details</h5>
        </div>
    
        <!-- row-6 -->
        <div formArrayName="customerContactDetails">
            <ng-container *ngFor="let contact of getContactArray().controls; index as
            idx; last as last">
                <div class="row" [formGroupName]="idx">
                    <div class="col-lg-3 form-group">
                        <label for="contact">Contact Person<span class="star_class">*</span></label>
                        <input class="form-control" type="text"
                        [class.is-invalid]="(register.submitted &&
                        this.supplierFormControl.get('customerContactDetails').controls[idx].controls['contactPerson'].invalid)
                        || (
                        this.supplierFormControl.get('customerContactDetails').controls[idx].touched
                        &&
                        this.supplierFormControl.get('customerContactDetails').controls[idx].controls['contactPerson'].invalid)
                        ||
                        (this.supplierFormControl.get('customerContactDetails').controls[idx].controls['contactPerson'].invalid
                        && directorBoolean)" placeholder="Enter Contact Person Name"
                        formControlName="contactPerson"  
                        autocomplete="off" required />
                    <div class="invalid-feedback"> Enter Contact Person Name</div>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="tel">Contact Number<span class="star_class">*</span></label>
                        <input class="form-control"  pKeyFilter="money"
                            formControlName="contactNumber"
                            [class.is-invalid]="register.submitted &&
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['contactNumber'].invalid ||
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['contactNumber'].invalid &&
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['contactNumber'].touched"
                            placeholder="Enter Contact Number" minlength="10"
                            maxlength="15"
                            autocomplete="off" required/>
                        <div class="invalid-feedback">
                            Enter Contact Number
                        </div>
                        
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="fax">Alternate Number</label>
                        <input class="form-control" type="text" pKeyFilter="money"
                            formControlName="alternetNumber" placeholder="Enter Alternate Number"
                            autocomplete="off" minlength="10" maxlength="15" />
                        <div class="error" *ngIf="register.submitted &&
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['alternetNumber'].invalid ||
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['alternetNumber'].invalid &&
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['alternetNumber'].touched">
                            Enter Contact Number
                        </div>
                    <div class="invalid-feedback">
                        Enter Contact Number with Country Code Atleast 10 digit
                    </div>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Email Address<span class="star_class">*</span></label>
                            <input type="text" class="form-control" [readonly]="idx == 0" placeholder="Enter Email Address" [class.is-invalid]="register.submitted &&
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['emailAddress']?.invalid ||
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['emailAddress']?.invalid &&
                            this.supplierFormControl.get('customerContactDetails').controls[idx].controls['emailAddress']?.touched" formControlName="emailAddress" required>
                        <div class="invalid-feedback" *ngIf="this.supplierFormControl.get('customerContactDetails').controls[idx].controls['emailAddress'].errors?.required">Email Address is Required</div>
                        <div class="invalid-feedback" *ngIf="this.supplierFormControl.get('customerContactDetails').controls[idx].controls['emailAddress'].errors?.pattern" >Invalid Email Address is Required</div>
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-lg-1 d-flex">
                        <button type="button"
                            (click)="addContactRow('add')"  style="padding :
                            6px 15px 6px; margin-right: 5px; height: 35px;">+</button>
    
                            <div *ngIf="getContactArray().value.length > 1 && last">
                                <button type="button"
                                    (click)="addContactRow('delete' ,
                                    getContactArray().value.length-1 )" style="padding :
                                    6px 15px 6px; height: 35px;">-</button>
                            </div>
                    </div>
                    
                </div>
                
            </ng-container>
        </div>
     </ng-container>

    <!-- Vendor Bank Details -->

    <ng-container *ngIf="!this.customerDetails?.crFlag">
        <div class="section-heading">
            <h5>Additional Details</h5>
        </div>
    
        <!-- row-7 -->
        <div class="row">
            <div class="col-lg-4 form-group">
                <label for="">Document Name</label>
                <input class="form-control" type="text" formControlName="tinNumber" placeholder="Enter Document Name"  autocomplete="off"/>
            </div>
            <div class="col-lg-4 form-group">
                <label for="">Other Document</label>
                <div *ngIf="!documentList.cancelCheque">
                    <input class="form-control" type="file" #cancelCheque multiple
                        (change)="upload_doc($event , 'cancelCheque')"
                        autocomplete="off" required />
                </div>
                <div *ngIf="documentList.cancelCheque">
                    <a [href]="documentList.cancelCheque" target="_blank">Preview</a> <span class="mx-2 " style="cursor: pointer;" (click)="documentList = {}">X</span>
                </div>
            </div>
    
            <div class="col-lg-4 form-group">
                <label for="applicationType">Application Type</label>
                <select class="form-control form-select" formControlName="applicationTypeId">
                    <option value="" disabled selected>Select Application Type</option>
                    <ng-container *ngFor="let application of applicationTypeDropdown">
                        <option [value]="application.id">{{application.value}}</option>
                    </ng-container>
                </select>
                <div class="error" *ngIf="register.submitted &&
                    supplierFormControl.get('applicapplicationTypeId')?.invalid ||
                    supplierFormControl.get('applicationTypeId')?.invalid &&
                    supplierFormControl.get('applicationTypeId')?.touched">Please
                    select Application Type</div>
            </div>
            <!-- <div class="col-lg-4 form-group">
                <label for="industry">Industry</label>
                <select class="form-control form-select" formControlName="industryId">
                    <option value="" disabled selected>Select Industry</option>
                    <ng-container *ngFor="let industry of industryDropdown">
                        <option [value]="industry.id">{{industry.value}}</option>
                    </ng-container>
                </select>
                <div class="error" *ngIf="register.submitted &&
                    supplierFormControl.get('industryId')?.invalid ||
                    supplierFormControl.get('industryId')?.invalid &&
                    supplierFormControl.get('industryId')?.touched">Please select Industry</div>
            </div> -->
            <div class="col-lg-4 form-group">
                <label for="freightHandlingCodeId">Freight Handling Code</label>
                <select class="form-control form-select" formControlName="freightHandlingCodeId">
                    <option value="" disabled selected>Select Freight Handling Code</option>
                    <ng-container *ngFor="let freight of freightCodeDropdown">
                        <option [value]="freight.id">{{freight.value}}</option>
                    </ng-container>
                </select>
                <div class="error" *ngIf="register.submitted &&
                    supplierFormControl.get('freightHandlingCodeId')?.invalid ||
                    supplierFormControl.get('freightHandlingCodeId')?.invalid &&
                    supplierFormControl.get('freightHandlingCodeId')?.touched">Please select Freight Handling Code</div>
            </div>
            <div class="col-lg-4 form-group">
                <label for="marketSegmentId">Market Segment</label>
                <select class="form-control form-select" formControlName="marketSegmentId">
                    <option value="" disabled selected>Select Market Segment</option>
                    <ng-container *ngFor="let industry of marketSegmentDropdown">
                        <option [value]="industry.id">{{industry.value}}</option>
                    </ng-container>
                </select>
                <div class="error" *ngIf="register.submitted &&
                    supplierFormControl.get('marketSegmentId')?.invalid ||
                    supplierFormControl.get('marketSegmentId')?.invalid &&
                    supplierFormControl.get('marketSegmentId')?.touched">Please select Market Segment</div>
            </div>
            
            <div class="col-lg-4 form-group">
                <label for="marketSubSegment">Market Subsegment {{supplierFormControl.get('marketSubsegmentId').value}}</label>
                <select class="form-control form-select" formControlName="marketSubsegmentId">
                    <option value="" disabled selected>Select Market Subsegment</option>
                    <ng-container *ngFor="let market of marketSubSegmentDropdown">
                        <option [value]="market.id">{{market.value}}</option>
                    </ng-container>
                </select>
                <div class="error" *ngIf="register.submitted &&
                    supplierFormControl.get('marketSubsegmentId')?.invalid ||
                    supplierFormControl.get('marketSubsegmentId')?.invalid &&
                    supplierFormControl.get('marketSubsegmentId')?.touched">Please select Market Subsegment</div>
            </div>
            <div class="col-lg-4 form-group">
                <label for="freight_handling_code">Region </label>
                <select class="form-control form-select" formControlName="regionId">
                    <option value="" disabled selected>Select Region </option>
                    <ng-container *ngFor="let region of regionDropdown">
                        <option [value]="region.id">{{region.value}}</option>
                    </ng-container>
                </select>
            </div>
            <div class="col-lg-4 form-group">
                <label for="SubRegion">Sub Region</label>
                <select class="form-control form-select" formControlName="subRegionId">
                    <option value="" disabled selected>Select Sub Region </option>
                    <ng-container *ngFor="let subRegion of subRegionDropdown">
                        <option [value]="subRegion.id">{{subRegion.value}}</option>
                    </ng-container>
                </select>
            </div>
            <div class="col-lg-4 form-group">
                <label for="CompanyOwnership">Company Ownership</label>
                <select class="form-control form-select" formControlName="companyOwnershipId">
                    <option value="" disabled selected>Select Company Ownership </option>
                    <ng-container *ngFor="let companyOwnership of companyOwnershipDropdown">
                        <option [value]="companyOwnership.id">{{companyOwnership.value}}</option>
                    </ng-container>
                </select>
                <div class="error" *ngIf="register.submitted &&
                    supplierFormControl.get('companyOwnership')?.invalid ||
                    supplierFormControl.get('companyOwnership')?.invalid &&
                    supplierFormControl.get('companyOwnership')?.touched">Please select Region </div>
            </div>
    
            <div class="col-lg-4 form-group">
                <label for="CarrierNumber">Carrier Number</label>
                <input type="text" class="form-control" placeholder="Enter Carrier Number" formControlName="carrierNumber" />
            </div>
            
            <div class="col-lg-4 form-group">
                <label for="">Remark</label>
                <textarea class="form-control" [class.is-invalid]="(register.submitted && supplierFormControl.get('bankAddress')?.invalid) || (supplierFormControl.get('bankAddress')?.invalid && supplierFormControl.get('bankAddress')?.touched)"
                 name="" id="" placeholder="Enter Remark" cols="30" rows="10" formControlName="bankAddress"></textarea>
                 <div class="invalid-feedback">Please Enter Remark</div>
            </div>
        </div>
    </ng-container>



     <!-- action-button -->
     <div class="d-flex" style="justify-content: right;" *ngIf="!readonlyField">
         <button type="submit" class="mx-2 btn">{{submitButton}}</button>
     </div>
 </form>
    <app-copyrights></app-copyrights>

</div>