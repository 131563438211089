<div class="recent-activities-box">
    <h3>Recent Activities</h3>

    <ul>
        <li class="alert alert-dismissible fade show" role="alert">
            <div class="icon">
                <i class='bx bx-layer'></i>
            </div>
            Your listing <strong><a href="#">Hills Hotel</a></strong> has been approved!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </li>
        <li class="alert alert-dismissible fade show" role="alert">
            <div class="icon">
                <i class='bx bx-star'></i>
            </div>
            <strong>Andy Smith</strong> left a review <div class="rating mid" data-rating="3.0"></div> on <strong><a href="#">Mad Grill</a></strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </li>
        <li class="alert alert-dismissible fade show" role="alert">
            <div class="icon">
                <i class='bx bx-heart'></i>
            </div>
            Someone bookmarked your <strong><a href="#">Mexican Grill</a></strong> listings!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </li>
        <li class="alert alert-dismissible fade show" role="alert">
            <div class="icon">
                <i class='bx bxs-star'></i>
            </div>
            Andy Smith left a review <div class="rating high" data-rating="5.0"></div> on <strong><a href="#">Mad Grill</a></strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </li>
        <li class="alert alert-dismissible fade show" role="alert">
            <div class="icon">
                <i class='bx bxs-bookmark-star'></i>
            </div>
            Someone bookmarked your <strong><a href="#">Grill</a></strong> listings!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </li>
        <li class="alert alert-dismissible fade show" role="alert">
            <div class="icon">
                <i class='bx bx-layer'></i>
            </div>
            Your listing <strong><a href="#">Hotel Hills</a></strong> has been approved!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </li>
        <li class="alert alert-dismissible fade show" role="alert">
            <div class="icon">
                <i class='bx bxs-star-half'></i>
            </div>
            <strong>James Andy</strong> left a review <div class="rating low" data-rating="2.5"></div> on <strong><a href="#">Mad Grill</a></strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </li>
    </ul>
</div>