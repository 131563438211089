<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <!-- <div class="send-email-checker2"> -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- </div> -->
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="card">
        <p-table #dt2 [value]="supplierList"
            dataKey="supplierId" [rows]="10"
            responsiveLayout="scroll" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,20,30,50]"
            [paginator]="true" currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [filterDelay]="0"
            [globalFilterFields]="['emailAddress','mobileNo','supplierAddress','manufacturingProcessList','materialofconstructionList',
            'isVerify' , 'supplierName' , 'isApproved', 'jdeNo']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text"
                        (input)="dt2.filterGlobal($event.target.value,
                        'contains')"
                        placeholder="Global Search" [(ngModel)]="filterval" />
                    <!-- <p-calendar appendTo="body" class="ml-2" dateFormat="dd-mm-yy" placeholder="Bill Date"
                                 [(ngModel)]="dateFilterVal"
                                (onSelect)="searchFilter($event)">
                            </p-calendar> -->
                    <p-button type="button" class="ml-2" (click)="reset(dt2)"
                        icon="bx bx-reset"></p-button>
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>JDE No.</th>
                    <th>Supplier Name</th>
                    <th>Assessment Status</th>
                    <th>Assessment Document</th>
                    <th>Assessment Action</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr class="p-selectable-row">
                    <td>{{i+1}}</td>
                    <td>{{customer.jdeNo ? customer.jdeNo : '-'}}</td>
                    <td>
                        <span>{{customer.supplierName }}</span>
                    </td>
                    <td>
                        <span *ngIf="customer.assessment">
                            <span class="product-badge status-Acknowledged" *ngIf="customer.assessmentStatus == 'Approved'">Approved</span>
                            <span *ngIf="customer.assessmentStatus == ''" class="product-badge status-">Pending</span>
                            <span *ngIf="customer.assessmentStatus == 'Conditional Approved'"  class="product-badge status-condition">Conditional Approved</span>
                            <span *ngIf="customer.assessmentStatus == 'Rejected'"  class="product-badge status-Rejected">Rejected</span>
                        </span>
                        <span *ngIf="!customer.assessment">
                            <span>-</span>
                        </span>
                    </td>
                    <td>
                        <span class="image-text"><a [href]="customer.assessmentUpload" [download]="customer.assessmentUpload" target="_blank" style="text-decoration: none;"><button pButton pRipple type="button" icon="bx bx-download"
                            class="p-button-rounded p-button-primary
                            p-button-text"></button></a></span>
                    </td>
                    <td>
                        <span class="image-text" >
                            <button pButton pRipple type="button" *ngIf="customer.assessmentStatus != 'Rejected' || customer.assessmentStatus == 'Approved'" icon="bx bx-check-circle"
                            class="p-button-rounded p-button-success 
                            p-button-text"  pTooltip="Approve" (click)="Confirm(customer , 'Approved')"></button>
                            <span *ngIf="customer.assessmentStatus != 'Approved' && customer.assessmentStatus != 'Rejected'"  (click)="Confirm(customer , 'Conditional Approved')" pTooltip="Conditional Approved" ><img src="assets/img/icon.png" style="width: 20px; margin-top: -22px; margin-left: 10px; margin-right: 10px; cursor: pointer;" alt=""></span>
                            <button *ngIf="customer.assessmentStatus != 'Approved' || customer.assessmentStatus == 'Rejected'" pTooltip="Rejected"   pButton pRipple type="button" icon="pi pi-times-circle"
                            class="p-button-rounded p-button-danger
                            p-button-text" (click)="Confirm(customer , 'Rejected')"></button>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex-grow-1"></div>
    </div>
    <!-- End -->

    <app-copyrights></app-copyrights>

</div>