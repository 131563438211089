import { Component,OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, Message } from 'primeng/api';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent {
  breadcrumb = [
    {
      title: 'Order Status',
      subTitle: 'Dashboard',
    },
  ];
  filterval:any;
  orderList:any[] = [];
  user:any;
   ngOnInit(): void {
    this.getOrderDetail();
  }

  constructor(public _utility: AppUtility, public _apiService: ApiServiceService) { }
  
  reset(dt2) {
    dt2.reset();
  }
  getOrderDetail() {
    this.user = this._utility.getLocalStorageDetails();
    this._utility.loader(true);
    let data = []
    this._apiService.orderDetails().then((res: any) => {
      // console.log(res);
      this._utility.loader(false);
      if (res.success == true) {
        data = res.returnValue;
        if(this.user?.roleName == 'Customer'){
          this._apiService.getCustomer(this.user?.supplierId).then((res: any) => {
            if (res.success) {
             const customer = res?.returnValue;
            data.forEach((element:any) => {
              if(element?.addressNumber == customer?.customerCode){
                this.orderList.push(element);
              }
            })
            }
          });
        }else{
          let orderArray:any[] = [];
          data.forEach((element: any) => {
            const formatDate = (dateString: string) => {
                const date = new Date(dateString);
                const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            };
           
            orderArray.push({
              orderNumber: element?.orderNumber,
              orderType: element?.orderType,
              reference: element?.reference,
              lineNumber: element?.lineNumber,
              addressNumber: element?.addressNumber,
              billToName: element?.billToName,
              orderDate: formatDate(element?.orderDate),
              requestedDate: formatDate(element?.requestedDate),
              invoiceDate: formatDate(element?.invoiceDate),
              invoiceNumber: element?.invoiceNumber,
              quantityOrdered: element?.quantityOrdered,
              quantityShipped: element?.quantityShipped,
              nextStatusDesc: element?.nextStatusDesc,
              paymentTerms: element?.paymentTerms,
              clientId: element?.clientId,
              itemNumber: element?.itemNumber,
              itemDesc: element?.itemDesc,

            })
        
            // this.orderList.push({
            //     orderNumber: element?.orderNumber,
            //     orderType: element?.orderType,
            //     reference: element?.reference,
            //     lineNumber: element?.lineNumber,
            //     addressNumber: element?.addressNumber,
            //     billToName: element?.billToName,
            //     orderDate: formatDate(element?.orderDate),
            //     requestedDate: formatDate(element?.requestedDate),
            //     invoiceDate: formatDate(element?.invoiceDate),
            //     invoiceNumber: element?.invoiceNumber,
            //     quantityOrdered: element?.quantityOrdered,
            //     quantityShipped: element?.quantityShipped,
            //     nextStatusDesc: element?.nextStatusDesc,
            //     paymentTerms: element?.paymentTerms,
            //     clientId: element?.clientId
            // });
        });
          this.orderList = orderArray;
          // console.log('orderList',this.orderList)
        }
      
      } else {
        this.orderList = [];
      }
    });
    


  }
  
}
