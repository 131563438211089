

<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

        <div class="add-listings-box1 my-3" *ngIf="showChangeList == 'List'">
            <h6>Please Select Tabs You Want to Change :</h6>
            <ng-container *ngFor="let data of ChangeRequest; index as index">
                <div class="d-flex my-2" style="align-items: center;
                    align-items: center;">
                    <input type="checkbox" style="width: 40px;"
                        (change)="request($event, data, index)"><label for=""
                        style="margin-bottom: 0; font-size: 15px;">{{data.value}}</label>
                </div>
            </ng-container>
            <div class="add-listings-btn col-lg-12 col-md-12 form-group p-0">
                <div class="submit-toggle1" style="text-align: right; width:
                    100%;">
                    <button type="submit" class="submit_btn"
                        (click)="submitRequest()">Submit</button>
                </div>
            </div>
        </div>

        <div class="add-listings-box1" *ngIf="showChangeList == 'Applied'" style="text-align: center; background: #dedede;">
            <span>Your Already Applied Change Request is in Pending State</span>
        </div>
        <div class="add-listings-box1" *ngIf="showChangeList == 'Rework'" style="text-align: center; background: #dedede;">
            <span>Your Status is in Rework State </span>
        </div>

    <!-- <p-table #dt2 [value]="supplierList"
            dataKey="changeRequestData" [rows]="10"
            responsiveLayout="scroll" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,20,30,50]"
            [paginator]="true" currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [filterDelay]="0">

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>JDE No.</th>
                    <th>Supplier Name</th>
                    <th>Supplier Email</th>
                    <th>Mobile No</th>
                    <th>Supplier Address</th>
                    <th>Approval Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                    <tr class="p-selectable-row">
                        <td>{{i+1}}</td>
                        <td>{{customer.jdeNo ? customer.jdeNo : '-'}}</td>
                        <td>
                            <span>{{customer.supplierName }}</span>
                        </td>
                        <td>
                            <span class="image-text">{{customer.emailAddress}}</span>
                        </td>
                        <td>
                            <span class="image-text">{{customer.mobileNo}}</span>
                        </td>
                        <td style="min-width: 150px; white-space:
                            nowrap!important;">
                            <span class="image-text">{{customer.supplierAddress}}</span>
                        </td>
                        <td>{{customer.raiseApproved == 'Approved' ? 'Approved' : customer.raiseVerify}}</td>
                    </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
    </p-table> -->
    <div class="flex-grow-1"></div>
    <app-copyrights></app-copyrights>
</div>
