
<div class="container">
    <!-- registerform -->
    <div class="signup-form">
        <form action="" #jdeSubmit="ngForm"
        (ngSubmit)="submitJDE(jdeSubmit)">
        <div class="row">
            <div class="col-lg-4 col-md-12 form-group m-0">
                <label for="first-name">JDE supplier/Customer No</label>
                <input type="text" [(ngModel)]="jdeForm.jdeNo"
                    class="form-control" [readonly]="!showSubmit"
                    placeholder="Enter JDE supplier/Customer No" name="jdeNo"
                    autocomplete="off" minlength="2"
                    maxlength='35' required
                    />
                <div *ngIf="jdeForm?.jdeNo?.length < 2 && jdeSubmit.submitted"
                    class="error-msg">Please Enter Atleast 2 digit JDE supplier/Customer No</div>
            </div>
            <div class="add-listings-btn col-lg-2 col-md-12 form-group" *ngIf="showSubmit">
                <button type="submit" class="submit_btn">Submit</button>
            </div>
        </div>
    </form>
    <!-- supplier registration form -->
    <div class="section-heading">
        <h5>Supplier Certificates</h5>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
            <a [href]="supplierList.documentUploadPath"
                target="_blank" style="font-size:13px; font-weight: 600;
                color: var(--mainColor);"
                [download]="supplierList.documentUploadPath">Organization
                Profile</a>
        </div>
        <ng-container *ngFor="let doc of
            supplierList.supplierCertificatesDetails">
            <ng-container *ngIf="doc.documentPath != '' &&
                doc.documentName != ''">
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <a [href]="doc.documentPath" style="font-size:13px;
                        font-weight: 600; color: var(--mainColor);"
                        target="_blank"
                        [download]="doc.documentPath">{{doc.documentName}}</a>
                </div>
            </ng-container>
        </ng-container>
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
            <a [href]="supplierList.cancelledCheque"
                target="_blank" style="font-size:13px; font-weight: 600;
                color: var(--mainColor);"
                [download]="supplierList.cancelledCheque">Cancelled
                Cheque Preview</a>
        </div>
    </div>
    <!-- row-1 -->
    <div class="section-heading">
        <h6 style="margin: 0;">Supplier Details</h6>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-12 form-group"
            *ngIf="supplierList?.jdeNo">
            <label for="first-name">JDE supplier/Customer No</label>
            <span>{{supplierList?.jdeNo}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="first-name">Bussiness Unit Number</label>
            <span>{{supplierList.bussinessName}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="first-name">Bussiness Unit</label>
            <span>{{supplierList.buName}}</span>
        </div>
    </div>

    <!-- row-2 -->
    <div class="row">
        <div class="col-lg-4 col-md-12 form-group">
            <label for="first-name">Supplier Name</label>
            <span>{{supplierList.supplierName}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="address">Address 1</label>
            <span>{{supplierList.address1}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="address">Address 2</label>
            <span>{{supplierList.address2}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="address">Address 3</label>
            <span>{{supplierList.address3}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="address">Address 4</label>
            <span>{{supplierList.supplierAddress ? supplierList.supplierAddress : '-'}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="state">Country</label>
            <ng-container *ngFor="let value of countryDropdown">
                <span *ngIf="supplierList.countryId == value.id">{{value.value}}</span>
            </ng-container>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="state">State</label>
            <ng-container *ngFor="let value of stateDropdown">
                <span *ngIf="supplierList.stateId == value.id">{{value.value}}</span>
            </ng-container>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="state">City</label>
            <span>{{supplierList.city}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="state">Pincode</label>
            <span>{{supplierList.pinCode}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="city">Purpose</label>
            <ng-container *ngFor="let value of purposeDropdown">
                <span *ngIf="supplierList.purposeId == value.id">{{value.value}}</span>
            </ng-container>
        </div>
        <div class="col-lg-4 col-md-12 form-group" *ngIf="supplierList.services">
            <label for="city">Service Provided</label>
            <span>{{supplierList.services}}</span>
        </div>

        <!-- row-3 -->
        <!-- <div class="row"> -->
        <div class="col-lg-4 col-md-12 form-group"
            *ngIf="supplierList.interCompany">
            <label for="city">In case of Intercompany
                correct Co. No. In GL off</label>
            <span>{{supplierList.interCompany}}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group">
            <label for="credit">Payment Terms</label>
            <span>{{supplierList.payTermsId}}</span>
        </div>

        <div class="col-lg-4 col-md-12 form-group"
            *ngIf="supplierList.documentUploadPath">
            <label for="">Organization Profile</label>
            <label><a [href]="supplierList.documentUploadPath"
                    target="_blank"
                    [download]="supplierList.documentUploadPath">Preview</a></label>
        </div>

        <div class="col-lg-4 col-md-12 form-group">
            <label for="tds-applicable">TDS Applicable</label>
            <span>{{supplierList.tdsApplicable ? 'Yes' : 'No' }}</span>
        </div>



        <div class="col-lg-4 col-md-12 form-group">
            <label for="email">Whether Domestic or Foreign </label>
            <span>{{supplierList.isDomestic ? 'Foreign' : 'Domestic' }}</span>
        </div>
        <div class="col-lg-4 col-md-12 form-group"
            *ngIf="supplierList.isDomestic">
            <label for="email">If Foreign give Currency</label>
            <span>{{supplierList.currency}}</span>
        </div>
        <!-- </div> -->

        <!-- Tax Registration Details -->


        <div class="section-heading">
            <h5>Tax Registration Details</h5>
        </div>

        <!-- row-5 -->
        <ng-container *ngIf="!supplierList.isDomestic">
            <div class="col-lg-3 col-md-12 form-group">
                <label for="city">GSTIN </label>
                <span>{{supplierList.gstinNo}}</span>
            </div>
            <div class="col-lg-3 col-md-12 form-group">
                <label for="city">GSTIN Date</label>
                <span>{{supplierList.gstinDate | date}}</span>
            </div>
            <div class="col-lg-3 col-md-12 form-group">
                <label for="city">PAN </label>
                <span>{{supplierList.panNo}}</span>
            </div>
        </ng-container>

        <!-- row-6 -->
        <!-- <div class="row"> -->
        <div class="col-lg-3 col-md-12 form-group"
            *ngIf="supplierList.tanNo">
            <label for="city">TAN Number</label>
            <span>{{supplierList.tanNo}}</span>
        </div>
        <div class="col-lg-3 col-md-12 form-group">
            <label for="msme">MSME Applicable</label>
            <span>{{supplierList.msmeApplicable ? 'Yes' : 'No'}}</span>
        </div>



        <!-- Vendor Contact Details -->

        <div class="section-heading">
            <h5>Supplier Contact Details</h5>
        </div>
        <div class="col-lg-3 form-group">
            <label for="contact">Contact Person</label>
            <span>{{supplierList.contactPerson}}</span>
        </div>
        <div class="col-lg-3 form-group">
            <label for="tel">Contact Number</label>
            <span>{{supplierList.mobileNo}}</span>
        </div>
        <!-- <div class="col-lg-3 form-group" *ngIf="supplierList.faxNo">
            <label for="fax">FAX Number</label>
            <span>{{supplierList.faxNo}}</span>
        </div> -->
        <div class="col-lg-3 form-group">
            <label for="email">Email Address</label>
            <span>{{supplierList.emailAddress}}</span>
        </div>
        <div class="col-lg-3 form-group"
            *ngIf="supplierList.alternateEmail">
            <label for="email">Alternate Email Address</label>
            <span>{{supplierList.alternateEmail}}</span>
        </div>

        <!-- row-7 -->

        <!-- Vendor Bank Details -->
        <div class="section-heading">
            <h6 style="margin: 0;">Bank Details</h6>
        </div>
        <!-- row-8-->
        <div class="row">
            <div class="col-lg-4 form-group">
                <label for="ifscCode">Account Type</label>
                <span>{{supplierList.accountType == 'C' ? 'Current Account'
                    : 'Saving Account'}}</span>
            </div>
            <div class="col-lg-4 form-group">
                <label for="bankName">Bank Name</label>
                <span>{{supplierList.bankName}}</span>
            </div>
            <div class="col-lg-4 form-group">
                <label for="account">Account No.</label>
                <span>{{supplierList.accountNo}}</span>

            </div>
            <div class="col-lg-4 form-group">
                <label for="ifscCode">IFSC Code or Swift Code </label>
                <span>{{supplierList.ifscCode}}</span>
            </div>

            <div class="col-lg-4 form-group" *ngIf="supplierList.micrNo">
                <label for="ifscCode">MICR No.</label>
                <span>{{supplierList.micrNo}}</span>
            </div>

            <div class="col-lg-4 form-group"
                *ngIf="supplierList.bankAddress">
                <label for="ifscCode">Bank Address</label>
                <span>{{supplierList.bankAddress}}</span>
                <!-- <textarea name="" id="" readonly [(ngModel)]="supplierList.bankAddress" cols="30" rows="10"></textarea> -->
            </div>
            <div class="col-lg-4 col-md-12 form-group"
                *ngIf="supplierList.cancelledCheque">
                <label for="">Cancelled Cheque</label>
                <label><a [href]="supplierList.cancelledCheque"
                        target="_blank"
                        [download]="supplierList.cancelledCheque">Preview</a></label>
            </div>
        </div>

        <!-- previous 3 years Turnover -->
        <div class="section-heading">
            <h6>Turnover Details</h6>
        </div>

        <!-- row-9 -->
        <div class="row">
            <div class="col-lg-3 form-group">
                <label for="city">Year of Establishment:</label>
            </div>
            <div class="col-lg-3 form-group">
                <span>{{supplierList.yearofEstablishment}}</span>
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3 form-group d-flex
                align-items-center">
                <label for="city" style="margin: 0;">Upload Balance Sheet</label>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 form-group" style="display:
                flex;
                justify-content:left; align-items: center;
                text-align:
                center;">
                <label for="city">Turnover (Year 1)</label>
            </div>
            <div class="col-lg-3 form-group">
                <span>{{supplierList.year1}}</span>
            </div>
            <div class="col-lg-3 form-group"
                *ngIf="supplierList?.turnover1">
                <div class="d-flex">
                    <ng-container *ngFor="let currency of currencyDropdown">
                        <span *ngIf="currency.id ==
                            supplierList.currencyId">{{currency.value}}</span>
                    </ng-container>
                    <span>{{supplierList?.turnover1}}</span>
                </div>
            </div>
            <div class="col-lg-3 form-group"
                *ngIf="supplierList.balanceSheet1">
                <label><a
                        [href]="supplierList.balanceSheet1"
                        target="_blank"
                        [download]="supplierList.balanceSheet1">Preview</a></label>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 form-group" style="display:
                flex;
                justify-content:left; align-items: center;
                text-align:
                center;">
                <label for="city">Turnover (Year 2)</label>
            </div>
            <div class="col-lg-3 form-group">
                <span>{{supplierList.year2}}</span>
            </div>
            <div class="col-lg-3 form-group"
                *ngIf="supplierList?.turnover2">
                <div class="d-flex">
                    <ng-container *ngFor="let currency of currencyDropdown">
                        <span *ngIf="currency.id ==
                            supplierList.currencyId">{{currency.value}}</span>
                    </ng-container>
                    <span>{{supplierList?.turnover2}}</span>
                </div>
            </div>
            <div class="col-lg-3 form-group"
                *ngIf="supplierList.balanceSheet2">
                <label><a [href]="supplierList.balanceSheet2"
                        target="_blank"
                        [download]="supplierList.balanceSheet2">Preview</a></label>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 form-group" style="display:
                flex;
                justify-content:left; align-items: center;
                text-align:
                center;">
                <label for="city">Turnover (Year 3)</label>
            </div>
            <div class="col-lg-3 form-group">
                <span>{{supplierList.year3}}</span>
            </div>
            <div class="col-lg-3 form-group">
                <div class="d-flex">
                    <ng-container *ngFor="let currency of currencyDropdown">
                        <span *ngIf="supplierList.currencyId == currency.id
                            && supplierList.currencyId">{{currency.value}}</span>
                    </ng-container>
                    <span>{{supplierList.turnover3}}</span>
                </div>
            </div>
            <div class="col-lg-3 form-group"
                *ngIf="supplierList.balanceSheet3">
                <label><a
                        [href]="supplierList.balanceSheet3"
                        target="_blank"
                        [download]="supplierList.balanceSheet3">Preview</a></label>
            </div>
        </div>


        <div
            class="section-heading"*ngIf="supplierList.supplierDirectorsDetails?.length>
            0" >
            <h6>Authorized Signatory Details</h6>
        </div>

        <!-- row-10 -->

        <ng-container *ngFor="let director of
            supplierList.supplierDirectorsDetails; index
            as idx; last as
            last">
            <div class="row">
                <div class="form-group col-lg-4 col-md-4"
                    style="margin-top:
                    0.3rem!important;">
                    <label for="" style="margin-bottom:
                        0.3rem!important;"> Name</label>
                    <span>{{director.directorName}}</span>
                </div>
                <div class=" form-group col-lg-4 col-md-4"
                    style="margin-top: 0.3rem!important;">
                    <label for="" style="margin-bottom:
                        0.3rem!important;"> Contact Number</label>
                    <span>{{director.mobileNo}}</span>
                </div>
                <div class="form-group col-lg-4 col-md-4"
                    style="margin-top:
                    0.3rem!important;">
                    <label for="" style="margin-bottom:
                        0.3rem!important;">Email Address</label>
                    <span>{{director.email}}</span>
                </div>
            </div>
        </ng-container>
        <div class="section-heading"
            *ngIf="supplierList.supplierMajorCustomersDetails?.length>0">
            <h6>Major Customer</h6>
        </div>
        <div class="row">
            <ng-container *ngFor="let majorCustomer of
                supplierList.supplierMajorCustomersDetails;
                index as
                idx; last as last">
                <div class="form-group col-lg-3 col-md-12"
                    style="margin-top: 0.3rem!important;">
                    <label for="" style="margin-bottom: 0.3rem!important;">Organization
                        Name</label>
                    <span>{{majorCustomer.organizationName}}</span>
                </div>
                <div class="form-group col-lg-3 col-md-12"
                    style="margin-top: 0.3rem!important;">
                    <label for="" style="margin-bottom: 0.3rem!important;">Designation</label>
                    <span>{{majorCustomer.designation}}</span>
                </div>
                <div class="form-group col-lg-3 col-md-12"
                    style="margin-top: 0.3rem!important;">
                    <label for="" style="margin-bottom: 0.3rem!important;">Contact
                        Person</label>
                    <span>{{majorCustomer.contactPerson}}</span>
                </div>
                <div class="form-group col-lg-3 col-md-12"
                    style="margin-top: 0.3rem!important;">
                    <label for="" style="margin-bottom:
                        0.3rem!important;">Email Address</label>
                    <span>{{majorCustomer.email}}</span>
                </div>
                <div class="form-group col-lg-3 col-md-12"
                    style="margin-top: 0.3rem!important;">
                    <label for="" style="margin-bottom: 0.3rem!important;">Mobile
                        no.</label>
                    <span>{{majorCustomer.mobileNo}}</span>
                </div>
            </ng-container>
        </div>
        <div class="section-heading">
            <h6>Manufacturing Scope</h6>
        </div>
        <!-- row-11 -->
        <div class="row">
            <div class="col-lg-4 col-md-12 form-group">
                <label for="">Manufacturing List</label>
                <span>{{supplierList?.manufacturingProcessList}}</span>
            </div>
            <div class="col-lg-4 col-md-12 form-group">
                <label for="">Material Construction List</label>
                <span>{{supplierList?.materialofconstructionList}}</span>
            </div>

            <div class="col-lg-4 col-md-12 form-group" *ngIf="supplierList.otherCategoryId">
                <label for="">Other Category</label>
                <ng-container *ngFor="let dropdown of otherCategoryArray">
                    <span [value]="dropdown.id"
                        *ngIf="supplierList.otherCategoryId == dropdown.id">{{dropdown.value}}</span>
                </ng-container>
            </div>
            <div class="col-lg-4 col-md-12 form-group"
                *ngIf="supplierList.otherRemark">
                <label for="">Other Remarks</label>
                <span>{{supplierList.otherRemark}}</span>
            </div>
        </div>
    </div>
</div>