<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <!-- <div class="add-listings-box1">
        <form [formGroup]="docuploadFormControl" (ngSubmit)="documentApi(documentForm)" #documentForm="ngForm">
            <div class="row">
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="status">Supplier Name<span class="star_class">*</span></label>
                        <input type="text" readonly class="form-control" formControlName="supplierName" required>
                    </div>

                    <div class="col-lg-4 col-md-12  form-group">
                        <label for="doc">Document Name<span class="star_class">*</span></label>
                        <select class="form-control form-select" id="doc" formControlName="documentName"  [class.is-invalid]="documentForm.submitted && docuploadFormControl.get('documentName')?.invalid" required>
                            <option value="">Select Document Name</option>
                            <ng-container *ngFor="let doc of document_dropdown">
                                <option [value]="doc.id">{{doc.value}}</option>
                            </ng-container>
                        </select>
                        <div class="invalid-feedback"> document name is Required </div>
                    </div>

                    <div class="col-lg-4 col-md-12  form-group">
                        <label for="">Upload File<span class="star_class">*</span></label>
                        <input type="file" class="form-control" formControlName="upload_doc" (change)="getDocument($event)" [class.is-invalid]="documentForm.submitted && docuploadFormControl.get('upload_doc')?.invalid" required>
                        <div class="invalid-feedback"> document is Required </div>
                    </div>

                <div class="add-listings-btn col-lg-4 col-md-6">
                    <div class="form-group">
                        <button type="submit">{{submitButton}}</button>
                        <button type="button" class="reset_btn mx-2" (click)="resetForm(documentForm)">Reset</button>
                    </div>
                </div>
            </div>
        </form>
    </div>  -->
    <!-- End -->  
    <!-- <button (click)="downloadAsZip()">Download As Zip</button> -->
    <div class="card" *ngIf="suppliers?.length>0">
        <p-table #dt2 [value]="suppliers" dataKey="id" [rows]="10" responsiveLayout="scroll" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [loading]="loading"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['name','country.name','status']">
            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>Document Name</th>
                    <th>Uploaded Date</th>
                    <th>Document Upload</th>
                    <th>Status Name</th>
                    <th>Document</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr>
                    <td>{{i+1}}</td>
                    <td>
                        <span class="image-text">{{customer.documentName}}</span>
                        <a  *ngIf="customer.documentFileName?.length>0" href="{{customer.documentFileName}}" style="text-decoration: none;" download="{{customer.documentFileName}}" target="_blank"><button  pButton pRipple type="button" icon="bx bx-download" class="p-button-rounded p-button-primary p-button-text" style="height: 21px;"></button></a>
                    </td>
                    <td>
                        <span class="image-text">{{customer.uploadingDate | date}}</span>
                    </td>
                    <td> 
                        <button *ngIf="customer.statusName == 'Rework'" (click)="fileBrowse.click()" class="form-control browse-file">browse file + <input type="file" #fileBrowse style="display:none" (change)="getDocument($event , customer)"></button>
                        <div style="text-align:  center; width: 100px;">
                            <span  *ngIf="customer.statusName == 'Approved' || customer.statusName == 'Pending' || customer.statusName == 'Reworked but not Reviewed'">-</span>
                        </div>
                    </td>
                    <td >
                        <span *ngIf="(customer.statusName == 'Pending') || (customer.statusName == 'Reworked but not Reviewed' && roleName == 'Supplier')">-</span>
                        <!-- <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Pending'"
                            label="-"
                            class="p-button-rounded status_button pending"
                            ></button> -->
                        <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Approved'"
                            label="Approved"
                            class="p-button-rounded status_button p-button-success"
                            ></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Rework'"
                            label="Rework"
                            class="p-button-rounded status_button"
                            ></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Reworked but not Reviewed' && roleName == 'User'"
                            label="Reworked but not Reviewed"
                            class="p-button-rounded status_button"
                            ></button>
                    </td>
                    <td style="padding: 0.5rem 1rem!important;">
                        <span class="image-text" *ngIf="customer.documentPath?.length>0"><a href="{{customer.documentPath}}" download="{{customer.documentPath}}" target="_blank">Preview</a></span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div> 

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->