<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="my-profile-box">
                <h3>Profile Details ({{localStorageObject.roleName}})</h3>
    
                <form>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Your Name</label>
                                <input type="text" readonly class="form-control" [value]="this.supplierProfile[0]?.name">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" readonly class="form-control" [value]="this.supplierProfile[0]?.emailAddress">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Phone</label>
                                <input type="text" readonly class="form-control" [value]="this.supplierProfile[0]?.mobileNo">
                            </div>
                        </div>
                        
                        <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Address</label>
                                <input type="text" readonly class="form-control" [value]="this.supplierProfile[0]?.address">
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.localStorageObject.roleName == 'Supplier'">
                        <div class="notification-alert-profile alert alert-success alert-dismissible fade show" role="alert" *ngIf="this.supplierProfile[0]?.isApproved == 'Rework'">
                            Your Status is in <strong>Rework</strong> State , Please Update Details ! <br>
                            Action : {{this.supplierProfile[0]?.isApprovedRemark}}
                            &nbsp;<span style="cursor: pointer; text-decoration: underline;" routerLink="/supplierMaster">  Go</span>
                        </div>
                        <div class="notification-alert-profile alert alert-success alert-dismissible fade show" role="alert" *ngIf="this.supplierProfile[0]?.isApproved != 'Rework' && this.supplierProfile[0]?.isApproved != ''">
                            Thank you for Registered with us ! <br>
                            <!-- Your Status is <strong>{{this.supplierProfile[0]?.isApproved}}</strong> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="my-profile-box">
                <h3>Change Password</h3>
    
                <form [formGroup]="changePasswordFormControl" (ngSubmit)="changePassword(signin)" #signin="ngForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Current Password <span class="star_class">*</span></label>
                                <input type="password" class="form-control" (blur)="checkForPassword()" formControlName="loginPassword" [class.is-invalid]="signin.submitted &&
                                changePasswordFormControl.get('loginPassword')?.invalid"
                                required>
                                <div class="invalid-feedback">
                                    Enter Current Password
                                </div>
                                <div class="error-msg" *ngIf="samePasswordError.length>0">{{samePasswordError}}</div>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>New Password <span class="star_class">*</span></label>
                                <input type="password" class="form-control" (blur)="checkForPassword()" formControlName="password" [class.is-invalid]="signin.submitted &&
                                changePasswordFormControl.get('password')?.invalid || (changePasswordFormControl.get('password')?.invalid && changePasswordFormControl.get('password')?.touched)" [(ngModel)]="password" required>
                                <div class="invalid-feedback">
                                    Enter New Password
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Confirm New Password <span class="star_class">*</span></label>
                                <input type="password" class="form-control" (blur)="checkForPassword()" formControlName="confirmPassword" [class.is-invalid]="signin.submitted &&
                                changePasswordFormControl.get('confirmPassword')?.invalid || (changePasswordFormControl.get('confirmPassword')?.invalid && changePasswordFormControl.get('confirmPassword')?.touched)" [(ngModel)]="confirmPassword" required>
                                <div class="invalid-feedback">
                                    Enter New Confirm Password
                                </div>
                                <h6 class="text-danger" *ngIf="confirmPassword">
                                    {{ password === "" && confirmPassword != "" ?
                                    "Enter password" : "" }} {{ password != ""
                                    && password != confirmPassword ? "Password Not Match" : "" }} {{password == confirmPassword && password.length < 8 ? "Enter Password atleast 8 characters" : ""}}
                                </h6>
                            </div>
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <button type="submit">Change Password</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->