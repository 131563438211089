<!-- signin form  -->
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- login -->
<div class="login-page">
    <div class="overlay"></div>

    <div>
        <div style="min-height: 100vh; overflow-y: hidden; display: flex; align-items: center; justify-content: center;">
            <div class="vesax-login">
                <div>
                    <div class="login-logo">
                        <img src="../../../../../assets/img/Idex_Logo-removebg-preview.png" style="width: 50%;" />
                    </div>
                    <form [formGroup]="userFormControl"
                    (ngSubmit)="signinWithEmail()" #signin="ngForm">
                    <div class="signup-head d-flex" style="justify-content: space-between;">
                        <span id="auth-head">
                            <h4>Login</h4>
                        </span>
                    </div>
                    <div class="form-group first_username">
                        <label for="emailAddress">Username<span class="star_class">*</span></label>
                        <input class="form-control"
                            id="emailAddress" autocomplete="off" placeholder="Enter Username"
                            formControlName="emailAddress"
                            [class.is-invalid]="signin.submitted &&
                            userFormControl.get('emailAddress')?.invalid || userFormControl.get('emailAddress')?.touched && userFormControl.get('emailAddress')?.invalid"
                            required />
                        <div class="invalid-feedback" *ngIf="userFormControl.controls.emailAddress?.errors?.required">
                            Username is Required
                        </div>
                    </div>
                    <div class="form-group first_username">
                        <label for="password">Password<span
                                class="star_class">*</span></label>
                        <input class="form-control" type="password"
                            id="password" autocomplete="off" placeholder="Enter Password"
                            name="password" formControlName="password"
                            [class.is-invalid]="signin.submitted &&
                            userFormControl.get('password')?.invalid || userFormControl.get('password')?.touched && userFormControl.get('password')?.invalid"
                            required>
                        <div class="invalid-feedback">
                            Password is Required
                        </div>
                    </div>
                    <button class="submit_login" type="submit">Login</button>
                    <div class="forgot-password">
                    <span style="font-size: 15px;">Forgot Your Password ? <a routerLink="/reset-password">Forgot Password</a></span>
                    </div>
                </form>
                </div>
            </div>
            <div class="div-right">
                <div class="ml-3">
                    <h3>Welcome to IDEX Portal</h3>
                    <p>"Trusted Solutions Improving Lives"</p>
                </div>
            </div>
        </div>
    </div>
</div>
