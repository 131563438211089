<!-- Start Copyright End -->
<div class="copyrights-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p>© IDEX Corporation</p>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 text-end">
            <p>Designed by <a href="http://webanixsolutions.com" target="_blank">WEBaniX</a></p>
            <!-- <p>Designed by ❤️ <a href="/" target="_blank">Webanix</a></p> -->
        </div>
    </div>
</div>
<!-- End Copyright End -->