<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="card change-ss" *ngIf="supplierList.length > 0">
        <p-table #dt2 [value]="supplierList"
            dataKey="id" [rows]="10"
            responsiveLayout="scroll" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,20,30,50]"
            [paginator]="true" currentPageReportTemplate="Showing  {totalRecords} of {{supplierList.length}} entries"
            [globalFilterFields]="['emailAddress','mobileNo','supplierAddress',
            'isVerify' , 'supplierName' , 'isApproved', 'jdeNo']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text"
                        (input)="dt2.filterGlobal($event.target.value,
                        'contains')"
                        placeholder="Global Search" [(ngModel)]="filterval" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)"
                        icon="bx bx-reset"></p-button>
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>Action</th>
                    <th>JDE No.</th>
                    <th>Checker1</th>
                    <th style="white-space: nowrap; min-width: 150px;">Checker2</th>
                    <th>Supplier Name</th>
                    <th>Supplier Email</th>
                    <th>Mobile No</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <ng-container>
                    <tr class="p-selectable-row">
                        <td>{{i+1}}</td>
                        <td>
                            <button pButton pRipple type="button" icon="bx bx-show"
                                class="p-button-rounded p-button-primary
                                p-button-text"
                                (click)="openModal(customer.supplierId ,
                                'show')"></button>
                        </td>
                        <td>{{customer.jdeNo ? customer.jdeNo : '-'}}</td>
                        <td style="white-space: nowrap;">
                            <button pButton pRipple type="button"
                                *ngIf="customer.raiseVerify == 'Verified'"
                                [label]="customer.raiseVerify"
                                class="p-button-rounded
                                p-button-success status_button"
                                style="font-size:
                                12px; padding:
                                0.2rem 1rem;"></button>
                            <button pButton pRipple type="button"
                                (click)="openModal(customer.supplierId)"
                                *ngIf="customer.raiseVerify == 'Pending'"
                                label="Pending" class="p-button-rounded
                                status_button pending"></button>
                            <button pButton pRipple type="button"
                                *ngIf="customer.raiseVerify == 'Rework'"
                                (click)="openModal(customer.supplierId ,'show')"
                                [label]="customer.raiseVerify"
                                class="p-button-rounded
                                p-button-info status_button"></button>
                            <button pButton pRipple type="button"
                                (click)="openModal(customer.supplierId ,'show')"
                                *ngIf="customer.raiseVerify == 'Rejected'"
                                [label]="customer.raiseVerify"
                                class="p-button-rounded
                                p-button-danger status_button"></button>
                        </td>
                        <td style="white-space: nowrap;">
                            <ng-container *ngIf="customer.raiseVerify == 'Verified'">
                                <button pButton pRipple type="button"
                                    *ngIf="customer.raiseApproved == 'Approved'"
                                    label="Approved for JDE"
                                    class="p-button-rounded
                                    p-button-success status_button"></button>
                                <button pButton pRipple type="button"
                                    *ngIf="customer.raiseApproved == 'Registered'"
                                    label="Registered In JDE"
                                    class="p-button-rounded
                                    p-button-success registered_button status_button"></button>
                                <button pButton pRipple type="button"
                                    (click)="openModal(customer.supplierId
                                    ,'show')"
                                    *ngIf="customer.raiseApproved == 'Pending'"
                                    label="Awaiting Checker2 Approval"
                                    class="p-button-rounded status_button
                                    pending"></button>
                                <a href="javascript:void(0)"
                                    *ngIf="customer.raiseApproved == '' &&
                                    customer.isVerify == 'Verified' &&
                                    userroleName.checker1 == true"
                                    (click)="confirm1(customer)">Send to Checker2</a>
                                <button pButton pRipple type="button"
                                    (click)="openModal(customer.supplierId ,
                                    'show')"
                                    *ngIf="customer.raiseApproved == 'Rework'"
                                    [label]="customer.raiseApproved"
                                    class="p-button-rounded p-button-info
                                    status_button"></button>
                                <button pButton pRipple type="button"
                                    (click)="openModal(customer.supplierId ,
                                    'show')"
                                    *ngIf="customer.raiseApproved == 'Rejected'"
                                    [label]="customer.raiseApproved"
                                    class="p-button-rounded p-button-danger
                                    status_button"></button>
                            </ng-container>
                        </td>
                        <td>
                            <span>{{customer.supplierName }}</span>
                        </td>
                        <td>
                            <span class="image-text">{{customer.emailAddress}}</span>
                        </td>
                        <td>
                            <span class="image-text">{{customer.mobileNo}}</span>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="card change-ss" *ngIf="supplierList.length == 0" style="padding: 10px; margin-top: 10px;">
        <p style="font-size: 13px; margin: 0!important;">No Change Request Found</p>
    </div>
    
    <div class="flex-grow-1"></div>
    <!-- End -->

    <app-copyrights></app-copyrights>

</div>












<p-dialog header="Supplier Change Request" [(visible)]="display" [modal]="true"
    [style]="{width: '95vw'}" [draggable]="false" [resizable]="false">
    <div class="container-fluid authentication">
        <div class="signup-form">
            <div class="row">
                <div class="col-lg-3 col-md-12 form-group">
                    <select class="form-control form-select" id="applicable"
                        required [disabled]="userCapture"
                        (change)="showRemark($event)"
                        [(ngModel)]="supplierListById.statusCapture">
                        <ng-container *ngFor="let approvalStatus of approvalStatus">
                            <option [value]='approvalStatus.value' [disabled]="approvalStatus.value == 'Pending'">{{approvalStatus.name}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-3 col-md-12 form-group" *ngIf="remarkBoolean">
                    <input class="form-control" [disabled]="userCapture" [(ngModel)]="remarkInput"
                        type="text" placeholder="Remarks">
                </div>
                <div class="add-listings-btn col-lg-2 col-md-12 form-group">
                    <div class="submit-toggle1">
                        <button type="submit" class="submit_btn"
                            (click)="captureStatus(supplierListById ,
                            'Submit')">Submit</button>
                    </div>
                </div>
            </div>

            <!-- <div class="section-heading">
                <h5>Supplier Certificates</h5>
            </div>
            <div class="row"> -->
            <!-- <ng-container *ngFor="let doc of certificates">
                    <ng-container *ngIf="doc.documentPath != '' &&
                        doc.documentName != ''">
                        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
                            <a [href]="doc.documentPath" style="font-size:13px;
                                font-weight: 600; color: var(--mainColor);"
                                target="_blank"
                                [download]="doc.documentPath">{{doc.documentName}}</a>
                        </div>
                    </ng-container> -->
            <!-- </ng-container> -->
            <!-- <div class="col-lg-3 col-md-6 col-sm-12 form-group">
                    <a [href]="supplierListById.cancelledCheque"
                        target="_blank" style="font-size:13px; font-weight: 600;
                        color: var(--mainColor);"
                        [download]="supplierListById.cancelledCheque">Cancelled
                        Cheque Preview</a>
                </div> -->
            <!-- </div> -->

            <div class="row">
                <div class="col-lg-6">
                    <h3 style="text-align: center;">Previous Details</h3>
                    <div class="row" style="padding-right: 0!important;">
                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Supplier Name')">
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="first-name">Supplier Name</label>
                                <input type="text" class="form-control" [(ngModel)]="supplierListById.supplierName" [disabled]="true">
                                <!-- <span class="form-control">{{supplierListById.supplierName}}</span> -->
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="address">Address 1</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1"
                                        placeholder="Enter Your Address"
                                        [(ngModel)]="supplierListById.address1"
                                        class="form-control" type="text"
                                        readonly
                                        required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="address">Address 2</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1"
                                        placeholder="Enter Your Address"
                                        [(ngModel)]="supplierListById.address2"
                                        class="form-control" type="text"
                                        readonly
                                        required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="address">Address 3</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1"
                                        placeholder="Enter Your Address"
                                        [(ngModel)]="supplierListById.address3"
                                        class="form-control" type="text"
                                        readonly
                                        required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group" *ngIf="supplierListById.supplierAddress">
                                <label for="address">Address 4</label>
                                <div class="d-flex">
                                    <textarea name="" id="" cols="1" rows="1"
                                        placeholder="Enter Your Address"
                                        [(ngModel)]="supplierListById.supplierAddress"
                                        class="form-control" type="text"
                                        readonly
                                        required></textarea>
                                </div>
                            </div>

                            <!-- row-2 -->
                            <div class="col-lg-6 col-md-12 form-group" *ngIf="supplierListById.stateId > 0">
                                <label for="state">State</label>
                                <ng-container *ngFor="let value of stateDropdown">
                                    <ng-container *ngIf="value.id == supplierListById.stateId"><span
                                            class="form-control">{{value.value}}</span></ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group" *ngIf="supplierListById.otherState">
                                <label for="state">Other State</label>
                                <span class="form-control">{{supplierListById.otherState}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">Purpose</label>
                                <ng-container *ngFor="let value of
                                    purposeDropdown">
                                    <ng-container *ngIf="value.id ==
                                        supplierListById.purposeId"><span
                                            class="form-control">{{value.value}}</span></ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">Service Provided</label>
                                <span class="form-control">{{supplierListById.services ? supplierListById.services : 'NA'}}</span>
                            </div>

                            <!-- row-3 -->
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">In case of Intercompany correct Co. No.</label>
                                <span class="form-control">{{supplierListById.interCompany ? supplierListById.interCompany : '-' }}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="credit">Payment Terms</label>
                                <span class="form-control">{{supplierListById.payTermsId}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="tds-applicable">TDS Applicable</label>
                                <span class="form-control">{{supplierListById.tdsApplicable
                                    ? 'Yes' : 'No'}}</span>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12 form-group" *ngIf="supplierListById.documentUploadPath">
                                <span class="form-control">
                                    <a [href]="supplierListById.documentUploadPath"
                                        target="_blank" style="font-size:13px;
                                        font-weight: 600;
                                        color: var(--mainColor);"
                                        [download]="supplierListById.documentUploadPath">Organization Profile</a>
                                </span>
                            </div>

                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="email">Whether Domestic or Foreign <span class="star_class">*</span></label>
                                <span class="form-control">{{supplierListById.isDomestic
                                    ? 'Foreign' : 'Domestic'}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group"
                                *ngIf="supplierListById.isDomestic">
                                <label for="email">If Foreign give Currency
                                    <span class="star_class">*</span></label>
                                <span class="form-control">{{supplierListById.currency}}</span>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Tax Details')">
                            <!-- Tax Registration Details -->
                            <div class="section-heading">
                                <h6 class="m-0">Tax Registration Details</h6>
                            </div>

                            <!-- row-5 -->
                            <ng-container *ngIf="! supplierListById.isDomestic">
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="city">GSTIN <span
                                            class="star_class">*</span></label>
                                    <span class="form-control">{{supplierListById.gstinNo}}</span>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="city">GSTIN Date</label>
                                    <span class="form-control">{{supplierListById.gstinDate
                                        | date : 'MM/dd/YYYY'}}</span>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="city">PAN <span
                                            class="star_class">*</span></label>
                                    <span class="form-control">{{supplierListById.panNo}}</span>
                                </div>
                            </ng-container>

                            <!-- row-6 -->
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">TAN Number</label>
                                <span class="form-control">{{supplierListById.tanNo}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="email">MSME Applicable</label>
                                <span class="form-control">{{supplierListById.msmeApplicable
                                    ? 'Yes' : 'No'}}</span>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Contact Details')">
                            <!-- Vendor Contact Details -->

                            <div class="section-heading">
                                <h6 class="m-0">Supplier Contact Details</h6>
                            </div>

                            <!-- row-7 -->
                            <div class="col-lg-6 form-group">
                                <label for="contact">Contact Person</label>
                                <span class="form-control">{{supplierListById.contactPerson}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="tel">Contact Number</label>
                                <span class="form-control">{{supplierListById.mobileNo}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="fax">Alternate Mobile Number</label>
                                <span class="form-control">{{supplierListById.faxNo}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="email">Email Address</label>
                                <span class="form-control">{{supplierListById.emailAddress}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="email">Alternate Email Address</label>
                                <span class="form-control">{{supplierListById.alternateEmail}}</span>
                            </div>
                        </ng-container>


                        <ng-container
                            *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Bank Details')">
                            <!-- Vendor Bank Details -->
                            <div class="section-heading">
                                <h6 class="m-0">Bank Details</h6>
                            </div>
                            <!-- row-8-->
                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">Account Type</label>

                                <span class="form-control">{{supplierListById.accountType
                                    == 'C' ? 'Current Account' : 'Saving
                                    Account'}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="bankName">Bank Name</label>
                                <span class="form-control">{{supplierListById.bankName}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="account">Account No.</label>
                                <span class="form-control">{{supplierListById.accountNo}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">IFSC Code or Swift Code</label>
                                <span class="form-control">{{supplierListById.ifscCode}}</span>
                            </div>

                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">MICR No.</label>
                                <span class="form-control">{{supplierListById.micrNo}}</span>
                            </div>

                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">Bank Address</label>
                                <!-- <span class="form-control">{{supplierListById.bankAddress}}</span> -->
                                <textarea name="" id="" cols="30" readonly rows="10" class="form-control" [(ngModel)]="supplierListById.bankAddress"></textarea>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <span class="form-control" *ngIf="supplierListById.cancelledCheque">
                                    <a [href]="supplierListById.cancelledCheque"
                                        target="_blank" style="font-size:13px;
                                        font-weight: 600;
                                        color: var(--mainColor);"
                                        [download]="supplierListById.cancelledCheque">Cancelled
                                        Cheque</a>
                                </span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Authorized Signatory Details')">
                            <div class="section-heading"*ngIf="supplierListById.supplierDirectorsDetails?.length>
                                0" >
                                <h6 style="margin: 0;">Authorized Signatory
                                    Details</h6>
                            </div>

                            <!-- row-10 -->
                            <ng-container *ngFor="let director of
                                supplierListById.supplierDirectorsDetails; index
                                as idx; last as
                                last">
                                <div class="form-group col-lg-4 col-md-4"
                                    style="margin-top:
                                    0.3rem!important;">
                                    <label for="" style="margin-bottom:
                                        0.3rem!important;">
                                        Name<span class="star_class">*</span></label>
                                    <!-- <span class="form-control">{{director.directorName}}</span> -->
                                    <input type="text" disabled class="form-control" [(ngModel)]="director.directorName" name="" id="">
                                </div>
                                <div class=" form-group col-lg-4 col-md-4"
                                    style="margin-top: 0.3rem!important;">
                                    <label for="" style="margin-bottom:
                                        0.3rem!important;">
                                        Contact Number<span
                                            class="star_class">*</span></label>
                                    <span class="form-control">{{director.mobileNo}}</span>
                                </div>
                                <div class="form-group col-lg-4 col-md-4"
                                    style="margin-top:
                                    0.3rem!important;">
                                    <label for="" style="margin-bottom:
                                        0.3rem!important;">Email
                                        Address<span class="star_class">*</span></label>
                                    <span class="form-control">{{director.email}}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-6" style="border-left: 1px solid #dde;">
                    <h3 style="text-align: center;">New Details</h3>

                    <!-- row-1 -->
                    <div class="row">
                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Supplier Name')">
                            <div class="row" style="padding-right: 0!important;">
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="first-name">Supplier Name</label>
                                    <span class="form-control" [ngClass]="[supplierJsonData.supplierName != supplierListById.supplierName ? 'field-css' : '']">{{supplierJsonData.supplierName}}</span>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="address">Address 1</label>
                                    <div class="d-flex">
                                        <textarea name="" id="" cols="1" rows="1"
                                            placeholder="Enter Your Address"
                                            [(ngModel)]="supplierJsonData.address1"
                                            class="form-control" type="text"
                                            readonly
                                            required></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="address">Address 2</label>
                                    <div class="d-flex">
                                        <textarea name="" id="" cols="1" rows="1"
                                            placeholder="Enter Your Address"
                                            [(ngModel)]="supplierJsonData.address2"
                                            class="form-control" type="text"
                                            readonly
                                            required></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="address">Address 3</label>
                                    <div class="d-flex">
                                        <textarea name="" id="" cols="1" rows="1"
                                            placeholder="Enter Your Address"
                                            [(ngModel)]="supplierJsonData.address3"
                                            class="form-control" type="text"
                                            readonly
                                            required></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group"n *ngIf="supplierListById.supplierAddress">
                                    <label for="address">Address 4</label>
                                    <div class="d-flex">
                                        <textarea name="" id="" cols="1" rows="1"
                                            placeholder="Enter Your Address"
                                            [(ngModel)]="supplierJsonData.supplierAddress"
                                            class="form-control" type="text"
                                            readonly
                                            required></textarea>
                                    </div>
                                </div>

                            <!-- row-2 -->
                            <!-- {{supplierJsonData.stateId}}
                            <ng-container  *ngIf="supplierJsonData.stateId > 0">
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="state">State</label>
                                    <ng-container *ngFor="let value of stateDropdown">
                                        <ng-container *ngIf="value.id == supplierJsonData.stateId"><span
                                                class="form-control">{{value.value}}</span></ng-container>
                                    </ng-container>
                                </div>
                            </ng-container> -->
                            <div class="col-lg-6 col-md-12 form-group" *ngIf="supplierJsonData.otherState">
                                <label for="state">Other State</label>
                                <span class="form-control">{{supplierJsonData.otherState}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">Purpose</label>
                                <ng-container *ngFor="let value of purposeDropdown">
                                    <ng-container *ngIf="value.id == supplierJsonData.purposeId"><span class="form-control"  [ngClass]="[supplierJsonData.purposeId != supplierListById.purposeId ? 'field-css' : '']">{{value.value}}</span></ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">Service Provided</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.services != supplierListById.services ? 'field-css' : '']">{{supplierJsonData.services ? supplierJsonData.services : '-'}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">In case of Intercompany correct Co. No. ...</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.interCompany != supplierListById.interCompany ? 'field-css' : '']">{{supplierJsonData.interCompany ? supplierJsonData.interCompany : '-'}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="credit">Payment Terms</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.payTermsId != supplierListById.payTermsId ? 'field-css' : '']">{{supplierJsonData.payTermsId ? supplierJsonData.payTermsId : '-'}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group"> 
                                <label for="tds-applicable">TDS Applicable</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.tdsApplicable != supplierListById.tdsApplicable ? 'field-css' : '']">{{supplierJsonData.tdsApplicable ? 'Yes' : 'No'}}</span>
                            </div>

                            <ng-container  *ngIf="supplierListById.organizationDocument">
                                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <span class="form-control">
                                        <a [href]="getImageUrl(supplierListById.organizationDocument)"
                                            target="_blank" style="font-size:13px;
                                            font-weight: 600;
                                            color: var(--mainColor);"
                                            [download]="getImageUrl(supplierListById.organizationDocument)">Organization Profile</a>
                                    </span>
                                </div>
                            </ng-container>

                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="email">Whether Domestic or Foreign
                                    <span class="star_class">*</span></label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.isDomestic != supplierListById.isDomestic ? 'field-css' : '']">{{supplierJsonData.isDomestic
                                    ? 'Foreign' : 'Domestic'}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group"
                                *ngIf="supplierJsonData.isDomestic">
                                <label for="email">If Foreign give Currency
                                    <span class="star_class">*</span></label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.currency != supplierListById.currency ? 'field-css' : '']">{{supplierJsonData.currency}}</span>
                            </div>
                        </div>
                            <!-- row-3 -->
                        </ng-container>


                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Tax Details')">
                            <!-- Tax Registration Details -->
                            <div class="section-heading">
                                <h6 class="m-0">Tax Registration Details</h6>
                            </div>

                            <!-- row-5 -->
                            <ng-container *ngIf="! supplierJsonData.isDomestic">
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="city">GSTIN </label>
                                    <span class="form-control"  [ngClass]="[supplierJsonData.gstinNo != supplierListById.gstinNo ? 'field-css' : '']">{{supplierJsonData.gstinNo}}</span>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="city">GSTIN Date</label>
                                    <span class="form-control"  [ngClass]="[supplierJsonData.gstinDate != supplierListById.gstinDate ? 'field-css' : '']">{{supplierJsonData.gstinDate | date : 'MM/dd/YYYY'}}</span>
                                </div>
                                <div class="col-lg-6 col-md-12 form-group">
                                    <label for="city">PAN </label>
                                    <span class="form-control"  [ngClass]="[supplierJsonData.panNo != supplierListById.panNo ? 'field-css' : '']">{{supplierJsonData.panNo}}</span>
                                </div>
                            </ng-container>

                            <!-- row-6 -->
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="city">TAN Number</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.tanNo != supplierListById.tanNo ? 'field-css' : '']">{{supplierJsonData.tanNo}}</span>
                            </div>
                            <div class="col-lg-6 col-md-12 form-group">
                                <label for="email">MSME Applicable</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.msmeApplicable != supplierListById.msmeApplicable ? 'field-css' : '']">{{supplierJsonData.msmeApplicable ? 'Yes' : 'No'}}</span>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Contact Details')">
                            <!-- Vendor Contact Details -->

                            <div class="section-heading">
                                <h6 class="m-0">Supplier Contact Details</h6>
                            </div>

                            <!-- row-7 -->
                            <div class="col-lg-6 form-group">
                                <label for="contact" >Contact Person</label>
                                <span class="form-control" [ngClass]="[supplierJsonData.contactPerson != supplierListById.contactPerson ? 'field-css' : '']">{{supplierJsonData.contactPerson}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="tel">Contact Number</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.mobileNo != supplierListById.mobileNo ? 'field-css' : '']">{{supplierJsonData.mobileNo}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="fax">Alternate Mobile Number</label>
                                <span class="form-control" [ngClass]="[supplierJsonData.faxNo != supplierListById.faxNo ? 'field-css' : '']">{{supplierJsonData.faxNo}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="email">Email Address</label>
                                <span class="form-control">{{supplierJsonData.emailAddress}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="email">Alternate Email Address</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.alternateEmail != supplierListById.alternateEmail ? 'field-css' : '']">{{supplierJsonData.alternateEmail}}</span>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Bank Details')">
                            <!-- Vendor Bank Details -->
                            <div class="section-heading">
                                <h6 class="m-0">Bank Details</h6>
                            </div>
                            <!-- row-8-->
                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">Account Type</label>
                                <div>
                                    <span class="form-control"  [ngClass]="[supplierJsonData.accountType != supplierListById.accountType ? 'field-css' : '']">{{supplierJsonData.accountType
                                        == 'C' ? 'Current Account' : 'Saving
                                        Account'}}</span>
                                </div>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="bankName">Bank Name</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.bankName != supplierListById.bankName ? 'field-css' : '']">{{supplierJsonData.bankName}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="account">Account No.</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.accountNo != supplierListById.accountNo ? 'field-css' : '']">{{supplierJsonData.accountNo}}</span>
                            </div>
                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">IFSC Code or Swift Code</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.ifscCode != supplierListById.ifscCode ? 'field-css' : '']">{{supplierJsonData.ifscCode}}</span>
                            </div>

                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">MICR No.</label>
                                <span class="form-control"  [ngClass]="[supplierJsonData.micrNo != supplierListById.micrNo ? 'field-css' : '']">{{supplierJsonData.micrNo}}</span>
                            </div>

                            <div class="col-lg-6 form-group">
                                <label for="ifscCode">Bank Address</label>
                                <!-- <span class="form-control">{{supplierJsonData.bankAddress}}</span> -->
                                <textarea name="" [ngClass]="[supplierJsonData.bankAddress != supplierListById.bankAddress ? 'field-css' : '']" readonly id="" cols="30" rows="10" class="form-control" [(ngModel)]="supplierJsonData.bankAddress"></textarea>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <span class="form-control">
                                    <a *ngIf="supplierListById.newCancelledCheque"
                                        [href]="_utility.getImageUrl(supplierListById.newCancelledCheque)"
                                        target="_blank" style="font-size:13px;
                                        font-weight: 600;
                                        color: var(--mainColor);"
                                        [download]="_utility.getImageUrl(supplierListById.newCancelledCheque)">New Cancelled Cheque</a>

                                    <span *ngIf="!supplierListById.newCancelledCheque">No Cancelled Cheque</span>
                                </span>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="supplierListById.supplierRaiseRequestList1?.includes('Authorized Signatory Details')">
                            <div class="section-heading"*ngIf="supplierJsonData.supplierDirectorsDetails?.length>0">
                                <h6 style="margin: 0;">Authorized Signatory
                                    Details</h6>
                            </div>
                            <!-- row-10 -->
                            <ng-container *ngFor="let director of supplierJsonData.supplierDirectorsDetails; index as idx; last as last">
                                <div class="row">
                                    <div class="form-group col-lg-4 col-md-4"
                                        style="margin-top:
                                        0.3rem!important;">
                                        <label for="" style="margin-bottom:
                                            0.3rem!important;">Name<span
                                                class="star_class">*</span></label>
                                        <input class="form-control" type="text" [ngClass]="[director.directorName != supplierListById.supplierDirectorsDetails[idx].directorName ? 'field-css' : '']" [(ngModel)]="director.directorName" readonly>
                                        <!-- <span class="form-control">{{director.directorName}}</span> -->
                                    </div>
                                    <div class=" form-group col-lg-4 col-md-4"
                                        style="margin-top: 0.3rem!important;">
                                        <label for="" style="margin-bottom:
                                            0.3rem!important;">Contact Number<span
                                                class="star_class">*</span></label>
                                        <input class="form-control" type="text" name="" id="" [ngClass]="[director.mobileNo != supplierListById.supplierDirectorsDetails[idx].mobileNo ? 'field-css' : '']" readonly [(ngModel)]="director.mobileNo">
                                        <!-- <span class="form-control">{{director.mobileNo}}</span> -->
                                    </div>
                                    <div class="form-group col-lg-4 col-md-4"
                                        style="margin-top:
                                        0.3rem!important;">
                                        <label for="" style="margin-bottom:
                                            0.3rem!important;">Email Address<span
                                                class="star_class">*</span></label>
                                        <input class="form-control" type="text" [ngClass]="[director.email != supplierListById.supplierDirectorsDetails[idx].email ? 'field-css' : '']" name="" id="" readonly [(ngModel)]="director.email">
                                        <!-- <span class="form-control">{{director.email}}</span> -->
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>