<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column dashboard-wallet">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngIf="this.roleName == 'Supplier'">
        <h1>{{breadcrumb[0].title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{breadcrumb[0].subTitle}}</a></li>
            <li class="item">{{breadcrumb[0].title}}</li>
        </ol>
    </div>
    <div class="breadcrumb-area" *ngIf="this.roleName == 'User' || this.roleName
        == 'Admin'">
        <h1>{{breadcrumb[1].title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{breadcrumb[1].subTitle}}</a></li>
            <li class="item">{{breadcrumb[1].title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->


    <!-- Start -->
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3
            offset-sm-3">
            <div class="stats-card-box">
                <h3>{{supplierEnquiryData[0]?.totalEnquiry}}</h3>
                <span class="sub-title">Total Enquiry</span>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="stats-card-box">
                <h3>{{supplierEnquiryData[0]?.replyEnquiry}}</h3>
                <span class="sub-title">Answered Enquiry</span>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="stats-card-box">
                <h3>{{supplierEnquiryData[0]?.pendingEnquiry}}</h3>
                <span class="sub-title">Pending Enquiry</span>
            </div>
        </div>

    </div>
    <!-- End -->


    <span style="text-align: right; color: red; font-size: 14px;" *ngIf="disableRemark">Note: If in case Supplier want
        to change the entered price, they can raise request using hand Icon.</span>
    <span style="text-align: right; color: red; font-size: 14px;" *ngIf="!disableRemark">Note: If in case of changed
        price from the Suppler side, User can approve or reject the raise request by using the hand icon.</span>

    <!-- Start -->



    <p-table #dt2 [value]="supplierEnquiryData" dataKey="supplierId" [rows]="10" responsiveLayout="scroll"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true"
        currentPageReportTemplate="Showing {first} to
        {last} of {totalRecords} entries" [filterDelay]="0" [globalFilterFields]="['mobileNo', 'enquiryNo' , 'supplierName' , 'userName',
        'itemName']">
        <ng-template pTemplate="caption">
            <span class="p-input-icon-left d-flex align-items-center">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                    'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
                <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
            </span>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th>S.No.</th>
                <th>Action</th>
                <th *ngIf="userObject.checker1 || userObject.checker2">Download Excel</th>
                <th>Status</th>
                <th>Unlock Supplier</th>
                <th>Enquiry No.</th>
                <th>Enquiry Date</th>
                <th>Part Number</th>
                <th>User Name</th>
                <th>Supplier Name</th>
                <th>Mobile No</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer let-i="rowIndex">
            <tr class="p-selectable-row">
                <td>{{i+1}}</td>
                <td class="d-flex" style="align-items: center;">
                    <button pButton pRipple type="button" pTooltip="Add Cost Breakup" icon="bx bx-show"
                        (click)="openModel1(customer)" class="p-button-rounded p-button-primary p-button-text"></button>
                    <img style="width: 20px; height: fit-content; cursor: pointer;"
                        pTooltip="You can raise request through the hand icon"
                        *ngIf="(roleName == 'Supplier' && customer.isAddTotal) || (roleName != 'Supplier' && customer.replyFlag)"
                        (click)="ChangeScreen(customer)" src="assets/img/hand.png" alt="">
                </td>
                <td *ngIf="userObject.checker1 || userObject.checker2">
                    <button *ngIf="!customer.isAddTotal" pButton pRipple type="button" icon="pi pi-file-excel"
                        (click)="downloadExcel(customer)"
                        class="p-button-rounded p-button-success p-button-text"></button>

                    <span *ngIf="customer.isAddTotal" style="margin-left: 17px;">-</span>
                </td>
                <td class="submit__btn">
                    <span *ngIf="roleName != 'Supplier'"><p-button [label]="customer.isRead ? 'New' : 'Submitted'"
                            [styleClass]="customer.isRead ?
                            'p-button-warning' : 'p-button-success'"></p-button></span>
                    <span *ngIf="roleName == 'Supplier'"><p-button [severity]="danger" [label]="customer.isRead ?
                            'Submitted' : 'New'" [styleClass]="customer.isRead
                            ? 'p-button-success' : 'p-button-warning'"></p-button></span>
                </td>

                <td>
                    <span *ngIf="!customer.isLock || this.loginName != customer.loginName"><span
                            class="p-button-icon bx bxs-lock-open"
                            style="font-size: 20px; cursor: pointer;"></span></span>
                    <span class="p-button-icon bx bxs-lock" style="font-size: 20px; cursor: pointer;"
                        *ngIf="customer.isLock && this.loginName == customer.loginName"
                        (click)="confirm1(customer , 'Unlock')" aria-hidden="true"></span>
                </td>
                <td>{{customer.enquiryNo}}</td>
                <td>{{customer.enquiryDate | date}}</td>
                <td>{{customer.itemName}}</td>
                <td> <span>{{customer.userName }}</span></td>
                <td> <span>{{customer.supplierName }}</span></td>
                <td>
                    <span class="image-text">{{customer.mobileNo}}</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No customers found.</td>
            </tr>
        </ng-template>
    </p-table>

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->

<p-dialog header="Engineering Drawing" [(visible)]="displayDrawing" [modal]="true" [draggable]="false"
    [resizable]="false" [breakpoints]="{'960px': '75vw', '640px':'90vw' , '1440px' : '50vw'}">
    <ng-container *ngFor="let drawing of editUploadedDrawing; let index= index">
        <span style="font-size: 12px;" class="mx-2">{{index+1}}.</span><a style="font-size: 12px;"
            [href]="drawing.engineeringDrawing" [download]="drawing.engineeringDrawing"
            target="_blank">{{_utility.getDrawingName(drawing.engineeringDrawing)}}</a>
    </ng-container>
</p-dialog>


<div class="engg-drawings">
    <p-dialog header="Other Drawing" [breakpoints]="{'960px': '75vw', '640px':'90vw', '1440px' : '50vw'}"
        [draggable]="false" [modal]="true" [(visible)]="displayOtherDialog">
        <ng-container *ngFor="let drawing of otherDocumentList; let index= index">
            <span style="font-size: 12px;" class="mx-2">{{index+1}}.</span><a style="font-size: 12px;"
                [href]="drawing.value" [download]="drawing.value"
                target="_blank">{{_utility.getDrawingName(drawing.value)}}</a>
        </ng-container>
    </p-dialog>
</div>


<p-dialog [header]="this.header" [(visible)]="displayChangeScreen" [modal]="true" [style]="{width: '85vw'}"
    [draggable]="false" [resizable]="false">
    <div class="add-listings-box-breakup" style="align-items:
        center;">
        <p style="color: red; font-size: 14px;">Note: you can raise change request through this screen</p>
        <h6>Part Details</h6>
        <p-table class="mb-2" #dt4 [value]="itemDetails" dataKey="id" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>Part Number</th>
                    <th>Part Description</th>
                    <th>Quantity</th>
                    <th>Material of Construction</th>
                    <th>Material Remark</th>
                    <th>Raw Part Weight (Kg)</th>
                    <th>Finish Part Weight (Kg)</th>
                    <th>Engineering Drawings</th>
                    <th>Other Documents</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr>
                    <td>{{i+1}}</td>
                    <td><span>{{customer?.itemName}}</span></td>
                    <td><span>{{customer?.itemDesc}}</span></td>
                    <td>
                        <span class="image-text">{{extraInfo.quantity}}</span>
                    </td>
                    <td><span class="image-text">{{customer?.materialofconstructionList}}</span></td>
                    <td><span class="image-text">{{customer?.materialRemark ?
                            customer?.materialRemark : '-'}}</span></td>
                    <td>
                        <span class="image-text">{{customer?.rawPartWeight}}kg</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer?.finishPartWeight}}kg</span>
                    </td>
                    <td (click)="showImagePreview(customer?.itemId , 'engg')" class="text-center"
                        style="cursor: pointer;">
                        <span class="image-text"><i class="bx bx-image"></i></span>
                    </td>
                    <td (click)="showImagePreview(customer?.itemId , 'other')" class="text-center"
                        style="cursor: pointer;">
                        <span class="image-text"><i class="bx bx-image"></i></span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No item found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="rfqReply1">
            <h6 class="my-3">Update Cost Breakup Details</h6>
            <p-table #dt3 [value]="costBreakupData" dataKey="id" responsiveLayout="scroll" [rows]="10"
                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="false"
                currentPageReportTemplate="Showing
                {first} to {last} of {totalRecords} entries" [globalFilterFields]="['name','country.name','status']">
                <ng-template pTemplate="header">
                    <tr>
                        <th>S. No.</th>
                        <th>Cost Breakup Name</th>
                        <th>Old Amount</th>
                        <th>New Amount</th>
                        <th>Checker Remarks</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer let-i="rowIndex">
                    <tr>
                        <td>
                            <span class="mx-2" style="margin-top: 10px;"><input type="checkbox"
                                    [disabled]="roleName == 'User' || costDisabled || customer.isVerify == 'Pending'"
                                    [(ngModel)]="customer.costFlag"></span>
                            <span style="margin-top : 3px">{{i+1}}</span>
                        </td>
                        <td>
                            <span>{{customer.costBreakupName}}</span>
                        </td>
                        <td>
                            <div style="display: flex; ">
                                <input type="text" class="form-control" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;" [disabled]="true">
                                <input pKeyFilter="money" type="text" [disabled]="true" class="form-control" style="border-left: none!important;
                                    border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                    border-bottom-left-radius: 0" [(ngModel)]="customer.amount">
                            </div>
                        </td>
                        <td>
                            <div style="display: flex; ">
                                <input type="text" class="form-control" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;" [disabled]="true">
                                <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important;
                                    border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                    border-bottom-left-radius: 0" [(ngModel)]="customer.newAmount"
                                    [disabled]="roleName == 'User' || !customer.costFlag || customer.costRaiseRequest || customer.isVerify == 'Pending'">
                            </div>
                        </td>
                        <td>
                            <span class="form-group"><textarea [disabled]="disableRemark" type="text"
                                    placeholder="Enter Remark" class="form-control" [(ngModel)]="customer.raiseRemark"
                                    style="height:35px;"></textarea></span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <div class="row">
                <ng-container *ngIf="roleName != 'Supplier'">
                    <div class="add-listings-btn col-lg-2 col-md-6"
                        *ngIf="costBreakupData[0]?.raiseVerify == 'Verified' || costBreakupData[0]?.raiseVerify == 'Pending'">
                        <div class="form-group" class="approval-status">
                            <button pButton type="button" style="width: 100%;"
                                (click)="submitCostData('Verified')">Verify</button>
                        </div>
                    </div>
                    <div class="add-listings-btn col-lg-2 col-md-6"
                        *ngIf="costBreakupData[0]?.raiseVerify == 'Rejected' || costBreakupData[0]?.raiseVerify == 'Pending'">
                        <div class="form-group" class="reject-status">
                            <button pButton type="button" style="width: 100%;"
                                (click)="submitCostData('Rejected')">Reject</button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="roleName == 'Supplier' || !isClose">
                    <div class="add-listings-btn col-lg-2 col-md-6" *ngIf="!costDisabled && roleName != 'User'">
                        <div class="form-group">
                            <button typ e="submit" class="submit_btn" (click)="submitCostData('update')">Update</button>
                        </div>
                    </div>
                    <div class="add-listings-btn col-lg-2 col-md-6"
                        *ngIf="costBreakupData[0]?.raiseVerify && costBreakupData[0].costRaiseRequest">
                        <div class="form-group"
                            [ngClass]="{'pending-status': costBreakupData[0]?.raiseVerify == 'Pending', 'approval-status': costBreakupData[0]?.raiseVerify == 'Verified', 'reject-status': costBreakupData[0]?.raiseVerify == 'Rejected'}">
                            <button pButton type="button"
                                style="width: 100%;">{{costBreakupData[0]?.raiseVerify}}</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [header]="this.header" [(visible)]="display" [modal]="true" [style]="{width: '85vw'}" [draggable]="false"
    [resizable]="false">
    <div class="add-listings-box-breakup extra-wallet" style="align-items:
        center;">
        <h6 style="text-decoration: underline;">Part Details</h6>
        <p-table class="mb-2" #dt4 [value]="itemDetails" dataKey="id" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>Part Number</th>
                    <th>Part Description</th>
                    <th>Quantity</th>
                    <th>Material Type</th>
                    <th>MOQ</th>
                    <th>Material of Construction</th>
                    <th>Material Remark</th>
                    <th>Raw Part Weight (Kg)</th>
                    <th>Finish Part Weight (Kg)</th>
                    <th>Volumn</th>
                    <th>Additional Notes</th>
                    <th>Engineering Drawings</th>
                    <th>Other Documents</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr>
                    <td>{{i+1}}</td>
                    <td><span>{{customer?.itemName}}</span></td>
                    <td><span>{{customer?.itemDesc}}</span></td>
                    <td>
                        <span class="image-text">{{extraInfo.quantity}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.materialType ?? '-'}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.moq ?? '-'}}</span>
                    </td>
                    <td><span class="image-text">{{customer?.materialofconstructionList}}</span></td>
                    <td><span class="image-text">{{customer?.materialRemark ? customer?.materialRemark : '-'}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer?.rawPartWeight}}kg</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer?.finishPartWeight}}kg</span>
                    </td>
                    <td>
                        <span class="image-text">{{partDetails?.amount}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{partDetails?.value ? partDetails?.value : '-'}}</span>
                    </td>
                    <td (click)="showImagePreview(customer?.itemId , 'engg')" class="text-center"
                        style="cursor: pointer;">
                        <span class="image-text"><i class="bx bx-image"></i></span>
                    </td>
                    <td (click)="showImagePreview(customer?.itemId , 'other')" class="text-center"
                        style="cursor: pointer;">
                        <span class="image-text"><i class="bx bx-image"></i></span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No item found.</td>
                </tr>
            </ng-template>
        </p-table>

        <div class="material-data form-group row">
            <div class="col-lg-3 col-md-6 col-sm-12 pl-0">
                <label for="">GST<span class="star_class">*</span></label>
                <select class="form-control
                form-select" [disabled]="!disableRemark || (disableRemark && isLock)" [(ngModel)]="extraInfo.taxId"
                    id="status" required>
                    <option value="0">Select GST</option>
                    <ng-container *ngFor="let status of tax_dropdown">
                        <option [value]="status.id">{{status.value}}</option>
                    </ng-container>
                </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label for="">Material Grade <span class="star_class">*</span></label>
                <select class="form-control
                        form-select" [disabled]="!disableRemark || isLock" [(ngModel)]="extraInfo.materialGridId"
                    id="status" required>
                    <option value="0" disabled>Select Material Grade</option>
                    <ng-container *ngFor="let status of materialGradeDropdown">
                        <option [value]="status.materialGridId">{{status.materialGridName}}</option>
                    </ng-container>
                </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label for="">Raw Material Form <span class="star_class">*</span></label>
                <select class="form-control
                        form-select" [disabled]="!disableRemark || isLock" [(ngModel)]="extraInfo.materialFormId"
                    id="status1" required>
                    <option value="0" disabled>Select Material Form</option>
                    <ng-container *ngFor="let status of materialFormDropdown">
                        <option [value]="status.materialFormId">{{status.materialFormName}}</option>
                    </ng-container>
                </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label for="">Rate(per kg) <span class="star_class">*</span></label>
                <input class="form-control" type="text" [disabled]="!disableRemark || isLock"
                    [(ngModel)]="extraInfo.ratePerkg" name="" id="" placeholder="Rate">
            </div>
        </div>

        <ng-container *ngIf="machiningRequire">
            <div class="d-flex" style="align-items: baseline;">
                <h6 style="text-decoration: underline;">Machining Rate</h6><span style="margin-left: 10px;">(rate /
                    hr)</span>
            </div>
            <span style="text-align: right; color: red; font-size: 14px;">Note : Kindly enter the price of Machining
                Rate according to your Respective Currency</span>

            <div class="row material-data form-group mt-3">
                <div class="col-lg-3 col-md-6 col-sm-12 pl-0">
                    <label for="">VMC</label>
                    <div class="d-flex">
                        <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                        <input pKeyFilter="money" type="text" placeholder="VMC" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.vmc"
                            [disabled]="!disableRemark || (disableRemark && isLock)">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <label for="">CNC</label>
                    <div class="d-flex">
                        <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                        <input pKeyFilter="money" type="text" placeholder="CNC" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.cnc"
                            [disabled]="!disableRemark || (disableRemark && isLock)">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <label for="">HMC</label>
                    <div class="d-flex">
                        <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                        <input pKeyFilter="money" type="text" placeholder="HMC" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.hmc"
                            [disabled]="!disableRemark || (disableRemark && isLock)">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <label for="">Lathe</label>
                    <div class="d-flex">
                        <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                        <input pKeyFilter="money" type="text" placeholder="Lathe" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.lathe"
                            [disabled]="!disableRemark || (disableRemark && isLock)">
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="coatingRequire">
            <div class="add-listings-box-breakup extra-wallet" style="border-top: 2px solid #dedede;">
                <h6 class="mt-3" style="text-decoration: underline;">Coating/Plating/HT</h6>
                <p-table #dt3 [value]="coatingDetails" dataKey="id" responsiveLayout="scroll" [rows]="10"
                    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="false"
                    currentPageReportTemplate="Showing
                    {first} to {last} of {totalRecords} entries">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>S. No.</th>
                            <th>Coating / Plating / HT</th>
                            <th>Rate</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer let-i="rowIndex">
                        <tr class="earnings-box">
                            <td>{{i+1}}</td>
                            <td>
                                <span class="form-group"><input type="text" placeholder="Enter Coating"
                                        [disabled]="!disableRemark || (disableRemark && isLock)" class="form-control"
                                        style="height: 35px;" [(ngModel)]="customer.coating"></span>
                            </td>
                            <td>
                                <div style="display: flex;">
                                    <input type="text" class="form-control" disabled="true"
                                        [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                                    border-bottom-right-radius: 0;
                                    border-top-right-radius: 0;" [disabled]="true">
                                    <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                        border-bottom-left-radius: 0" [(ngModel)]="customer.rate"
                                        [disabled]="!disableRemark || (disableRemark && isLock)">
                                </div>
                            </td>
                            <td class="d-flex" *ngIf="(!isLock && roleName == 'Supplier')">
                                <button type="button" class="form-group" *ngIf="i ==
                                coatingDetails.length-1" (click)="addNewRow('add','coatingDetails')">+</button>
                                <button type="button" class="form-group mx-1"
                                    (click)="addNewRow('remove' , 'coatingDetails' , i)">-</button>
                            </td>
                        </tr>
                        <tr *ngIf="i == coatingDetails.length-1">
                            <td></td>
                            <td style="font-size: 15px;"><b>Total Coating Amount</b></td>
                            <td>
                                <div style="display: flex; ">
                                    <input type="text" class="form-control" [disabled]="true"
                                        [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                                    border-bottom-right-radius: 0;
                                    border-top-right-radius: 0;">
                                    <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                        border-bottom-left-radius: 0" [(ngModel)]="rfqCoatingTotal" [disabled]="true">
                                </div>
                            </td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </ng-container>

        <div class="rfqReply">
            <h6 style="text-decoration: underline;">Add Cost Breakup Details</h6>
            <div class="form-group d-flex" style="align-items: center">
                <ng-container *ngIf="disableRemark && !documentPath  && !isLock">
                    <div style="width: 100%;" class="mx-3">
                        <input #uploadSupplierDoc (change)="getMultipleImages($event)" class="form-control mx-2"
                            type="file">
                    </div>
                </ng-container>
                <a href="{{documentPath}}" *ngIf="documentPath" style="text-decoration: none; margin-right: 5px;"
                    download="{{documentPath}}" target="_blank"><button pButton pRipple type="button"
                        icon="bx bx-download" class="p-button-rounded
                        p-button-primary p-button-text"></button></a>
                <input type="text" class="form-control" [disabled]="!disableRemark || (disableRemark && isLock)"
                    class="form-control" [(ngModel)]="extraInfo.hsnName" placeholder="Enter HSN">
            </div>
        </div>
        <p-table #dt3 [value]="costBreakupData" dataKey="id" responsiveLayout="scroll" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="false"
            currentPageReportTemplate="Showing
            {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header">
                <tr>
                    <th>S. No.</th>
                    <th>Cost Breakup Name</th>
                    <th>Amount</th>
                    <th>Supplier Remarks</th>
                    <th>Checker Remarks</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex;">
                <tr>
                    <td>{{i+1}}</td>
                    <td>
                        <span>{{customer.costBreakupName}}</span>
                    </td>
                    <td>
                        <div style="display: flex; ">
                            <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2"
                                style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;">
                            <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="customer.amount"
                                [disabled]="!disableRemark || (disableRemark && isLock)">
                        </div>
                    </td>
                    <td>
                        <span class="form-group"><textarea [disabled]="(isLock || !disableRemark )" type="text"
                                placeholder="Enter Remark" class="form-control" [(ngModel)]="customer.checkerRemark"
                                style="height:35px;"></textarea></span>
                    </td>
                    <td>
                        <span class="form-group"><textarea [disabled]="disableRemark" type="text"
                                placeholder="Enter Remark" class="form-control" [(ngModel)]="customer.remark"
                                style="height:35px;"></textarea></span>
                    </td>
                </tr>
                <tr *ngIf="i == costBreakupData.length-1">
                    <td></td>
                    <td style="font-size: 15px;"><b>Total Cost Breakup Amount</b></td>
                    <td>
                        <div style="display: flex; ">
                            <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2"
                                style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;">
                            <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="rfqTotal" [disabled]="true">
                        </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <ng-container *ngFor="let item of itemDetails">
        <div *ngIf="item.materialType != 'Bought out'" class="add-listings-box-breakup extra-wallet"
            style="border-top: 2px solid #dedede;">
            <h6 class="mt-3" style="text-decoration: underline;">Add Tools</h6>
            <p-table #dt3 [value]="toolData" dataKey="id" responsiveLayout="scroll" [rows]="10"
                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="false"
                currentPageReportTemplate="Showing
            {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header">
                    <tr>
                        <th>S. No.</th>
                        <th>Tool Name</th>
                        <th>Amount</th>
                        <th>Supplier Remarks</th>
                        <th>Checker Remarks</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer let-i="rowIndex">
                    <tr class="earnings-box">
                        <td>{{i+1}}</td>
                        <td>
                            <span class="form-group"><input type="text" placeholder="Enter Tool Name"
                                    [disabled]="!disableRemark || (disableRemark && isLock)" class="form-control"
                                    style="height: 35px;" [(ngModel)]="customer.toolLoanAgreementName"></span>
                        </td>
                        <td>
                            <div style="display: flex;">
                                <input type="text" class="form-control" disabled="true" [(ngModel)]="partDetails.cond2"
                                    style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;" [disabled]="true">
                                <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="customer.toolLoanAgreementAmount"
                                    [disabled]="!disableRemark || (disableRemark && isLock)">
                            </div>
                        </td>
                        <td>
                            <span class="form-group"><textarea type="text" placeholder="Enter Remark"
                                    class="form-control" style="height: 35px;"
                                    [disabled]="(isLock && !disableRemark ) || !disableRemark"
                                    [(ngModel)]="customer.toolLoanAgreementRemark"></textarea></span>
                        </td>
                        <td>
                            <span class="form-group"><textarea type="text" placeholder="Enter Remark"
                                    class="form-control" style="height: 35px;" [disabled]="disableRemark"
                                    [(ngModel)]="customer.toolLoanAgreementCheckerRemark"></textarea></span>
                        </td>
                        <td class="d-flex" *ngIf="(!isLock && roleName == 'Supplier')">
                            <button type="button" class="form-group" *ngIf="i ==
                            toolData.length-1" (click)="addNewRow('add','ToolData')">+</button>
                            <button type="button" class="form-group mx-1"
                                (click)="addNewRow('remove' , 'ToolData' , i)">-</button>
                        </td>
                    </tr>
                    <tr *ngIf="i == toolData.length-1">
                        <td></td>
                        <td style="font-size: 15px;"><b>Total Tools Amount</b></td>
                        <td>
                            <div style="display: flex; ">
                                <input type="text" class="form-control" [disabled]="true"
                                    [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;">
                                <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="rfqToolTotal" [disabled]="true">
                            </div>
                        </td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </ng-container>

    <div class="extra-wallet" style="border-top: 2px solid #dedede;">
        <h6 style="text-decoration: underline;" class="mt-4">Additional Details</h6>
        <div class="row form-group">
            <div class="col-lg-4 col-md-12" style="height: 100%;">
                <label>Payment Terms <span class="star_class">*</span></label>
                <textarea name="" id="" [disabled]="!disableRemark || (disableRemark && isLock)" cols="100" rows="3"
                    [(ngModel)]="extraInfo.paymentTerms" class="form-control"
                    placeholder="Enter Payment Terms"></textarea>
            </div>
            <div class="col-lg-4 col-md-12" style="height: 100%;">
                <label>Lead Time (In Weeks)<span class="star_class">*</span></label>
                <textarea name="" id="" [disabled]="!disableRemark || (disableRemark && isLock)" cols="100" rows="3"
                    [(ngModel)]="extraInfo.deliveryNotes" class="form-control" placeholder="Enter Lead Time"></textarea>
            </div>
            <div class="col-lg-4 col-md-12" style="height: 100%;">
                <label>Other Remarks</label>
                <textarea name="" id="" [disabled]="!disableRemark || (disableRemark && isLock)" cols="100" rows="3"
                    class="form-control" [(ngModel)]="extraInfo.otherRemark"
                    placeholder="Enter Other Remarks"></textarea>
            </div>
        </div>
    </div>

    <div class="add-listings-btn col-lg-4 col-md-6"
        *ngIf="!isClose && ((!isLock && roleName == 'Supplier') || (roleName != 'Supplier'))">
        <div class="form-group">
            <button type="submit" class="submit_btn" (click)="submitCostData('submit')">Submit</button>
        </div>
    </div>
</p-dialog>