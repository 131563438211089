<!-- verification form  -->
<app-navbar-style-one></app-navbar-style-one>
<div class="login-page">
    <div class="overlay"></div>
    <div style="min-height: 100vh; overflow-y: hidden; display: flex; align-items: center; justify-content: center;">
        <div class="vesax-forgot">
            <div>
                <div class="login-logo">
                    <img src="../../../../../assets/img/Idex_Logo-removebg-preview.png" style="width: 50%;" />
                </div>
                <form [formGroup]="verificationFormControl"
                (ngSubmit)="verifyPhoneEmail(signin)" #signin="ngForm">
                <div class="signin-head">
                    <span id="auth-head">
                        <h4>{{this.verificationUser}} Verification</h4>
                    </span>
                </div>
    
                <div class="form-group">
                    <label for="emailAddress">Email Address <span
                            class="star_class">*</span></label>
                    <input class="form-control" type="text"
                        formControlName="emailAddress"
                        [class.is-invalid]="signin.submitted &&
                        verificationFormControl.get('emailAddress')?.invalid || verificationFormControl.get('emailAddress')?.invalid && verificationFormControl.get('emailAddress')?.touched"
                        autocomplete="off" placeholder="Enter Your Email Address"
                        required/>
                    <div class="invalid-feedback" *ngIf="verificationFormControl.get('emailAddress').errors?.required">
                        Email Address is Required
                    </div>
                    <div class="invalid-feedback" *ngIf="verificationFormControl.get('emailAddress').errors?.pattern">
                        Enter Valid Email Address
                    </div>
                </div>
                <div class="form-group">
                    <label for="emailAddress">Username<span
                            class="star_class">*</span></label>
                    <input class="form-control" type="text"
                        formControlName="userName"
                        [class.is-invalid]="signin.submitted &&
                        verificationFormControl.get('userName')?.invalid || verificationFormControl.get('userName')?.invalid && verificationFormControl.get('userName')?.touched"
                        autocomplete="off" placeholder="Enter Your Username"
                        required/>
                    <div class="invalid-feedback" *ngIf="verificationFormControl.get('userName').errors?.required">
                        Username is Required
                    </div>
                </div>
                <div class="form-group" *ngIf="submitButton == 'Submit'">
                    <label for="otp">OTP<span
                            class="star_class">*</span></label>
                    <input class="form-control" type="text"
                        formControlName="otp" 
                        [class.is-invalid]="signin.submitted &&
                        verificationFormControl.get('otp')?.invalid || verificationFormControl.get('otp')?.invalid && verificationFormControl.get('otp')?.touched"
                        autocomplete="off" placeholder="Enter OTP"/>
                    <div class="invalid-feedback">
                        OTP is Required
                    </div>
                </div>
    
    
                <!-- <div class="form-group">
                    <label for="phone">Mobile Number</label>
                    <input class="form-control" type="text" formControlName="phone" (keydown)="_utility.inputMobile($event)" [class.is-invalid]="signin.submitted && verificationFormControl.get('phone')?.invalid" placeholder="Enter Your Mobile Number">
                    <div class="invalid-feedback">
                        Phone Number is Required
                    </div>  
                    <h6 class="text-danger" *ngIf="invalidMobile">
                        {{ invalidMobile ? "Invalid Mobile" : "" }}
                    </h6>
                </div> -->
                <button type="submit">{{submitButton}}</button>
            </form>
            </div>
        </div>
    </div>
<!-- <app-footer-style-one></app-footer-style-one> -->
