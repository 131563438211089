import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-common-dashboard-popup',
  templateUrl: './common-dashboard-popup.component.html',
  styleUrls: ['./common-dashboard-popup.component.scss']
})
export class CommonDashboardPopupComponent implements OnInit , OnChanges {
  allData: any = [];
  extraInfo: any = {};
  costBreakupData: any;
  display : boolean = true;
  disableRemark : boolean = true;
  @Input() data : any = '';
  tax_dropdown: any = [];
  materialFormDropdown: any;
  materialGradeDropdown: any;
  machiningRequire: boolean;
  coatingRequire: boolean;
  ngOnInit(): void {
    console.log(this.data);
    this.roleName = this._utility.getLocalStorageDetails()?.roleName;
    // this.openModel1();
  }

  ngOnChanges(){
    this.tax();
    this.openModel1();
  }

  constructor(private _apiService : ApiServiceService , private _utility : AppUtility){}
  itemId: string = ''
  header: string = ''
  supplierId: string = ''
  supplierItemId: any;
  documentPath: any;
  isLock : boolean = false;
  roleName : string = '';
  rfqTotal : any = 0;
  rfqToolTotal : any = 0;
  coatingDetails : any = [];
  async openModel1() {
    this._utility.loader(true);
    this.itemId = this.data.itemId;
    this.rfqTotal = 0;
    this.rfqToolTotal = 0;
    this.rfqCoatingTotal = 0;
    this.supplierId = this.data.supplierId;
    this.getItemDetails();
    let object = {
      itemId: this.data.itemId,
      supplierId: this.data.supplierId,
      enquiryNo: this.data.enquiryNo,
      roleName: this.roleName
    }

    this.getCurrency(this.data);
    await this._apiService.getAssignedSupplierQuotationCostBreakup(object).then((res: any) => {
      if(res.returnValue?.assignToolLoanAgreementDetails.length > 0){
        this.toolData = res.returnValue?.assignToolLoanAgreementDetails;
      }

      else{
        this.toolData = [{toolLoanAgreementName : '' , toolLoanAgreementAmount : 0 , toolLoanAgreementId : 0 , toolLoanAgreementRemark : '' , toolLoanAgreementCheckerRemark : ''}]
      }

      // ;
      this.supplierItemId = res.returnValue?.supplierItemId;
      this.isLock = res.returnValue?.isLock;
      this.documentPath = res.returnValue?.documentUpload;
      this.allData = res.returnValue;
      this.extraInfo['paymentTerms'] = res.returnValue?.paymentTerms;
      this.extraInfo['deliveryNotes'] = res.returnValue?.deliveryNotes;
      this.extraInfo['otherRemark'] = res.returnValue?.otherRemark;
      this.extraInfo['taxId'] = res.returnValue?.emailAddresses;
      this.extraInfo['hsnName'] = res.returnValue?.hsnName;
      this.extraInfo['quantity'] = res.returnValue?.quantity;
      this.extraInfo['materialGridId'] = res.returnValue.materialGridId;
      this.extraInfo['materialFormId'] = res.returnValue.materialFormId;
      this.extraInfo['vmc'] = res.returnValue.vmc;
      this.extraInfo['cnc'] = res.returnValue.cnc;
      this.extraInfo['hmc'] = res.returnValue.hmc;
      this.extraInfo['lathe'] = res.returnValue.lathe;
      this.extraInfo['ratePerkg'] = res.returnValue.ratePerkg;
      this.coatingDetails = res.returnValue.coatingDetails
      this.costBreakupData = res.returnValue?.assignCostBreakupDetails;
    })

    this.allData?.assignCostBreakupDetails.forEach((response:any)=>{
      if(response.isActive){
        this.rfqTotal = response.amount + this.rfqTotal;
      }
    })

    this.allData?.assignToolLoanAgreementDetails.forEach((response:any)=>{
      this.rfqToolTotal = response.toolLoanAgreementAmount + this.rfqToolTotal;
    })
    
    this.allData?.coatingDetails.forEach((response:any)=>{
      this.rfqCoatingTotal = response.rate + this.rfqCoatingTotal;
    })

    // console.log(this.rfqTotal , this.allData,this.rfqToolTotal);
    setTimeout(() => {
      this.display = true;
      this._utility.loader(false);
    }, 2000);
  }

  rfqCoatingTotal : number = 0;
  toolData: any = [{toolLoanAgreementName : '' , toolLoanAgreementAmount : 0 , toolLoanAgreementId : 0 , toolLoanAgreementRemark : '' , toolLoanAgreementCheckerRemark : ''}];
  itemDetails: any = [];
  async getItemDetails() {
    this.itemDetails = [];
    await this._apiService.getItemMasterById(this.itemId).then((res: any) => {
      if (res.success) {
        this.itemDetails.push(res.returnValue);
      }
    })

    this.itemDetails.forEach((res:any)=>{
      if(res.machining){
        this.machiningRequire = true;
      }

      if(res.coating){
        this.coatingRequire = true;
      }
  })
  
    this.header = 'Part No. ' + this.itemDetails[0].itemName;
  }


  partDetails : any = {};
  async getCurrency(itemDetail){
    console.log(itemDetail);
    let data = {
      Mode : 'EnquiryItemDetail',
      supplierId : itemDetail.supplierId,
      itemId : itemDetail.itemId,
      cond3 : itemDetail.enquiryId
    }
    await this._apiService.dropdowndata('' , data).then((res:any)=>{
      if(res.success){
        this.partDetails = res.returnValue[0];
      }
      else{
        this.partDetails = {};
      }
    })
  }

  async tax() {
    await this._apiService.dropdowndata('tax').then((res: any) => {
      if(res.success){
        this.tax_dropdown = res.returnValue;
      }
    });
    await this._apiService.getMaterialForm().then((res: any) => {
      if(res.success){
        this.materialFormDropdown = res.returnValue;
      }
    });
    await this._apiService.getMaterialGride().then((res: any) => {
      if(res.success){
        this.materialGradeDropdown = res.returnValue;
      }
    });
  }

  displayOtherDialog : boolean = false;
  displayDrawing : boolean = false;
  otherDocumentList : any = [];
  editUploadedDrawing : any = [];

  async showImagePreview(itemId: any , string:any) {
    console.log(itemId);
    this._utility.loader(true);
    if(string == 'engg'){
      await this._apiService.getEnggDrawing(itemId).then((res: any) => {
        this._utility.loader(false);
        if (res.success) {
          this.editUploadedDrawing = res.returnValue;
          this.displayDrawing = true;
        }
  
        else {
          this.editUploadedDrawing = [];
          this._apiService.showMessage('Engineering Drawing not Found' , 'error');
        }
      })
    }

    else{
      // let enquiryId = this.requestForQuotationForm.get('enquiryId')?.value;
      let object = {
        Mode : 'DrawingDetails',
        Cond3 :  this.costBreakupData[0]?.enquiryId,
        itemId : itemId
      }
      this._apiService.dropdowndata('' , object).then((res:any)=>{
        console.log(res);
        this._utility.loader(false);
        if(res.success){
          this.displayOtherDialog = true;
          this.otherDocumentList = res.returnValue;
        }
  
        else{
          this.otherDocumentList = [];
          this._apiService.showMessage('Other Document not Found' , 'error');
        }
      })
    }
    

    console.log(this.editUploadedDrawing);
  }

}
