import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message, PrimeNGConfig } from 'primeng/api';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-assessment-doc',
  templateUrl: './assessment-doc.component.html',
  styleUrls: ['./assessment-doc.component.scss']
})
export class AssessmentDocComponent implements OnInit{
  supplierList : any = [];
  msgs: Message[] = [];
  filterval: string;
  dateFilterVal: string;
  ngOnInit(): void {
    this.getAllSupplierDetails();
  }
  constructor(private _apiService: ApiServiceService, public _utility: AppUtility, private primengConfig: PrimeNGConfig, private confirmationService: ConfirmationService) { }

  breadcrumb = [
    {
      title: 'Assessment Document',
      subTitle: 'Dashboard'
    }
  ]

  getAllSupplierDetails(){
    this._utility.loader(true);
    this.supplierList = [];
    this._apiService.supplierAssessment().then((res:any)=>{
      console.log(res);
      this._utility.loader(false);
      if(res.success){
        res.returnValue.forEach((resp:any)=>{
          if(resp.assessmentUpload){
            this.supplierList.push(resp);
          }
        })
      }
    })
  }

  Confirm(customer: any , status:any) {
    let message : string  = '';
    if(customer.assessmentStatus != 'Approved' && customer.assessmentStatus != 'Rejected'){
      if(status == 'Approved'){
        message = 'You want to Approve Assessment Document'
      }
      
      else if(status == 'Rejected'){
        message = 'You want to Reject Assessment Document'
      }
      
      else if(status == 'Conditional Approved'){
        message = 'You want to Conditional Approve Assessment Document'
      }
      
      this.confirmationService.confirm({
        message: message, 
        header: 'Assessment Document',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.updateAssessmentDocument(customer , status);
          this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        },
        reject: () => {
          this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
        }
      });
    }
  }

  updateAssessmentDocument(customer , status){
    this._utility.loader(true);
    let object = {
      supplierId : customer.supplierId , 
      supplierName : customer.supplierName ,
      userName : customer.userName,
      assessmentStatus : status
    }
    this._apiService.assessmentStatus(object).then((res:any)=>{
      if(res.success){
        this._apiService.showMessage(res.message , 'success');
        this.getAllSupplierDetails();
      }
      else{
        this._apiService.showMessage(res.message , 'error');

      }
    })
  }

  reset(dt2) {
    dt2.reset();
    this.filterval = '';
    this.dateFilterVal = ''
  }

}
