<p-dialog [header]="this.header" [(visible)]="display" [modal]="true"
    [style]="{width: '85vw'}" [draggable]="false" [resizable]="false">
    <div class="add-listings-box-breakup extra-wallet my-2" style="align-items:
        center;">
        <h6  style="text-decoration: underline;">Part Details</h6>
        <p-table class="mb-2" #dt4 [value]="itemDetails"    
            dataKey="id" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>Part Number</th>
                    <th>Part Description</th>
                    <th>Quantity</th>
                    <th>Material of Construction</th>
                    <th>Material Remark</th>
                    <th>Raw Part Weight (Kg)</th>
                    <th>Finish Part Weight (Kg)</th>
                    <th>Volumn</th>
                    <th>Additional Notes</th>
                    <th>Engineering Drawings</th>
                    <th>Other Documents</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr>
                    <td>{{i+1}}</td>
                    <td><span>{{customer?.itemName}}</span></td>
                    <td><span>{{customer?.itemDesc}}</span></td>
                    <td>
                        <span class="image-text">{{extraInfo.quantity}}</span>
                    </td>
                    <td><span class="image-text">{{customer?.materialofconstructionList}}</span></td>
                    <td><span class="image-text">{{customer?.materialRemark ? customer?.materialRemark : '-'}}</span></td>
                    <td>
                        <span class="image-text">{{customer?.rawPartWeight}}kg</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer?.finishPartWeight}}kg</span>
                    </td>
                    <td>
                        <span class="image-text">{{partDetails?.amount}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{partDetails?.value}}</span>
                    </td>
                    <td (click)="showImagePreview(customer?.itemId , 'engg')"
                        class="text-center" style="cursor: pointer;">
                        <span class="image-text"><i class="bx bx-image"></i></span>
                    </td>
                    <td (click)="showImagePreview(customer?.itemId , 'other')"
                        class="text-center" style="cursor: pointer;">
                        <span class="image-text"><i class="bx bx-image"></i></span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No item found.</td>
                </tr>
            </ng-template>
        </p-table>

        <div class="material-data form-group row">
            <div class="col-lg-3 col-md-6 col-sm-12 pl-0">
                <label for="">GST<span class="star_class">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="extraInfo.taxId">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label for="">Material Grade <span class="star_class">*</span></label>
                <select class="form-control
                        form-select" [disabled]="true" [(ngModel)]="extraInfo.materialGridId" id="status"
                        required>
                        <option value="0" disabled>Select Material Grade</option>
                        <ng-container *ngFor="let status of materialGradeDropdown">
                            <option [value]="status.materialGridId">{{status.materialGridName}}</option>
                        </ng-container>
                </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label for="">Material Form <span class="star_class">*</span></label>
                <select class="form-control
                        form-select" [disabled]="true" [(ngModel)]="extraInfo.materialFormId" id="status1"
                        required>
                        <option value="0" disabled>Select Material Form</option>
                        <ng-container *ngFor="let status of materialFormDropdown">
                            <option [value]="status.materialFormId">{{status.materialFormName}}</option>
                        </ng-container>
                </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label for="">Rate(per kg) <span class="star_class">*</span></label>
                <input class="form-control" type="text" [disabled]="true" [(ngModel)]="extraInfo.ratePerkg" name="" id="" placeholder="Rate">
            </div>
        </div>

        <ng-container *ngIf="this.machiningRequire">
            <div class="d-flex" style="align-items: baseline;">
                <h6 style="text-decoration: underline;">Machining Rate</h6><span style="margin-left: 10px;">(rate / hr)</span>
            </div>
            <span style="text-align: right; color: red; font-size: 14px;">Note : Kindly enter the price of Machining Rate according to your Respective Currency</span>
            <div class="row material-data form-group mt-3">
                <div class="col-lg-3 col-md-6 col-sm-12 pl-0">
                    <label for="">VMC</label>
                    <div class="d-flex">
                    <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                    <input pKeyFilter="money" type="text" placeholder="VMC" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.vmc">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <label for="">CNC</label>
                    <div class="d-flex">
                    <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                    <input pKeyFilter="money" type="text" placeholder="CNC" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.cnc">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <label for="">HMC</label>
                    <div class="d-flex">
                    <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                    <input pKeyFilter="money" type="text" placeholder="HMC" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.hmc">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <label for="">Lathe</label>
                    <div class="d-flex">
                    <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;">
                    <input pKeyFilter="money" type="text" placeholder="Lathe" class="form-control" style="border-left: none!important; width: 210px;
                        border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 0" [(ngModel)]="extraInfo.lathe">
                    </div>
                </div>
            </div>
        </ng-container>

        
    <ng-container *ngIf="coatingRequire">
        <div class="add-listings-box-breakup extra-wallet" style="border-top: 2px solid #dedede;">
            <h6 class="mt-3" style="text-decoration: underline;">Coating/Plating/HT</h6>
            <p-table #dt3 [value]="coatingDetails"
                dataKey="id" responsiveLayout="scroll" [rows]="10"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,20,30,50]"
                [paginator]="false" currentPageReportTemplate="Showing
                {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header">
                    <tr>
                        <th>S. No.</th>
                        <th>Coating / Plating / HT</th>
                        <th>Rate</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer let-i="rowIndex">
                    <tr class="earnings-box">
                        <td>{{i+1}}</td>
                        <td>
                            <span class="form-group"><input type="text"
                                    placeholder="Enter Coating" [disabled]="true"
                                    class="form-control" style="height: 35px;"
                                    [(ngModel)]="customer.coating"></span>
                        </td>
                        <td>
                            <div style="display: flex;">
                                <input type="text" class="form-control" disabled="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;" [disabled]="true">
                                <input pKeyFilter="money" type="text" class="form-control"
                                        style="border-left: none!important; width: 150px;
                                    border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                    border-bottom-left-radius: 0" [(ngModel)]="customer.rate" [disabled]="!disableRemark || (disableRemark && isLock)">
                            </div>
                        </td>
                        <td class="d-flex" *ngIf = "(!isLock && roleName == 'Supplier')">
                            <button type="button" class="form-group" *ngIf="i ==
                            coatingDetails.length-1" (click)="addNewRow('add','coatingDetails')">+</button>
                            <button type="button" class="form-group mx-1"
                                (click)="addNewRow('remove' , 'coatingDetails' , i)">-</button>
                        </td>
                    </tr>
                    <tr *ngIf="i == coatingDetails.length-1">
                        <td></td>
                        <td style="font-size: 15px;"><b>Total Coating Amount</b></td>
                        <td>
                            <div style="display: flex; ">
                                <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;">
                                <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                    border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                    border-bottom-left-radius: 0" [(ngModel)]="rfqCoatingTotal" [disabled]="true">
                            </div>
                        </td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </ng-container>

       

        <div class="rfqReply">
            <h6 style="text-decoration: underline;">Add Cost Breakup Details</h6>
            <div class="form-group d-flex" style="align-items: center">
                <ng-container *ngIf="disableRemark && !documentPath  && !isLock">
                    <div style="width: 100%;" class="mx-3">
                        <input #uploadSupplierDoc
                            (change)="getMultipleImages($event)"
                            class="form-control mx-2" type="file">
                    </div>
                </ng-container>
                <a href="{{documentPath}}" *ngIf="documentPath"
                    style="text-decoration: none; margin-right: 5px;" download="{{documentPath}}"
                    target="_blank"><button pButton pRipple type="button"
                        icon="bx bx-download" class="p-button-rounded
                        p-button-primary p-button-text"></button></a>
                <input type="text" class="form-control"
                        [disabled]="!disableRemark || (disableRemark && isLock)" class="form-control"
                    [(ngModel)]="extraInfo.hsnName" placeholder="Enter HSN">
            </div>
        </div>

        <p-table #dt3 [value]="costBreakupData"
            dataKey="id" responsiveLayout="scroll" [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,20,30,50]"
            [paginator]="false" currentPageReportTemplate="Showing
            {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header">
                <tr>
                    <th>S. No.</th>
                    <th>Cost Breakup Name</th>
                    <th>Amount</th>
                    <th>Supplier Remarks</th>
                    <th>Checker Remarks</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex;">
                <tr>
                    <td>{{i+1}}</td>
                    <td>
                        <span>{{customer.costBreakupName}}</span>
                    </td>
                    <td>
                        <div style="display: flex; ">
                            <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;">
                            <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="customer.amount" [disabled]="isLock || roleName == 'User'">
                        </div>
                    </td>
                    <td>
                        <span class="form-group"><textarea
                                [disabled]="isLock || roleName == 'User'" type="text"
                                placeholder="Enter Remark" class="form-control"
                                [(ngModel)]="customer.checkerRemark"
                                style="height:35px;"></textarea></span>
                    </td>
                    <td>
                        <span class="form-group"><textarea
                                [disabled]="disableRemark" type="text"
                                placeholder="Enter Remark" class="form-control"
                                [(ngModel)]="customer.remark"
                                style="height:35px;"></textarea></span>
                    </td>
                </tr>
                <tr *ngIf="i == costBreakupData.length-1">
                    <td>{{costBreakupData.length + 1}}</td>
                    <td><b>Total Cost Breakup Amount</b></td>
                    <td>
                        <div style="display: flex; ">
                            <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;">
                            <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="rfqTotal" [disabled]="true">
                        </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="add-listings-box-breakup extra-wallet" style="border-top: 2px solid #dedede;">
        <h6 class="mt-3" style="text-decoration: underline;">Tool List</h6>
        <p-table #dt3 [value]="toolData"
            dataKey="id" responsiveLayout="scroll" [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,20,30,50]"
            [paginator]="false" currentPageReportTemplate="Showing
            {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header">
                <tr>
                    <th>S. No.</th>
                    <th>Tool Name</th>
                    <th>Amount</th>
                    <th>Supplier Remarks</th>
                    <th>Checker Remarks</th>
                    <!-- <th></th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr class="earnings-box">
                    <td>{{i+1}}</td>
                    <td>
                        <span class="form-group"><input type="text"
                                placeholder="Enter Tool Name" [disabled]="isLock || roleName == 'User'"
                                class="form-control" style="height: 35px;"
                                [(ngModel)]="customer.toolLoanAgreementName"></span>
                    </td>
                    <td>
                        <div style="display: flex;">
                            <input type="text" class="form-control" disabled="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;" [disabled]="true">
                            <input pKeyFilter="money" type="text" class="form-control"
                                    style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="customer.toolLoanAgreementAmount" [disabled]="isLock || roleName == 'User'">
                        </div>
                    </td>
                    <td>
                        <span class="form-group"><textarea type="text"
                                placeholder="Enter Remark" class="form-control"
                                style="height: 35px;" [disabled]="true"
                                [(ngModel)]="customer.toolLoanAgreementRemark"></textarea></span>
                    </td>
                    <td>
                        <span class="form-group"><textarea type="text"
                                placeholder="Enter Remark" class="form-control"
                                style="height: 35px;" [disabled]="true"
                                [(ngModel)]="customer.toolLoanAgreementCheckerRemark"></textarea></span>
                    </td>
                </tr>
                <tr *ngIf="i == toolData.length-1">
                    <td>{{toolData.length + 1}}</td>
                    <td><b>Total Tools Amount</b></td>
                    <td>
                        <div style="display: flex; ">
                            <input type="text" class="form-control" [disabled]="true" [(ngModel)]="partDetails.cond2" style="width: 80px; border-radius: 5px; line-height :  22px; display: flex; align-items: center; border-right: none!important;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;">
                            <input pKeyFilter="money" type="text" class="form-control" style="border-left: none!important; width: 150px;
                                border-top-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px;
                                border-bottom-left-radius: 0" [(ngModel)]="rfqToolTotal" [disabled]="true">
                        </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="extra-wallet" style="border-top: 2px solid #dedede;">
        <h6  style="text-decoration: underline;" class="mt-4">Additional Details</h6>
        <div class="row form-group">
            <div class="col-lg-4 col-md-12" style="height: 100%;">
                <label>Payment Terms <span class="star_class">*</span></label>
                <textarea name="" id="" [disabled]="isLock || roleName == 'User'"
                    cols="100" rows="3" [(ngModel)]="extraInfo.paymentTerms"
                    class="form-control" placeholder="Enter Payment Terms"></textarea>
            </div>
            <div class="col-lg-4 col-md-12" style="height: 100%;">
                <label>Lead Time (In Weeks)<span class="star_class">*</span></label>
                <textarea name="" id="" [disabled]="isLock || roleName == 'User'"
                    cols="100" rows="3"
                    [(ngModel)]="extraInfo.deliveryNotes"
                    class="form-control" placeholder="Enter Lead Time"></textarea>
            </div>
            <div class="col-lg-4 col-md-12" style="height: 100%;">
                <label>Other Remarks</label>
                <textarea name="" id="" [disabled]="isLock || roleName == 'User'"
                    cols="100" rows="3" class="form-control"
                    [(ngModel)]="extraInfo.otherRemark" placeholder="Enter Other Remarks"></textarea>
            </div>
        </div>
    </div>
 </p-dialog>


 
<p-dialog header="Engineering Drawing" [(visible)]="displayDrawing"
[modal]="true" [draggable]="false" [resizable]="false">
<ng-container *ngFor="let drawing of editUploadedDrawing; let index= index">
    <span style="font-size: 12px;" class="mx-2">{{index+1}}.</span><a
        style="font-size: 12px;" [href]="drawing.engineeringDrawing"
        [download]="drawing.engineeringDrawing" target="_blank">{{_utility.getDrawingName(drawing.engineeringDrawing)}}</a>
</ng-container>
</p-dialog>


<div class="engg-drawings">
<p-dialog header="Other Drawing" [breakpoints]="{'960px': '50vw', '640px':
    '90vw'}" [draggable]="false" [modal]="true"
    [(visible)]="displayOtherDialog">
    <ng-container *ngFor="let drawing of otherDocumentList; let index= index">
        <span style="font-size: 12px;" class="mx-2">{{index+1}}.</span><a
            style="font-size: 12px;" [href]="drawing.value"
            [download]="drawing.value" target="_blank">{{_utility.getDrawingName(drawing.value)}}</a>
    </ng-container>
    <!-- <p-carousel [value]="otherDocumentList">
        <ng-template let-drawing pTemplate="item">
            <a [href]="drawing.value" [download]="drawing.value"
                target="_blank"><img [src]="drawing.value" style="width:
                    120px;" alt="">{{_utility.getDrawingName(drawing.value)}}</a>
        </ng-template>
    </p-carousel> -->
</p-dialog>
</div>