import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardGuard } from './AuthGuards/auth-guard.guard';
import { ReportsComponent } from './components/custom/common-components/reports/reports.component';
import { SuccessPageComponent } from './components/custom/common-components/success-page/success-page.component';
import { LoginComponent } from './components/custom/user-profile/login/login.component';
import { ResetAccountInfoComponent } from './components/custom/user-profile/reset-account-info/reset-account-info.component';
// import { AboutUsComponent } from './components/pages/about-us/about-us.component';
// import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
// import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
// import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
// import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
// import { CartComponent } from './components/pages/cart/cart.component';
// import { CategoriesComponent } from './components/pages/categories/categories.component';
// import { CheckoutComponent } from './components/pages/checkout/checkout.component';
// import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
// import { ContactComponent } from './components/pages/contact/contact.component';
// import { DashboardBookingsComponent } from './components/pages/dashboard/dashboard-bookings/dashboard-bookings.component';
// import { DashboardBookmarksComponent } from './components/pages/dashboard/dashboard-bookmarks/dashboard-bookmarks.component';
// import { DashboardInvoiceComponent } from './components/pages/dashboard/dashboard-invoice/dashboard-invoice.component';
// import { DashboardMessagesComponent } from './components/pages/dashboard/dashboard-messages/dashboard-messages.component';
// import { DashboardMyListingsComponent } from './components/pages/dashboard/dashboard-my-listings/dashboard-my-listings.component';
// import { DashboardReviewsComponent } from './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
// import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
// import { EventsComponent } from './components/pages/events/events.component';
// import { FaqComponent } from './components/pages/faq/faq.component';
// import { GalleryComponent } from './components/pages/gallery/gallery.component';
// import { GridListingsFullWidthComponent } from './components/pages/grid-listings-full-width/grid-listings-full-width.component';
// import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
// import { GridListingsRightSidebarComponent } from './components/pages/grid-listings-right-sidebar/grid-listings-right-sidebar.component';
// import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
// import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
// import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ProductImageComponent } from './components/custom/common-components/product-image/product-image.component';
import { BUMasterComponent } from './components/custom/supplier-master/bumaster/bumaster.component';
import { BussinessUnitMasterComponent } from './components/custom/supplier-master/bussiness-unit-master/bussiness-unit-master.component';
import { CostBreakerComponent } from './components/custom/supplier-master/cost-breaker/cost-breakup.component';
import { ItemMasterComponent } from './components/custom/supplier-master/item-master/item-master.component';
import { ManufacturingProcessListComponent } from './components/custom/supplier-master/manufacturing-process-list/manufacturing-process-list.component';
import { MaterialConstructionProcessComponent } from './components/custom/supplier-master/material-construction-process/material-construction-process.component';
import { MaterialFormComponent } from './components/custom/supplier-master/material-form/material-form.component';
import { MaterialGradeComponent } from './components/custom/supplier-master/material-grade/material-grade.component';
import { OthercategorymasterComponent } from './components/custom/supplier-master/othercategorymaster/othercategorymaster.component';
import { CustomerBranchPlantMasterComponent } from './components/custom/customer-master/customer-branch-plant-master/customer-branch-plant-master.component';
import { CustomerBussinessMasterComponent } from './components/custom/customer-master/customer-bussiness-master/customer-bussiness-master.component';
import { DocumentMasterComponent } from './components/custom/customer-master/document-master/document-master.component';
import { PaymentTermsComponent } from './components/custom/customer-master/payment-terms/payment-terms.component';
import { SupplierTypeDocumentComponent } from './components/custom/supplier-master/supplier-type-document/supplier-type-document.component';
import { CustomerChangeRequestListComponent } from './components/custom/customer-component/customer-change-process/customer-change-request-list/customer-change-request-list.component';
import { CustomerChangeRequestLogsComponent } from './components/custom/customer-component/customer-change-process/customer-change-request-logs/customer-change-request-logs.component';
import { CustomerRaiseRequestComponent } from './components/custom/customer-component/customer-change-process/customer-raise-request/customer-raise-request.component';
import { CustomerAuditLogsComponent } from './components/custom/customer-component/customer-registration-process/customer-audit-logs/customer-audit-logs.component';
import { CustomerEditFormComponent } from './components/custom/customer-component/customer-registration-process/customer-edit-form/customer-edit-form.component';
import { CustomerFormComponent } from './components/custom/customer-component/customer-registration-process/customer-form/customer-form.component';
import { CustomerLinkGenerationComponent } from './components/custom/customer-component/customer-registration-process/customer-link-generation/customer-link-generation.component';
import { CustomerListComponent } from './components/custom/customer-component/customer-registration-process/customer-list/customer-list.component';
import { CustomerUploadDocComponent } from './components/custom/customer-component/customer-upload-doc/customer-upload-doc.component';
import { ChangeRequestLogsComponent } from './components/custom/supplier-components/supplier-change-request-process/change-request-logs/change-request-logs.component';
import { SupplierChangeApprovalComponent } from './components/custom/supplier-components/supplier-change-request-process/supplier-change-approval/supplier-change-approval.component';
import { SupplierchangerequestComponent } from './components/custom/supplier-components/supplier-change-request-process/supplierchangerequest/supplierchangerequest.component';
import { APQPComponent } from './components/custom/supplier-components/supplier-documents/apqp/apqp.component';
import { LoanToolAgreementComponent } from './components/custom/supplier-components/supplier-documents/loan-tool-agreement/loan-tool-agreement.component';
import { OrdersComponent } from './components/custom/supplier-components/supplier-documents/orders/orders.component';
import { PpapComponent } from './components/custom/supplier-components/supplier-documents/ppap/ppap.component';
import { AssessmentDocComponent } from './components/custom/supplier-components/supplier-registration-process/assessment-doc/assessment-doc.component';
import { DashboardAddListingsComponent } from './components/custom/supplier-components/supplier-registration-process/dashboard-add-listings/dashboard-add-listings.component';
import { SupplierFormDetailsComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-form-details/supplier-form-details.component';
import { SupplierListComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-list/supplier-list.component';
import { SupplierOnboardingDataComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-onboarding-data/supplier-onboarding-data.component';
import { SupplierOnboardingComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-onboarding/supplier-onboarding.component';
import { SuppliersAuditLogsComponent } from './components/custom/supplier-components/supplier-registration-process/suppliers-audit-logs/suppliers-audit-logs.component';
import { UserAccountComponent } from './components/custom/supplier-components/supplier-registration-process/user-account/user-account.component';
import { VerificationFormComponent } from './components/custom/supplier-components/supplier-registration-process/verification-form/verification-form.component';
import { CommonDashboardPopupComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/common-dashboard-popup/common-dashboard-popup.component';
import { DashboardWalletComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/dashboard-wallet/dashboard-wallet.component';
import { EnquiryComparisonByIdComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/enquiry-comparison-by-id/enquiry-comparison-by-id.component';
import { EnquiryComparisonComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/enquiry-comparison/enquiry-comparison.component';
import { RequestForQuotationComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/request-for-quotation/request-for-quotation.component';
import { SupplierAssignListComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/supplier-assign-list/supplier-assign-list.component';
import { SupplierAssignComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/supplier-assign/supplier-assign.component';
import { SupplierUploadDocComponent } from './components/custom/supplier-upload-doc/supplier-upload-doc.component';
import { DashboardMyProfileComponent } from './components/custom/user-profile/dashboard-my-profile/dashboard-my-profile.component';
import { RegisterUserComponent } from './components/custom/user-profile/register-user/register-user.component';
import { SupplierOrderDetailsComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/supplier-order-details/supplier-order-details.component';
import { LoginFormComponent } from './components/custom/user-profile/login-form/login-form.component';
import { ToolLoanComponent } from './components/custom/supplier-components/tool-loan/tool-loan.component';
import { PartDrawingComponent } from './components/custom/supplier-master/part-drawing/part-drawing.component';
import { OrderStatusComponent } from './components/custom/customer-component/order-status/order-status.component';
// import { PricingComponent } from './components/pages/pricing/pricing.component';
// import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
// import { ProductsListComponent } from './components/pages/products-list/products-list.component';
// import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
// import { VerticalListingsFullWidthComponent } from './components/pages/vertical-listings-full-width/vertical-listings-full-width.component';
// import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
// import { VerticalListingsRightSidebarComponent } from './components/pages/vertical-listings-right-sidebar/vertical-listings-right-sidebar.component';

const routes: Routes = [
    {path: 'dashboard', component: DashboardComponent, canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin']}},
    {path: 'dashboard-my-profile', component: DashboardMyProfileComponent, canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'User' , 'Admin' , 'Supplier', 'Normal User' , 'Bussiness head', 'Finance','CS Team','Finance-JDE' , 'Customer-Approval','Customer' , 'Customer-JDE']}},
    {path: 'linkGeneration', component: DashboardAddListingsComponent , canActivate : [AuthGuardGuard] , data: {roles: ['User' , 'Admin', 'Normal User', 'Bussiness head', 'Finance']}},

    // Here add new pages component
    {path: '', redirectTo:'login' , pathMatch: 'full'},
    // {path: 'login', component: LoginComponent , pathMatch: 'full'},
    {path: 'login', component: LoginFormComponent , pathMatch: 'full'},
    {path: 'register/:email', component: UserAccountComponent , pathMatch: 'full'},
    {path: 'reset-password', component: ResetAccountInfoComponent , pathMatch: 'full'},
    {path: 'verification/:roleName', component: VerificationFormComponent , pathMatch: 'full'},
    {path: 'supplierOnboarding', component: SupplierOnboardingComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Supplier']}},
    {path: 'APQP', component: APQPComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Supplier' , 'Admin']}},
    {path: 'PPAP', component: PpapComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Supplier']}},
    {path: 'toolLoanAgreement', component: LoanToolAgreementComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin' , 'Supplier' ,'User' , 'Normal User' , 'Finance' , 'Bussiness head']}},
    {path: 'toolhealthCheck', component: LoanToolAgreementComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin' , 'Supplier' ,'User' , 'Normal User' , 'Finance' , 'Bussiness head']}},
    {path: 'manufacturingProcessList', component: ManufacturingProcessListComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin']}},
    {path: 'materialConstructionList', component: MaterialConstructionProcessComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin']}},
    {path: 'myOrders', component: SupplierOrderDetailsComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Supplier']}},
    {path: 'supplierOrders', component: OrdersComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin' , 'User' , 'Supplier', 'Normal User' , 'Bussiness head']}},
    {path: 'itemMaster', component: ItemMasterComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] ,  data: {roles: ['SuperAdmin' , 'Admin' , 'User', 'Finance', 'Bussiness head'] }},
    {path: 'costBreakup', component: CostBreakerComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin', 'Finance' ,  'Admin' , 'User', 'Bussiness head']}},
    {path: 'requestForQuotation', component: RequestForQuotationComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin','Admin' , 'User' , 'Normal User' , 'Bussiness head']}},
    {path: 'assessmentDoc', component: AssessmentDocComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'User' , 'Normal User' , 'Bussiness head', 'Finance']}},
    {path: 'requestForQuotation/:id', component: RequestForQuotationComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'User' , 'Normal User' , 'Bussiness head','Admin']}},
    {path: 'apqpDocument', component: APQPComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'User' , 'Normal User' , 'Bussiness head','Admin']}},
    {path: 'ppapDocument', component: PpapComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'User' , 'Normal User' , 'Bussiness head','Admin']}},
    {path: 'supplierAssign/:id', component: SupplierAssignComponent , pathMatch: 'full' ,  canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' , 'Normal User', 'Bussiness head' ]}},
    {path: 'supplierAssign', component: SupplierAssignComponent , pathMatch: 'full' ,  canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' , 'Normal User' , 'Bussiness head']}},
    {path: 'supplierDashboard', component: DashboardWalletComponent, canActivate : [AuthGuardGuard] , data: {roles: ['Supplier' , 'User' , 'Normal User' , 'Bussiness head' , 'Admin']}},    
    {path: 'userRegistration', component: RegisterUserComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'Normal User' , 'Bussiness head']}},
    {path: 'supplierUploadedDocList', component: SupplierOnboardingDataComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin','User', 'Finance', 'Bussiness head','Normal User']}},    
    {path: 'enquiryPurchase/:status', component: EnquiryComparisonComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' ,'Normal User' , 'Bussiness head', 'Finance']}},    
    {path: 'enquiryPurchase', component: EnquiryComparisonComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' ,'Normal User' , 'Bussiness head']}},    
    {path: 'enquiryPurchase', component: EnquiryComparisonComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' ,'Normal User' , 'Bussiness head']}},    
    {path: 'enquiryPurchase/:enquiryId/:itemId', component: EnquiryComparisonByIdComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' ,'Normal User' , 'Bussiness head']}},    
    {path: 'report', component: ReportsComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' , 'Supplier' , 'Normal User' , 'Bussiness head','Customer-Approval' , 'Customer-JDE','CS Team','Finance-JDE']}},    
    {path: 'supplierAssignList', component: SupplierAssignListComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' , 'Normal User' , 'Bussiness head']}},    
    {path: 'successPage', component: SuccessPageComponent , pathMatch: 'full'},   
    {path: 'successPage1', component: CommonDashboardPopupComponent , pathMatch: 'full'},   
    {path: 'othercategorymaster', component: OthercategorymasterComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin']}},
    {path: 'buMaster', component: BUMasterComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin']}},
    {path: 'bussinessUnitNumber', component: BussinessUnitMasterComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin']}},
    {path: 'materialGrade', component: MaterialGradeComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin']}},
    {path: 'materialForm', component: MaterialFormComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin']}},
    {path: 'supplier-upload-doc', component: SupplierUploadDocComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Admin', 'User', 'Bussiness head' , 'Finance','Supplier']}},
    {path: 'payment-terms', component: PaymentTermsComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data : {roles: ['Admin']}},

    //audit logs of both customer and supplier
    {path: 'customer-audit-logs', component: CustomerAuditLogsComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data : {roles: ['Admin', 'Finance','CS Team','Customer-JDE', 'Customer-Approval']}},
    {path: 'supplier-audit-logs', component: SuppliersAuditLogsComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data : {roles: ['Admin','Finance','CS Team']}},

    //document master
    {path: 'supplierdocumentMaster', component: SupplierTypeDocumentComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data : {roles: ['Admin' ,'User' , 'Bussiness head' , 'Finance']}},
    {path: 'documentMaster', component: DocumentMasterComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'Customer Approval' , 'Customer-JDE']}}, 

    //product page
    {path: 'product-page', component: ProductImageComponent , pathMatch: 'full'},

    //supplier
    {path: 'supplierList', component: SupplierListComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'Supplier' , 'User' , 'Normal User' , 'Bussiness head', 'Finance']}},    
    {path: 'supplierMaster', component: SupplierFormDetailsComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['Supplier']}},  


    //supplier change request 
    {path: 'raiseChangeRequest', component: SupplierchangerequestComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data: {roles: ['Supplier']}},
    {path: 'supplierChangeRequests', component: SupplierChangeApprovalComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' , 'Normal User' , 'Finance', 'Bussiness head']}},    
    {path: 'supplierChangeRequestLogs', component: ChangeRequestLogsComponent , pathMatch: 'full', canActivate : [AuthGuardGuard] , data: {roles: ['SuperAdmin' , 'Admin' , 'User' , 'Normal User' , 'Finance']}},  
    
    //customer 
    {path: 'customer-form/:action/:slug', component: CustomerFormComponent, pathMatch: 'full'},
    {path: 'customer-list', component: CustomerListComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data : {roles: ['Admin' , 'User', 'Normal User','CS Team', 'Finance-JDE', 'Customer-Approval' , 'Customer-JDE']}},
    {path: 'customer-edit-form', component: CustomerEditFormComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data : {roles: ['Admin' , 'Customer' , 'Customer-JDE' , 'Finance-JDE']}},
    {path: 'customer-link-generation', component: CustomerLinkGenerationComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data : {roles: ['Admin' , 'User', 'Normal User','CS Team' , 'Finance-JDE' , 'Customer-Approval' , 'Customer-JDE']}},
    {path: 'customer-upload-doc', component: CustomerUploadDocComponent , pathMatch: 'full' , canActivate : [AuthGuardGuard] , data : {roles: ['Admin' , 'User', 'Normal User','CS Team' , 'Finance-JDE' , 'Customer-Approval','Customer' , 'Customer-JDE']}},

    //customer change request
    {path : 'customerChangeRequestLogs' , component : CustomerChangeRequestLogsComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['CS Team' , 'Customer-Approval' , 'Customer-JDE' , 'Admin']}},
    {path : 'customerRaiseChangeRequest' , component : CustomerRaiseRequestComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['Customer','CS Team']}},
    // {path : 'customerRaiseChangeRequest/:id' , component : CustomerRaiseRequestComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['Customer','CS Team']}},
    {path : 'customerRaiseChangeRequestList' , component : CustomerChangeRequestListComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['CS Team' , 'Customer-Approval' , 'Customer-JDE', 'Admin']}},
    {path : 'customerBuMaster' , component : CustomerBussinessMasterComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['CS Team' , 'Customer-Approval' , 'Customer-JDE', 'Admin']}},
    {path : 'branchPlant' , component : CustomerBranchPlantMasterComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['CS Team' , 'Customer-Approval' , 'Customer-JDE', 'Admin']}},
    {path : 'tool-loan' , component : ToolLoanComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['Admin','User','Finance']}},
    {path : 'part-drawing' , component : PartDrawingComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['Admin']}},
    {path : 'order-status' , component : OrderStatusComponent , pathMatch : 'full' , canActivate : [AuthGuardGuard] , data : {roles : ['Admin','Finance', 'CS Team','Customer-Approval','Customer']}},

    {path: '**', component: NotFoundComponent}, // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }