import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/api-service.service';

@Component({
  selector: 'app-suppliers-audit-logs',
  templateUrl: './suppliers-audit-logs.component.html',
  styleUrls: ['./suppliers-audit-logs.component.scss']
})

export class SuppliersAuditLogsComponent implements OnInit{
  breadcrumb = [
    {
      title: 'Supplier Audit Logs',
      subTitle: 'Dashboard',
    },
  ];

  constructor(private _apiService : ApiServiceService){

  }

  supplierList : any = [];

  ngOnInit(){
    this._apiService.getAuditLogsOfSuppliers()
    .then((res)=>{
      console.log(res);
      if(res['status']){
        this.supplierList = res['returnValue'];
        this.supplierList.sort((a: any, b: any) => (new Date(b.crDate) as any) - (new Date(a.crDate) as any));

      }else{
        this.supplierList = [];
      }
    })
  }
}