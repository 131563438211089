import { Component, Input, OnInit , OnChanges , Output , EventEmitter} from '@angular/core';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit , OnChanges {
  myDate: Date;
  header: string;
  primengConfig: any;
  userroleName: any;
  stateDropdown: any;
  paymentDropdown: any;
  purposeDropdown: any;
  manufacturingData: any = [];
  materialConstructionData: any = [];
  otherCategoryArray: any;
  document_dropdown: any;
  currencyDropdown: any;
  @Input() supplierList : any;
  @Output() displayFinance = new EventEmitter<string>();

  breadcrumb = [
    {
      title: 'Supplier List',
      subTitle: 'Dashboard'
    }
  ]
  countryDropdown: any;
  jdeForm = {
    jdeNo : '',
    jdeApproved : true,
    userName : '',
    supplierId : '',
    supplierName : '' 
  }


  showSubmit : boolean = true;
  ngOnChanges(): void{
    this.jdeForm['supplierId'] = this.supplierList.supplierId;
    this.jdeForm['userName'] = this.supplierList.userName;
    this.jdeForm['supplierName'] = this.supplierList.supplierName;
    this.jdeForm['jdeNo'] = this.supplierList.jdeNo;
    if(this.jdeForm['jdeNo']){
      this.showSubmit = false;
    }
    else{
      this.showSubmit = true; 
    }
  }


  async submitJDE(form:any){
    if(form.valid){
      this.displayFinance.emit('true');
      this._utility.loader(true);
      await this._apiService.putJDEEntry(this.jdeForm)
      .then((res:any)=>{
        if(res.success){
          console.log(res);
          this._apiService.showMessage(res.message , 'success');
          this._utility.loader(false);
          this.displayFinance.emit('false');
          window.location.reload();
        }
        
        else{
          this._apiService.showMessage(res.message , 'error');
          this._utility.loader(false);
          this.displayFinance.emit('false');
        }
      })
    }
  }

  constructor(private _utility : AppUtility , private _apiService : ApiServiceService){}
  ngOnInit(): void {
    this.getStatic();
    this.myDate = new Date();
    this.header = "Supplier Detail"
    this.userroleName = this._utility.getLocalStorageDetails();
  }

  getStatic() {
    let data = this._utility.getDropdownData();
    data.then((res) => {
      res.forEach((response: any) => {
        if (response.stateDropdown) {
          this.stateDropdown = response.stateDropdown;
        }
        else if (response.paymentDropdown) {
          this.paymentDropdown = response.paymentDropdown;
        }
        else if (response.purposeDropdown) {
          this.purposeDropdown = response.purposeDropdown;
        }
        else if (response.otherCategoryData) {
          this.otherCategoryArray = response.otherCategoryData;
        }
      });
    })

    this._apiService.dropdowndata('document').then((res: any) => {
      this.document_dropdown = res.returnValue;
    })

    this._apiService.dropdowndata('currency').then((res: any) => {
      this.currencyDropdown = res.returnValue;
    })

    this._apiService.dropdowndata('country').then((res: any) => {
      this.countryDropdown = res.returnValue
    })
  }
}
