import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { ApiServiceService } from "src/app/api-service.service";
import { AppUtility } from "src/app/interceptor/appUtitlity";
import { Router } from "@angular/router";
import { filter } from "jszip";

@Component({
  selector: "app-tool-loan",
  templateUrl: "./tool-loan.component.html",
  styleUrls: ["./tool-loan.component.scss"],
})
export class ToolLoanComponent {
  breadcrumb = [
    {
      title: "Tool Loan Agreement",
      subTitle: "Dashboard",
    },
  ];
  BussinessUnitNumberTable: any = [];
  supplierList: any = [];
  showLinkInput: boolean = false;
  locationVal: any;
  currencyDropdownValue: any;
  partDrawingData: any;
  showAssetNoInput: boolean = false;
  showExpenseInput: boolean = false;
  poTypeData: any;
  budata: any;
  code: any = {};
  role: any;
  constructor(
    private fb: FormBuilder,
    private _apiService: ApiServiceService,
    private _utility: AppUtility,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getBusinessUnit();
    this.getAllSupplierDetail();
    this.getPoType();
    this.getBUdata();
    this.role = JSON.parse(localStorage.getItem("UserObject"));
    if (this.role.roleName === "Finance") {
      this.toolLoanForm.disable();
      this.toolLoanForm.controls.capitalAssetNo.enable();
    }else{
      this.toolLoanForm.get("capitalAssetNo").clearValidators();
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                       // get business unit master api                      */
  /* -------------------------------------------------------------------------- */
  getBusinessUnit() {
    this._utility.loader(true);
    this._apiService
      .dropdowndata("BussinessUnitNumber")
      .then((res: any) => {
        console.log(res);
        this._utility.loader(false);
        if (res.success == true) {
          this.BussinessUnitNumberTable = res.returnValue;
        } else {
          this.BussinessUnitNumberTable = [];
        }
      })
      .catch((error: any) => {
        this._utility.loader(false);
        this.BussinessUnitNumberTable = [];
      });
  }
  /* -------------------------------------------------------------------------- */
  /*                              // supplier list                              */
  /* -------------------------------------------------------------------------- */
  getAllSupplierDetail() {
    this._utility.loader(true);
    this._apiService.getSupplier().then((res: any) => {
      console.log(res);
      this._utility.loader(false);
      if (res.success == true) {
        this.supplierList = res.returnValue;
      } else {
        this.supplierList = [];
      }
    });

    // to get currency value
    this._apiService.dropdowndata("currency").then((res: any) => {
      if (res.success) {
        this.currencyDropdownValue = res.returnValue;
        console.log(this.currencyDropdownValue);
      } else {
        this.currencyDropdownValue = [];
      }
    });
    //to get partDrawing data
    this._apiService.getPartDrawing().then((res: any) => {
      if (res.success == true) {
        this.partDrawingData = res.returnValue;
        console.log(this.partDrawingData);
      } else {
        this.partDrawingData = [];
      }
    });
  }
  //get po type dropdown data
  getPoType() {
    this._apiService.getPoType().then((res: any) => {
      if (res.success == true) {
        console.log(res);
        this.poTypeData = res.returnValue;
      } else {
        this.poTypeData = [];
        console.log("no data found in po type");
      }
    });
  }
  // get supplier data on dropdown selection
  // getSupplierData(data: any) {
  //   var supplierId = data.target.value;
  //   console.log(data.target.value);
  //   // this.locationVal = data.target.value;
  //   this.supplierList.forEach((key: any) => {
  //     if (key.supplierId == supplierId) {
  //       this.toolLoanForm.controls.location.setValue(key.city);
  //     }
  //   });
  // }
  getSupplierData(data: any) {
    var part = data.value;
    console.log(part);
    // this.locationVal = data.target.value;
    this.toolLoanForm.controls.location.setValue(data.value);
    this.supplierList.forEach((key: any) => {
      if (key.supplierId == part) {
        this.toolLoanForm.controls.location.setValue(key.city);
      }
    });
  }
  // get draiwng no. on dropdown selection
  // getPartDrawingId(data: any) {
  //   var partId = data.target.value;
  //   //   let tool=PartDrawingId.filter(id =>{
  //   // })
  //   // const selectedToolId = data.target.value;

  //   //  const selectedTool = partDrawingId.find(tool => tool.id === selectedToolId);
  //   this.partDrawingData.forEach((key: any) => {
  //     if (key.partId == partId) {
  //       console.warn(key.partDrawingName)
  //       this.toolLoanForm.controls.toolDescription.setValue(key.PartDrawingDescription);
  //     }
  //   });
  // }

  getPartDrawingId(data: any) {
    var partValue = this.toolLoanForm.controls.partNo.value;
    // var toString = partValue.toString()
    // console.log('partNo',toString)
    // this.toolLoanForm.controls.partNo.setValue(toString)
    var partId = data.value;
    console.log(partId);
    //   let tool=PartDrawingId.filter(id =>{
    // })
    // const selectedToolId = data.target.value;

    //  const selectedTool = partDrawingId.find(tool => tool.id === selectedToolId);
    this.partDrawingData.forEach((key: any) => {
      if (key.partId == partId) {
        console.warn(key.partDrawingName);
        this.toolLoanForm.controls.toolDescription.setValue(
          key.PartDrawingDescription
        );
      }
    });
  }
  // agreement signed or not function
  // agreementSigned(event: any) {
  //   if (event.target.value == "yes") {
  //     this.showLinkInput = true;
  //     this.toolLoanForm.controls.LinkorToolLoanAgreement.setValidators(
  //       Validators.required
  //     );
  //     this.toolLoanForm.controls.LinkorToolLoanAgreement.updateValueAndValidity();
  //   } else {
  //     this.showLinkInput = false;
  //     this.toolLoanForm.controls.LinkorToolLoanAgreement.removeValidators;
  //     this.toolLoanForm.controls.LinkorToolLoanAgreement.updateValueAndValidity();
  //   }
  // }
  // get tool type
  getToolType(event: any) {
    this.code = {};
    if (event.target.value == "capital") {
      this.showAssetNoInput = true;
      this.showExpenseInput = false;
      this.toolLoanForm.controls.idexAssetNo.setValue("");
    } else {
      this.showAssetNoInput = false;
      this.showExpenseInput = true;
      this._apiService.getAssestCode().then((code: any) => {
        console.log(code);
        this.code = code.EnquiryNumber;
        this.toolLoanForm.controls.idexAssetNo.setValue(this.code);
      });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                            // form controls name                           */
  /* -------------------------------------------------------------------------- */
  toolLoanForm = this.fb.group({
    bu: new FormControl(null, [Validators.required]),
    businessUnit: new FormControl(null, [Validators.required]),
    poType: new FormControl(null, [Validators.required]),
    PoNo: new FormControl(null, [Validators.required]),
    partNo: new FormControl(null, [Validators.required]),
    toolDescription: new FormControl(null, [Validators.required]),
    Currency: new FormControl("", [Validators.required]),
    value: new FormControl(null, [Validators.required]),
    toolingCustody: new FormControl("", [Validators.required]),
    location: new FormControl(null, [Validators.required]),
    ToolLoanAgreementSigned: new FormControl("no"),
    // LinkorToolLoanAgreement: new FormControl(null),
    capital: new FormControl(null, [Validators.required]),
    idexAssetNo: new FormControl(null),
    dieShift: new FormControl(false, [Validators.required]),
    shiftFSupplier: new FormControl(""),
    shiftTSupplier: new FormControl(""),
    remarks: new FormControl(null, [Validators.required]),
    isActive: new FormControl(true),
    capitalAssetNo: new FormControl("", [Validators.required]),
  });
  // financeRoleForm = this.fb.group({
  //   capitalAssetNo: new FormControl('', [Validators.required]),
  // });
  customValidator(group: FormGroup) {
    // const toolLoanAgreementSigned = group.get('ToolLoanAgreementSigned').value;
    // const linkOrToolLoanAgreement = group.get('LinkorToolLoanAgreement').value;
    const capital = group.get("capital").value;
    const idexAssetNo = group.get("idexAssetNo").value;
    if (capital === "capital" && !idexAssetNo) {
      group.get("idexAssetNo").setErrors({ required: true });
    } else {
      group.get("idexAssetNo").setErrors(null);
    }
    return null;
  }

  submitForm() {
    console.log(this.toolLoanForm.valid, this.toolLoanForm.value);
    if (this.toolLoanForm.valid) {
      if (this.role.roleName === "Finance") {
        console.log(this.toolLoanForm.valid, this.toolLoanForm.value);
        this.confirmationService.confirm({
          message:
            "Are you sure you want to proceed? After adding the information, there will be no option for further edits to the form.",
          header: "Confirmation",
          icon: "pi pi-info-circle",
          accept: () => {
            let formData = new FormData;
            formData.append('IdexAssetNo',this.toolLoanForm.controls.capitalAssetNo.value);
            formData.append('ToolListId',localStorage.getItem("toolId"))
            // let data = {
            //   IdexAssetNo: this.toolLoanForm.controls.capitalAssetNo.value,
            //   ToolListId: localStorage.getItem("toolId"),
            // };
            // let body = JSON.stringify(data);
            this._apiService.sendAssetNo(formData).then((res: any) => {
              if (res.success == false) {
                this._apiService.showMessage(res.message, "error");
              } else {
                this._apiService.showMessage(res.message, "success");
                this.router.navigateByUrl("/toolLoanAgreement");
              }
            });
          },
        });
      } else {
        const jsonObject = this.toolLoanForm.value;
        const formData = JSON.stringify(jsonObject);
        console.log(formData);
        this.confirmationService.confirm({
          message:
            "Are you sure you want to proceed? After adding the information, there will be no option for further edits to the form.",
          header: "Confirmation",
          icon: "pi pi-info-circle",
          accept: () => {
            this._apiService.addToolLoanAgreement(formData).then((res: any) => {
              if (res.success == false) {
                this._apiService.showMessage(res.message, "error");
              } else {
                this._apiService.showMessage(res.message, "success");
                this.router.navigateByUrl("/toolLoanAgreement");
              }
            });
          },
        });
      }
    } else {
      this._apiService.showMessage("Please fill form details", "error");
      console.log("Form not valid");
    }
  }

  //get bu data dropdown
  getBUdata() {
    this._apiService
      .dropdowndata("BUMaster")
      .then((res: any) => {
        console.log(res);
        if (res.success == true) {
          this.budata = res.returnValue;
        } else {
          this.budata = [];
        }
      })
      .catch((error: any) => {
        this.budata = [];
      });
  }
  // getDieShift(event:any){
  //   if(event.target.value == 'yes'){
  //     this.toolLoanForm.controls.dieShift.setValue(true);
  //   }else{
  //     this.toolLoanForm.controls.dieShift.setValue(false);
  //   }
  //  console.log(event.target.value);
  // }
}
