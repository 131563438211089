import { Component, DebugElement, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-supplier-upload-doc',
  templateUrl: './supplier-upload-doc.component.html',
  styleUrls: ['./supplier-upload-doc.component.scss']
})

export class SupplierUploadDocComponent implements OnInit {
  date: any;
  submitButton: string = 'Submit'
  manufactureTable: any = [];
  currentDate: any;
  loading: boolean = false;
  msgs: Message[] = [];
  userLogin : any;
  constructor(private _apiService: ApiServiceService, private _utility: AppUtility , private confirmService : ConfirmationService) { }

  ngOnInit(): void {
    
    this.currentDate =  new Date().toLocaleString();
    this.getStatic();
    this.getAllTableData();
    this.userLogin = JSON.parse(localStorage.getItem('UserObject'));
  }

  breadcrumb = [
    {
      title: 'Supplier Additional Document',
      subTitle: 'Dashboard'
    }
  ]


  supplierList : any = [];
  documentList : any = [];
  async getStatic(){
    await this._apiService.getSupplier().then((res:any)=>{
      if(res.success){
        this.supplierList = res.returnValue;
      }else{
        this.supplierList = [];
      }
    })
    console.log('supplierList', this.supplierList);

    this.manufactureTable.forEach((obj) => {
      // Find the corresponding customerName from supplierList
      const supplier = this.supplierList.find((supplier) => supplier.supplierId === obj.supplierId);
  
      // If a matching supplier is found, push the customerName into the object
      if (supplier) {
          obj.supplierName = supplier.supplierName;
      } else {
          // If no matching supplier is found, you can handle it according to your requirements
          // For example, you can set a default value or throw an error
          obj.customerName = "";
          console.log('unknown')
      }
  });
  console.log('manufactureList',this.manufactureTable);
    await this._apiService.getDocument().then((res:any)=>{
      if(res.success){
        this.documentList = res.returnValue.filter(res=> res.documentType == 'Supplier');
      }else{
        this.documentList = [];
      }
    })

    let localStorage = this._utility.getLocalStorageDetails();
    if(localStorage.roleName == 'Supplier'){
      this.roleName = 'Supplier';
      this.manufactureProcessForm.controls['supplierName'].setValue(localStorage?.supplierId);
      this.setJDENo();
    }
  }





  getFileExtension(path: string): string {
    if (!path) {
      return '';
    }
    // Extract the filename from the path (ignores query parameters)
    const fileName = path.split('?')[0].split('/').pop();

    return fileName?.split('.').pop()?.toLowerCase() || '';
    
  }
  
  manufactureProcessForm = new FormGroup({
    supplierName: new FormControl('', [Validators.required]),
    documentUpload: new FormControl('', [Validators.required]),
    documentName: new FormControl('', [Validators.required]),
    jdeNo: new FormControl('', [Validators.required]),
    invoiceNo: new FormControl(''),
    orderNo: new FormControl('')
  })

  manufactureProcessFormSubmit(manufacture: FormGroupDirective) {
    console.log(this.manufactureProcessForm.value);
    if (this.manufactureProcessForm.valid) {
      const supplierId = this.manufactureProcessForm.controls['supplierName'].value
      let supplierEmail
      let supplierName
   
 
      this._utility.loader(true);
      let formData = new FormData();
      if(this.userLogin.supplierName == ''){
        this.supplierList.forEach((supplier) => {
          if (supplier.supplierId === supplierId) {
            supplierEmail = supplier.emailAddress;
            supplierName = supplier.supplierName
            console.log(supplier);
            formData.append('EmailId',supplierEmail)
            formData.append('ResourceName',supplierName)
          }else{

          }
        });
      }
      formData.append('supplierId' , this.manufactureProcessForm.controls['supplierName'].value)
      formData.append('documentName' , this.manufactureProcessForm.controls['documentName'].value)
      formData.append('document' , this.manufactureProcessForm.controls['documentUpload'].value)
      formData.append('JDECustNo' , this.manufactureProcessForm.controls['jdeNo'].value)
      formData.append('invoiceNo' , this.manufactureProcessForm.controls['invoiceNo'].value)
      formData.append('orderNumber' , this.manufactureProcessForm.controls['orderNo'].value)
      if (this.submitButton == 'Submit') {
        this._apiService.addSupplierDocument(formData).then((res: any) => {
          this._utility.loader(false);
          if (res.success == true) {
            this._apiService.showMessage(res.message, 'success')
            window.scroll(0, 0)
            this.manufactureProcessForm.reset();
            Object.keys(this.manufactureProcessForm.controls).forEach(key => {
              this.manufactureProcessForm.controls[key].setErrors(null)
            });
            manufacture.resetForm();
          }
          else {
            this._apiService.showMessage(res.message, 'success');
          }
          this.getAllTableData();
        })
      }
      else {
        formData.append('supplierDocumentId' , this.editItemId)
        this._apiService.editSupplierDocument(formData).then((res: any) => {
          this._utility.loader(false);
          if (res.success == true) {
            this._apiService.showMessage(res.message, 'success');
            this.manufactureProcessForm.reset();
            Object.keys(this.manufactureProcessForm.controls).forEach(key => {
              this.manufactureProcessForm.controls[key].setErrors(null)
            });
            manufacture.resetForm();
            this.submitButton = 'Submit'
            this.getAllTableData();
          }

          else {
            this._apiService.showMessage(res.message, 'error');
          }
        })
      }
    }
  }

  editItemId: any;
  EditItem(customer: any) {
    if (customer.supplierDocumentId) {
      Object.keys(this.manufactureProcessForm.controls).forEach(key => {
        this.manufactureProcessForm.controls[key].setValue(customer[key]);
      });
      this.submitButton = 'Update'
      this.editItemId = customer.supplierDocumentId;
    }
  }

  roleName : any = '';
  getAllTableData() {
    this._utility.loader(true);
    this._apiService.getSupplierDocument()
      .then((res: any) => {
        this._utility.loader(false);
        if (res.success == true) {
          this.manufactureTable = res.returnValue;
        }
        else {
          this.manufactureTable = [];
        }
      })
      .catch((error: any) => {
        this._utility.loader(false);
        this.manufactureTable = [];
      })

  }

  confirm1(costBreakupId: any) {
    this.confirmService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Delete Manufacture Record',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteItem(costBreakupId);
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  deleteItem(itemId: any) {
    this._utility.loader(true);
    this._apiService.deleteSupplierDocument(itemId)
    .then((res: any) => {
      this._utility.loader(false);
      console.log(res);      
      if (res.success == true) {
        this._apiService.showMessage(res.message, 'success');
        this.getAllTableData();
      }

      else {
        window.scroll(0, 0);
        this._apiService.showMessage(res.message, 'error');
        this.getAllTableData();
      }

    })
  }

  getFile(event){
    let file = this._utility.onFileChange(event);
    this.manufactureProcessForm.controls['documentUpload'].setValue(file);
  }

  setJDENo(){ 
    let supplierId = this.manufactureProcessForm.controls['supplierName'].value;
    let object = this.supplierList.filter(res=> res.supplierId == supplierId);
    console.log(object[0]?.jdeNo);
    this.manufactureProcessForm.controls['jdeNo'].setValue(object[0]?.jdeNo);
  }
}
