<div class="add-listings-box-report">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="form-group">
                <label>Select Date</label>
                <p-calendar [(ngModel)]="rangeDates" (onSelect)="selectedDate()" #calendar [readonlyInput]="true" selectionMode="range" [showIcon]="true" placeholder="Select Date Range"></p-calendar>
            </div>
        </div>
        
        <div class="col-lg-4 col-md-6">
            <div class="form-group">
                <label>Enquiry No.</label>
                <input type="text" class="form-control" [(ngModel)]="rfqList.enquiryNo" (change)="getFormValues()" placeholder="Enter Enquiry No.">
            </div>
        </div>
    
        <div class="col-lg-4 col-md-6">
            <div class="form-group">
                <label>Status Name</label>
                <select class="form-control form-select" [(ngModel)]="rfqList.statusId" (change)="getFormValues()">
                    <option value="" disabled>Select Status</option>
                    <option value="1">Open</option>
                    <option value="0">Closed</option>
                </select>
            </div>
        </div>
    </div>
</div>
