<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
        </div>
        <!-- End Breadcrumb Area -->

        
    <!-- End -->   
    <div class="card" *ngIf="suppliers.length > 0">
        <p-table #dt2 [value]="suppliers" dataKey="id" [rows]="10" responsiveLayout="scroll"  [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [loading]="loading"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['documentName','supplierName','status']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                        placeholder="Global Search" [(ngModel)]="filterval" />
                            <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr style="width: 100%;">
                    <th style="min-width: 70px;">S. No.</th>
                    <th style="min-width: 220px;">Supplier Name</th>
                    <th style="min-width: 220px;">Document Name</th>
                    <th style="min-width: 150px;">Uploaded Date</th>
                    <th style="min-width: 150px;">Status Name</th>
                    <!-- <th *ngIf="this.userDetails.roleName != 'Finance'">Update Status</th> -->
                    <th>Document</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr>
                    <td style="min-width: 70px;">{{i+1}}</td>
                    <td style="min-width: 220px;"><span>{{customer.supplierName}}</span></td>
                    <td style="min-width: 220px;">
                        <span class="image-text">{{customer.documentName}}</span>
                    </td>
                    <td style="min-width: 150px;">
                        <span class="image-text">{{customer.uploadingDate | date}}</span>
                    </td>
                    <td >
                        <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Pending'"
                            label="Pending"
                            class="p-button-rounded status_button pending"
                            ></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Approved'"
                            label="Approved"
                            class="p-button-rounded status_button p-button-success"
                            ></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Rejected'"
                            label="Rejected"
                            class="p-button-rounded status_button p-button-danger"
                            ></button>
                        <button pButton pRipple type="button"
                            *ngIf="customer.statusName == 'Rework'"
                            label="Rework"
                            class="p-button-rounded status_button"
                            ></button>
                    </td>
                    <!-- <td style="display: flex;" *ngIf="this.userDetails.roleName != 'Finance'">
                            <button pbutton pRipple type="button" icon="bx bx-check-circle" *ngIf="customer.statusName == 'Pending' || customer.statusName == 'Approved' || customer.statusName == 'Rework'" class="p-element p-button-rounded p-button-success p-button-text p-button p-component p-button-icon-only ng-star-inserted" ng-reflect-icon="bx bx-check-circle"  (click)="updateStatus(customer , 'Approved')"><span class="p-button-icon bx bx-check-circle" aria-hidden="true"></span><span aria-hidden="true" class="p-button-label">&nbsp;</span></button>
                            <button pButton pRipple type="button" icon="pi pi-times-circle" *ngIf="customer.statusName == 'Pending' || customer.statusName == 'Rejected' || customer.statusName == 'Rework'" class="p-button-rounded p-button-danger p-button-text" (click)="updateStatus(customer , 'Rejected')"></button>
                    </td> -->
                    <td>
                        <a  href="{{customer.documentPath}}" style="text-decoration: none;" download="{{customer.documentPath}}" target="_blank"><button  pButton pRipple type="button" icon="bx bx-download" class="p-button-rounded p-button-primary p-button-text"></button></a>
                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No document found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div> 

    <div class="flex-grow-1"></div>

    <!-- <p-dialog [header]="this.header" [(visible)]="display" [modal]="true"
    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">

    <div class="form-group">
        <label for="">Status</label>
        <select [(ngModel)]="default_status" class="form-control form-select">
            <option [value]="status.id"  *ngFor="let status of status_dropdown">{{status.value}}</option>
        </select>
    </div>
    <div class="add-listings-btn">
       <div class="form-group mt-3">
        <button class="form-control submit_btn" (click)="updateStatus()">Submit</button>
       </div>
    </div>
    </p-dialog> -->

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->