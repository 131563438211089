<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
        <!-- End Breadcrumb Area -->
        <app-cost *ngIf="userLogin.roleName == 'Admin'" (getTableData)="getTable($event)" [EditCostBreakup]="EditCostBreakup"></app-cost>        

        <div class="card cost-breaker" *ngIf="costBreakerTable?.length>0">
            <p-table #dt2 [value]="costBreakerTable" responsiveLayout="scroll"  dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]"
                [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="['costBreakupName']">
                <ng-template pTemplate="caption">
                    <span class="p-input-icon-left d-flex align-items-center">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text"
                            (input)="dt2.filterGlobal($event.target.value,
                            'contains')"
                            placeholder="Global Search" [(ngModel)]="filterval" />
                        <p-button type="button" class="ml-2" (click)="reset(dt2)"
                            icon="bx bx-reset"></p-button>
                    </span>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 250px;">Cost Breakup Name</th>
                        <th style="min-width: 250px;">Add In Total</th>
                        <th style="min-width: 250px;" *ngIf="userLogin.roleName == 'Admin'">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td style="min-width: 250px;"><span>{{customer.costBreakupName}}</span></td>
                        <td style="min-width: 250px;">
                            <button pButton pRipple type="button" icon="bx bx-check-circle"  *ngIf="customer.isAddTotal" class="p-button-rounded p-button-success p-button-text"></button>
                            <button pButton pRipple type="button" icon="pi pi-times-circle"  *ngIf="!customer.isAddTotal" class="p-button-rounded p-button-danger p-button-text"></button>
                        </td>
                        <td style="min-width: 250px; padding: 0.1rem 0.1rem!important;" *ngIf="userLogin.roleName == 'Admin'">
                            <button pButton pRipple type="button"  icon="bx bx-pencil" class="p-button-rounded p-button-primary p-button-text" (click)="EditItem(customer)"></button>
                            <button pButton pRipple type="button" icon="bx bx-trash" class="p-button-rounded p-button-danger p-button-text" (click)="confirm1(customer.costBreakupId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No cost breaker found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div> 
        <app-copyrights></app-copyrights>

</div>