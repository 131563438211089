<!-- Start Navbar Area -->
<div class="navbar-area navbar-style-two">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/main-logo.png" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img
                        src="assets/img/main-logo.png" alt="logo"></a>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div class="modal fade loginRegisterModal" id="loginRegisterModal" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal"
                aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link active" id="login-tab"
                        data-bs-toggle="tab" data-bs-target="#login"
                        type="button" role="tab" aria-controls="login"
                        aria-selected="true">Login</button>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Login & Register Modal -->