<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <!-- <div class="send-email-checker2"> -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- </div> -->
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="card">
        <p-table #dt2 [value]="customerList" dataKey="customerId" [rows]="10" responsiveLayout="scroll"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true"
            currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [filterDelay]="0"
            [globalFilterFields]="['isVerify' , 'isApproved','customerName','jdeCustNo','customerAuditRemark','crDate','userName']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                        'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th>Customer Name</th>
                    <th>IsVerify</th>
                    <th>IsApproved</th>
                    <th>JDE No.</th>
                    <th>Remark</th>
                    <th>Date</th>
                    <th>CS Team</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr class="p-selectable-row">
                    <td>{{i+1}}</td>
                    <td>
                        <span>{{customer.customerName}}</span>
                    </td>
                    <td>
                        <span>{{customer.isVerify ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{customer.isApproved != '' ? customer.isApproved : '-'}}</span>
                    </td>
                    <td>
                        <span>{{customer.jdeCustNo != '' ? customer.jdeCustNo : '-'}}</span>
                    </td>
                    <td>
                        <span>{{customer.customerAuditRemark != '' ? customer.customerAuditRemark : '-'}}</span>
                    </td>
                    
                    <td>
                        <span>{{customer.crDate  | date}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.userName}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex-grow-1"></div>
    </div>
    <!-- End -->

    <app-copyrights></app-copyrights>

</div>