<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
        </div>
        <!-- End Breadcrumb Area -->

        
    <!-- End -->   
    <div class="card">
        <p-table #dt2 [value]="suppliers" dataKey="id" [rows]="10" responsiveLayout="scroll"  [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [loading]="loading"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['name','country.name','status']">
            <ng-template pTemplate="header">
                <tr style="width: 100%;">
                    <th style="min-width: 150px;">Enquiry No.</th>
                    <th style="min-width: 220px;">Supplier Name</th>
                    <th style="min-width: 220px;">Document Name</th>
                    <th style="min-width: 150px;">Uploaded Date</th>
                    <th style="min-width: 150px;">Update Status</th>
                    <th style="min-width: 150px;">Status Name</th>
                    <th>Document</th>
                </tr>
                <tr>
                    <th style="min-width: 150px;"><p-columnFilter type="text" field="enquiryNo"></p-columnFilter></th>
                    <th style="min-width: 220px;"><p-columnFilter type="text" field="supplierName"></p-columnFilter></th>
                    <th style="min-width: 220px;"><p-columnFilter type="text" field="documentName"></p-columnFilter></th>
                    <th style="min-width: 150px;"></th>
                    <th></th>
                    <th style="min-width: 150px;"><p-columnFilter type="boolean" field="isApproved"></p-columnFilter></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <td style="min-width: 250px;">
                        <span class="image-text">{{customer.enquiryNo}}</span>
                    </td>
                    <td style="min-width: 220px;"><span>{{customer.supplierName}}</span></td>
                    <td style="min-width: 220px;">
                        <span class="image-text">{{customer.documentName}}</span>
                    </td>
                    <td style="min-width: 150px;">
                        <span class="image-text">{{customer.uploadingDate | date}}</span>
                    </td>
                    <td style="min-width: 220px;">
                        <span class="image-text">{{customer.isApproved ? 'Approved' : 'Pending'}}</span>
                    </td>
                    <td style="display: flex;">
                        <button pbutton pripple type="button" icon="bx bx-check-circle" class="p-element p-button-rounded p-button-success p-button-text p-button p-component p-button-icon-only ng-star-inserted" ng-reflect-icon="bx bx-check-circle"  (click)="updateStatus(customer , true)"><span class="p-button-icon bx bx-check-circle" aria-hidden="true"></span><span aria-hidden="true" class="p-button-label">&nbsp;</span></button>
                        <button pButton pRipple type="button" icon="pi pi-times-circle" class="p-button-rounded p-button-danger p-button-text" (click)="updateStatus(customer , false)"></button>
                    </td>
                    <td>
                        <a href="{{customer.documentPath}}" style="text-decoration: none;" download="{{customer.documentPath}}" target="_blank"><button pButton pRipple type="button" icon="bx bx-download" class="p-button-rounded p-button-primary p-button-text"></button></a>
                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No document found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div> 

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->