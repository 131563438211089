<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area --> 
     
    <div class="card" *ngIf="supplierAssignList">
        <p-table #dt2 [value]="supplierAssignList" [globalFilterFields]="['enquiryNo','itemName']" dataKey="id" [rows]="10" responsiveLayout="scroll" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [loading]="loading"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header">
                <tr style="width: 100%;">
                    <th>S. No.</th>
                    <th>Enquiry No</th>
                    <th>Enquiry Date</th>
                    <th>Status</th>
                    <th>View</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text"
                        (input)="dt2.filterGlobal($event.target.value,'contains')"
                        placeholder="Global Search" [(ngModel)]="filterval" [ngModelOptions]="{standalone: true}" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)"
                        icon="bx bx-reset"></p-button>
                </span>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr>
                    <td>{{i+1}}</td>
                    <td>
                        <span class="image-text">{{customer.enquiryNo}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.enquiryDate | date}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{!customer.isClose ? 'Open' : 'Closed'}}</span>
                    </td>
                    <td>
                        <button pButton pRipple type="button" icon="bx bx-show" *ngIf="!customer.isClose" routerLink="/supplierAssign/{{customer.enquiryNo}}" class="p-button-rounded p-button-primary
                            p-button-text pt-2"></button>
                        <button pButton pRipple type="button" icon="bx bx-show-alt" *ngIf="customer.isClose"  class="p-button-rounded p-button-primary
                            p-button-text pt-2"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div> 

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->