<!-- Start -->
<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <div class="add-listings-box1 supplierUploadDoc">
        <form [formGroup]="manufactureProcessForm" #manufacture="ngForm"
            (ngSubmit)="manufactureProcessFormSubmit(manufacture)">
            <div class="row">
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="status">Customer Name<span class="star_class">*</span></label>
                    <p-dropdown [options]="supplierList" [filter]="true" filterBy="customerName"
                        *ngIf="roleName != 'Customer'" [class.is-invalid]="manufacture.submitted &&
                    manufactureProcessForm.get('customerName').invalid" placeholder="Select Customer Name"
                        optionValue="customerId" (onChange)="setcustomerCode()" formControlName="customerName"
                        optionLabel="customerName" required></p-dropdown>
                    <ng-container *ngIf="roleName == 'Customer'">
                        <ng-container *ngFor="let supplierId of supplierList">
                            <span class="form-control"
                                *ngIf="supplierId.customerId == manufactureProcessForm.get('customerName').value">{{supplierId.customerName}}</span>
                        </ng-container>
                    </ng-container>
                    <div class="invalid-feedback"> Customer Name is Required
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="status">Document Name<span class="star_class">*</span></label>
                    <select formControlName="documentName" class="form-control form-select" [class.is-invalid]="(manufacture.submitted &&
                    manufactureProcessForm.get('documentName')?.invalid) ||
                    (manufactureProcessForm.get('documentName')?.touched &&
                    manufactureProcessForm.get('documentName')?.invalid)">
                        <option value="">Select Document Name</option>
                        <ng-container *ngFor="let document of documentList">
                            <option [value]="document.documentName">{{document.documentName}}</option>
                        </ng-container>
                    </select>
                    <!-- <input type="text" class="form-control" maxlength="35"  
                        formControlName="documentName" placeholder="Enter Document Name" [class.is-invalid]="(manufacture.submitted &&
                        manufactureProcessForm.get('documentName')?.invalid) ||
                        (manufactureProcessForm.get('documentName')?.touched &&
                        manufactureProcessForm.get('documentName')?.invalid)"
                        required> -->
                    <div class="invalid-feedback"> Document Name is Required
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="status">JDE Supplier/Customer No.<span class="star_class">*</span></label>
                    <input type="text" class="form-control" maxlength="35" readonly [class.is-invalid]="(manufacture.submitted &&
                    manufactureProcessForm.get('customerCode')?.invalid) ||
                    (manufactureProcessForm.get('customerCode')?.touched &&
                    manufactureProcessForm.get('customerCode')?.invalid)" required formControlName="customerCode"
                        placeholder="Enter Customer JDE No.">
                    <div class="invalid-feedback"> JDE No is Required </div>
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="status">Invoice No.</label>
                    <input type="text" class="form-control" maxlength="35" formControlName="invoiceNo"
                        placeholder="Enter Invoice No.">
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="status">Order No.</label>
                    <input type="text" class="form-control" maxlength="35" formControlName="orderNo"
                        placeholder="Enter Order Number">
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="status">Customer PO No.</label>
                    <input type="text" class="form-control" maxlength="35" formControlName="customerPONo"
                        placeholder="Enter PO Number">
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="status">Document Upload<span class="star_class">*</span></label>
                    <input type="file" class="form-control" [class.is-invalid]="(manufacture.submitted &&
                        manufactureProcessForm.get('documentUpload')?.invalid) ||
                        (manufactureProcessForm.get('documentUpload')?.touched &&
                        manufactureProcessForm.get('documentUpload')?.invalid)" (change)="getFile($event)" required>
                    <div class="invalid-feedback"> Document is Required
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 form-group">
                    <label for="">Current Date</label>
                    <span class="form-control">{{currentDate}}</span>
                </div>
                <div class="d-flex my-2" style="align-items: center;
                    align-items: center;">
                        <input type="checkbox" class="mr-2" formControlName="IsShow" (change)="ShowtoCustomer($event)">
                        <label for="" style="margin-bottom: 0; font-size: 15px;">Show to Customer</label>
                </div>
                <div class="add-listings-btn col-lg-4 col-md-6">
                    <div class="form-group">
                        <button type="submit" class="submit_btn mt-1 mb-2">{{submitButton}}</button>
                        <!-- <button type="button" class="mx-3 reset_btn mt-1 mb-2"
                            (click)="setFieldValues()">Reset</button> -->
                    </div>
                </div>
            </div>
        </form>

        <div class="card cost-breaker" *ngIf="manufactureTable?.length>0">
            <p-table #dt2 [value]="manufactureTable" responsiveLayout="scroll" dataKey="id" [rows]="10"
                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="['customerName', 'documentName', 'invoiceNo', 'orderNumber', 'customerPONo','jdeCustNo']">
                <ng-template pTemplate="caption">
                    <span class="p-input-icon-left d-flex align-items-center">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                            'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
                        <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                    </span>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 250px;">Customer Name</th>
                        <th style="min-width: 250px;">JDE No.</th>
                        <th style="min-width: 250px;">Document Name</th>
                        <th style="min-width: 250px;">Document Preview</th>
                        <th style="min-width: 250px;">Invoice No.</th>
                        <th style="min-width: 250px;">Order No.</th>
                        <th style="min-width: 250px;">Customer PO No.</th>
                        <th style="min-width: 250px;" *ngIf="userLogin.roleName == 'admin'">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td style="min-width: 250px;">
                                <span>{{customer.customerName ?
                                    customer?.customerName : '-'}}</span>
                        </td>
                        <td style="min-width: 250px;"><span>{{customer.jdeCustNo ? customer.jdeCustNo : '-'}}</span>
                        </td>
                        <td style="min-width: 250px;"><span>{{customer.documentName}}</span></td>
                        <!-- <td style="min-width: 250px;"><span><a [href]="customer.path" target="_blank"
                                    [download]="customer.path">Preview</a></span></td> -->
                                    <td style="min-width: 250px; ">
                                        <span style="margin-left: 25px;">
                                            <!-- Image Preview -->
                                            <a *ngIf="getFileExtension(customer.path) === 'jpg' || 
                                                  getFileExtension(customer.path) === 'png' || 
                                                  getFileExtension(customer.path) === 'jpeg'"  [href]="customer.path" target="_blank">
                                                <img [src]="customer.path" alt="Preview Image" 
                                                    style="width: auto; height: 50px; object-fit: cover;" />
                                            </a>
            
                                            <!-- PrimeNG Dialog for Image Preview -->
                                         
            
                                            <!-- PDF Preview (Opens in a new tab) -->
                                            <a *ngIf="getFileExtension(customer.path) === 'pdf'" [href]="customer.path"
                                                target="_blank" rel="noopener noreferrer">
                                                <i class="pi pi-file-pdf" style="font-size: 32px !important; color: red;"></i>
                                            </a>
            
                                            <!-- Excel File Preview (Opens in a new tab) -->
                                            <a *ngIf="getFileExtension(customer.path) === 'xlsx'" [href]="customer.path"
                                                target="_blank" rel="noopener noreferrer">
                                                <i class="pi pi-file-excel" style="font-size: 32px !important; color: green;"></i>
                                            </a>
                                        </span>
            
                                    </td>
                        <td><span>{{customer.invoiceNo ? customer.invoiceNo : '-'}}</span></td>
                        <td style="min-width: 250px;"><span>{{customer.orderNumber ? customer.orderNumber : '-'}}</span>
                        </td>
                        <td style="min-width: 250px;"><span>{{customer.customerPONo ? customer.customerPONo :
                                '-'}}</span></td>
                        <td style="padding: 0.1rem 0.1rem!important;">
                            <button pButton pRipple type="button" *ngIf="userLogin.roleName == 'Admin'"
                                icon="bx bx-trash" class="p-button-rounded p-button-danger p-button-text"
                                (click)="confirm1(customer.customerDocumentId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No document found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<!-- End -->