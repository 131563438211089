<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>

    <div class="card">
        <p-table #dt2 [value]="orderList" dataKey="clientId" [rows]="10" responsiveLayout="scroll"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true"
            currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [filterDelay]="0" [globalFilterFields]="['orderNumber','orderType','lineNumber','orderDate','requestedDate','invoiceDate','invoiceNumber','quantityOrdered','quantityShipped','paymentTerms','nextStatusDesc','billToName','reference','addressNumber', 'itemNumber','itemDesc']">
            <ng-template pTemplate="caption" *ngIf="user?.roleName !== 'Customer'">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                        'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th style="text-align: center;">Oder No.</th>
                    <th>Order Type</th>
                    <th>Customer PO</th>
                    <th>Line Number</th>
                    <th>Address Number</th>
                    <th>Customer</th>
                    <th>Order Date</th>
                    <th>Requested Date</th>
                    <th>Invoice Date</th>
                    <th>Invoice Number</th>
                    <th>Item Name</th>
                    <th>Item Desc</th>
                    <th>Quantity Ordered</th>
                    <th>Quantity Shipped</th>
                    <th>Order Status</th>
                    <th>Payment Terms</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order let-i="rowIndex">
                <tr class="p-selectable-row">
                    <td>{{i+1}}</td>
                    <td>
                        <span>{{order?.orderNumber ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.orderType ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.reference ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.lineNumber ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.addressNumber ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{ order?.billToName !== '' ? order?.billToName : '-' }}</span>
                    </td>
                    <td>
                        <span>{{ order?.orderDate ?? '-'  }}</span>
                    </td>
                    <td>
                        <span>{{order?.requestedDate  ?? '-' }}</span>
                    </td>
                    <td>
                        <span>{{ (order?.invoiceDate == 'Jan 1, 1900') ? '-' : (order?.invoiceDate ?? '-') }}</span>
                    </td>
                    
                    <td>
                        <!-- <span>{{order?.invoiceNumber ?? '-'}}</span> -->
                        <span>{{ (order?.invoiceNumber == 0) ? '-' : (order?.invoiceNumber ?? '-') }}</span>

                    </td>
                    <td>
                        <span>{{order?.itemNumber ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.itemDesc ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.quantityOrdered ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.quantityShipped ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{order?.nextStatusDesc ?? '-'}}</span>
                    </td>
                    <td>
                        <!-- <span>{{order?.paymentTerms ?? '-'}}</span> -->
                        <span>{{ (order?.paymentTerms == ' ') ? '-' : (order?.paymentTerms ?? '-') }}</span>

                    </td>
                   
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No Orders found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex-grow-1"></div>
    </div>
    <!-- End -->

    <app-copyrights></app-copyrights>
</div>