import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ConfirmationService, Message, PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit {
  supplierList: any = [];
  supplierListById: any = [];
  section_dropdown: any = [];
  document_dropdown: any = [];
  supplierId: any;
  header: any;
  displayCheque: boolean = false;
  displayYear1: boolean = false;
  displayYear2: boolean = false;
  displayYear3: boolean = false;
  displayProfile: boolean = false;
  assessmentBoolean: boolean = false;

  docObject = {
    cheque : '',
    year1File :'',
    year2File : '',
    year3File : '',
    organizationProfile : '',
    assessment : ''
  }
  myDate : any;
  display: boolean = false;
  approvalStatus: any = [];
  userroleName: any;
  msgs: Message[] = [];

  @ViewChild('cancelCheque') cancelCheque: ElementRef;
  @ViewChild('year1File') year1File: ElementRef;
  @ViewChild('year2File') year2File: ElementRef;
  @ViewChild('year3File') year3File: ElementRef;
  @ViewChild('organizationProfile') organizationProfile: ElementRef;
  @ViewChild('assessment') assessmentdoc: ElementRef;
  countryDropdown: any;
  constructor(private _apiService: ApiServiceService, public _utility: AppUtility, private primengConfig: PrimeNGConfig, private confirmationService: ConfirmationService) { }

  breadcrumb = [
    {
      title: 'Supplier List',
      subTitle: 'Dashboard'
    }
  ]


  @ViewChild ('dt2') FilteredData:Table;


  dateFilterVal:string;
  filterval: string;

  ngOnInit(): void {
    this.getStatic();
    this.myDate = new Date();
    this.header = "Supplier Detail"
    this.primengConfig.ripple = true;
    this.userroleName = this._utility.getLocalStorageDetails();
    this.getAllSupplierDetail();
    if (this.userroleName.checker1 == true) {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Verified', value: "Verified" }, { name: 'Rework', value: "Rework" }, { name: 'Rejected', value: "Rejected" } , { name: 'Reworked but not Reviewed', value: "Reworked but not Reviewed" })
    }
    else if (this.userroleName.checker2 == true) {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Approved', value: "Approved" }, { name: 'Rework', value: "Rework" }, { name: 'Rejected', value: "Rejected" } , { name: 'Reworked but not Reviewed', value: "Reworked but not Reviewed" } , { name: 'Send For Rework', value: "send For rework" } )
    }
    else {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Approved', value: "Approved" }, { name: 'Rework', value: "Rework" }, { name: 'Rejected', value: "Rejected" }, { name: 'Verified', value: "Verified" } , { name: 'Reworked but not Reviewed', value: "Reworked but not Reviewed" })
    }
  }

  getAllSupplierDetail() {
    this._utility.loader(true);
    this._apiService.getSupplier().then((res: any) => {
      console.log(res);
      this._utility.loader(false);
      if (res.success == true) {
        this.supplierList = res.returnValue;
        this.displayFinance = 'false';
      }
      else {
        this.supplierList = [];
      }

      this.supplierList.forEach((res:any)=>{
        res.supplierTypeName = res.isDomestic ? 'Foreign' : 'Domestic'
      })
    })
  }


  userCapture: any;
  readonly: boolean = false;
  showString : any;
  certificates: any = [];
  async openModal(supplierId: any, string: any) {
    let supplierManufacturingDropdown = [0];
    let supplierMaterialDropdown = [0];
    this.supplierId = supplierId;
    this.document = [];
    this.displayCheque = false;
    this.displayYear1 = false;
    this.displayYear2 = false;
    this.displayYear3 = false;
    this.displayProfile = false;
    this._utility.loader(true);
    this.supplierListById = [];
    this.document = [];
    this.selectedSections = [];
    this.supplierRemark = '';
    await this._apiService.getSupplier(supplierId)
    .then((res: any) => {
      if (res.success == true) {
        let object = {
          Mode : 'Onboardingdocument',
          cond3 : supplierId
        }
        this._apiService.dropdowndata('',object).then((res:any)=>{
          if(res.success){
            this.document_dropdown = res.returnValue;
          }

          else{
            this.document_dropdown = [];
          }
        })
        this.supplierListById = res.returnValue;
        this.certificates = res.returnValue.supplierCertificatesDetails;
        console.log(this.supplierListById);

        //assignData
        if (this.supplierListById.supplierManufacturingDetails?.length > 0) {
          this.supplierListById.supplierManufacturingDetails.forEach((res: any) => {
            supplierManufacturingDropdown?.push(res.manufacturingId);
          })
        }

        if (this.supplierListById.supplierMaterialconstructionDetails?.length > 0) {
          this.supplierListById.supplierMaterialconstructionDetails.forEach((res: any) => {
            supplierMaterialDropdown?.push(res.materialconstructionId);
          })
        }

        this.supplierListById.supplierManufacturingDropdown = supplierManufacturingDropdown;
        this.supplierListById.supplierMaterialDropdown = supplierMaterialDropdown;
        this.supplierListById.documentUploadPath = res.returnValue.documentsUploadPath;

        // ;
        this.supplierListById.date = moment(res.returnValue.gstinDate).format('MM/DD/YYYY'),
        this.supplierListById.yearOfEstablished = String(res.returnValue.yearofEstablishment)
        this.supplierListById.year1 = String(res.returnValue.year1)
        this.supplierListById.year2 = String(res.returnValue.year2)
        this.supplierListById.year3 = String(res.returnValue.year3)
        this.assessmentBoolean = res.returnValue.assessment;

        this.showString = string;
        if (string == 'show') {
          this.readonly = true;
          //checker Roles
          if ((this.userroleName.checker2 == true)) { 
            if (this.supplierListById.isApproved == 'Pending' || this.supplierListById.isApproved == 'Rework' || this.supplierListById.isApproved == '' || this.supplierListById.isApproved == 'Reworked but not Reviewed' || this.supplierListById.isApproved == 'Approved') {
              this.supplierListById.statusCapture = this.supplierListById.isApproved == '' ? 'Pending' : this.supplierListById.isApproved;
              if (this.supplierListById.isVerify == 'Pending') {
                this.userCapture = true;
              }
              else if(this.supplierListById.isApproved == 'Rework' || this.supplierListById.isApproved == 'Reworked but not Reviewed' || this.supplierListById.isApproved == 'Approved'){
                this.userCapture = false;
                this.supplierRemark = this.supplierListById.isApprovedRemark
              }

              else {
                this.userCapture = false;
                
              }
            }

            else if(this.supplierListById.isApproved == 'send For rework'){
              this.supplierListById.statusCapture = 'send For rework';
              this.supplierRemark = this.supplierListById.isApprovedRemark;
            }
            else {
              if(this.supplierListById.isApproved == 'Rejected'){
                this.userCapture = true;
                this.supplierListById.statusCapture = 'Rejected';
              }
              else{
                this.userCapture = true;
                this.supplierListById.statusCapture = 'Approved';
              }
            }
          }

          else if (this.userroleName.checker1 == true) {
            this.supplierListById.statusCapture = this.supplierListById.isVerify;
            this.userCapture = this.supplierListById.isVerify == 'Pending' || this.supplierListById.isVerify == 'Rework' || this.supplierListById.isVerify == 'Reworked but not Reviewed' ? false : true;
            if(this.supplierListById.isVerify == 'Rework' || this.supplierListById.isVerify == 'Reworked but not Reviewed'){
              this.supplierRemark = this.supplierListById.isVerifyRemark
            }
          }

          else {
            this.supplierListById.statusCapture = this.supplierListById.isApproved == 'Approved' ? this.supplierListById.isApproved : this.supplierListById.isVerify;
            this.userCapture = true;
          }
        }

        console.log(this.userroleName.roleName);
        if(this.userroleName.roleName == 'Finance'){
          this.displayFinance = 'true';
        }
        else{
          this.display = true;
        }

      }
      else {
        this.supplierListById = []
      }
      this._utility.loader(false)

      console.log(this.supplierListById);        
    })
  }

  document: any = [];
  displayFinance : string = 'false';
  supplierRemark: string = '';
  async captureStatus(supplierListById: any , string : any) {
   console.log(string , supplierListById.statusCapture);
   if(string == 'sendForrework'){
    supplierListById.isApproved = 'Rework'
    supplierListById.statusCapture = supplierListById.isApproved;
    this.supplierRemark = supplierListById.isApprovedRemark;
    string = 'Submit';
    this.supplierId = supplierListById.supplierId
   }
   if(string == 'Submit'){
    if (supplierListById.statusCapture != 'Pending' && ((supplierListById.statusCapture == 'Verified' && supplierListById.buName && supplierListById.bussinessName) || supplierListById.statusCapture == 'Rework' || (supplierListById.statusCapture == 'Approved' && supplierListById.buName && supplierListById.bussinessName) || (supplierListById.statusCapture == 'send For rework'))) {
      // if (confirm("Do you want to approve supplier status" + msg)) {
        console.log(supplierListById);
        this._utility.loader(true);
        this.display = false;
        let object1: any = [];
        if (this.document.length > 0) {
          this.document.map((res: any) => {
            object1.push({ documentId: res.id, supplierId: this.supplierId, documentName: res.value })
          })
        }
        let object = {
          supplierId: this.supplierId,
          emailAddress: supplierListById.emailAddress,
          SupplierOnboardingDetails: object1,
          supplierName : supplierListById.supplierName , 
          newSupplierName : supplierListById.supplierName ?? supplierListById.supplierName
        }

        let documentDetails : any = [];
        object1.forEach((res:any)=>{
          documentDetails.push(res.documentName);
        })
        
        if (this.userroleName.checker1 == true) {
          object['isVerifyRemark'] = this.supplierRemark,
          object['isVerify'] = supplierListById.statusCapture,
          object['checker1'] = this.userroleName.checker1,
          object['checker2'] = this.userroleName.checker2,
          object['bussinessName'] = supplierListById.bussinessName,
          object['buName'] = supplierListById.buName,
          object['userName'] = supplierListById.userName,
          object['assessment'] = this.assessmentBoolean,
          object['reviewFlag'] = this.selectedSections.length > 0 ? true : false
          object['documentFlag'] = object1.length > 0 ? true : false
          object['formDetails'] = this.selectedSections.toString()
          object['documentDetails'] = documentDetails.toString()
          object['supplierRemark'] = this.supplierRemark
        }
        else if (this.userroleName.checker2 == true) {
          object['isApprovedRemark'] = this.supplierRemark;
          object['isApproved'] = supplierListById.statusCapture;
          object['checker2'] = this.userroleName.checker2;
          object['checker1'] = this.userroleName.checker1,
          object['assessment'] = this.assessmentBoolean,
          object['userName'] = supplierListById.userName,
          object['reviewFlag'] = this.selectedSections.length > 0 ? true : false,
          object['documentFlag'] = object1.length > 0 ? true : false,
          object['formDetails'] = this.selectedSections.toString(),
          object['documentDetails'] = documentDetails.toString()
          object['supplierRemark'] = this.supplierRemark
        }

        console.log(object);
        await this._apiService.supplierDocUpload(object).then((res: any) => {
          console.log(res);
          this._utility.loader(false);
          if (res.success == true) {
            this._apiService.showMessage(res.message, "success");
            this.getAllSupplierDetail();
          }
          else {
            this._apiService.showMessage(res.message, "error");
          }
        })

        if(this.docObject.assessment){
          this._utility.loader(true);
          this.uploadAssessment();
        }
        this.getAllSupplierDetail();
    }

    else {
      // this.display = false;
      this._apiService.showMessage('Please select B.U.Name or B.U. Number or Status' , 'error')
    }
   }
   
  }

  selectedSections : any = [];
  async uploadAssessment(){
    let formData = new FormData();
    formData.append('assessment' , JSON.stringify(this.assessmentBoolean));
    formData.append('supplierId' , this.supplierListById.supplierId);
    formData.append('userName' , this.supplierListById.userName);
    if(this.docObject.assessment){
      formData.append('assessmentUpload' , this.docObject.assessment);
    }
    await this._apiService.assessmentUpload(formData).then((res:any)=>{
      console.log(res);
      this._utility.loader(false);
      if(res.success){
        this._apiService.showMessage(res.message , 'success');
        this.getAllSupplierDetail();
      }
    })
  }

  supplierFormDataList : any = [];


  stateDropdown: any = [];
  paymentDropdown: any = [];
  purposeDropdown: any = [];
  manufacturingArray: any = [];
  materialConstructionArray: any = [];
  otherCategoryArray: any = [];
  currencyDropdown: any = [];

  async getStatic() {
    this._apiService.dropdowndata('state').then((res: any) => {
      if(res.success){
        this.stateDropdown = res.returnValue;
      }
    })
    this._apiService.dropdowndata('paymentTerms').then((res: any) => {
      if(res.success){
        this.paymentDropdown = res.returnValue;
      }
    })
    this._apiService.dropdowndata('purpose').then((res: any) => {
      if(res.success){
        this.purposeDropdown = res.returnValue;
      }
    })
    this._apiService.dropdowndata('manufacturing').then((res: any) => {
      if(res.success){
        res.returnValue.forEach((res: any) => {
          this.manufacturingArray.push({
            id: res.id,
            value: res.value
          })
        })
      }
    })
    this._apiService.dropdowndata('materialconstruction').then((res: any) => {
      if(res.success){
        this.materialConstructionArray = res.returnValue;
      }
    })
    this._apiService.dropdowndata('othercategory').then((res: any) => {
      if(res.success){
        this.otherCategoryArray  = res.returnValue;
      }
    })

    this._apiService.dropdowndata('currency').then((res: any) => {
      if(res.success){
        this.currencyDropdown = res.returnValue;
      }
    })
    
    this._apiService.dropdowndata('country').then((res: any) => {
      if(res.success){
        this.countryDropdown = res.returnValue;
      }
    })

    this._apiService.dropdowndata('BussinessUnitNumber').then((res: any) => {
      console.log(res);
      if(res.success){
        this.bussinessName = res.returnValue;
      }
    })

    this._apiService.dropdowndata('BUMaster').then((res: any) => {
      if(res.success){
        this.bussinessUnit = res.returnValue;
      }
    })

    let object = {
      Cond3 : 1,
      Mode : 'CRChangeList'
    }

    await this._apiService.dropdowndata('' , object).then((res: any) => {
      if(res.success){
        if(res.success){
          this.section_dropdown = res.returnValue;
        }
      }
    })

  }


  bussinessName : any = [];
  bussinessUnit : any = [];
  getSelectedSupplier(customer) {
    console.log(customer);
    this.display = false;
    this._utility.loader(true);
    let sendForChecker2s: any = [];

    sendForChecker2s.push({
      'supplierId': customer.supplierId,
      'sendForChecker2': true,
      'supplierName': customer.supplierName,
      'userName' : customer.userName
    })

    let object: any = {};
    object.sendForChecker2s = sendForChecker2s;
    object.supplierName = customer.supplierName;
    object.userName = customer.userName;
    this._apiService.supplierUpdate(object, 'sendForChecker2').then((res: any) => {
      this._utility.loader(false);
      if (res.success == true) {
        this._apiService.showMessage(res.message, 'success');
        this.getAllSupplierDetail();
      }
      else {
        this._apiService.showMessage(res.message, 'error');
      }
    })
  }

  confirm1(customer: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Send for JDE Approval',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.getSelectedSupplier(customer);
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }


  showCurrency: boolean = false;

  setYears(string: any) {
    if (string == 'year1') {
      let year1 = this.supplierListById.year1;
      year1 = moment(year1).format('yyyy');
      this.supplierListById.year2 = String(Number(year1) - 1)
      this.supplierListById.year1 = String(Number(year1))
      this.supplierListById.year3 = String(Number(year1) - 2)
    }
    else if (string == 'year2') {
      let year2 = this.supplierListById.year2;
      year2 = moment(year2).format('yyyy');
      this.supplierListById.year1 = String(Number(year2) + 1)
      this.supplierListById.year2 = String(Number(year2))
      this.supplierListById.year3 = String(Number(year2) - 1)
    }
    else if (string == 'year3') {
      let year3 = this.supplierListById.year2;
      year3 = moment(year3).format('yyyy');
      this.supplierListById.year1 = String(Number(year3) + 2)
      this.supplierListById.year2 = String(Number(year3) + 1)
      this.supplierListById.year3 = String(Number(year3))
    }
  }

  selectManufacturingList(string:any){
    if(string == 'manufacture'){
      this.supplierListById.manufacturingProcessList = this.supplierListById.supplierManufacturingDropdown;
    }    

    else if(string == 'material'){
      this.supplierListById.materialofconstructionList = this.supplierListById.supplierMaterialDropdown;
    }

    console.log(this.supplierListById.materialofconstructionList , this.supplierListById.manufacturingProcessList);
  }

  upload_doc(event: any, string?: any, idx?: any) {
    let file = this._utility.onFileChange(event);
    console.log(file);    
    if (string == 'cancelCheque') {
      if (file == false) {
        this.docObject.cheque = null;
        this.cancelCheque.nativeElement.value = null;
      }
      else {
        this.docObject.cheque  = file;

      }
    }

    else if (string == 'organizationProfile') {
      if (file == false) {
        this.docObject.organizationProfile = null;
        this.organizationProfile.nativeElement.value = null;
      }
      else {
        this.docObject.organizationProfile = file;
      }
    }

    else if(string == 'Assessment'){
      if (file == false) {
        this.docObject.assessment = null;
        this.assessmentdoc.nativeElement.value = null;
      }
      else {
        this.docObject.assessment = file;
        if(this.supplierListById.isVerify == 'Verified' || this.supplierListById.isApproved == 'Approved'){
          this.display = false;
          this._utility.loader(true);
          this.uploadAssessment();
        }
      }
    }

    else {
      if (string == 'year1') {
        if (file == false) {
          this.docObject.year1File  = '';
          this.year1File.nativeElement.value = null;
        }
        else {
          this.docObject.year1File  = file;
        }
      }
      else if (string == 'year2') {
        if (file == false) {
          this.docObject.year2File  = '';
          this.year2File.nativeElement.value = null;
        }
        else {
          this.docObject.year2File = file;
        }
      }
      else if (string == 'year3') {
        if (file == false) {
          this.docObject.year3File = '';
          this.year3File.nativeElement.value = null;
        }
        else {
          this.docObject.year3File = file;
        }
      }
    }
  }

  reset(dt2) {
    dt2.reset();
    this.filterval = '';
    this.dateFilterVal = ''
  }

  displayJde : boolean = false;
  jdeHeader : string = 'Send to Buyer For Rework';
  jdeForm = {
    jdeNo : '',
    isApprovedRemark : '',
    jdeApproved : true,
    supplierId : '',
    supplierName : ''
  }
  jdeWindow(customer:any){
    if(this.userroleName?.roleName == 'Finance'){
      this.displayJde = true;
      this.jdeForm.supplierId = customer.supplierId;
      this.jdeForm.supplierName = customer.supplierName;
      this.jdeForm.jdeNo = customer.jdeNo;
    }
  }

  async submitJDE(form:NgForm , string ? : any){
    
  if(string == 'rework' && this.jdeForm.isApprovedRemark.length > 0){
      this._utility.loader(true);
      await this._apiService.putReworkByFinance(this.jdeForm)
      .then((res:any)=>{
        if(res.success){
          this._utility.loader(false);
          console.log(res);
          this._apiService.showMessage(res.message , 'success');
          this.displayJde = false;
          this.getAllSupplierDetail();
        }
    
        else{
          this._utility.loader(false);
          this._apiService.showMessage(res.message , 'error');
          this.displayJde = false;
          this.getAllSupplierDetail();
        }
      })
    }

    else{
      if(form.valid){
        this._utility.loader(true);
        await this._apiService.putJDEEntry(this.jdeForm)
        .then((res:any)=>{
          if(res.success){
            this._utility.loader(false);
            console.log(res);
            this._apiService.showMessage(res.message , 'success');
            this.displayJde = false;
            this.getAllSupplierDetail();
          }
  
          else{
            this._utility.loader(false);
            this._apiService.showMessage(res.message , 'error');
            this.displayJde = false;
            this.getAllSupplierDetail();
          }
        })
      }
    }

  }

  confirmDelete(supplierId: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Delete Supplier Record',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSupplier(supplierId);
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  
  customerInfo : any;
  confirmblock(customer: any) {
    let message = !customer.isBlock ? 'Are You sure you want to Block Supplier' : 'Are You sure you want to Unblock Supplier'
    this.confirmationService.confirm({
      message: message,
      header: !customer.isBlock ? 'Block Supplier Record' : 'Unblock Supplier Record',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        if(customer.isBlock){
          this.displayRemarkDialog = true;
          this.customerInfo = customer;
        }

        else{
          this.blockSupplier(customer , 'block');
        }
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }


  blockSupplier(customer? : any , string?:any){
    let object : any;
    if(string == 'block'){
      this._utility.loader(true);
      console.log(customer);
      object = {
        isBlock : !customer.isBlock,
        supplierId : customer.supplierId
      }
    }
    else{
      this.displayRemarkDialog = false;
      object = {
        isBlock : !this.customerInfo.isBlock,
        supplierId : this.customerInfo.supplierId,
        unBlockRemark : this.customerInfo.unblockRemark
      }
    }
    this._apiService.supplierBlock(object).then((res:any)=>{
      this._utility.loader(false);
      console.log(res);
      if(res.success){
        this._apiService.showMessage(res.message , 'success');

        this.getAllSupplierDetail();
      }
      else{
        this._apiService.showMessage(res.message , 'error');
      }
    })
  }


  deleteSupplier(supplierId){
    this._apiService.supplierDelete(supplierId).then((res:any)=>{
      console.log(res);
      if(res.success){
        this._apiService.showMessage(res.message , 'success');
        this.getAllSupplierDetail();
      }
      else{
        this._apiService.showMessage(res.message , 'error');
      }
    })
  }


  unblockRemark : string = '';
  displayRemarkDialog : boolean;
  callFunction(event:any){
      this._utility.loader(true);
    // this.displayFinance = 'false';
    // ;
    // console.log('event called' , event , event == 'true' , event == 'false')
    if(event == 'false'){
      this.getAllSupplierDetail();
    }

    // this._utility.loader(true);
    // if(event == 'true'){
    //   this.displayFinance = 'false';
    // }
    // else{
    //     console.log('called func')
    //     this._utility.loader(false);
    //     this.getAllSupplierDetail();
    // }
  }

  approvalChangeStatus(event:any){
    if(event.target.value != 'Verified'){
      if(this.userroleName.checker1){
        this.supplierListById.bussinessName = '';
        this.supplierListById.buName = '';
      }
    }
  }
  
}



