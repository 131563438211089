<section class="enquiry-section">
    <div class="section-heading" style="margin-top: 0!important;">
        <h4 style="margin-bottom: 0!important;">Supplier Price Comparison</h4><span>(Per Piece)</span>
    </div>

    <div class="enquiryNo">
        <span class="d-flex" style="align-items: baseline;">
            <h6 class="mx-2">Enquiry No : </h6><span style="font-size: 16px;">{{enquiryNoHeading}}</span>
        </span>
        <span class="d-flex" style="align-items: baseline;">
            <h6 class="mx-2" *ngIf="ProjectNameHeading">Project Name : </h6><span
                style="font-size: 16px;">{{ProjectNameHeading}}</span>
        </span>
        <h6 class="mx-2"></h6>
    </div>
    <div class="earning-comparison-box mt-3">
        <table class="table vanillaHandsets table-responsive" style="width: 100%;">
            <thead>
                <tr>
                    <th>Sr No</th>
                    <ng-container *ngFor="let item of comparisonHeaders">
                        <th style="white-space: nowrap;">
                            <span
                                *ngIf="item == 'Part Number' || item == 'Quantity' || item == 'Cost Breakup Name'">{{item}}</span>
                            <span *ngIf="item != 'Part Number' && item != 'Quantity' && item != 'Cost Breakup Name'"
                                style="display: flex; align-items: center;">{{_utility.getSupplierName(item)}} <a
                                    class="nav-link" style="margin-left: 5px; margin-top: 4px; cursor: pointer;">
                                    <p class="bx bx-show" style="font-size: 18px;" pTooltip="view RFQ Details"
                                        (click)="getRFQPage(item)"></p>
                                </a></span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody *ngFor="let supplierDetails of supplierQuotation | groupby : 'Part Number'; index as i">
                <tr *ngFor="let itemDetails of supplierDetails.value; index as index; last as last">
                    <td style="display: flex;"><input type="checkbox"
                            (change)="selectOneCheckbox($event ,i+1 , itemDetails)" [id]="i+1" *ngIf="index == 0"
                            class="mx-2">{{index == 0 ? i+1 : ''}}</td>
                    <ng-container *ngFor="let item of comparisonHeaders; index as comparisonIndex; last as last1">
                        <ng-container
                            *ngIf="item == 'Item Name' || item == 'Item Code' || item == 'Part Number' || item == 'Quantity'; then firstblock else secondblock"></ng-container>
                        <ng-template #firstblock>
                            <td>{{index == 0 ? itemDetails[item] : ''}}</td>
                        </ng-template>
                        <ng-template #secondblock>
                            <td
                                *ngIf="itemDetails[item] != '-zzTotal' && itemDetails[item] != 'zzzTotal Price' && itemDetails[item] != '--zTotal Tool Loan'  && itemDetails[item] != 'zzzzPaymentTerms' && itemDetails[item] != 'zzzzDeliveryNotes' ">
                                <span *ngIf="supplierDetails.value.length-4 == index">
                                    <ng-container *ngFor="let itemvalue of totalArray">
                                        <ng-container *ngIf="itemvalue.ItemId == itemDetails.ItemId;">
                                            <ng-container *ngIf="itemvalue.minAmount != null">
                                                <span [ngClass]="{my_class : itemvalue.minAmount == itemDetails[item]}"
                                                    *ngIf="itemvalue.minAmount == itemDetails[item]"
                                                    style="background-color:red; padding: 5px; color: #fff; border-radius: 5px;">{{itemDetails[item]
                                                    ? itemDetails[item] : '-'}}</span>
                                                <span *ngIf="itemvalue.minAmount != itemDetails[item]"><b>{{itemDetails[item]
                                                        ? itemDetails[item] : '-'}}</b></span>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </span>
                                <span *ngIf="supplierDetails.value.length-4 != index">
                                    <ng-container *ngIf="last">
                                        <span style="color : var(--mainColor)"><b>{{itemDetails[item] ?
                                                itemDetails[item] : '-'}}</b></span>
                                    </ng-container>
                                    <ng-container *ngIf="supplierDetails.value.length-3 == index">
                                        {{itemDetails[item] ? itemDetails[item] : '-'}}
                                    </ng-container>
                                    <ng-container *ngIf="supplierDetails.value.length-2 == index">
                                        {{itemDetails[item] ? itemDetails[item] : '-'}}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="supplierDetails.value.length-2 != index && supplierDetails.value.length-3 != index && !last">
                                        {{itemDetails[item] ? itemDetails[item] : '-'}}
                                    </ng-container>
                                </span>
                            </td>
                            <td *ngIf="itemDetails[item] == '-zzTotal'"><b>Total Unit Price(Per Piece)</b></td>
                            <td *ngIf="itemDetails[item] == 'zzzTotal Price'"><b>Total Price</b></td>
                            <td *ngIf="itemDetails[item] == '--zTotal Tool Loan'" style="color : var(--mainColor)">
                                <b>Tool , Jig and Fixture Cost </b></td>
                            <td *ngIf="itemDetails[item] == 'zzzzPaymentTerms'"><b>Payment Terms</b></td>
                            <td *ngIf="itemDetails[item] == 'zzzzDeliveryNotes'"><b>Lead Time</b></td>
                        </ng-template>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</section>


<div class="enquiry-id">
    <p-dialog header="Supplier Finalization" [(visible)]="display1" [modal]="true" [draggable]="false"
        (onHide)="onHide()" [blockScroll]="true">
        <ng-container *ngIf="!popupCheck">
            <table class="table vanillaHandsets1" style="width: 100%;">
                <thead>
                    <tr>
                        <th></th>
                        <th>Sr No</th>
                        <th>Supplier Name</th>
                        <th>Total Amount</th>
                        <th>Extra Details</th>
                    </tr>
                </thead>
                <tbody *ngFor="let supplierDetails of getEnquiryData ; index as i">
                    <tr>
                        <td><input [id]="i+1" [(ngModel)]="supplierDetails.isActive"
                                [disabled]="submitBoolean || userObject.loginId != toolsList?.clientId"
                                (change)="selectOneCheckbox($event ,i+1 , supplierDetails , 'orderDetails')"
                                type="checkbox"></td>
                        <td>{{i+1}}</td>
                        <td>{{supplierDetails.value}}</td>
                        <td><span
                                [ngClass]="{'minimum-amount' : minimumAmount == supplierDetails.amount}">{{supplierDetails.amount}}</span>
                        </td>
                        <td>
                            <button pButton pRipple type="button" style="height: 0!important;" icon="bx bx-show"
                                *ngIf="supplierDetails.paymentTerms.length > 0" (click)="showExtraInfo(supplierDetails)"
                                class="p-button-rounded p-button-primary p-button-text"
                                style="background: none!important;"></button>
                            <span *ngIf="supplierDetails.paymentTerms.length == 0" style="margin-left: 15px">-</span>
                            <a [href]="supplierDetails.documentUpload" *ngIf="supplierDetails.documentUpload.length > 0"
                                [download]="supplierDetails.documentUpload" target="_blank"
                                style="text-decoration: none;"><button pButton pRipple type="button"
                                    icon="bx bx-download" class="p-button-rounded p-button-primary p-button-text"
                                    style="background: none!important;"></button></a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="" style="font-size: 15px;"><b>Approval Status : </b></label><br>
                        <ng-container *ngIf="selectedHeads.includes(userObject.loginId)">
                            <!-- <ng-container *ngIf="(userObject.roleName == 'Bussiness head' ) || (userObject.roleName == 'Precurement head')"> -->
                            <span class="approval-status"
                                *ngIf="toolsList.status == 'Approved' || toolsList.status == 'Pending' || toolsList.status == ''">
                                <button pButton pRipple [disabled]="toolsList.status == 'Approved'" type="button"
                                    icon="pi pi-check" label="Approved"
                                    (click)="submitOrderQuantity('Approved' , getEnquiryData[0].itemId)"
                                    class="p-button-rounded"></button>
                            </span>
                            <span class="reject-status"
                                *ngIf="toolsList.status == 'Rejected' || toolsList.status == 'Pending' || toolsList.status == ''">
                                <button pButton pRipple [disabled]="toolsList.status == 'Rejected'" type="button"
                                    label="Rejected" icon="pi pi-times"
                                    (click)="submitOrderQuantity('Rejected' , getEnquiryData[0].itemId)"
                                    class="p-button-rounded mx-2"></button>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!selectedHeads.includes(userObject.loginId)">
                            <span
                                [ngClass]="{'pending-status': toolsList.status == '', 'approval-status': toolsList.status == 'Approved', 'reject-status': toolsList.status == 'Rejected'}">
                                <button pButton pRipple type="button"
                                    label="{{toolsList.status ? toolsList.status : 'Pending'}}"
                                    class="p-button-rounded"></button>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-6" *ngIf="this.userObject.loginId == toolsList?.clientId">
                    <div class="form-group">
                        <label for="" style="font-size: 15px;"><b>Head List : </b></label>
                        <p-multiSelect [filter]="false" [showToggleAll]="false"
                            [disabled]="submitBoolean || userObject.loginId != toolsList?.clientId"
                            class="multiselect-custom-virtual-scroll" [(ngModel)]="selectedHeads" [options]="headEmails"
                            placeholder="Select Head Emails" optionLabel="value" optionValue="id"></p-multiSelect>
                    </div>

                </div>
                <label for="" *ngIf="this.userObject.loginId != toolsList?.clientId"><span
                        style="font-size: 15px;">Heads :</span> <ng-container *ngFor="let head of headEmails">
                        <ng-container *ngFor="let selectedHead of selectedHeads; last as last">
                            <span style="font-size: 15px;" *ngIf="selectedHead == head.id"> {{head.value}} <span
                                    *ngIf="!last">,</span></span>
                        </ng-container>
                    </ng-container>
                </label>
            </div>
            <div class="row">
                <div class="col-lg-6 my-2">
                    <label for=""><b>Remark</b></label>
                    <textarea name="" id="" class="form-control"
                        [disabled]="userObject.loginId != toolsList?.clientId || submitBoolean || toolsList.status == 'Approved'"
                        placeholder="Enter Remark" cols="30" rows="4" [(ngModel)]="toolsList.comparisonRemark"
                        style="width: 100%; height: unset;"></textarea>
                </div>
                <div class="col-lg-6 my-2">
                    <label for=""><b>Bussiness / procurement head Remark</b></label>
                    <textarea name="" id="" class="form-control"
                        [disabled]="toolsList.status == 'Approved' || !selectedHeads.includes(userObject.loginId)"
                        placeholder="Enter Remark" cols="30" rows="4" [(ngModel)]="toolsList.headRemark"
                        style="width: 100%; height: unset;"></textarea>
                </div>
            </div>

            <div class="add-listings-btn d-flex">
                <div class="form-group">
                    <button class="submit_btn final_submit my-0" type="button"
                        *ngIf="(this.userObject.loginId == toolsList?.clientId) && !submitBoolean"
                        (click)="submitOrderQuantity('')">{{submitButton}}</button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="popupCheck">
            <span style="cursor: pointer;" (click)="popupCheck = !popupCheck"><i class='pi pi-arrow-left'></i></span>
            <ng-container *ngIf="popupValue.paymentTerms.length > 0">
                <p>Payment Terms : {{popupValue.paymentTerms}}</p>
            </ng-container>
            <ng-container *ngIf="popupValue.deliveryNotes.length > 0">
                <p>Lead Time (In Weeks) : {{popupValue.deliveryNotes}}</p>
            </ng-container>
            <ng-container *ngIf="popupValue.otherRemark.length > 0">
                <p>Other Remarks : {{popupValue.otherRemark}}</p>
            </ng-container>
        </ng-container>
    </p-dialog>
</div>



<div *ngIf="displayRFQ">
    <app-common-dashboard-popup [data]="rfqData"></app-common-dashboard-popup>
</div>