<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- end Breadcrumb -->
    <form [formGroup]="toolLoanForm" (ngSubmit)="submitForm()" #register="ngForm">
        <!-- Start -->
        <div class="add-listings-box1">
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Business Unit<span class="star_class">*</span></label>
                    <select class="form-control form-select" formControlName="bu">
                      <option selected disabled>Select Business Unit</option>
                      <ng-container *ngFor="let item of BussinessUnitNumberTable">
                        <option [value]="item.id">{{item.value}}</option>
                      </ng-container>
                    </select>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('bu')?.invalid ||
                    toolLoanForm.get('bu')?.invalid &&
                    toolLoanForm.get('bu')?.touched">Please select Business Unit</div>
                  </div>

                  <div class="col-lg-4 col-md-12 form-group">
                    <label>BU<span class="star_class">*</span></label>
                    <select class="form-control form-select" formControlName="businessUnit">
                      <option value="" selected disabled>Select BU</option>
                      <ng-container *ngFor="let item of budata">
                        <option [value]="item.id">{{item.value}}</option>
                      </ng-container>
                    </select>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('businessUnit')?.invalid ||
                    toolLoanForm.get('businessUnit')?.invalid &&
                    toolLoanForm.get('businessUnit')?.touched">Please select BU</div>
                  </div>
                  
                <div class="col-lg-4 col-md-12 form-group">
                    <label>PO Type<span class="star_class">*</span></label>
                    <select class="form-control form-select" formControlName="poType">
                        <option value="" disabled>Select PO Type</option>
                        <ng-container *ngFor="let item of poTypeData">
                            <option [value]="item.id">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('poType')?.invalid ||
                    toolLoanForm.get('poType')?.invalid &&
                    toolLoanForm.get('poType')?.touched">Please
                        select PO Type</div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>PO Number<span class="star_class">*</span></label>
                    <input class="form-control" type="text" formControlName="PoNo" placeholder="Enter PO Number"
                        autocomplete="off" [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('PoNo')?.invalid ||
                        toolLoanForm.get('PoNo')?.invalid &&
                        toolLoanForm.get('PoNo')?.touched" />
                    <div class="invalid-feedback">PO Number is Required</div>
                </div>
                <div class="col-lg-4 col-md-12 form-group " >
                    <label>Part/Drawing No<span class="star_class">*</span></label>
                    <!-- <select class="form-control form-select" formControlName="partNo"
                        (change)="getPartDrawingId($event)"> -->
                    <p-dropdown [options]="partDrawingData" [filter]="true" filterBy="partDrawingName"
                        placeholder="Select partdrawing Name" optionValue="partId" formControlName="partNo"
                        optionLabel="partDrawingName" (onChange)="getPartDrawingId($event)" class="custom-dd">

                        <option value="" disabled selected>Select Part/Drawing No</option>
                        <!-- <ng-container *ngFor="let item of partDrawingData">
                                <option value="{{item.partId}}">{{item.partDrawingName}}</option>
                            </ng-container> -->

                    </p-dropdown>

                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('partNo')?.invalid ||
                    toolLoanForm.get('partNo')?.invalid &&
                    toolLoanForm.get('partNo')?.touched">Please
                        select Part/Drawing No</div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Tool Description<span class="star_class">*</span></label>
                    <textarea name="" id="" cols="1" rows="1" [class.is-invalid]="register.submitted &&
                    toolLoanForm.get('toolDescription')?.invalid ||
                    toolLoanForm.get('toolDescription')?.invalid &&
                    toolLoanForm.get('toolDescription')?.touched" placeholder="Enter Part Description"
                        formControlName="toolDescription" class="form-control" type="text" readonly></textarea>
                    <div class="invalid-feedback">Part Description is Required </div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Currency<span class="star_class">*</span></label>
                    <select class="form-control form-select" formControlName="Currency">
                        <option value="" disabled>Select Currency</option>
                        <ng-container *ngFor="let currency of currencyDropdownValue" >
                            <option [value]="currency.id">{{currency.value}}</option>
                        </ng-container>
                    </select>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('Currency ')?.invalid ||
                    toolLoanForm.get('Currency ')?.invalid &&
                    toolLoanForm.get('Currency ')?.touched">Please
                        select Currency</div>
                </div>                
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Value<span class="star_class">*</span></label>
                    <input class="form-control" type="text" formControlName="value" placeholder="Value"
                        autocomplete="off" [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('value')?.invalid ||
                        toolLoanForm.get('value')?.invalid &&
                        toolLoanForm.get('value')?.touched" />
                    <div class="invalid-feedback">
                        Value is Required </div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Tooling Custody<span class="star_class">*</span></label>
                    <!-- <select class="form-control form-select" formControlName="toolingCustody"
                        (change)="getSupplierData($event)">
                        <option value="" disabled>Select Tooling Custody</option>
                        <ng-container *ngFor="let item of supplierList">
                            <option value="{{item.supplierId}}">{{item.supplierName}}</option>
                        </ng-container>
                    </select> -->
                    <p-dropdown [options]="supplierList" [filter]="true" filterBy="supplierName"
                        placeholder="Select tooling Custody" optionValue="supplierId" formControlName="toolingCustody"
                        optionLabel="supplierName" (onChange)="getSupplierData($event)"  class="custom-dd">

                        <option value="" disabled>Select Tooling Custody</option>
                    </p-dropdown>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('toolingCustody')?.invalid ||
                    toolLoanForm.get('toolingCustody')?.invalid &&
                    toolLoanForm.get('toolingCustody')?.touched">Please
                        select Tooling Custody</div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Location<span class="star_class">*</span></label>
                        <ng-container>
                            <input type="text" formControlName="location" placeholder="Location" class="form-control" readonly/>
                            <!-- <span class="form-control" formControlName="location" placeholder="Location"
                            autocomplete="off">{{locationVal}}</span>-->
                        </ng-container>
                    <!-- <div class="invalid-feedback">
                        location is Required </div> -->
                </div>
                <!-- <div class="col-lg-4 col-md-12 form-group">
                    <label>Tool Loan Agreement Signed or Not. <span class="star_class">*</span></label>
                    <div style="display: flex;">
                        <div style="display: flex; align-items: center; margin: 0 5px;">
                            <input type="radio" value="yes" for="yes" (change)="agreementSigned($event)"
                                formControlName="ToolLoanAgreementSigned" autocomplete="off" /> <span
                                style="margin: 0 2px;">Yes</span>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <input type="radio" value="no" for="no" (change)="agreementSigned($event)"
                                formControlName="ToolLoanAgreementSigned" autocomplete="off" /><span
                                style="margin: 0 2px;">No</span>
                        </div>
                    </div>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('ToolLoanAgreementSigned ')?.invalid ||
                    toolLoanForm.get('ToolLoanAgreementSigned ')?.invalid &&
                    toolLoanForm.get('ToolLoanAgreementSigned ')?.touched">Please
                        select Tooling Custody</div>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="showLinkInput">
                    <label>Link for Tool Loan Agreement <span class="star_class">*</span></label>
                    <input class="form-control" type="text" formControlName="LinkorToolLoanAgreement" placeholder="Enter LinkorToolLoanAgreement "
                        autocomplete="off" [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('LinkorToolLoanAgreement ')?.invalid ||
                        toolLoanForm.get('LinkorToolLoanAgreement ')?.invalid &&
                        toolLoanForm.get('LinkorToolLoanAgreement ')?.touched" />
                    <div class="invalid-feedback">
                        Link for Tool Loan Agreement is Required </div>
                </div>
                 -->
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Tool Type<span class="star_class">*</span></label>
                    <div style="display: flex;">
                        <div style="display: flex; align-items: center; margin: 0 5px;">
                            <input type="radio" value="capital" for="capital" (change)="getToolType($event)"
                                formControlName="capital" autocomplete="off" /> <span
                                style="margin: 0 2px;">Capital</span>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <input type="radio" value="expense" for="expense" (change)="getToolType($event)"
                                formControlName="capital" autocomplete="off" /><span
                                style="margin: 0 2px;">Expense</span>
                        </div>
                    </div>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('capital')?.invalid ||
                    toolLoanForm.get('capital')?.invalid &&
                    toolLoanForm.get('capital')?.touched">Please
                        select Tool Type</div>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="this.role.roleName === 'Finance'">
                    <label>Asset No.<span class="star_class">*</span></label>
                    <input class="form-control" type="text" formControlName="capitalAssetNo" placeholder="Enter Asset No."
                        autocomplete="off" [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('capitalAssetNo')?.invalid ||
                        toolLoanForm.get('capitalAssetNo')?.invalid &&
                        toolLoanForm.get('capitalAssetNo')?.touched" />
                    <div class="invalid-feedback">
                        Asset No. is Required </div>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="showExpenseInput">
                    <label>Expense<span class="star_class">*</span></label>
                        <ng-container>
                            <input type="text" class="form-control"  placeholder="Expense"
                            autocomplete="off" formControlName="idexAssetNo" [value]="code" readonly>
                        </ng-container>
                    <div class="invalid-feedback">
                        Expense is Required </div>
                </div>
                <!-- <div class="col-lg-4 col-md-12 form-group">
                    <label>Die Shift<span class="star_class">*</span></label>
                    <div style="display: flex;">
                        <div style="display: flex; align-items: center; margin: 0 5px;">
                            <input type="radio" [value]="true" for="yes" disabled
                              autocomplete="off" /> <span
                                style="margin: 0 2px;">Yes</span>
                     </div>
                        <div style="display: flex; align-items: center;">
                            <input type="radio" [value]="false" for="no"
                              autocomplete="off" checked /><span
                                style="margin: 0 2px;">No</span>
                        </div>
                    </div>
                    <div class="error" *ngIf="register.submitted &&
                    toolLoanForm.get('dieShift')?.invalid ||
                    toolLoanForm.get('dieShift')?.invalid &&
                    toolLoanForm.get('dieShift')?.touched">Please
                        select Die Shift</div>
                </div> -->
                <!-- <div class="col-lg-4 col-md-12 form-group">
                    <label>Shift from Supplier/Location<span class="star_class">*</span></label>
                    <input class="form-control" type="text" formControlName="shiftFSupplier" placeholder="Enter shift from supplier"
                        autocomplete="off" [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('shiftFSupplier')?.invalid ||
                        toolLoanForm.get('shiftFSupplier')?.invalid &&
                        toolLoanForm.get('shiftFSupplier')?.touched" />
                    <div class="invalid-feedback">
                        Shift from Supplier is Required </div>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Shift to Supplier/Location<span class="star_class">*</span></label>
                    <input class="form-control" type="text" formControlName="shiftTSupplier" placeholder="Enter shift to supplier"
                        autocomplete="off" [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('shiftTSupplier')?.invalid ||
                        toolLoanForm.get('shiftTSupplier')?.invalid &&
                        toolLoanForm.get('shiftTSupplier')?.touched" />
                    <div class="invalid-feedback">
                        Shift to Supplier is Required </div>
                </div> -->
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Remarks<span class="star_class">*</span></label>
                    <textarea class="form-control" type="text" formControlName="remarks" placeholder="Enter remarks"
                        autocomplete="off" [class.is-invalid]="register.submitted &&
                        toolLoanForm.get('remarks')?.invalid ||
                        toolLoanForm.get('remarks')?.invalid &&
                        toolLoanForm.get('remarks')?.touched"></textarea>
                    <div class="invalid-feedback">
                        Remarks are Required </div>
                </div>
            </div>
            <div class="form-group" >
                <button type="submit" class="submit_btn">Submit</button>
                
            </div>
        </div>
    </form>

</div>