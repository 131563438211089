import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-enquiry-comparison-by-id',
  templateUrl: './enquiry-comparison-by-id.component.html',
  styleUrls: ['./enquiry-comparison-by-id.component.scss']
})
export class EnquiryComparisonByIdComponent implements OnInit {

  constructor(private router: ActivatedRoute, private _apiService: ApiServiceService, private route: Router, public _utility: AppUtility) { }

  ngOnInit(): void {
    let slug = this.router.snapshot.params;
    this.userObject = this._utility.getLocalStorageDetails();
    this.submitButton = 'Submit';
    console.log(this.submitButton);
    this.getSupplierByItemId(slug);
    this.getHeadEmail();
  }


  itemName: string = '';
  quantity: any;
  userObject: any;
  comparisonHeaders: any = [];
  supplierQuotation: any = [];
  totalArray: any = [];
  display1: boolean = false;
  popupCheck: boolean = false;
  enquiryNoHeading: string = '';
  ProjectNameHeading: string = '';
  Currency: string = '';
  itemObject: any;
  submitButton: string;
  getSupplierByItemId(enquiry: any) {
    console.log(enquiry);
    this._utility.loader(true);
    this.supplierQuotation = [];
    this.comparisonHeaders = [];

    // api call
    let object = {
      enquiryId: enquiry.enquiryId,
      itemId: enquiry.itemId
    }


    this.itemObject = {
      enquiryId: enquiry.enquiryId,
      itemId: enquiry.itemId
    };

    this._apiService.getJson(object).then((res: any) => {
      this._utility.loader(false);
      if (res.Status == true) {
        let allData = JSON.parse(res.ReturnValue.m_StringValue);
        this.supplierQuotation = allData[0];

        allData[1].forEach((res: any) => {
          this.supplierQuotation.push(res);
        })
        this.Currency = this.supplierQuotation[0].Currency;
        this.enquiryNoHeading = this.supplierQuotation[0]['Enquiry No'];
        this.ProjectNameHeading = this.supplierQuotation[0]['Project Name'];
        Object.keys(this.supplierQuotation[0]).forEach((data: any) => {
          if (!(['Srno', 'PurchaseEnquiryCompareId', 'Enquiry No', 'Currency', 'Project Name', 'ItemWiseClose', 'EnquiryItemId', 'EnquiryId', 'HSNName', 'UnitName', 'OrderQuantity', 'TaxId', 'ItemId'].includes(data))) {
            this.comparisonHeaders.push(data)
          }
        })

        allData[2].forEach((res: any) => {
          this.supplierQuotation.push(res);
        })
        allData[3].forEach((res: any) => {
          this.supplierQuotation.push(res);
        })

        this.getMinimumAmount(allData[0])

      }
      else {
        this.supplierQuotation = [];
      }
      // this.display = true;
    })
  }

  getMinimumAmount(allData0: any) {
    allData0.forEach((data: any) => {
      if (data['Cost Breakup Name'] == 'zzzTotal Price') {
        this.totalArray = [];
        let totalAmount: number = 0;
        let count = 0;
        Object.keys(data).forEach((res: any) => {
          if (data[res]) {
            if (!['Srno', 'PurchaseEnquiryCompareId', 'Currency', 'ItemWiseClose', 'Enquiry No', 'Project Name', 'EnquiryItemId', 'EnquiryId', 'HSNName', 'UnitName', 'OrderQuantity', 'TaxId', 'ItemId', 'Cost Breakup Name', 'Item Code', 'Part Number', 'Quantity'].includes(res)) {
              if (count == 0) {
                totalAmount = data[res]
              }
              else {
                if (totalAmount > data[res]) {
                  totalAmount = data[res];
                }
              }
              count = count + 1;
            }

            data.minAmount = totalAmount;
          }
        })

        this.totalArray.push(data);
        totalAmount = 0;
      }
    })
  }


  getHeadsEmailAndIds() {
    ;
    this.selectedHeadEmails = [];
    if (this.selectedHeads.length > 0) {
      this.headEmails.forEach((res: any) => {
        this.selectedHeads.forEach((resp: any) => {
          if (resp == res.id) {
            this.selectedHeadEmails.push(res.value);
          }
        })
      })
    }


  }

  selectedHeadEmails: any = [];
  selectedHeadsIds: any = [];
  submitOrderQuantity(string?: any, itemId?: any) {
    console.log(this.supplierName, this.emailAddress, this.toolsList, this.selectedHeads)
    let submitOrderData: any = [];
    if (((this.selectedHeads.length > 0 && this.userObject.loginId == this.toolsList?.clientId) || this.selectedHeads.includes(this.userObject.loginId))) {
      this.getHeadsEmailAndIds();
      for (let i = 0; i < this.getEnquiryData.length; i++) {
        let dataObject: any = {};
        dataObject = {
          purchaseEnquiryCompareId: this.getEnquiryData[i]?.id,
          EnquiryId: this.selectedItem.EnquiryId,
          comparisonRemark: this.toolsList.comparisonRemark,
          status: itemId == this.getEnquiryData[i]?.itemId ? string : '',
          isActive: this.supplierId == this.getEnquiryData[i]?.supplierId ? true : false,
          EnquiryItemId: this.selectedItem.EnquiryItemId,
          UnitName: this.selectedItem.UnitName,
          quantity: this.selectedItem.Quantity,
          supplierId: this.getEnquiryData[i]?.supplierId,
          itemId: this.getEnquiryData[i]?.itemId,
          headRemark: this.toolsList.headRemark,
          apqpRequire: this.toolsList.apqpRequire,
          emailAddress: this.emailAddress,
          emailId: this.selectedHeads.toString(),
          ppapRequire: this.toolsList.ppapRequire
        }
        submitOrderData.push(dataObject);
      }

      if (submitOrderData.length > 0) {
        if (this.supplierId) {
          this._utility.loader(true);
          this.display1 = false;
          let data: any = {};
          data.purchaseEnquiryCompareDetails = submitOrderData,
            data.EnquiryId = submitOrderData[0]?.EnquiryId,
            data.itemId = this.getEnquiryData[0]?.itemId
          data.itemName = this.itemName,
            data.supplierName = this.supplierName,
            data.enquiryNo = this.enquiryNoHeading,
            data.userName = this.userName,
            data.emailId = this.selectedHeads.toString(),
            data.status = string,
            data.emailAddress = this.emailAddress,
            data.headEmail = this.selectedHeadEmails.toString(),
            data.headRemark = this.toolsList.headRemark,
            data.comparisonRemark = this.toolsList.comparisonRemark,
            data.roleName1 = this.userObject?.roleName
          this._apiService.submitOrderQuantity(data)
            .then((res: any) => {
              if (res.success == true) {
                window.scroll(0, 0);
                this.display1 = false;
                this._apiService.showMessage(res.message, 'success');
                let slug = this.router.snapshot.params;
                this.getSupplierByItemId(slug);
                this.route.navigateByUrl('/enquiryPurchase/openEnquiry')
              }

              else {
                window.scroll(0, 0)
                this.display1 = false;
                this._apiService.showMessage(res.message, 'error');
              }
            })
        }

        else {
          this._utility.loader(false);
          this._apiService.showMessage('Please Select Atleast a Supplier', 'error')
        }
      }

      else {
        this.display1 = false;
      }
    }

    else {
      this._apiService.showMessage('Please Select Atleast one head for further approval', 'error')
    }

  }

  popupValue: any;
  showExtraInfo(data: any) {
    console.log(data);
    this.popupValue = data;
    this.popupCheck = true
  }


  selectOneCheckbox(event: any, id: any, itemDetails: any, string: any) {
    if (!string) {
      if (event.target.checked) {
        const checkboxes = document.querySelectorAll('.vanillaHandsets input[type="checkbox"]');
        checkboxes.forEach((item) => {
          const item1 = item as HTMLInputElement;
          if (parseInt(item.id, 10) !== parseInt(id, 10)) {
            item1.checked = false;
          }
        });
        console.log(itemDetails);
        this._utility.loader(true);
        this.itemName = itemDetails['Part Number'];
        this.getItemEnquiryData(itemDetails);
      }

      else {
        this.display1 = false;
      }
    }
    else {
      if (event.target.checked) {
        console.log(itemDetails);
        this.supplierId = itemDetails.supplierId;
        this.userName = itemDetails.userName;
        this.emailAddress = itemDetails.emailAddress;
        this.supplierName = itemDetails.value;
        const checkboxes = document.querySelectorAll('.vanillaHandsets1 input[type="checkbox"]');
        checkboxes.forEach((item) => {
          const item1 = item as HTMLInputElement;
          if (parseInt(item.id, 10) !== parseInt(id, 10)) {
            item1.checked = false;
          }
        });
      }

      else {
        this.supplierId = 0;
        this.userName = '';
        this.emailAddress = '';
        this.supplierName = '';
      }
    }
  }


  supplierId: any;
  supplierName: any;
  userName: any = '';
  emailAddress: any = '';
  onHide() {
    this.popupCheck = false;
    const checkboxes = document.querySelectorAll('.vanillaHandsets input[type="checkbox"]');
    checkboxes.forEach((item) => {
      const item1 = item as HTMLInputElement;
      item1.checked = false;
    });
  }



  getEnquiryData: any = [];
  toolsList: any = [];
  selectedItem: any = {};
  submitBoolean: boolean = false;
  enquiryClosed: boolean = false;
  minimumAmount: number = 0;
  getItemEnquiryData(itemDetails: any) {
    this.minimumAmount = 0;
    this.selectedItem = itemDetails;
    let object = {
      Mode: 'enquirywiseSupplier',
      Cond3: itemDetails.EnquiryId,
      supplierId: itemDetails.ItemId
    }
    this._apiService.dropdowndata('', object).then((res: any) => {
      console.log(res.returnValue[0]?.cond2);
      this._utility.loader(false);
      if (res.success == true) {
        this.getEnquiryData = res.returnValue;
        let allHeads = res.returnValue[0]?.cond2.split(',');
        console.log(allHeads);
        if (allHeads[0] != '0') {
          this.selectedHeads = allHeads.map(str => {
            return parseInt(str, 10);
          });
        }
        this.toolsList = this.getEnquiryData[0];
        this.display1 = true;
        this.submitBoolean = res.returnValue[0]?.status == 'Approved' ? true : false;
        this.minimumAmount = res.returnValue[0]?.amount;
        res.returnValue.forEach((resp: any) => {
          if (this.minimumAmount > resp.amount && resp.amount > 0) {
            this.minimumAmount = resp.amount;
          }
          if (resp.isActive) {
            this.supplierId = resp.supplierId;
            this.supplierName = resp.value,
              this.userName = resp.userName,
              this.emailAddress = resp.emailAddress
          }

          console.log("this.selectedHeads", this.selectedHeads);
        })
      }
    })
  }

  headEmails: any = [];
  selectedHeads: any = [];
  getHeadEmail() {
    this._apiService.dropdowndata('headEmail').then((res: any) => {
      console.log(res);
      this.headEmails = res.returnValue;
    })
  }

  displayRFQ: boolean = false;
  rfqData: any = {};
  getRFQPage(item: any) {
    // console.log(item);
    this.displayRFQ = true;
    this.rfqData = {
      supplierId: item.split('|')[1],
      itemId: this.itemObject.itemId,
      enquiryId: this.itemObject?.enquiryId,
      enquiryNo: this?.enquiryNoHeading,
    }

    console.log(this.rfqData);
  }
}
