<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="add-listings-box1">
        <form [formGroup]="apqpGroup" #apqpForm="ngForm" (ngSubmit)="getAPQPList()">
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="status">Enquiry No.<span class="star_class">*</span></label>
                    <select class="form-control form-select" required 
                        formControlName="enquiryId" (change)="callFunctionBasedOnRole($event)"
                        [class.is-invalid]="apqpForm.submitted && apqpGroup.get('enquiryId').invalid"
                        autocomplete="off" required>
                        <option value="" disabled>Select Enquiry No</option>
                        <ng-container *ngFor="let enquiry of enquiry_dropdown; let i = index">
                            <option [value]="i">{{enquiry.value}}</option>
                        </ng-container>
                    </select>
                    <div class="invalid-feedback">Enquiry no is required!</div>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="roleName != 'Supplier'">
                    <label for="status">Suppliers<span class="star_class">*</span></label>
                    <p-dropdown [options]="supplierList"
                        [filter]="true" filterBy="value" placeholder="Enter Supplier Name"
                        [class.is-invalid]="apqpForm.submitted && apqpGroup.get('supplierId2').invalid"
                        optionId="supplierId" formControlName="supplierId2"
                        optionLabel="value" required></p-dropdown>
                    <div class="invalid-feedback">Supplier is required</div>
                </div>
                <div class="add-listings-btn col-lg-4 col-md-12 form-group" *ngIf="roleName != 'Supplier'">
                    <div class="submit_form_group">
                        <button type="submit">Submit</button>
                    </div>
                </div>

                <ng-container *ngIf="apqpGroup.get('enquiryId').value">
                    <div class="col-lg-4 col-md-12 form-group" *ngIf="ppapData[0]?.documentPath">
                        <label for="account">Uploaded PPAP Document<span
                                class="star_class">*</span></label>
                        <label><a [href]="ppapData[0]?.documentPath"
                                target="_blank"
                                [download]="ppapData[0]?.documentPath">Preview</a><span
                                style="cursor: pointer;" *ngIf="roleName == 'Supplier'" (click)="updateFile()">&nbsp; x</span></label>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group" *ngIf="!(ppapData[0]?.documentPath) && apqpGroup.get('supplierId').value">                                                                                                                        
                        <label for="account">Upload PPAP Document<span
                                class="star_class">*</span></label>
                        <input class="form-control" type="file"
                            (change)="upload_doc($event , ppapData)"
                            autocomplete="off" required />
                    </div>
                </ng-container>

                <div class="col-lg-4 form-group" *ngIf="ppapData[0]?.docUrl">
                    <label for="account">Download PPAP Document</label>
                    <label><a [href]="ppapData[0]?.docUrl" target="_blank"
                        [download]="ppapData[0]?.docUrl">download PPAP</a></label>
                </div>
            </div>
        </form>
    </div>
    <!-- End -->

    <div class="add-listings-box1" *ngIf="enquiryppapData.length > 0">
        <div class="card">
            <p-table #dt3 [value]="enquiryppapData"
                dataKey="id" responsiveLayout="scroll" [rows]="10"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,20,30,50]"
                [paginator]="false" currentPageReportTemplate="Showing
                {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="['name','country.name','status']">
                <ng-template pTemplate="header">
                    <tr style="width: 100%;">
                        <th>S.No.</th>
                        <th style="min-width: 200px;">Part Name</th>
                        <th style="min-width: 200px;">MOQ</th>
                        <th style="min-width: 200px;">Material of Construction</th>
                        <!-- <th style="min-width: 200px;">Other Category</th> -->
                        <th style="min-width: 180px;">Raw Part Weight (Kg)</th>
                        <th style="min-width: 180px;">Finish Part Weight (Kg)</th>
                        <th>Volume</th>
                        <th style="min-width: 180px;">Engineering Drawing</th>
                        <th style="min-width: 180px;">View PPAP List</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer let-i="rowIndex">
                    <tr>
                        <td>{{i+1}}</td>
                        <td style="min-width: 100px;" ><span>{{customer.itemName}}</span></td>
                        <td>
                            <span class="image-text">{{customer.quantity}}</span>
                        </td>
                        <td style="min-width: 200px;">
                            <span class="image-text">{{customer.materialofconstructionList}}</span>
                        </td>
                        <td  style="min-width: 180px;">
                            <span class="image-text">{{customer?.rawPartWeight}}</span>
                        </td>
                        <td  style="min-width: 180px;">
                            <span class="image-text">{{customer?.finishPartWeight}}</span>
                        </td>
                        <td>
                            <span class="image-text">{{customer.volume}}</span>
                        </td>
                        <td>
                            <button pButton pRipple type="button"
                            icon="bx bx-show" (click)="getAllItemDrawing(customer.itemId)" class="p-button-rounded p-button-primary
                            p-button-text"></button>
                        </td>
                        <td>
                            <button pButton pRipple type="button"
                            icon="bx bx-show" (click)="getAPQPList()" class="p-button-rounded p-button-primary
                            p-button-text"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No item found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <h6 style="text-align: center;" *ngIf="ppapData.length> 0"><span>Part Number : {{ this.selectItemName}}</span></h6>
    <span style="text-align: right;">{{lastUploaded}}</span>
    <div class="card px-3 py-2" *ngIf="ppapData.length > 0">
        <h5>PPAP List</h5>
        <p-table [value]="ppapData" dataKey="index" editMode="row"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 100px;">S.No</th>
                    <th style="min-width: 250px;">Requirement</th>
                    <th style="min-width: 100px;">Format No.</th>
                    <th style="min-width: 150px; text-align: center;">Required (Y/N) <br> <input type="checkbox" [disabled]="roleName == 'Supplier'"  [(ngModel)]="allCheckboxRequired" (change)="selectAllCheckbox($event , 'required')"></th>
                    <th style="min-width: 150px; text-align: center;">Received(Y/N) <br> <input type="checkbox" [disabled]="roleName == 'Supplier'" [(ngModel)]="allDocCheckbox" (change)="selectAllCheckbox($event , 'received')"></th>
                    <th style="min-width: 150px;">Remarks</th>
                    <!-- <th></th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-editing="editing"
                let-ri="rowIndex">
                <tr [pEditableRow]="product">
                    <td style="min-width: 100px;">{{ri+1}}</td>
                    <td style="min-width: 200px;">
                        {{product.requirement}}
                    </td>
                    <td style="min-width: 200px;">
                        {{product.formatNo}}
                    </td>
                    <td style="text-align: center!important;" class="form-group">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input type="checkbox" name="" id="" [disabled]="roleName == 'Supplier'"
                                [(ngModel)]="product.require">
                                <!-- <select class="form-control form-select"
                                    [disabled]="roleName == 'Supplier'"
                                    [(ngModel)]="product.require">
                                    <option value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="No">No</option>
                                </select> -->
                            </ng-template>
                            <ng-template pTemplate="output">
                                <input type="checkbox" (change)="selectAllCheckbox($event , 'requiredone')" [disabled]="roleName == 'Supplier'"
                                [(ngModel)]="product.require">
                                <!-- <select class="form-control form-select"
                                [disabled]="roleName == 'Supplier'"
                                [(ngModel)]="product.require">
                                <option value="">Select</option>
                                <option value="yes">Yes</option>
                                <option value="No">No</option>
                            </select> -->
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="text-align: center!important;" class="form-group">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input type="checkbox" name="" id="" [disabled]="roleName == 'Supplier'"
                                [(ngModel)]="product.received">
                                <!-- <select class="form-control form-select"
                                    [disabled]="roleName == 'Supplier'"
                                    [(ngModel)]="product.received">
                                    <option value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="No">No</option>
                                </select> -->
                            </ng-template>
                            <ng-template pTemplate="output">
                                <input type="checkbox" (change)="selectAllCheckbox($event , 'receivedone')"  name="" id="" [disabled]="roleName == 'Supplier'"
                                [(ngModel)]="product.received">
                                <!-- <select class="form-control form-select"
                                    [disabled]="roleName == 'Supplier'"
                                    [(ngModel)]="product.received">
                                    <option value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="No">No</option>
                                </select> -->
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input type="text" class="form-control" placeholder="Enter Remarks" [(ngModel)]="product.remarks">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <textarea class="form-control" placeholder="Enter Remarks" [(ngModel)]="product.remarks" name="" id="" cols="30" rows="10"></textarea>
                                <!-- <input type="text" class="form-control" placeholder="Enter Remarks" [(ngModel)]="product.remarks"> -->
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="add-listings-btn m-0">
                            <button class="submit_btn m-0"
                                *ngIf="ppapData.length-1 == ri && ((ppapData[0]?.ppapId > 0 && apqpGroup.get('supplierId').value) || apqpGroup.get('supplierId2').value)" 
                                (click)="onRowEditSave()" type="submit">Submit</button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->
