<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>   
    </div>
    <div class="text-right add-listings-btn" *ngIf="supplierList.length == 0">
        <div class="form-group">
            <button type="submit" class="submit_btn m-0"  (click)="editQuotation()">Edit Quotation</button>
        </div>
    </div>
    <!-- End Breadcrumb Area -->



    <form [formGroup]="itemEnquiryForm" (ngSubmit)="submitEnquiryForm()">
        <!-- Start -->
        <div class="add-listings-box1">
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Enquiry No.<span class="star_class">*</span></label>
                    <input type="text" class="form-control" style="width: 100%;"
                        formControlName="enquiryNo">
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Enquiry Date<span class="star_class">*</span></label>
                    <p-calendar formControlName="enquiryDate" tabindex="1"
                        [showIcon]="true" placeholder="Select Date"></p-calendar>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label>Project Name<span class="star_class">*</span></label>
                    <input type="text" class="form-control" style="width: 100%;"
                        formControlName="projectName">
                </div>
            </div>
        </div>
        <!-- End -->

        <!-- Start -->
        <div class="add-listings-box1">
            <div formArrayName="itemEnquiryArray">
                <div class="card">
                    <p-table #dt3 [value]="getItemEnquiryDataArray().controls"
                        dataKey="id" responsiveLayout="scroll" [rows]="10"
                        [showCurrentPageReport]="true"
                        [rowsPerPageOptions]="[10,20,30,50]"
                        [paginator]="false" currentPageReportTemplate="Showing
                        {first} to {last} of {totalRecords} entries">
                        <ng-template pTemplate="header">
                            <tr style="width: 100%;">
                                <th></th>
                                <th>S.No.</th>
                                <th>Part Number</th>
                                <th>MOQ</th>
                                <th style="min-width: 200px;">Material of Construction</th>
                                <th>Raw Part Weight (Kg)</th>
                                <th>Finish Part Weight (Kg)</th>
                                <th>Additional Notes</th>
                                <th>Unit Name</th>
                                <th>Volume</th>
                                <th>APQP Require</th>
                                <th>PPAP Require</th>
                                <th>Machining</th>
                                <th>Coating</th>
                                <th>Engineering Drawing</th>
                                <th>Other Drawing</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-customer let-i="rowIndex">
                            <tr>
                                <td (click)="getSelectedItems($event , customer.value , i)"><input type="checkbox"></td>
                                <td>{{i+1}}</td>
                                <td style="min-width: 100px;" ><span>{{customer.value?.itemName}}</span></td>
                                <td>
                                    <span class="image-text">{{customer.value?.quantity}}</span>
                                </td>
                                <td style="min-width: 200px;">
                                    <span class="image-text">{{customer.value?.materialOfConstruction}}</span>
                                </td>
                                <td  style="min-width: 180px;">
                                    <span class="image-text">{{customer?.value?.rawPartWeight}}kg</span>
                                </td>
                                <td  style="min-width: 180px;">
                                    <span class="image-text">{{customer?.value?.finishPartWeight}}kg</span>
                                </td>
                                <td  style="min-width: 180px;">
                                    <span class="image-text" style="margin-left: 40px;">{{customer?.value?.enquiryDesc ? customer?.value?.enquiryDesc : '-'}}</span>
                                </td>
                                <td  style="min-width: 180px;">
                                    <span class="image-text">{{customer?.value?.unitName}}</span>
                                </td>
                                <td>
                                    <span class="image-text">{{customer.value?.volume ?? ''}}</span>
                                </td>
                                <td>
                                    <span class="image-text"><input type="checkbox" disabled [checked]="customer.value.apqpRequire"></span>
                                </td>
                                <td>
                                    <span class="image-text"><input type="checkbox" disabled [checked]="customer.value.ppapRequire"></span>
                                </td>
                                <td>
                                    <span class="image-text"><input type="checkbox" disabled [checked]="customer.value.machining"></span>
                                </td>
                                <td>
                                    <span class="image-text"><input type="checkbox" disabled [checked]="customer.value.coating"></span>
                                </td>
                                <td>
                                    <button pButton pRipple type="button"
                                    icon="bx bx-image" (click)="getAllItemDrawing(customer.value)" class="p-button-rounded p-button-primary
                                    p-button-text"></button>
                                </td>
                                <td>
                                    <button pButton pRipple type="button"
                                    icon="bx bx-image" (click)="getOtherDocument(customer.value)" class="p-button-rounded p-button-primary
                                    p-button-text"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">No item found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <!-- End -->

        <!-- Start -->
        <div class="add-listings-box1">
            <div class="col-lg-12 col-md-12 form-group" *ngIf="assignCostBreakupDetails.length > 0">
                <label style="font-size: 18px;"><b>Costbreakup Details</b></label>
                <div class="d-flex" style="max-width: 100%; flex-wrap: wrap;">
                    <div *ngFor="let status of assignCostBreakupDetails; let i = index;">
                        <div *ngIf="status.costBreakupName != ''" style="display: flex; align-items: flex-end; margin: 0 10px 0 0;">
                            <label for="status">{{i+1}} . {{status.costBreakupName}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End -->

        <!-- Start -->
        <div class="add-listings-box1" >
            <div class="row">
                <div class="col-lg-6 col-md-12 form-group">
                    <div style="display: flex; justify-content: space-between;
                        align-items: center;">
                        <label>Supplier Assign<span class="star_class">*</span></label>
                    </div>

                    <!-- <span *ngIf="selectSupplier.length > 0">
                        <ng-container *ngFor="let supplier of selectSupplier">
                            {{supplier.supplierName}}
                        </ng-container>
                    </span> -->
                    
                    <div formArrayName="supplierDetailArray">
                        <div class="form-group">
                            <p-multiSelect
                                [ngModelOptions]="{standalone: true}"
                                [showToggleAll]="false"
                                class="multiselect-custom-virtual-scroll"
                                [options]="getSupplierDataArray().value"
                                [(ngModel)]="selectSupplier" placeholder="Select Supplier Name" optionLabel="supplierName"></p-multiSelect>
                        </div>
                    </div>
                </div>
                <div class="add-listings-btn col-lg-2 col-md-6">
                    <div class="submit-toggle">
                        <button type="submit" class="submit_btn">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End -->
        <!-- Start -->
        <div class="add-listings-box1" *ngIf="supplierList.length>0">
            <h6>Assigned Supplier List</h6>
            <div class="row">
                <p-table #dt3 [value]="supplierList"
                dataKey="id" [scrollable]="true" [rows]="10"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,20,30,50]" [globalFilterFields]="['name','itemName','supplierName']"
                [paginator]="false" currentPageReportTemplate="Showing
                {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="caption">
                    <span class="p-input-icon-left d-flex align-items-center">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text"
                            (input)="dt3.filterGlobal($event.target.value,'contains')"
                            placeholder="Global Search" [(ngModel)]="filterval" [ngModelOptions]="{standalone: true}" />
                        <p-button type="button" class="ml-2" (click)="reset(dt3)"
                            icon="bx bx-reset"></p-button>
                    </span>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="padding: 0.2rem 1rem!important;">S.No.</th>
                        <th style="padding: 0.2rem 1rem!important;">Part Number</th>
                        <th style="padding: 0.2rem 1rem!important;">Supplier Name</th>
                        <th style="padding: 0.2rem 1rem!important;" *ngIf="roleName == 'Admin'">Remove Supplier</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer let-i="rowIndex">
                    <tr>
                        <td>{{i+1}}</td>
                        <td><span>{{customer.itemName}}</span></td>
                        <td><span>{{customer.supplierName}}</span></td>
                        <td *ngIf="roleName == 'Admin'"><button pButton pRipple type="button" icon="bx bx-trash" class="p-button-rounded p-button-danger p-button-text" (click)="confirm1(customer)"></button></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No item found.</td>
                    </tr>
                </ng-template>
            </p-table>
            </div>
        </div>
        <!-- End -->
    </form>



    <app-copyrights></app-copyrights>

</div>


<div class="engg-drawings">
    <p-dialog header="Part : {{itemName}}" [style]="{'width' : '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [modal]="true" [(visible)]="displayDialog">
        <ng-container *ngFor="let drawing of editUploadedDrawing; let index = index;">
           <span style="font-size: 14px;">{{index+1}}. </span> 
           <a [href]="drawing.engineeringDrawing" style="font-size: 14px;" target="_blank" [download]="drawing.engineeringDrawing">{{_utility.getDrawingName(drawing.engineeringDrawing)}}</a>
           <br>
        </ng-container>
    </p-dialog>
</div>

<div class="engg-drawings">
    <p-dialog header="Other Drawing" [style]="{'width' : '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [modal]="true" [(visible)]="displayOtherDialog">   
        <ng-container *ngFor="let drawing of otherDocumentList; let index = index;">
            <span style="font-size: 14px;">{{index+1}}. </span> 
            <a [href]="drawing.value" style="font-size: 14px;" target="_blank" [download]="drawing.value">{{_utility.getDrawingName(drawing.value)}}</a>
            <br>
         </ng-container>
    </p-dialog>
</div>