import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-customer-raise-request',
  templateUrl: './customer-raise-request.component.html',
  styleUrls: ['./customer-raise-request.component.scss']
})

export class CustomerRaiseRequestComponent implements OnInit {
  supplierId: any;

  constructor(private _apiService: ApiServiceService, private router : Router , private activate: ActivatedRoute, private route: Router, public _utility: AppUtility) { }
  errorMessage: string = ''
  errorMessageCheck: string = ''
  userArray: any;
  invalidEmail: boolean = false;
  submitButton: string = 'Submit'
  imageUploadShowHide: boolean = false;

  //formGroup
  userRegisterFormControl = new FormGroup({
    changeRequest: new FormControl('', [Validators.required])
  })


  ngOnInit(): void {
    this.supplierId = this._utility.getLocalStorageDetails().supplierId;
    this.getChangeRequestDetails();
  }

  supplierList : any;

  breadcrumb = [
    {
      title: 'Raise Request',
      subTitle: 'Dashboard'
    }
  ]


  ChangeRequest: any = [];
  async getChangeRequestDetails() {
    this._utility.loader(true);

    let object = {
      Cond3 : this.supplierId,
      Mode : 'CustomerCRChangeList'
    }

    let object1 = {
      loginId : this._utility.getLocalStorageDetails()?.loginId,
      roleName : 'Customer'
    }

    await this._apiService.profile(object1).then(async (resp:any)=>{
      console.log(resp);
      if(resp.success){
        if(resp.returnValue[0].isApproved != 'Rework' && resp.returnValue[0].isApproved != 'Reworked but not Reviewed'){
          await this._apiService.dropdowndata('' , object).then((res: any) => {
            if(res.success){
              if(res.success){
                this.ChangeRequest = res.returnValue;
              }
            }
          })
        }

        else{
          this.showChangeList = 'Rework'
        }
      }
    })

    if(this.ChangeRequest.length > 0){
      if(this.ChangeRequest[0].approved){
        this.showChangeList = 'Applied';
      }
      else{
        this.showChangeList = 'List';
      }
    }


    this._utility.loader(false);
  }

  showChangeList : string = 'List';
  submitRequest() {
    console.log(this.selectedRequest);
    if(this.selectedRequest.length > 0){
      let supplierId = this._utility.getLocalStorageDetails()?.supplierId;
      this.selectedRequest.forEach((res:any)=>{
        res.supplierId = supplierId,
        res.CRChangeId = res.id,
        res.name = res.value,
        res.customerId = this.selectedRequest[0].supplierId
      })
      let object : any = {};
      object['raiseRequestDetails'] = this.selectedRequest;
      object['customerId'] = this.selectedRequest[0].supplierId;
      this._apiService.customerRaiseRequest(object).then((res:any)=>{
        if(res.success){
          this._apiService.showMessage(res.message , 'success');
          this.ChangeRequest = [];
          this.getChangeRequestDetails();
          this.router.navigateByUrl('/customer-edit-form')
        }

        else{
          this._apiService.showMessage(res.message , 'error');
        }
      })
    }

    else{
      this._apiService.showMessage('Please Select Atleast one Request' , 'error');
    }
  }

  selectedRequest: any = [];
  request(event, data, index) {
    if (event.target.checked) {
      this.selectedRequest.push(data);
    }

    else {
      let i = this.selectedRequest.indexOf(data);
      this.selectedRequest.splice(i, 1);
    }


    console.log(this.selectedRequest);
  }
}
