import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, Message } from 'primeng/api';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-dashboard-wallet',
  templateUrl: './dashboard-wallet.component.html',
  styleUrls: ['./dashboard-wallet.component.scss']
})
export class DashboardWalletComponent implements OnInit {
  @ViewChild('uploadSupplierDoc') uploadSupplierDoc: ElementRef;
  supplierId: any;
  tax_dropdown: any = [];
  supplierEnquiryData: any = [];
  assignSupplierData: any = [];
  costBreakupData: any = [];
  getBreakupData: any = [];
  display: boolean = false;
  roleName: string = ''
  disableRemark: boolean = false;
  isRead: boolean = false;
  extraInfo: any = { taxId: 0 };
  displayOtherDialog: boolean;
  otherDocumentList: any;
  filterval: string;
  dateFilterVal: string;
  msgs: Message[] = [];
  userObject : any ;
  constructor(private _apiService: ApiServiceService, private confirmService : ConfirmationService , private router: Router, public _utility: AppUtility , private sanitizer : DomSanitizer) { }

  ngOnInit(): void {
    let local_id = localStorage.getItem('UserObject');
    if (local_id) {
      this.supplierId = JSON.parse(local_id).supplierId;
      this.userObject = JSON.parse(local_id);
      this.roleName = JSON.parse(local_id).roleName;
      console.log(this.supplierId , this.roleName);
    }
    this.tax();
    this.getAssignQuotation();
    // this.getDashboardValue()
  }


  breadcrumb = [
    {
      title: 'Supplier Dashboard',
      subTitle: 'Dashboard'
    },
    {
      title: 'RFQ Conversation Dashboard',
      subTitle: 'Dashboard'
    }
  ]

  loginName : string = '';
  getAssignQuotation() {
    let roleName = this._utility.getLocalStorageDetails();
    this.disableRemark = roleName.roleName == 'Supplier' ? true : false;
    this.roleName = roleName.roleName;
    this.loginName = roleName.loginName;
    let object = {};
    if (roleName.roleName == 'Supplier') {
      object = {
        supplierId: this.supplierId,
        roleName: this.roleName,
        loginId: roleName.loginId,
      }
    }

    else if (roleName.roleName != 'Supplier') {
      object = {
        loginId: roleName.loginId,
        roleName: this.roleName,
        supplierId : 0
      }
    }

    this._apiService.getAssignedSupplierById(object).then((res: any) => {
      if (res.success == true) {
        if (res.success) {
          this.supplierEnquiryData = res.returnValue;
          console.log(res);
        }
        else {
          this.supplierEnquiryData = [];
        }
      }
    })
  }

  enquiryNo: any = 0;
  supplierName: string = ''
  isClose: boolean = false;


  async getDashboardValue() {
    this._utility.loader(true);
    let object = {
      roleName: this.roleName
    }
    await this._apiService.getAssignedSupplierByIdWithEnquiryNo(object)
      .then((res: any) => {
        this._utility.loader(false);
        console.log(res.returnValue);
        if (res.success == true) {
          this.assignSupplierData = res.returnValue;
        }
        else {
          this.assignSupplierData = [];
        }
      })

      .catch((error: any) => {
        this._utility.loader(false);
        this.errorMsg = error.message,
          this.errorMsgCheck = "error"
        this._apiService.showMessage(this.errorMsg, this.errorMsgCheck);
      })

  }

  enquiryId : any;
  costBreakupdata(string:any) {
    let validData : any = [];
    let raiseVerify = '';
    let raiseApproved = '';
    this.getBreakupData = [];
    if(string == 'submit'){
      validData = this.costBreakupData.filter((res: any) => res.amount == 0)
    }

    else if(string == 'update'){
      validData = this.costBreakupData.filter((res: any) => ((res.newAmount == 0 && res.costFlag)));
      console.log(validData);
    }

    else if(string == 'Verified'){
      raiseVerify = 'Verified';
      raiseApproved = 'Approved'
    }
    else if(string == 'Rejected'){
      raiseVerify = 'Rejected';
      raiseApproved = 'Rejected'
    }

    if (validData.length == 0 && (string == 'update' || string == 'Verified' || string == 'Rejected')) {
      this.displayChangeScreen = false;
      this.costBreakupData?.forEach((res: any) => {
        if(res.costFlag){
          this.getBreakupData.push({
            costBreakupId: res?.costBreakupId,
            enquiryId: res?.enquiryId,
            newAmount : JSON.parse((res?.newAmount * 1).toString()),
            costRaiseRequest : res.costFlag,
            amount: res?.amount > 0 ? JSON.parse((res?.amount * 1).toString()) : 0,
            supplierId: this.supplierId,
            ItemId: this.itemId,
            checkerRemark: res?.checkerRemark,
            remark: res?.remark,
            enquiryCostBreakupId : res?.enquiryCostBreakupId,
            enquiryItemId : res?.enquiryItemId,
            supplierRFQReplyId: res?.supplierRFQReplyId,
            isAddTotal: res?.isActive,
            raiseApproved : raiseApproved,
            raiseRemark : res?.raiseRemark,
            raiseVerify : raiseVerify,
          })
        }
      });
      return 1;
    }
    else if (validData.length == 0 && string == 'submit') {
      this.costBreakupData?.forEach((res: any) => {
          this.getBreakupData.push({
            costBreakupId: res?.costBreakupId,
            enquiryId: res?.enquiryId,
            newAmount : JSON.parse((res?.newAmount * 1).toString()),
            costRaiseRequest : res.costFlag,
            amount: res?.amount > 0 ? JSON.parse((res?.amount * 1).toString()) : res?.amount,
            supplierId: this.supplierId,
            ItemId: this.itemId,
            checkerRemark: res?.checkerRemark,
            remark: res?.remark,
            enquiryCostBreakupId : res?.enquiryCostBreakupId,
            enquiryItemId : res?.enquiryItemId,
            supplierRFQReplyId: res?.supplierRFQReplyId,
            isAddTotal: res?.isActive,
            raiseApproved : raiseApproved,
            raiseRemark : res?.raiseRemark,
            raiseVerify : raiseVerify,
          })
      });
      return 1;
    }

    else {
      this._apiService.showMessage('Please fill required costbreakup details', 'error');
      return 0;
    }



  }

  errorMsg: string = ''
  errorMsgCheck: string = ''
  submitCostData(string:any) {
    let validCostBreakup : any
    let object = {};
    let formData = new FormData();
    if(string == 'submit'){
      validCostBreakup = this.costBreakupdata('submit');
    }
    else if(string == 'update'){
      validCostBreakup = this.costBreakupdata('update')
    }
    if (validCostBreakup == 1 && (string == 'submit' || string == 'update')) {
      if (this.selectedImages) {
        formData.append('document', this.selectedImages);
      }
      object['supplierId'] = this.getBreakupData[0]?.supplierId;
      object['ItemId'] = this.getBreakupData[0]?.ItemId;
      object['enquiryId'] = this.getBreakupData[0]?.enquiryId;
      object['supplierItemId'] = this.supplierItemId;
      object['deliveryNotes'] = this.extraInfo.deliveryNotes;
      object['paymentTerms'] = this.extraInfo.paymentTerms;
      object['taxId'] = this.extraInfo.taxId;
      object['hsnName'] = this.extraInfo.hsnName;
      object['materialGridId'] = this.extraInfo.materialGridId;
      object['materialFormId'] = this.extraInfo.materialFormId;
      object['vmc'] = this.extraInfo.vmc;
      object['cnc'] = this.extraInfo.cnc;
      object['hmc'] = this.extraInfo.hmc;
      object['lathe'] = this.extraInfo.lathe;
      object['ratePerkg'] = this.extraInfo.ratePerkg;
      object['roleName'] = this.roleName,
      object['enquiryNo'] = this.allData?.enquiryNo,
      object['loginName'] = this.allData?.loginName,
      object['itemName'] = this.allData?.itemName,
      object['userName'] = this.partDetails.userName,
      object['supplierName'] = this.partDetails.status,
      object['emailAddress'] = this.partDetails.emailAddress

      if (this.extraInfo.otherRemark) {
        object['otherRemark'] = this.extraInfo.otherRemark
      }

      console.log(object); 

      let finaltool : any = [];
      let finalCoating : any = [];
      let error : string = 'false';
      let error1 : string = 'false';
      if(this.toolData){
        this.toolData.forEach((res:any)=>{
          if(error == 'false'){
            if(res.toolLoanAgreementName || res.toolLoanAgreementAmount){
              if(res.toolLoanAgreementAmount && res.toolLoanAgreementName){
                  finaltool.push(res);
              }
              else{
                error = 'true';
              }
            }
          }

          else{
            console.log(error);
            this._apiService.showMessage('Please Fill Tool Details Properly' , 'error')
          }
        })
      }


      if(this.coatingDetails){
        this.coatingDetails.forEach((res:any)=>{
          if(error1 == 'false'){
            if(res.coating || res.rate){
              if(res.rate && res.coating){
                  finalCoating.push(res);
              }
              else{
                error1 = 'true';
              }
            }
          }

          else{
            console.log(error);
            this._apiService.showMessage('Please Fill Coating Details Properly' , 'error')
          }

        })
      }

      if(error1 == 'false' && error == 'false'){
        object['assignSupplierListDetails'] = this.getBreakupData;
        object['AssignToolLoanAgreementDetails'] = this.toolData;
        object['coatingDetails'] = this.coatingDetails;
        formData.append("jsonData", JSON.stringify(object));
        
        if (this.extraInfo['paymentTerms'] != '' && this.extraInfo['deliveryNotes'] != ''  && this.extraInfo['taxId'] != '' && this.extraInfo['materialGrid'] != '' && this.extraInfo['materialForm'] != '' && this.extraInfo['ratePerkg'] != '') {
          this._utility.loader(true);
          this.display = false;
          if(string == 'submit'){
            this.display  = false;
            this._apiService.postSupplierRFQReply(formData).then((res: any) => {
              this._utility.loader(false);
              this.display = false;
              if (res.success == true) {
                this.errorMsg = res.message,
                this.errorMsgCheck = "success"
                this._apiService.showMessage(this.errorMsg, this.errorMsgCheck);
                this.getAssignQuotation();
                this.extraInfo = {};
              }
    
              else {
                this.errorMsg = res.message,
                this.errorMsgCheck = "error"
                this.display  = false;
                this._apiService.showMessage(this.errorMsg, this.errorMsgCheck);
                this.getAssignQuotation();
              }
            })
    
            .catch((error: any) => {
              this._utility.loader(false);
              this.errorMsg = error.message,
              this.errorMsgCheck = "error"
              this._apiService.showMessage(this.errorMsg, this.errorMsgCheck);
              this.getAssignQuotation();
              this.display  = false;
            })
          }
  
          else{
            object['taxId'] = JSON.stringify(object['taxId'])
            this._apiService.postSupplierRFQChangeReply(object).then((res: any) => {
              this._utility.loader(false);
              this.displayChangeScreen = false;
              if (res.success == true) {
                this.errorMsg = res.message,
                this.errorMsgCheck = "success"
                this._apiService.showMessage(this.errorMsg, this.errorMsgCheck);
                this.getAssignQuotation();
                this.extraInfo = {};
              }
    
              else {
                this.errorMsg = res.message,
                this.errorMsgCheck = "error"
                this._apiService.showMessage(this.errorMsg, this.errorMsgCheck);
                this.getAssignQuotation();
              }
            })
    
            .catch((error: any) => {
              this._utility.loader(false);
              this.errorMsg = error.message,
              this.errorMsgCheck = "error"
              this._apiService.showMessage(this.errorMsg, this.errorMsgCheck);
              this.getAssignQuotation();
            })
          }
        }
  
        else {
          this._apiService.showMessage('Please fill required payment terms or delivery details or tax or material form or material grid or ratePerkg', 'error');
        }
      }

      else{
        this._apiService.showMessage('Please Fill Tool Details or Coating Details Properly' , 'error')
      }

    }

    if(string == 'Verified'){
      validCostBreakup = this.costBreakupdata('Verified')
      this._utility.loader(true);
      if(validCostBreakup){
        this.displayChangeScreen = false;
        object['assignSupplierListDetails'] = this.getBreakupData;
        object['raiseVerify'] = 'Verified'
        object['raiseApproved'] = 'Approved',
        object['roleName'] = this.roleName,
        object['enquiryNo'] = this.allData?.enquiryNo,
        object['loginName'] = this.allData?.loginName,
        object['itemName'] = this.allData?.itemName,
        object['userName'] = this.partDetails.userName,
        object['supplierName'] = this.partDetails.status,
        object['emailAddress'] = this.partDetails.emailAddress
      }
    }
    else if(string == 'Rejected'){
      validCostBreakup = this.costBreakupdata('Rejected');
      this._utility.loader(true);
      if(validCostBreakup){
        this.displayChangeScreen = false;
        object['assignSupplierListDetails'] = this.getBreakupData;
        object['raiseVerify'] = 'Rejected'
        object['raiseApproved'] = 'Rejected',
        object['roleName'] = this.roleName,
        object['enquiryNo'] = this.allData?.enquiryNo,
        object['loginName'] = this.allData?.loginName,
        object['itemName'] = this.allData?.itemName,
        object['userName'] = this.partDetails.userName,
        object['supplierName'] = this.partDetails.status,
        object['emailAddress'] = this.partDetails.emailAddress
      }
    }

    if(string == 'Verified' || string == 'Rejected' && validCostBreakup){
      this._apiService.RFQVerify(object).then((res:any)=>{
        console.log(res);
        this.displayChangeScreen = false;
        if(res.success){
          this._utility.loader(false);
          this._apiService.showMessage(res.message , 'success');
          this.getAssignQuotation();
        }

        else{
          this._utility.loader(false);
          this._apiService.showMessage(res.message , 'error');
          this.getAssignQuotation();
        }
      })
    }
  }


  partDetails : any = {};
  async getCurrency(itemDetail){
    console.log(itemDetail);
    let data = {
      Mode : 'EnquiryItemDetail',
      supplierId : itemDetail.supplierId,
      itemId : itemDetail.itemId,
      cond3 : itemDetail.enquiryId
    }
    await this._apiService.dropdowndata('' , data).then((res:any)=>{
      if(res.success){
        this.partDetails = res.returnValue[0];
        console.log(this.partDetails.cond2);
      }
      
    })
  }

  itemId: string = ''
  header: string = ''
  supplierItemId: any;
  documentPath: any;
  isLock : boolean = false;
  // currency : string = '';
  rfqTotal : any = 0;
  rfqCoatingTotal : any = 0;
  rfqToolTotal : any = 0;
  coatingDetails : any = [];
  submitEnable : boolean = false;
  async openModel1(itemDetail: any) {
    this.itemId = itemDetail.itemId;
    this.submitEnable =  itemDetail.loginName == this.loginName ? true : false;
    this.rfqTotal = 0;
    this.rfqToolTotal = 0;
    this.rfqCoatingTotal = 0;
    this.supplierId = itemDetail.supplierId;
    this.getItemDetails();
    this.header = 'Part Name : ' + itemDetail.itemName;
    let object = {
      itemId: itemDetail.itemId,
      supplierId: itemDetail.supplierId,
      enquiryNo: itemDetail.enquiryNo,
      roleName: itemDetail.roleName
    }

    this.getCurrency(itemDetail);
    await this._apiService.getAssignedSupplierQuotationCostBreakup(object).then((res: any) => {
      if(res.returnValue.assignToolLoanAgreementDetails.length > 0){
        this.toolData = res.returnValue.assignToolLoanAgreementDetails;
      }

      else if(res.returnValue.assignToolLoanAgreementDetails.length == 0){
        this.toolData = [{toolLoanAgreementName : '' , toolLoanAgreementAmount : 0 , toolLoanAgreementId : 0 , toolLoanAgreementRemark : '' , toolLoanAgreementCheckerRemark : ''}]
      }

      if(res.returnValue.coatingDetails.length > 0){
        this.coatingDetails = res.returnValue.coatingDetails;
      }

      else if(res.returnValue.coatingDetails.length == 0){
        this.coatingDetails = [{coating : '' , rate : 0 }]
      }
      this.supplierItemId = res.returnValue.supplierItemId;
      this.isLock = res.returnValue.isLock;
      this.isClose = res.returnValue.isClose;
      this.documentPath = res.returnValue.documentUpload;
      this.allData = res.returnValue;
      this.extraInfo['paymentTerms'] = res.returnValue.paymentTerms;
      this.extraInfo['deliveryNotes'] = res.returnValue.deliveryNotes;
      this.extraInfo['otherRemark'] = res.returnValue.otherRemark;
      this.extraInfo['taxId'] = res.returnValue.taxId;
      this.extraInfo['hsnName'] = res.returnValue.hsnName;
      this.extraInfo['quantity'] = res.returnValue.quantity;
      this.extraInfo['materialGridId'] = res.returnValue.materialGridId;
      this.extraInfo['materialFormId'] = res.returnValue.materialFormId;
      this.extraInfo['vmc'] = res.returnValue.vmc;
      this.extraInfo['cnc'] = res.returnValue.cnc;
      this.extraInfo['hmc'] = res.returnValue.hmc;
      this.extraInfo['lathe'] = res.returnValue.lathe;
      this.extraInfo['ratePerkg'] = res.returnValue.ratePerkg;
      this.costBreakupData = res.returnValue.assignCostBreakupDetails;
    })

    this.allData?.assignCostBreakupDetails.forEach((response:any)=>{
      if(response.isActive){
        this.rfqTotal = response.amount + this.rfqTotal;
      }
    })

    this.allData?.assignToolLoanAgreementDetails.forEach((response:any)=>{
      this.rfqToolTotal = response.toolLoanAgreementAmount + this.rfqToolTotal;
    })

    this.allData?.coatingDetails.forEach((response:any)=>{
      this.rfqCoatingTotal = response.rate + this.rfqCoatingTotal;
    })


    console.log(this.rfqTotal , this.allData,this.rfqToolTotal);
    this.display = true;
  }

  allData : any;
  selectedImages: any = [];
  getMultipleImages(event: any) {
    this.selectedImages = this._utility.onFileChange(event);
    console.log(this.selectedImages);
  }
  
  materialGradeDropdown : any = [];
  materialFormDropdown : any = [];
  async tax() {
    await this._apiService.dropdowndata('tax').then((res: any) => {
      if(res.success){
        this.tax_dropdown = res.returnValue;
      }
    });
    await this._apiService.getMaterialForm().then((res: any) => {
      if(res.success){
        this.materialFormDropdown = res.returnValue;
      }
    });
    await this._apiService.getMaterialGride().then((res: any) => {
      if(res.success){
        this.materialGradeDropdown = res.returnValue;
      }
    });
  }

  toolData: any = [{toolLoanAgreementName : '' , toolLoanAgreementAmount : 0 , toolLoanAgreementId : 0 , toolLoanAgreementRemark : '' , toolLoanAgreementCheckerRemark : ''}];
  itemDetails: any = [];
  coatingRequire : boolean = false;
  machiningRequire : boolean = false;
  displayItem: boolean = false;
  async getItemDetails() {
    ;
    this.machiningRequire = false;
    this.coatingRequire = false;
    this.itemDetails = [];
    await this._apiService.getItemMasterById(this.itemId)
    .then((res: any) => {
      console.log(res);
      if (res.success) {
        this.itemDetails.push(res.returnValue);
        this.displayItem = true;
      }
    })

    .catch((err)=>{
      console.log(err);
    })


    console.log(this.itemDetails);
    this.itemDetails.forEach((res:any)=>{
        if(res.machining){
          this.machiningRequire = true;
        }

        if(res.coating){
          this.coatingRequire = true;
        }
    })

    console.log(this.itemDetails , this.machiningRequire , this.coatingRequire);
  }


  editUploadedDrawing : any;
  displayDrawing : boolean = false;
  async showImagePreview(itemId: any , string:any) {
    console.log(itemId);
    this._utility.loader(true);
    if(string == 'engg'){
      await this._apiService.getEnggDrawing(itemId).then((res: any) => {
        this._utility.loader(false);
        if (res.success) {
          this.editUploadedDrawing = res.returnValue;
          this.displayDrawing = true;
        }
  
        else {
          this.editUploadedDrawing = [];
          this._apiService.showMessage('Engineering Drawing not Found' , 'error');
        }
      })
    }

    else{
      // let enquiryId = this.requestForQuotationForm.get('enquiryId')?.value;
      let object = {
        Mode : 'DrawingDetails',
        Cond3 :  this.costBreakupData[0]?.enquiryId,
        itemId : itemId
      }
      this._apiService.dropdowndata('' , object).then((res:any)=>{
        console.log(res);
        this._utility.loader(false);
        if(res.success){
          this.displayOtherDialog = true;
          this.otherDocumentList = res.returnValue;
        }
  
        else{
          this.otherDocumentList = [];
          this._apiService.showMessage('Other Document not Found' , 'error');
        }
      })
    }
    

    console.log(this.editUploadedDrawing);
  }

  addNewRow(string:any,string1:any , index : any){
    // ;
    if(string1 == 'ToolData'){
      if(string == 'add'){
        this.toolData.push({toolLoanAgreementName : '' , toolLoanAgreementAmount : 0 , toolLoanAgreementId : 0 , toolLoanAgreementRemark : '' , toolLoanAgreementCheckerRemark : ''})
      }
  
      else{
        if(this.toolData.length > 1){
          this.toolData.splice(index , 1);
        }
      }
    }

    else{
      if(string == 'add'){
        this.coatingDetails.push({coating : '' , rate : 0 })
      }
  
      else{
        if(this.coatingDetails.length > 1){
          this.coatingDetails.splice(index , 1);
        }
      }
    }
  }

  
  getSafeUrl(file:any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(file);
  }

  reset(dt2) {
    dt2.reset();
    this.filterval = '';
    this.dateFilterVal = ''
  }

  displayChangeScreen : boolean = false;
  downloadExcel(customer){
    this._utility.loader(true);
    let object = {
      ids : customer.enquiryId,
      itemId : customer.itemId,
      supplierId : customer.supplierId
    }

    this._apiService.downloadExcel(object).then((res:any)=>{
      this._utility.loader(false);
      this._utility.exportExcel(JSON.parse(res.ReturnValue.m_StringValue));
    })
  }


  ChangeScreen(customer){
    this.displayChangeScreen = true;
    this.costBreakupData = [];
    this.itemId = customer.itemId;
    this.supplierId = customer.supplierId;
    this.getCurrency(customer);

    // ;
    this.getItemDetails();
    this.header = 'Part Number : ' + customer.itemName;
    let object = {
      itemId: customer.itemId,
      supplierId: customer.supplierId,
      enquiryNo: customer.enquiryNo,
      roleName: this.roleName
    }
    this._apiService.getAssignedSupplierQuotationCostBreakup(object).then((res: any) => {
      console.log(res);
      this.isClose = res.returnValue.isClose;
      if(res.returnValue.assignToolLoanAgreementDetails.length > 0){
        this.toolData = res.returnValue.assignToolLoanAgreementDetails;
      }

      else{
        this.toolData = [{toolLoanAgreementName : '' , toolLoanAgreementAmount : 0 , toolLoanAgreementId : 0 , toolLoanAgreementRemark : '' , toolLoanAgreementCheckerRemark : ''}]
      }
      this.supplierItemId = res.returnValue.supplierItemId;
      console.log('supplierItemId', this.supplierItemId);
      this.documentPath = res.returnValue.documentUpload;
      this.allData = res.returnValue;
      this.extraInfo['paymentTerms'] = res.returnValue.paymentTerms;
      this.extraInfo['deliveryNotes'] = res.returnValue.deliveryNotes;
      this.extraInfo['otherRemark'] = res.returnValue.otherRemark;
      this.extraInfo['taxId'] = res.returnValue.taxId;
      this.extraInfo['hsnName'] = res.returnValue.hsnName;
      this.extraInfo['quantity'] = res.returnValue.quantity;
      res.returnValue.assignCostBreakupDetails.forEach((res:any)=>{
        res.costFlag = res.costRaiseRequest;
        if(res.costRaiseRequest){
          this.costDisabled = true;
        }
        this.costBreakupData.push(res);
      })

      console.log(this.costBreakupData);
    })
  }


  costDisabled : boolean = false;
  confirm1(customer: any , string) {
    if(this.userObject.checker1 || this.userObject.checker2 || this.userObject.roleName == 'Admin'){
      this.confirmService.confirm({
       message: 'Are you sure that you want to proceed?',
        header: string +''+ ' Enquiry',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.lockEnquiry(customer);
          this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
        },
        reject: () => {
          this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
        }
      }); 
    }
  }


  lockEnquiry(customer){
    let supplier : any = [];
    supplier.push({supplierId : customer.supplierId , enquiryId : customer.enquiryId , itemId : customer.itemId})
    let object = {
      assignSupplierId : customer.assignSupplierId
    }
    this._apiService.unlockRFQReply(object).then((res:any)=>{
      if(res.success){
        this._apiService.showMessage(res.message , 'success');
        this.getAssignQuotation();
      }
      
      else{
        this._apiService.showMessage(res.message , 'error');
        this.getAssignQuotation();
      }
    })
  }



}