import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';

@Component({
  selector: 'app-customer-change-request-logs',
  templateUrl: './customer-change-request-logs.component.html',
  styleUrls: ['./customer-change-request-logs.component.scss']
})


export class CustomerChangeRequestLogsComponent implements OnInit{
  stateDropdown: any;
  paymentDropdown: any;
  purposeDropdown: any;
  manufacturingArray: any;
  materialConstructionArray: any;
  otherCategoryArray: any;
  currencyDropdown: any;
  userroleName: any;
  approvalStatus: any = [];
  msgs: Message[] = [];
  filterval: string;
  dateFilterVal: string;
  countryDropdown: any;
  customerCategory: any;
  incotermsDropdown: any;
  applicationTypeDropdown: any;
  industryDropdown: any;
  freightCodeDropdown: any;
  regionDropdown: any;
  subRegionDropdown: any;
  companyOwnershipDropdown: any;
  marketSegmentDropdown: any;
  marketSubSegmentDropdown: any;
  customerBuDropdown: any;
  branchPlantDropdown: any;
  userLogin: any;
  paymentTermsList: any;
  constructor(private _utility : AppUtility , private _apiService : ApiServiceService , private confirmationService : ConfirmationService){}
  ngOnInit(){
    this.getState();
    this.userroleName = this._utility.getLocalStorageDetails();
    if (this.userroleName.checker1 == true) {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Verified', value: "Verified" }, { name: 'Rejected', value: "Rejected" })
    }
    else if (this.userroleName.roleName == 'Finance') {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Registered', value: "Registered" })
    }
    else if (this.userroleName.checker2 == true) {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Approved', value: "Approved" }, { name: 'Rejected', value: "Rejected" })
    }
    else {
      this.approvalStatus.push({ name: 'Pending', value: "Pending" }, { name: 'Approved', value: "Approved" }, { name: 'Verified', value: "Verified" })
    }
    this.getAllCustomerDetail();
  }

  customerList : any = [];
  display : boolean = false;
  getAllCustomerDetail() {
    this._utility.loader(true);
    this.customerList = [];
    this._apiService.customerChangesLog().then((res: any) => {
      console.log(res);
      this._utility.loader(false);
      if (res.success == true) {
        this.customerList = res.returnValue;
        // res.returnValue.forEach((resp:any)=>{
        //   this.customerList.push(resp);
        // })
      }
      else {
        this.customerList = [];
      }
      console.log(this.customerList);
      
    })
  }

  breadcrumb = [
    {
      title: 'Customer Change Request Logs',
      subTitle: 'Dashboard'
    }
  ]

  otherCategoryDropdown: any = [];
  getState() {
    this._apiService
      .dropdowndata('country')
      .then((res: any) => {
        if (res.success) {
          this.countryDropdown = res.returnValue;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('currency')
      .then((res: any) => {
        if (res.success) {
          this.currencyDropdown = res.returnValue;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('paymentTerms')
      .then((res: any) => {
        if (res.success) {
          this.paymentDropdown = res.returnValue;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CustomerCategory')
      .then((res: any) => {
        if (res.success) {
          this.customerCategory = res.returnValue;
        } else {
          this.customerCategory = [
            { id: 1, value: 'category1' },
            { id: 2, value: 'category2' },
            { id: 3, value: 'category3' },
            { id: 4, value: 'category4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('Incoterms')
      .then((res: any) => {
        if (res.success) {
          this.incotermsDropdown = res.returnValue;
        } else {
          this.incotermsDropdown = [
            { id: 1, value: 'Incoterms1' },
            { id: 2, value: 'Incoterms2' },
            { id: 3, value: 'Incoterms3' },
            { id: 4, value: 'Incoterms4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('ApplicationType')
      .then((res: any) => {
        if (res.success) {
          this.applicationTypeDropdown = res.returnValue;
        } else {
          this.applicationTypeDropdown = [
            { id: 1, value: 'ApplicationType1' },
            { id: 2, value: 'ApplicationType2' },
            { id: 3, value: 'ApplicationType3' },
            { id: 4, value: 'ApplicationType4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('Industry')
      .then((res: any) => {
        if (res.success) {
          this.industryDropdown = res.returnValue;
        } else {
          this.industryDropdown = [
            { id: 1, value: 'ApplicationType1' },
            { id: 2, value: 'ApplicationType2' },
            { id: 3, value: 'ApplicationType3' },
            { id: 4, value: 'ApplicationType4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('FreightHandlingCode')
      .then((res: any) => {
        if (res.success) {
          this.freightCodeDropdown = res.returnValue;
        } else {
          this.freightCodeDropdown = [
            { id: 1, value: 'freight_code1' },
            { id: 2, value: 'freight_code2' },
            { id: 3, value: 'freight_code3' },
            { id: 4, value: 'freight_code4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('Region')
      .then((res: any) => {
        if (res.success) {
          this.regionDropdown = res.returnValue;
        } else {
          this.regionDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('SubRegion')
      .then((res: any) => {
        if (res.success) {
          this.subRegionDropdown = res.returnValue;
        } else {
          this.subRegionDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CompanyOwnership')
      .then((res: any) => {
        if (res.success) {
          this.companyOwnershipDropdown = res.returnValue;
        } else {
          this.companyOwnershipDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('marketSegment')
      .then((res: any) => {
        if (res.success) {
          this.marketSegmentDropdown = res.returnValue;
        } else {
          this.marketSegmentDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('marketSubSegment')
      .then((res: any) => {
        if (res.success) {
          this.marketSubSegmentDropdown = res.returnValue;
        } else {
          this.marketSubSegmentDropdown = [
            { id: 1, value: 'regionDropdown1' },
            { id: 2, value: 'regionDropdown2' },
            { id: 3, value: 'regionDropdown3' },
            { id: 4, value: 'regionDropdown4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CustomerBUMaster')
      .then((res: any) => {
        if (res.success) {
          this.customerBuDropdown = res.returnValue;
        } else {
          this.customerBuDropdown = [
            { id: 1, value: 'BUMaster1' },
            { id: 2, value: 'BUMaster2' },
            { id: 3, value: 'BUMaster3' },
            { id: 4, value: 'BUMaster4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._apiService
      .dropdowndata('CustomerBussinessUnitNumber')
      .then((res: any) => {
        if (res.success) {
          this.branchPlantDropdown = res.returnValue;
        } else {
          this.branchPlantDropdown = [
            { id: 1, value: 'BussinessUnit1' },
            { id: 2, value: 'BussinessUnit2' },
            { id: 3, value: 'BussinessUnit3' },
            { id: 4, value: 'BussinessUnit4' },
          ];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if(this.userLogin){
      this._apiService.getpaymentTerms()
        .then((res: any) => {
          if (res.success) {
            this.paymentTermsList = res.returnValue;
          } 
          
          else {
            this.paymentTermsList = [
              { id: 1, value: 'regionDropdown1' },
              { id: 2, value: 'regionDropdown2' },
              { id: 3, value: 'regionDropdown3' },
              { id: 4, value: 'regionDropdown4' },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
          this.paymentTermsList = []
        });
    }    
  }

  userCapture: any;
  readonly: boolean = false;
  supplierId : any;
  customerListById : any = {};
  customerJsonData : any = {};
  customerRaiseRequestList : any ;
  RaiseRequestData : any = [];
  certificates: any = [];
  async openModal(customer: any, string: any) {
    console.log(customer);
    this.supplierId = customer.customerId;
    // this.customerListById = JSON.parse(customer.jsonData);
    // this.supplierJsonData = JSON.parse(customer.previousData);
    // this._utility.loader(true);
    await this._apiService.customerChangesLog(this.supplierId).then((res)=>{
      this._utility.loader(false);
      if (res.success == true) {
        this.display = true;
        this.RaiseRequestData = res.returnValue;
        this.customerRaiseRequestList = res.returnValue[0].customerRaiseRequestList1;
        this.customerListById = JSON.parse(res.returnValue[0]?.customerRaiseRequestList)[0];
        this.customerJsonData = JSON.parse(res.returnValue[0]?.jsonData)
      }
    })

    if (this.userroleName.roleName == 'Finance') {
      this.RaiseRequestData.statusCapture = this.RaiseRequestData.raiseApproved == 'Approved' ? 'Pending' : this.RaiseRequestData.raiseApproved;
      this.userCapture = this.RaiseRequestData.raiseApproved == 'Approved' ? false : true;
    }

    else if ((this.userroleName.checker2 == true)) {
      if (this.RaiseRequestData.raiseApproved == 'Pending' || this.RaiseRequestData.raiseApproved == 'Rework' || this.RaiseRequestData.raiseApproved == '') {
        this.RaiseRequestData.statusCapture = this.RaiseRequestData.raiseApproved == '' ? 'Pending' : this.RaiseRequestData.raiseApproved;
        if (this.RaiseRequestData.raiseVerify == 'Pending') {
          this.userCapture = true;
        }

        if(this.RaiseRequestData.raiseApproved == 'Rework'){
          this.remarkBoolean = true;
          this.remarkInput = this.RaiseRequestData.changeRemark;
        }
        
        else {
          this.userCapture = false;
        }
      }
      else {
        this.userCapture = true;
        this.customerListById.statusCapture = 'Approved';
      }
    }

    else if (this.userroleName.checker1 == true) {
      this.customerListById.statusCapture = this.customerListById.raiseVerify;
      this.userCapture = this.customerListById.raiseVerify == 'Pending' || this.customerListById.raiseVerify == 'Rework' ? false : true;
      if(this.customerListById.raiseVerify == 'Rejected'){
        this.remarkBoolean = true;
        this.remarkInput = this.customerListById.raiseRemark;
      }
    }
    else {
      this.customerListById.statusCapture = this.customerListById.raiseApproved == 'Approved' ? this.customerListById.raiseApproved : this.customerListById.raiseApproved;
      this.userCapture = true;
    }
  }

  captureStatus(customerListById: any , string: any) {
   console.log(string);
   if(string == 'Submit'){
    let msg: string = '';
    if (customerListById.statusCapture != 'Pending') {
      // if (confirm("Do you want to approve supplier change request" + msg)) {
        console.log(customerListById , this.customerJsonData);
        let object = this.customerJsonData;
        this._utility.loader(true);
        this.display = false;
        object['userName'] = this.userroleName.userName;
        if (this.userroleName.roleName == 'CS Team') {
          object['raiseVerify'] = customerListById.statusCapture;
          object['raiseRemark'] = this.remarkInput;
          // object['checker1'] = this.userroleName.checker1;
        }
        else if (this.userroleName.roleName == 'Customer-JDE') {
          object['raiseApproved'] = customerListById.statusCapture;
        }
        else if (this.userroleName.roleName == 'Customer-Approval') {
          object['raiseApproved'] = customerListById.statusCapture;
          object['raiseRemark'] = this.remarkInput;
          // object['checker2'] = this.userroleName.checker2;
        }
        let formData = new FormData(); 
        formData.append('jsonData', JSON.stringify(object))
        this._apiService.putRaiseRequest(formData)
        .then((res: any) => {
          this._utility.loader(false);
          console.log(res);
          if (res.success == true) {
            this._apiService.showMessage(res.message, "success");
            this.getAllCustomerDetail();
          }
          else {
            this._apiService.showMessage(res.message, "error");
          }
        })
      // }
    }

    else {
      this.display = false;
    }
   }
  }

  reset(dt2) {
    dt2.reset();
    this.filterval = '';
    this.dateFilterVal = ''
  }

  remarkBoolean : boolean = false;
  remarkInput : string = '';
  showRemark(event:any){
    console.log(event.target.value);
    if(event.target.value == 'Rejected'){
      this.remarkBoolean = true;
    }

    else{
      this.remarkBoolean = false;
    }
  }

  confirm1(customer: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Send for JDE Approval',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.getSelectedSupplier(customer);
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  getSelectedSupplier(customer) {
    console.log(customer , JSON.stringify(this.customerJsonData));
    this.display = false;
    this._utility.loader(true);
    let object: any = {
      raiseApproved : 'Pending',
      supplierId  : customer.supplierId,
      supplierName : customer.supplierName
    };

    
    this._apiService.putRaiseRequestChecker2(object).then((res: any) => {
    this._utility.loader(false);
    if (res.success == true) {
      this._apiService.showMessage(res.message, 'success');
      this.getAllCustomerDetail();
    }
    else {
      this._apiService.showMessage(res.message, 'error');
    }
    })
  }
}

