<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <!-- <div class="send-email-checker2"> -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- </div> -->
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="card">
        <p-table #dt2 [value]="customerList" dataKey="customerId" [rows]="10" responsiveLayout="scroll"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
            [globalFilterFields]="['emailAddress','mobileNumber','addressWithPinCode', 'status' , 'customerName', 'userName', 'csTeam', 'customerCode']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                    'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXLSExport()"
                        class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom" style="margin-left: 8px;"></button>
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th style="text-align: center;">Action</th>
                    <th>CS Team Status</th>
                    <th>Finance Status</th>
                    <th>CS Team</th>
                    <th>Customer Name</th>
                    <th>Customer Code</th>
                    <th>Customer Username</th>
                    <th>Customer Email</th>
                    <th>Customer Address</th>
                    <th *ngIf="userroleName.roleName == 'Customer-Approval'">Upload Document</th>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style="padding: 4px;"><input pInputText type="text" [ngModel]="isVerify" (input)="dt2.filter($event.target.value, 'isVerify', 'contains')"
                            placeholder="Keyword" /></td>
                    <td>
                        <input pInputText type="text" [ngModel]="isApproved" (input)="dt2.filter($event.target.value, 'isApproved', 'contains')"
                            placeholder="Keyword" />
                        </td>
                    <td><input pInputText type="text" [ngModel]="csTeam" (input)="dt2.filter($event.target.value, 'csTeam', 'contains')"
                            placeholder="Keyword" /></td>
                    <td><input pInputText type="text"
                            (input)="dt2.filter($event.target.value, 'customerName', 'contains')"
                            placeholder="Keyword" [ngModel]="customerName" /></td>
                    <td><input pInputText type="text"
                            (input)="dt2.filter($event.target.value, 'customerCode', 'contains')"
                            placeholder="Keyword" [ngModel]="customerCode2"/></td>
                    <td><input pInputText type="text" (input)="dt2.filter($event.target.value, 'userName', 'contains')"
                            placeholder="Keyword" [ngModel]="userName"/></td>
                    <td><input pInputText type="text"
                            (input)="dt2.filter($event.target.value, 'emailAddress', 'contains')"
                            placeholder="Keyword" [ngModel]="emailAddress" /></td>
                    <td></td>
                    <td *ngIf="userroleName.roleName == 'Customer-Approval'"></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr class="p-selectable-row">
                    <td>{{i+1}}</td>
                    <td class="d-flex" style="text-align: center;">
                        <button pButton pRipple type="button" icon="bx bx-show" class="p-button-rounded p-button-primary
                            p-button-text" (click)="openModal(customer , 'show')"></button>
                        <ng-container *ngIf="customer.isApproved == 'Approved' || customer.isApproved == 'Registered'">
                            <button pButton pRipple type="button" icon="bx bx-pencil" class="p-button-rounded p-button-primary
                            p-button-text"
                                *ngIf="userroleName.roleName == 'CS Team' && (!['Rework' , 'Reworked but not Reviewed'].includes(customer.isVerify) && !['Rework' , 'Reworked but not Reviewed'].includes(customer.isVerify))"
                                (click)="openModal(customer , 'edit')" disabled></button>
                        </ng-container>
                        <ng-container *ngIf="userroleName.roleName !== 'Customer-Approval'">
                            <ng-container
                                *ngIf="customer?.isApproved !== 'Approved' && customer.isApproved !== 'Registered'">
                                <button pButton pRipple type="button" icon="bx bx-pencil" class="p-button-rounded p-button-primary
                                    p-button-text" (click)="openModal(customer , 'edit')"></button>
                            </ng-container>
                        </ng-container>

                        <!-- <img style="width: 20px; height: fit-content; cursor: pointer; margin-top: 10px;" pTooltip="You can raise request through the hand icon"  *ngIf="userroleName.roleName == 'CS Team'"
                        src="assets/img/hand.png" routerLink="/customerRaiseChangeRequest/{{customer.customerId}}"> -->
                        <button *ngIf="this.userroleName.roleName == 'Admin' &&
                            customer.isApproved != 'Registered'" pButton pRipple type="button" icon="bx bx-trash"
                            class="p-button-rounded p-button-danger
                            p-button-text" (click)="confirmDelete(customer.customerId)"></button>
                        <button *ngIf="this.userroleName.roleName == 'Admin'" pButton pRipple type="button"
                            icon="bx bx-block" class="p-button-rounded p-button-text" [pTooltip]="customer.isBlock
                            ? 'Blocked' : 'Unblock'" [ngClass]="[!customer.isBlock ? 'p-button-success' :
                            'p-button-danger']" (click)="confirmblock(customer)"></button>
                    </td>
                    <td>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Verified'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-success status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Pending'" label="Pending"
                            disabled class="p-button-rounded status_button pending"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Rework'"
                            [label]="customer.isVerify" class="p-button-rounded p-button-info
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Rejected'"
                            [label]="customer.isVerify" class="p-button-rounded p-button-danger
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Reworked but not Reviewed'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-primary status_button"></button>
                        <span *ngIf="customer.isVerify == ''">-</span>
                    </td>
                    <td>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Approved'"
                            (click)="jdeWindow(customer)" label="Approved for JDE" class="p-button-rounded
                            p-button-success status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Registered'"
                            label="Registered In JDE" class="p-button-rounded
                            registered_button status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Pending'" label="Pending"
                            disabled class="p-button-rounded status_button pending"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Rework'"
                            [label]="customer.isApproved" class="p-button-rounded p-button-info
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Rejected'"
                            [label]="customer.isApproved" class="p-button-rounded p-button-danger
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Reworked but not Reviewed'"
                            [label]="customer.isApproved" class="p-button-rounded
                            p-button-primary status_button"></button>
                        <span *ngIf="customer.isApproved == ''">-</span>
                    </td>
                    <td>
                        <span>{{customer.csTeam ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{customer.customerName}}</span>
                    </td>
                    <td style="text-align: center;">
                        <span>{{customer.customerCode ? customer.customerCode : '-'}}</span>
                    </td>
                    <td style="text-align: center;">
                        <span>{{customer.userName ? customer.userName : '-'}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.emailAddress}}</span>
                    </td>
                    <td>
                        <span class="image-text" *ngIf="customer.micrNo == 'Sold To Details'">{{customer.address1}}
                            {{customer.address2}} {{customer.address3}}</span>
                        <span class="image-text" *ngIf="customer.micrNo == 'Ship To Details'">{{customer.shipAddress1}}
                            {{customer.shipAddress2}} {{customer.shipAddress3}}</span>
                    </td>
                    <td *ngIf="userroleName.roleName == 'Customer-Approval'" style="text-align:center"><button
                            routerLink="/customer-upload-doc" pButton pRipple type="button" icon="bx bx-download"
                            class="p-button-rounded p-button-primary p-button-text"></button>
                    </td>
                </tr>
            </ng-template>
            <!-- <ng-template pTemplate="body" let-customer let-i="rowIndex" *ngIf="userroleName.roleName == 'Customer-JDE'">
                <tr class="p-selectable-row"
                    *ngIf="customer.isVerify == 'Verified' && customer.isApproved == 'Approved' || customer.isApproved == 'Registered'">
                    <td>{{i+1}}</td>
                    <td class="d-flex" style="text-align: center;">
                        <button pButton pRipple type="button" icon="bx bx-show" class="p-button-rounded p-button-primary
                            p-button-text" (click)="openModal(customer , 'show')"></button>
                        <ng-container *ngIf="customer.isApproved == 'Approved' || customer.isApproved == 'Registered'">
                            <button pButton pRipple type="button" icon="bx bx-pencil" class="p-button-rounded p-button-primary
                            p-button-text"
                                *ngIf="userroleName.roleName == 'CS Team' && (!['Rework' , 'Reworked but not Reviewed'].includes(customer.isVerify) && !['Rework' , 'Reworked but not Reviewed'].includes(customer.isVerify))"
                                (click)="openModal(customer , 'edit')" disabled></button>
                        </ng-container>
                        <ng-container
                            *ngIf="customer?.isApproved !== 'Approved' && customer.isApproved !== 'Registered'">
                            <button pButton pRipple type="button" icon="bx bx-pencil" class="p-button-rounded p-button-primary
                                    p-button-text"
                                *ngIf="userroleName.roleName == 'CS Team' && (!['Rework' , 'Reworked but not Reviewed'].includes(customer.isVerify) && !['Rework' , 'Reworked but not Reviewed'].includes(customer.isVerify))"
                                (click)="openModal(customer , 'edit')"></button>
                        </ng-container>
                        <button *ngIf="this.userroleName.roleName == 'Admin' &&
                            customer.isApproved != 'Registered'" pButton pRipple type="button" icon="bx bx-trash"
                            class="p-button-rounded p-button-danger
                            p-button-text" (click)="confirmDelete(customer.customerId)"></button>
                        <button *ngIf="this.userroleName.roleName == 'Admin'" pButton pRipple type="button"
                            icon="bx bx-block" class="p-button-rounded p-button-text" [pTooltip]="customer.isBlock
                            ? 'Blocked' : 'Unblock'" [ngClass]="[!customer.isBlock ? 'p-button-success' :
                            'p-button-danger']" (click)="confirmblock(customer)"></button>
                    </td>
                    <td>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Verified'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-success status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Pending'" label="Pending"
                            disabled class="p-button-rounded status_button pending"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Rework'"
                            [label]="customer.isVerify" class="p-button-rounded p-button-info
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Rejected'"
                            [label]="customer.isVerify" class="p-button-rounded p-button-danger
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isVerify == 'Reworked but not Reviewed'"
                            [label]="customer.isVerify" class="p-button-rounded
                            p-button-primary status_button"></button>
                        <span *ngIf="customer.isVerify == ''">-</span>
                    </td>
                    <td>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Approved'"
                            (click)="jdeWindow(customer)" label="Approved for JDE" class="p-button-rounded
                            p-button-success status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Registered'"
                            label="Registered In JDE" class="p-button-rounded
                            registered_button status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Pending'" label="Pending"
                            disabled class="p-button-rounded status_button pending"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Rework'"
                            [label]="customer.isApproved" class="p-button-rounded p-button-info
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Rejected'"
                            [label]="customer.isApproved" class="p-button-rounded p-button-danger
                            status_button"></button>
                        <button pButton pRipple type="button" *ngIf="customer.isApproved == 'Reworked but not Reviewed'"
                            [label]="customer.isApproved" class="p-button-rounded
                            p-button-primary status_button"></button>
                        <span *ngIf="customer.isApproved == ''">-</span>
                    </td>
                    <td>
                        <span>{{customer.csTeam ?? '-'}}</span>
                    </td>
                    <td>
                        <span>{{customer.customerName}}</span>
                    </td>
                    <td style="text-align: center;">
                        <span>{{customer.customerCode ? customer.customerCode : '-'}}</span>
                    </td>
                    <td style="text-align: center;">
                        <span>{{customer.userName ? customer.userName : '-'}}</span>
                    </td>
                    <td>
                        <span class="image-text">{{customer.emailAddress}}</span>
                    </td>
                    <td>
                        <span class="image-text" *ngIf="customer.micrNo == 'Sold To Details'">{{customer.address1}}
                            {{customer.address2}} {{customer.address3}}</span>
                        <span class="image-text" *ngIf="customer.micrNo == 'Ship To Details'">{{customer.shipAddress1}}
                            {{customer.shipAddress2}} {{customer.shipAddress3}}</span>
                    </td>
                    <td *ngIf="userroleName.roleName == 'Customer-Approval'" style="text-align:center"><button
                            routerLink="/customer-upload-doc" pButton pRipple type="button" icon="bx bx-download"
                            class="p-button-rounded p-button-primary p-button-text"></button>
                    </td>
                </tr>
            </ng-template> -->
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex-grow-1"></div>
    </div>
    <!-- End -->

    <app-copyrights></app-copyrights>

</div>

<p-dialog header="Customer Form" *ngIf="customerDetails" [(visible)]="showModal" [modal]="true"
    [style]="{width: '70vw'}" [draggable]="false" [resizable]="false">
    <div class="container authentication">
        <!-- registerform -->
        <div class="signup-form">
            <!-- supplier registration form -->
            <div class="row">
                <ng-container
                    *ngIf="userroleName.roleName == 'Customer-Approval' || userroleName.roleName == 'CS Team'">
                    <div class="col-lg-3 col-md-12 form-group">
                        <select class="form-control form-select" id="applicable" required [disabled]="userCapture"
                            [(ngModel)]="customerDetails.statusCapture">
                            <ng-container *ngFor="let approvalStatus of approvalStatus">
                                <option [value]='approvalStatus.value'
                                    [disabled]="approvalStatus.value == 'Reworked but not Reviewed' || approvalStatus.value == 'Pending'">
                                    {{approvalStatus.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-lg-3 col-md-12 form-group">
                        <textarea class="form-control" placeholder="Action" [(ngModel)]="supplierRemark"
                            autocomplete="off" required></textarea>
                    </div>
                </ng-container>

                <ng-container *ngIf="userroleName.roleName == 'Finance-JDE' || userroleName.roleName == 'Customer-JDE'">
                    <div class="col-lg-4 form-group">
                        <label for="">JDE Customer Code</label>
                        <input type="text" name="" [(ngModel)]="customerCode" [readonly]="customerDetails.customerCode"
                            placeholder="Enter Customer Code" id="" class="form-control">
                    </div>
                </ng-container>
                <div class="add-listings-btn col-lg-2 col-md-6"
                    *ngIf="!userCapture && userroleName.roleName != 'Admin'">
                    <div class="form-group"
                        [ngClass]="[(userroleName.roleName == 'Customer-JDE' || userroleName.roleName == 'Finance-JDE') ? 'marginTop' : '']">
                        <button type="submit" (click)="submitStatus(customerDetails)" class="submit_btn">Submit</button>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="userroleName.roleName == 'Customer-Approval' || userroleName.roleName == 'CS Team'">
                <div class="section-heading">
                    <h5>Additional Documents</h5>
                </div>
                <form [formGroup]="documentForm" (ngSubmit)="submitForm()">
                    <div>
                        <div formArrayName="documents">
                            <div *ngFor="let docGroup of documents.controls; let i = index" [formGroupName]="i"
                                class="row align-items-end">
                                <div class="col-lg-4 form-group">
                                    <label for="">Document Name</label>
                                    <input class="form-control" type="text" formControlName="documentName"
                                        placeholder="Enter Document Name" autocomplete="off" [disabled]="isDisable" />
                                </div>
                                <div class="col-lg-4 form-group">
                                    <label for="">Other Document</label>
                                    <div>
                                        <input class="form-control" type="file"
                                            accept="image/jpeg,image/gif,image/png,application/pdf"
                                            (change)="uploadDoc($event, i)" autocomplete="off" required
                                            [disabled]="isDisable" />
                                    </div>
                                </div>
                                <div class="col-lg-auto">
                                    <button type="button" class="btn btn-primary btnicons" (click)="addDocument()"
                                        [disabled]="isDisable">+</button>
                                    <button type="button" class="btn btn-danger btnicons ms-2"
                                        (click)="removeDocument(i)"
                                        [disabled]="documents.length === 1 && i === 0">-</button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-primary w-100 w-md-auto"
                                [disabled]="isDisable">Submit</button>
                        </div>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="userroleName.roleName == 'Customer-Approval' || userroleName.roleName == 'CS Team' || userroleName.roleName == 'Customer-JDE'">
                <div *ngIf="attachedDocuments && attachedDocuments.length > 0" class="section-heading">
                    <h5>Attached Documents</h5>
                </div>

                <ng-container>
                    <div>
                        <ng-container *ngFor="let data of attachedDocuments">
                            <div class="row align-items-end">
                                <div class="col-lg-4 col-md-6 form-group">
                                    <label for="">Document Name</label>
                                    <input class="form-control" type="text" disabled [value]="data.documentName">
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 form-group mt-0 text-right text-md-start">
                                    <a [href]="data?.path" target="_blank" style="font-size:13px; font-weight: 600;
                                        color: var(--mainColor);" [download]="data?.path">Document Preview</a>
                                </div>
                            </div>

                        </ng-container>

                    </div>
                </ng-container>
            </ng-container>
            <div class="section-heading"
                *ngIf="customerDetails?.cancelCheque || customerDetails?.gstinUpload || customerDetails?.panUpload">
                <h5>Customer Certificates</h5>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 form-group" *ngIf="customerDetails?.cancelCheque">
                    <a [href]="customerDetails?.cancelCheque" target="_blank" style="font-size:13px; font-weight: 600;
                        color: var(--mainColor);" [download]="customerDetails?.cancelCheque">Other Document Preview</a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 form-group" *ngIf="customerDetails.gstinUpload">
                    <a [href]="customerDetails.gstinUpload" target="_blank" style="font-size:13px; font-weight: 600;
                        color: var(--mainColor);" [download]="customerDetails.gstinUpload">GST Document Preview</a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 form-group" *ngIf="customerDetails?.panUpload">
                    <a [href]="customerDetails?.panUpload" target="_blank" style="font-size:13px; font-weight: 600;
                        color: var(--mainColor);" [download]="customerDetails.panUpload">PAN Document Preview</a>
                </div>
            </div>

            <div class="section-heading">
                <h5>CS Team Details</h5>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Branch Plant</label>
                    <span class="form-control" *ngIf="!customerDetails?.branchPlant"></span>
                    <ng-container *ngFor="let branch of branchPlantDropdown">
                        <input *ngIf="branch.id == customerDetails?.branchPlant" class="form-control" type="text"
                            disabled placeholder="Branch Plant" [value]="branch.value">
                    </ng-container>
                </div>

                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">IDEX Bussiness Unit</label>
                    <span class="form-control" *ngIf="!customerDetails?.businessUnit"></span>
                    <ng-container *ngFor="let branch of customerBuDropdown">
                        <input *ngIf="branch.id == customerDetails.businessUnit" class="form-control" type="text"
                            disabled placeholder="Bussiness Unit" [value]="branch.value">
                    </ng-container>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Credit Limit</label>
                    <input type="text" class="form-control" [disabled]="readonly"
                        [(ngModel)]="customerDetails.creditLimit" placeholder="Enter Credit Limit">
                </div>
                <!-- <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Suggest Bussiness Unit</label>
                    <input type="text" class="form-control" [disabled]="readonly"
                        [(ngModel)]="customerDetails.bussinessUnit" placeholder="Enter Bussiness Unit">
                </div> -->
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Customer Category</label>
                    <select class="form-control form-select" placeholder="Enter Customer Category"
                        [(ngModel)]="customerDetails.customerCategory" required>
                        <option disabled value="">Select</option>
                        <ng-container *ngFor="let category of customerCategory">
                            <option [value]=category.id>{{category.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Incoterms</label>
                    <select class="form-control form-select" [(ngModel)]="customerDetails.incotermsId" required>
                        <option disabled value="0">Select Incoterms</option>
                        <ng-container *ngFor="let incoterm of incotermsDropdown">
                            <option [value]=incoterm.id>{{incoterm.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Employee Code</label>
                    <input type="text" class="form-control" [disabled]="readonly"
                        [(ngModel)]="customerDetails.employeeCode" placeholder="Enter Credit Limit">
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Customer Mode</label>
                    <input type="text" class="form-control" [disabled]="readonly"
                        [(ngModel)]="customerDetails.supplierMode" placeholder="Enter Customer Mode">
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Payment Terms Code</label>
                    <input type="text" class="form-control" [disabled]="readonly"
                        [(ngModel)]="customerDetails.paymentTerms" placeholder="Enter Credit Limit">
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="">Payment Terms Description</label>
                    <input type="text" class="form-control" [disabled]="readonly"
                        [(ngModel)]="customerDetails.paymentTermsDesc" placeholder="Enter Credit Limit">
                </div>
            </div>
            <!-- Supplier / Vendor Master - Supplier Master -->
            <div class="section-heading">
                <h5>Customer Master</h5>
            </div>

            <!-- row-1 -->
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.customerCode">
                    <label for="first-name">Customer Code<span class="star_class">*</span></label>
                    <input type="text" [readonly]="readonly" class="form-control" placeholder="Enter Customer Code"
                        [(ngModel)]="customerDetails.customerCode" autocomplete="off" minlength="3" maxlength='35'
                        required />
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="first-name">Company Name<span class="star_class">*</span></label>
                    <input type="text" [readonly]="readonly" class="form-control" placeholder="Enter Supplier Name"
                        [(ngModel)]="customerDetails.customerName" autocomplete="off" minlength="3" maxlength='35'
                        required />
                </div>

                <!-- row-2 -->
                <!-- <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.tinNumber">
                    <label for="">TIN Number</label>
                    <input type="text" class="form-control" [disabled]="readonly"
                        [(ngModel)]="customerDetails.tinNumber" placeholder="Enter TIN Number">
                </div> -->
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.purposeId">
                    <label for="">Customer Type</label>
                    <ng-container *ngFor="let customerType of customerType">
                        <span class="form-control"
                            *ngIf="customerDetails.purposeId == customerType.id">{{customerType.name}}</span>
                    </ng-container>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="email">Whether Domestic or Foreign <span class="star_class">*</span></label>
                    <!-- <span class="form-control">{{customerDetails?.supplierType == 'true' ? 'Foreign' :
                        'Domestic'}}</span> -->
                    <span class="form-control">{{customerDetails?.supplierType }}</span>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="email">Address Type <span class="star_class">*</span></label>
                    <span class="form-control">{{customerDetails?.micrNo}}</span>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.currency">
                    <label for="">Currency</label>
                    <ng-container *ngFor="let currency of currencyDropdown">
                        <ng-container *ngIf="currency.id == customerDetails?.currency">
                            <span class="form-control">{{currency.value}}</span>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="industry">Company</label>
                    <select class="form-control form-select" disabled [(ngModel)]="customerDetails.industryId">
                        <option
                            value="{{customerDetails.industryId == '' || customerDetails.industryId == 0 ? customerDetails.industryId : ''}}"
                            disabled selected>Select Industry</option>
                        <ng-container *ngFor="let industry of industryDropdown">
                            <option [value]="industry.id">{{industry.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.intercompanyCorrectCONo">
                    <label for="city">In case of Intercompany correct Co. No. In
                        GL off</label>
                    <input class="form-control" [readonly]="readonly"
                        [(ngModel)]="customerDetails.intercompanyCorrectCONo" autocomplete="off"
                        placeholder="Enter CO No." />
                </div>
                <!-- row-3 -->
            </div>

            <!-- Sold By -->
            <ng-container *ngIf="customerDetails?.micrNo == 'Sold To Details'">
                <div class="section-heading">
                    <h5>Sold to / Ship to Details</h5>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 1<span class="star_class">*</span></label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.address1" class="form-control" type="text"
                                [readonly]="readonly" required></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 2<span class="star_class">*</span></label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.address2" class="form-control" type="text"
                                [readonly]="readonly" required></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 3<span class="star_class">*</span></label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.address3" class="form-control" type="text"
                                [readonly]="readonly" required></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 4</label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.addressWithPinCode" class="form-control" type="text"
                                [readonly]="readonly"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="country">Country<span class="star_class">*</span></label>
                        <ng-container *ngFor="let value of countryDropdown">
                            <span class="form-control"
                                *ngIf="value.id == customerDetails.countryId">{{value.value}}</span>
                        </ng-container>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.stateId> 0">
                        <label for="state">State<span class="star_class">*</span></label>

                        <ng-container *ngFor="let value of stateDropdown">
                            <span class="form-control"
                                *ngIf="value.id == customerDetails.stateId">{{value.value}}</span>
                        </ng-container>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.otherState">
                        <label for="">State<span class="star_class">*</span></label>
                        <input type="text" class="form-control" [disabled]="readonly"
                            [(ngModel)]="customerDetails.otherState" placeholder="Enter Your State">
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="">City<span class="star_class">*</span></label>
                        <input type="text" class="form-control" [disabled]="readonly" [(ngModel)]="customerDetails.city"
                            placeholder="Enter Your City">
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="">Pincode<span class="star_class">*</span></label>
                        <input type="text" class="form-control" [disabled]="readonly"
                            [(ngModel)]="customerDetails.pincode" placeholder="Enter Your pincode">
                    </div>
                    <div class="col-lg-3 col-md-12 form-group" *ngIf="customerDetails.supplierType == 'true'">
                        <label for="email">If Foreign give Currency
                            <span class="star_class">*</span></label>
                        <input class="form-control" [readonly]="readonly" [(ngModel)]="customerDetails.currency"
                            id="currency" placeholder="Enter Currency Type" />
                    </div>
                </div>
            </ng-container>



            <!-- Ship to Details -->
            <ng-container *ngIf="['Ship To Details' , 'Sold To Details'].includes(customerDetails?.micrNo)">
                <div class="section-heading">
                    <h5>Ship to Details</h5>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 1<span class="star_class">*</span></label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.shipAddress1" class="form-control" type="text"
                                [readonly]="readonly" required></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 2<span class="star_class">*</span></label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.shipAddress2" class="form-control" type="text"
                                [readonly]="readonly" required></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 3<span class="star_class">*</span></label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.shipAddress3" class="form-control" type="text"
                                [readonly]="readonly" required></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="address">Address 4</label>
                        <div class="d-flex">
                            <textarea name="" id="" cols="1" rows="1" placeholder="Enter Your Address"
                                [(ngModel)]="customerDetails.ShipAddress4" class="form-control" type="text"
                                [readonly]="readonly" required></textarea>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="country">Country<span class="star_class">*</span></label>
                        <ng-container *ngFor="let value of countryDropdown">
                            <span class="form-control"
                                *ngIf="value.id == customerDetails.shipCountryId">{{value.value}}</span>
                        </ng-container>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails?.shipCountryId == 1">
                        <label for="state">State<span class="star_class">*</span></label>

                        <ng-container *ngFor="let value of stateDropdown">
                            <span class="form-control"
                                *ngIf="value.id == customerDetails.shipStateId">{{value.value}}</span>
                        </ng-container>
                    </div>
                    <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails?.shipCountryId != 1">
                        <label for="">State<span class="star_class">*</span></label>
                        <input type="text" class="form-control" [disabled]="readonly"
                            [(ngModel)]="customerDetails.shipOtherState" placeholder="Enter Your State">
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="">City<span class="star_class">*</span></label>
                        <input type="text" class="form-control" [disabled]="readonly"
                            [(ngModel)]="customerDetails.shipCity" placeholder="Enter Your City">
                    </div>
                    <div class="col-lg-4 col-md-12 form-group">
                        <label for="">Pincode<span class="star_class">*</span></label>
                        <input type="text" class="form-control" [disabled]="readonly"
                            [(ngModel)]="customerDetails.shipPincode" placeholder="Enter Your pincode">
                    </div>
                </div>
            </ng-container>

            <!-- Tax Registration Details -->


            <div class="section-heading">
                <h5>Tax Registration Details</h5>
            </div>

            <!-- row-5 -->
            <div class="row" *ngIf="customerDetails.supplierType != 'foreign'">
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="city">GSTIN<span class="star_class">*</span></label>
                    <input class="form-control" [readonly]="readonly" [(ngModel)]="customerDetails.gstin_no"
                        placeholder="Enter GSTIN" minlength="15" maxlength="15" autocomplete="off" required />
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.gstin_Date">
                    <label for="city">GSTIN Date<span class="star_class">*</span></label>
                    <span class="form-control">{{customerDetails.gstin_Date | date}}</span>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails.panNumber">
                    <label for="city">PAN Card<span class="star_class">*</span></label>
                    <input class="form-control" type="text" [readonly]="readonly"
                        [(ngModel)]="customerDetails.panNumber" minlength="10" maxlength="10" required
                        autocomplete="off" />
                </div>
            </div>

            <!-- row-6 -->
            <div class="row">
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="city">TAN Number</label>
                    <input class="form-control" [readonly]="readonly" [(ngModel)]="customerDetails.tanNumber"
                        placeholder="Enter TAN Number" minlength="10" maxlength="10" autocomplete="off" />
                </div>
                <div class="col-lg-4 col-md-12 form-group">
                    <label for="city">CIN/LLP Number</label>
                    <input class="form-control" [readonly]="readonly" [(ngModel)]="customerDetails.vanNumber"
                        placeholder="Enter CIN/LLP Number" autocomplete="off" />
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails?.supplierType == 'foreign'">
                    <label for="city">Other No</label>
                    <input class="form-control" [readonly]="readonly" [(ngModel)]="customerDetails.foreignNo"
                        placeholder="Enter Other No" autocomplete="off" />
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails?.gstinUpload">
                    <label for="city">GSTIN Upload<span class="star_class">*</span></label>
                    <span class="form-control"><a href="{{customerDetails?.gstinUpload}}" target="_blank"
                            download="{{customerDetails?.gstinDocument}}">Preview</a></span>
                </div>
                <div class="col-lg-4 col-md-12 form-group" *ngIf="customerDetails?.panUpload">
                    <label for="city">PAN Upload<span class="star_class">*</span></label>
                    <span class="form-control"><a href="{{customerDetails?.panUpload}}" target="_blank"
                            download="{{customerDetails?.panDocument}}">Preview</a></span>
                </div>
            </div>

            <!-- Vendor Contact Details -->

            <div class="section-heading">
                <h5>Customer Contact Details</h5>
            </div>

            <!-- row-7 -->
            <div class="row" *ngFor="let contact of customerDetails?.customerContactDetails">
                <div class="col-lg-3 form-group">
                    <label for="contact">Contact Person<span class="star_class">*</span></label>
                    <input class="form-control" [readonly]="readonly" placeholder="Enter
                        Contact Person" [(ngModel)]="contact.contactPerson" autocomplete="off" required />
                </div>
                <div class="col-lg-3 form-group">
                    <label for="tel">Contact Number<span class="star_class">*</span></label>
                    <input class="form-control" maxlength="10" [(ngModel)]="contact.contactNumber" maxlength="10"
                        placeholder="Enter Contact Number" autocomplete="off" [readonly]="readonly" required />
                </div>
                <div class="col-lg-3 form-group">
                    <label for="tel">Alternate Contact Number</label>
                    <input class="form-control" maxlength="10" [(ngModel)]="contact.alternetNumber" maxlength="10"
                        placeholder="Enter Contact Number" autocomplete="off" [readonly]="readonly" required />
                </div>
                <div class="col-lg-3 form-group">
                    <label for="email">Email Address<span class="star_class">*</span></label>
                    <span class="form-control">{{contact?.emailAddress}}</span>
                </div>
                <!-- <div class="col-lg-4 form-group">
                    <label for="email">Alternate Email Address</label>
                    <input class="form-control" type="text"
                        [disabled]="readonly"
                        [(ngModel)]="customerDetails.alternateEmail"
                        placeholder="Enter Email Address"
                        autocomplete="off" required/>
                </div> -->
            </div>

            <div class="section-heading">
                <h5>Additional Details</h5>
            </div>

            <div class="row">
                <div class="col-lg-4 form-group">
                    <label for="">Document Name</label>
                    <input class="form-control" type="text" readonly [value]="customerDetails?.tinNumber">
                </div>
                <div class="col-lg-4 form-group">
                    <label for="">Other Document</label>
                    <div *ngIf="customerDetails.cancelCheque">
                        <span class="form-control"><a [href]="customerDetails.cancelCheque" target="_blank"
                                [download]="customerDetails.cancelCheque">Preview</a></span>
                    </div>
                    <div *ngIf="!customerDetails.cancelCheque">
                        <span class="form-control">-</span>
                    </div>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="applicationType">Application Type</label>
                    <select class="form-control form-select" disabled [(ngModel)]="customerDetails.applicationTypeId">
                        <option
                            value="{{customerDetails.applicationTypeId == '' || customerDetails.applicationTypeId == 0 ? customerDetails.applicationTypeId : ''}}"
                            disabled selected>Select Application Type</option>
                        <ng-container *ngFor="let application of applicationTypeDropdown">
                            <option [value]="application.id">{{application.value}}</option>
                        </ng-container>
                    </select>
                </div>
               
                <div class="col-lg-4 form-group">
                    <label for="freightHandlingCodeId">Freight Handling Code</label>
                    <select class="form-control form-select" disabled
                        [(ngModel)]="customerDetails.freightHandlingCodeId">
                        <option value=0 disabled selected>Select Freight Handling Code</option>
                        <ng-container *ngFor="let freight of freightCodeDropdown">
                            <option [value]="freight.id">{{freight.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="marketSegmentId">Market Segment</label>
                    <select class="form-control form-select" disabled [(ngModel)]="customerDetails.marketSegmentId">
                        <option value="0" disabled selected>Select Market Segment</option>
                        <ng-container *ngFor="let industry of marketSegmentDropdown">
                            <option [value]="industry.id">{{industry.value}}</option>
                        </ng-container>
                    </select>
                </div>

                <div class="col-lg-4 form-group">
                    <label for="marketSubSegment">Market Subsegment</label>
                    <select class="form-control form-select" disabled [(ngModel)]="customerDetails.marketSubsegmentId">
                        <option value="0" disabled selected>Select Market Subsegment</option>
                        <ng-container *ngFor="let industry of marketSubSegmentDropdown">
                            <option [value]="industry.id">{{industry.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="freight_handling_code">Region </label>
                    <select class="form-control form-select" disabled [(ngModel)]="customerDetails.regionId">
                        <option value="0" disabled selected>Select Region </option>
                        <ng-container *ngFor="let region of regionDropdown">
                            <option [value]="region.id">{{region.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="SubRegion">Sub Region</label>
                    <select class="form-control form-select" disabled [(ngModel)]="customerDetails.subRegionId">
                        <option value="0" disabled selected>Select Sub Region </option>
                        <ng-container *ngFor="let subRegion of subRegionDropdown">
                            <option [value]="subRegion.id">{{subRegion.value}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="CompanyOwnership">Company Ownership</label>
                    <select class="form-control form-select" disabled [(ngModel)]="customerDetails.companyOwnershipId">
                        <option value="0" disabled selected>Select Company Ownership </option>
                        <ng-container *ngFor="let companyOwnership of companyOwnershipDropdown">
                            <option [value]="companyOwnership.id">{{companyOwnership.value}}</option>
                        </ng-container>
                    </select>
                </div>

                <div class="col-lg-4 form-group">
                    <label for="CarrierNumber">Carrier Number</label>
                    <input type="text" class="form-control" disabled placeholder="Enter Carrier Number"
                        [(ngModel)]="customerDetails.carrierNumber" />
                </div>
                <!-- <div class="col-lg-4 form-group">
                        <label for="CarrierNumber">Category Code</label>
                        <input type="text" class="form-control" disabled placeholder="Enter Category Code" [(ngModel)]="customerDetails.categoryCode" />
                    </div> -->

                <div class="col-lg-4 form-group">
                    <label for="">Remark</label>
                    <textarea class="form-control" disabled placeholder="Enter Remark" cols="30" rows="10"
                        [(ngModel)]="customerDetails.bankAddress"></textarea>
                    <div class="invalid-feedback">Please Enter Remark</div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Customer Unblock Remark" [(visible)]="displayRemarkDialog" [modal]="true" [style]="{width: '30vw'}"
    [draggable]="false" [resizable]="false">
    <div class="row">
        <div class="col-lg-12 form-group">
            <input class="form-control" type="text" [(ngModel)]="unblockRemark" placeholder="Enter Remark for Unblock">
        </div>
        <div class="col-lg-12">
            <div class="add-listings-btn">
                <button class="submit_btn" (click)="blockCustomer()">Submit</button>
            </div>
        </div>
    </div>
</p-dialog>